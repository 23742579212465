import * as React from 'react';
import { useData } from 'fieldFactory/offline/XMany';
import useViewConfig from 'util/hooks/useViewConfig';
import { Button, Divider, IconButton } from '@material-ui/core';
import { Field, touch, untouch } from 'redux-form';
import Add from '@material-ui/icons/Add';
import WrappedSimpleForm from 'components/generics/genericCreate/SimpleForm2';
import deepEql from 'deep-eql';
import Clear from '@material-ui/icons/Clear';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import { RootState, useAppSelector, useAppStore } from 'reducers/rootReducer';
import formTypeContext from 'components/generics/form/formTypeContext';
import { useInlineBackrefsToParent } from 'fieldFactory/display/components/RefmanyMultiselect/util/useInlineBackrefsToParent';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';
import { useBackrefProperties } from 'components/generics/form/EntityFormContext/util/createBackrefSelector';
import { getAccessLevelForEntityField } from 'components/generics/utils/viewConfigUtils';
import { useFetchRowData } from './Datagrid';
import { usePubSubKey } from './syncValuesInMultipleWidgetsOnTheSameDataField/usePubSub';
import { stringify } from 'querystring';

const useCallWhenSubmitSucceeds = (meta, cb: () => void) => {
    const submitState = useAppSelector((state: RootState) => state.entityFormSubmissionState[meta.form]);
    const prevSubmitState = React.useRef(submitState);
    React.useEffect(() => {
        const shouldCall = prevSubmitState.current === 'submitting' && submitState === 'submitted';
        prevSubmitState.current = submitState;
        if (shouldCall) {
            cb();
        }
    }, [cb, submitState]);
};

interface InlineDatagridProps {
    source: string;
    id: string; // coming from props.record.id
    rootEntityType: string;
    resource: string;
    filter?: string;
    overrideViewName?: string;
    referencedByField?: string;
    showCreate?: boolean;
    disabled?: boolean;
    useCreateView?: boolean;
    createViewName?: string;
    renderNoData?: () => JSX.Element;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
}
interface FieldProps {
    input: {
        onChange: (value: {}[]) => void;
        value?: {}[];
    };
    meta?: {
        submitFailed?: boolean;
        form?: string;
    };
}
interface InlineDatagridFieldProps extends InlineDatagridProps, FieldProps {}

const combineIntoFormValue = (dataRows: any[], addedRows: { _key: string; __touched?: boolean }[]) => {
    const value = [...dataRows.map(({ id }) => ({ id })), ...addedRows.map(({ _key, __touched, ...rest }) => rest)];
    return value;
};

const useBackrefIfNoParent = (referencedByField: string) => {
    const fc = useCurrentFormContext();
    const evaledBackref = useBackrefProperties(fc['initialValues'] ?? null);
    const pathBack = React.useMemo(() => {
        if (evaledBackref && referencedByField) {
            const pathToAddToExistingBackref = referencedByField.endsWith('.id')
                ? referencedByField.slice(0, -3)
                : (() => {
                      console.error('unexpected referencedByField: ' + referencedByField);
                      return null;
                  })();
            if (pathToAddToExistingBackref) {
                return {
                    ...evaledBackref,
                    path: [pathToAddToExistingBackref, evaledBackref.path].join('.'),
                };
            }
        }
        return null;
    }, [referencedByField, evaledBackref]);
    return pathBack;
};

interface InlineCreateListCoreProps {
    source: string;
    resource: string;
    disabled?: boolean;
    createViewName?: string;
    renderNoData?: () => JSX.Element;
    accessLevel: number;
    rows?: {
        id: string;
        [key: string]: unknown;
    }[];
    backrefs?: {
        parentId: string;
        parentEntityName: string;
        parentFieldInChild: string;
    };
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
}

function InlineCreateListCore({
    source,
    resource,
    input: { value, onChange },
    disabled,
    renderNoData,
    createViewName,
    evaluatedAdhocSPELVariables,
    meta,
    accessLevel,
    rows,
    backrefs,
}: InlineCreateListCoreProps & FieldProps) {
    const viewConfig = useViewConfig();
    // TODO: support createViewName
    const inlineCreateViewName = createViewName || viewConfig.entities[resource]?.defaultViews?.CREATE?.name;

    const initialValue = React.useMemo(() => {
        if (Array.isArray(value)) {
            return value.filter((e) => !e['id']).map((e) => ({ ...e, _key: uuidv4() }));
        }
        return [];
    }, [value]);
    const savedInitialValue = React.useMemo(() => {
        return initialValue;
    }, []); // eslint-disable-line

    /**
     * Syncs values across InlineMany components which are all subscribed to the same data.
     */
    const [additionalRows, setAdditionalRows] = usePubSubKey<{ _key: string; __touched?: boolean }[]>(
        [meta.form, resource, source].join(':'),
        initialValue,
    );

    const resetAdditionalRows = React.useCallback(() => {
        setAdditionalRows([]);
    }, [setAdditionalRows]);

    useCallWhenSubmitSucceeds(meta, resetAdditionalRows);

    const initialExpectedOuterValue = React.useMemo(() => {
        const value = combineIntoFormValue(rows, additionalRows);
        return value;
    }, []); // eslint-disable-line
    const expectedOuterValue = React.useRef<any[]>(initialExpectedOuterValue);

    React.useEffect(() => {
        const newValue = combineIntoFormValue(rows, additionalRows);
        if (isEqual(new Set(newValue), new Set(expectedOuterValue.current))) {
            return;
        }
        expectedOuterValue.current = newValue;
        onChange(newValue);
    }, [rows, additionalRows, onChange]);

    const submitFailed = meta?.submitFailed;
    const store = useAppStore();

    const someRowTouched = additionalRows?.some((r) => r?.['__touched']);
    React.useEffect(() => {
        if (someRowTouched) {
            store.dispatch(touch(meta.form, source));
        } else {
            store.dispatch(untouch(meta.form, source));
        }
    }, [someRowTouched, store, meta.form, source]);

    // to set 'initial data' correctly if we hide, and re-show the component
    const initialDataSearches = React.useMemo(() => savedInitialValue.map((iv) => stringify(iv)), [savedInitialValue]);
    const additionalRowElements = React.useMemo(() => {
        return (
            <ul style={{ listStyle: 'none', marginLeft: 0, paddingLeft: 0 }}>
                {additionalRows.map((r, i) => {
                    return (
                        <li key={r._key} style={{ marginTop: '1em' }}>
                            {/* Final-form embedded create form. */}
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }}>
                                        <formTypeContext.Provider value="CREATE">
                                            <WrappedSimpleForm
                                                evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                                                parentEntityIdValue={backrefs?.parentId}
                                                parentEntityName={backrefs?.parentEntityName}
                                                parentField={backrefs?.parentFieldInChild}
                                                outerSubmitFailed={submitFailed}
                                                viewName={inlineCreateViewName}
                                                search={initialDataSearches[i]}
                                                // we don't provide initial values.
                                                formId={(source + ':tempadd:' + r._key).split('.').join('_~_')}
                                                // can we just subscribe here, somehow?
                                                save={(data) => {}}
                                                subscribe={(_values) => {
                                                    const values = { ..._values, _key: r._key };

                                                    if (deepEql(values, additionalRows[i])) {
                                                        return;
                                                    }
                                                    setAdditionalRows([
                                                        ...(i > 0 ? additionalRows.slice(0, i) : []),
                                                        values,
                                                        ...(i !== additionalRows.length - 1
                                                            ? additionalRows.slice(i + 1)
                                                            : []),
                                                    ]);
                                                }}
                                                toolbar={<div />}
                                            />
                                        </formTypeContext.Provider>
                                    </div>
                                    <div>
                                        <IconButton
                                            onClick={() => {
                                                setAdditionalRows([
                                                    ...additionalRows.slice(0, i),
                                                    ...additionalRows.slice(i + 1),
                                                ]);
                                            }}
                                            aria-label={'Delete new row ' + (i + 1)}
                                            size="small"
                                        >
                                            <Clear color="error" />
                                        </IconButton>
                                    </div>
                                </div>
                                <Divider />
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }, [
        additionalRows,
        source,
        inlineCreateViewName,
        submitFailed,
        backrefs,
        evaluatedAdhocSPELVariables,
        setAdditionalRows,
        initialDataSearches,
    ]);

    return (
        <div>
            {!additionalRows?.length && renderNoData?.()}
            {!disabled && Boolean(additionalRows?.length) && additionalRowElements}
            {disabled || accessLevel <= 2 ? null : (
                <Button
                    color="primary"
                    endIcon={<Add />}
                    onClick={() => {
                        setAdditionalRows([...additionalRows, { _key: uuidv4() }]);
                    }}
                >
                    Add Row
                </Button>
            )}
        </div>
    );
}

function InlineCreateList({
    id,
    source,
    rootEntityType,
    resource,
    filter,
    input,
    disabled,
    renderNoData,
    createViewName,
    referencedByField,
    meta,
    evaluatedAdhocSPELVariables,
}: InlineDatagridFieldProps) {
    const backrefsIfId = useInlineBackrefsToParent({
        parentEntityName: rootEntityType,
        parentId: id,
        endWith: '.id',
        linkedEntityFormat: 'linked<entityType>',
        source,
    });

    const backrefsIfNoId = useBackrefIfNoParent(referencedByField);
    const backrefs =
        backrefsIfId ??
        (() => {
            // even if we don't know our current record's id, it might be because we are in a CREATE view.
            // in that case, we can check the secret value in our initialValues which passes that data along from any possible ancestor views
            if (!backrefsIfNoId) return null;
            const { entityType, path, id } = backrefsIfNoId;
            return {
                parentEntityName: entityType,
                parentFieldInChild: path,
                parentId: id,
            };
        })();

    const data = useData(rootEntityType, source, id, filter);

    const rows = React.useMemo(() => Object.values(data) as any[], [data]);
    // if we 'clear' a set of relationships by setting e.g. 'field': []
    // we still haven't removed the links back to this root record on those individual entities.
    // to make sure 'data' returns what's relevant, we should refetch anything which points to our root record,
    // to ensure that's still the case.
    useFetchRowData(rows, resource);
    const viewConfig = useViewConfig();

    const accessLevel = React.useMemo(() => {
        return getAccessLevelForEntityField(viewConfig, rootEntityType, source, 'TRAVERSE_PATH');
    }, [viewConfig, rootEntityType, source]);

    return (
        <InlineCreateListCore
            evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
            source={source}
            resource={resource}
            input={input}
            disabled={disabled}
            renderNoData={renderNoData}
            createViewName={createViewName}
            meta={meta}
            accessLevel={accessLevel}
            rows={rows}
            backrefs={backrefs}
        />
    );
}

const renderField = (props) => {
    return <InlineCreateList {...props} />;
};
const InlineCreateListField = (props: InlineDatagridProps) => {
    return <Field {...props} name={props.source} component={renderField} />;
};

const CoreField = (props) => {
    const rows = React.useMemo(() => props.meta.initial ?? [], [props.meta.initial]);
    return <InlineCreateListCore {...props} rows={rows} />;
};

export const InlineCreateListCoreField = (props: InlineCreateListCoreProps) => {
    return <Field {...props} name={props.source} component={CoreField} />;
};

export default InlineCreateListField;
