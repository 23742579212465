import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, Button, Typography } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { TaskForm } from 'reducers/taskFormType';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import capitalize from 'lodash/capitalize';
import ErrorsListItems from 'bpm/components/TaskDetail/TaskForm/ErrorsList/ErrorsList';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.main,
        },
    }),
);

interface ErrorPopupProps {
    formErrors: {
        [field: string]: string[];
    } | null;
    formDefinition: TaskForm;
}

const ErrorPopup: React.FC<ErrorPopupProps> = (props) => {
    const { formErrors, formDefinition } = props;
    const classes = useStyles(props);
    const [open, setOpen] = useState(Boolean(formErrors));
    useEffect(() => {
        if (formErrors) {
            setOpen(true);
        }
    }, [formErrors]);
    const { translate } = useEvaluateFormattedMessage();
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
        >
            <div style={{ padding: '.5em', paddingRight: '1em' }}>
                <Typography variant="h5" component="div">
                    {capitalize(translate({ id: 'validate.errors' }))}:
                </Typography>
                <ul>
                    <ErrorsListItems
                        classes={{
                            li: classes.error,
                        }}
                        formErrors={formErrors}
                        fields={formDefinition?.fields}
                    />
                </ul>
            </div>
            <Button style={{ margin: '2px' }} variant="contained" color="primary" onClick={() => setOpen(false)}>
                Close
            </Button>
        </Dialog>
    );
};
export default ErrorPopup;
