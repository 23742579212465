import f from 'f';
import React from 'react';
import { storageController } from 'storage';

const LastLogin = () => {
    const instant = React.useMemo(() => storageController.getPreviousLoginTime(), []);
    const formattedInstant = React.useMemo(() => {
        if (!instant) {
            return null;
        }
        try {
            return f().instant.of(instant)?.format('MM/dd/YYYY h:mm a');
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [instant]);
    return <>{formattedInstant}</>;
};
export default LastLogin;
