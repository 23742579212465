import React, { useMemo, useContext, useCallback } from 'react';
import ViewConfig from 'reducers/ViewConfigType';
import { useFormContext } from 'react-hook-form';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { tryCatch } from 'fp-ts/lib/Option';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import {
    getAttrOfTraversedFieldExprIncludingLinkedX,
    getRefEntity,
    isRefManyField,
    isRefManyManyField,
    isRefOneField,
    isValueSetField,
    isValueSetManyField,
} from 'components/generics/utils/viewConfigUtils';
import { ListboxComponent } from '../../virtualize';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import useViewConfig from 'util/hooks/useViewConfig';
import uniq from 'lodash/uniq';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';

interface FieldPathProps {
    defaultValue?: string;
    resource: string;
    depth: number;
    // e.g. occupations.primaryPhone
    allowPropertiesOnManys?: boolean;
    required?: boolean;
    appendId?: boolean | 'both'; // appends 'Id' to ref-1 and valueset-1 fields
    appendIds?: boolean | 'both'; // appends 'Ids' to ref-many, many-many, and valueset-many fields
    allowCalcs?: boolean;
}

const filter = createFilterOptions<string>();

export const getFieldsForDepth = (
    depth: number,
    resource: string,
    viewConfig: ViewConfig,
    appendId: boolean | 'both' = false,
    appendIds: boolean | 'both' = false,
    allowCalcs = true,
): string[] => {
    if (depth === 0) {
        return [];
    }
    const fieldsAtCurrentLevel = Object.values(viewConfig.entities[resource].fields);
    if (depth === 1) {
        return fieldsAtCurrentLevel.map((f) => f.name);
    }
    return fieldsAtCurrentLevel
        .flatMap((f): string[] => {
            try {
                const calcType = getAttrOfTraversedFieldExprIncludingLinkedX<'calcType', ViewConfig>(
                    viewConfig,
                    resource,
                    f.name,
                    'calcType',
                );
                if (calcType && !allowCalcs) {
                    return [];
                }
            } catch (e) {
                console.error(e);
            }
            if (
                isRefOneField(viewConfig, resource, f.name, 'TRAVERSE_PATH') ||
                isValueSetField(viewConfig, resource, f.name, 'TRAVERSE_PATH')
            ) {
                const relatedEntity = (() => {
                    if (f.relatedEntity) {
                        return f.relatedEntity;
                    }
                    if (f.valueSet) {
                        // really it would be nice if this was configured in f.relatedEntity
                        // but it's not always the case, so just adding this right now.
                        return 'Concept';
                    }
                    throw new Error('No relatedEntity found on field ' + JSON.stringify(f));
                })();
                const baseFields = [f.name + (appendId !== false ? 'Id' : '')];
                if (appendId === 'both') {
                    baseFields.push(f.name);
                }
                if (f.name === 'verificationStatus') {
                    baseFields.push('verificationStatusCode');
                }
                return [
                    ...baseFields,
                    ...getFieldsForDepth(depth - 1, relatedEntity, viewConfig, appendId, appendIds, allowCalcs).map(
                        (fname) => `${f.name}.${fname}`,
                    ),
                ];
            }
            if (
                appendIds !== false &&
                (isRefManyField(viewConfig, resource, f.name, 'TRAVERSE_PATH') ||
                    isRefManyManyField(viewConfig, resource, f.name, 'TRAVERSE_PATH') ||
                    isValueSetManyField(viewConfig, resource, f.name, 'TRAVERSE_PATH'))
            ) {
                if (appendIds === 'both') {
                    return [f.name, f.name + 'Ids'];
                }
                return [f.name + 'Ids'];
            }
            return [f.name];
        })
        .sort((a, b) => {
            const numOfDotsInA = a.split('.').length - 1;
            const numOfDotsInB = b.split('.').length - 1;
            if (numOfDotsInA < numOfDotsInB) {
                return -1;
            }
            if (numOfDotsInB < numOfDotsInA) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            if (b < a) {
                return 1;
            }
            return 0;
        });
};

const getFieldsExtending = (
    depthMore: number,
    baseResource: string,
    currPath: string,
    viewConfig: ViewConfig,
    allowPropertiesOnManys = true,
    appendId: boolean | 'both' = false,
    appendIds: boolean | 'both' = false,
    allowCalcs = true,
): string[] => {
    try {
        const entityEntry = getRefEntity(viewConfig, baseResource, currPath, true);
        const dataType = getAttrOfTraversedFieldExprIncludingLinkedX<'dataType', ViewConfig>(
            viewConfig,
            baseResource,
            currPath,
            'dataType',
        );
        const calcType = getAttrOfTraversedFieldExprIncludingLinkedX<'calcType', ViewConfig>(
            viewConfig,
            baseResource,
            currPath,
            'calcType',
        );
        if (!allowCalcs && calcType) {
            return [];
        }
        if (
            !allowPropertiesOnManys &&
            (dataType === 'REFMANY' || dataType === 'REFMANYMANY' || dataType === 'REFMANYJOIN')
        ) {
            return [];
        }
        return [
            currPath,
            ...getFieldsForDepth(depthMore, entityEntry.name, viewConfig, appendId, appendIds, allowCalcs).map(
                (e) => currPath + '.' + e,
            ),
        ];
    } catch {
        return [];
    }
};

export const createFieldPathsWithDepthSelector = () =>
    createSelector(
        (state: RootState, props: FieldPathProps) => props.depth,
        (state: RootState, props: FieldPathProps) => props.resource,
        (state: RootState, props: FieldPathProps) => state.viewConfig,
        (state: RootState, props: FieldPathProps) => props.appendId,
        (state: RootState, props: FieldPathProps) => props.appendIds,
        (state: RootState, props: FieldPathProps) => props.allowCalcs,
        getFieldsForDepth,
    );

export const useFilterOptions = (props: {
    resource: string;
    allowPropertiesOnManys?: boolean;
    appendId?: boolean | 'both';
    appendIds?: boolean | 'both';
    allowCalcs?: boolean;
}) => {
    const viewConfig = useViewConfig();

    return useCallback(
        (options: string[], params: FilterOptionsState<string>) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
                if (params.inputValue.includes('linkedEntity') && !options.includes(params.inputValue)) {
                    const res1 = [params.inputValue];
                    return res1;
                }
                // filtered.push(params.inputValue);

                const isValid = tryCatch(() => getRefEntity(viewConfig, props.resource, params.inputValue, true)).fold(
                    false,
                    () => true,
                );
                const lowerCaseInputValue = params.inputValue.toLowerCase();
                const res2 = uniq(
                    [
                        ...filtered,
                        ...getFieldsExtending(
                            3,
                            props.resource,
                            params.inputValue.endsWith('.')
                                ? params.inputValue.slice(0, -1)
                                : params.inputValue.includes('.') && !isValid
                                ? params.inputValue.slice(0, params.inputValue.lastIndexOf('.'))
                                : params.inputValue,
                            viewConfig,
                            props.allowPropertiesOnManys,
                            props.appendId,
                            props.appendIds,
                            props.allowCalcs,
                        ),
                    ].filter((f) => f.toLowerCase().startsWith(lowerCaseInputValue)),
                );
                console.log({
                    res2,
                    allowCalcs: props.allowCalcs,
                });
                return res2;
            }
            return filtered.filter((f) => f.startsWith(params.inputValue));
        },
        [props.resource, props.allowPropertiesOnManys, viewConfig, props.appendId, props.appendIds, props.allowCalcs],
    );
};

export const FieldPath: React.FunctionComponent<FieldPathProps> = (props) => {
    const { register, unregister, setValue } = useFormContext<{
        fieldPath: string;
    }>();
    const fieldPathsWithDepthSelector = useMemo(createFieldPathsWithDepthSelector, []);

    const initialFieldPaths = useAppSelector((state: RootState) => fieldPathsWithDepthSelector(state, props));
    const handleChange = (e, targetName) => {
        setValue(targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    React.useEffect(() => {
        register({ name: 'fieldPath' }, { required: props.required !== false ? 'A fieldPath is required' : undefined });
        return () => {
            unregister('fieldPath');
        };
    }, [props.required]); // eslint-disable-line

    const filterOptions = useFilterOptions({
        resource: props.resource,
        allowPropertiesOnManys: props.allowPropertiesOnManys,
        appendId: props.appendId,
        appendIds: props.appendIds,
        allowCalcs: props.allowCalcs,
    });
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <Autocomplete
            options={initialFieldPaths} // <- here are our options
            defaultValue={props.defaultValue}
            autoHighlight
            onChange={(e, value) => handleChange(value, 'fieldPath')}
            getOptionLabel={(option) => option}
            renderOption={(option) => option}
            // set below for Creatable mode
            selectOnFocus
            // TODO add back the below on Mui upgrade
            // clearOnBlur
            // handleHomeEndKeys
            filterOptions={filterOptions}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    label="Field Path"
                    margin="normal"
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        draggable: false,
                        autoComplete: 'disabled',
                    }}
                />
            )}
        />
    );
};

export default FieldPath;
