import { useMemo } from 'react';
import produce from 'immer';
import { useRemovedFieldsForEntity, useViewFormContext } from '../EnhancedRGridTabbable';

// takes fieldsByTab and removes tabs where all fields are hidden.
const useFieldsByTab = (
    _fieldsByTab: {
        [tab: string]: any[];
    } = {},
    context: 'show' | 'edit',
) => {
    const { getFieldRemoved } = useRemovedFieldsForEntity({ isShow: context === 'show' });
    const fc = useViewFormContext(context);

    const getFieldRemovedByTab = useMemo(() => {
        const res: {
            [tabName: string]: (source: string) => boolean;
        } = {};
        Object.entries(_fieldsByTab).forEach(([tabName, fields]) => {
            res[tabName] = getFieldRemoved(fields.map((f) => f.props.source).filter(Boolean));
        });
        return res;
    }, [_fieldsByTab, getFieldRemoved]);

    const tabsWithAllFieldsHidden = useMemo(() => {
        return Object.entries(_fieldsByTab)
            .filter(([tabName, fields]) => {
                return fields.every((f) => {
                    if (
                        f.props.fieldInstanceIdentifier &&
                        f.props.source?.endsWith('Id') &&
                        fc.hiddenFields[f.props.fieldInstanceIdentifier + 'Id']
                    ) {
                        // special case to accommodate old (bad) way of doing things where we appended
                        // 'Id' to the fieldInstanceIdentifier.
                        return true;
                    }
                    if (
                        f.props.fieldInstanceIdentifier?.endsWith('Id') &&
                        fc.hiddenFields[f.props.fieldInstanceIdentifier.slice(0, 'Id'.length * -1)]
                    ) {
                        // handle mapped component fields like $component.fooId, where the visibility expressions lack the 'Id' part.
                        // See  7b279379d8a7db and FISH-4942
                        return true;
                    }
                    return (
                        fc.hiddenFields[f.props.fieldInstanceIdentifier || f.props.source] ||
                        getFieldRemovedByTab[tabName](f.props.source)
                    );
                });
            })
            .map(([k]) => k);
    }, [fc.hiddenFields, _fieldsByTab, getFieldRemovedByTab]);
    const fieldsByTab = useMemo(() => {
        return produce(_fieldsByTab, (fbt) => {
            tabsWithAllFieldsHidden.forEach((t) => {
                delete fbt[t];
            });
            return fbt;
        });
    }, [_fieldsByTab, JSON.stringify(tabsWithAllFieldsHidden)]); // eslint-disable-line
    return fieldsByTab;
};
export default useFieldsByTab;
