import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import { cancelTaskButton } from './cancel';
import { deleteTaskButton } from './delete';
import { reopenTaskButton } from './reopen';
import Popup from '../../../../components/Popup';
import UserIsSuper from 'components/UserIsSuper';
import {
    CardActions,
    Card,
    Button,
    CardContent,
    IconButton,
    // WithStyles, used to type props
    createStyles,
    Theme,
    withStyles,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Restore from '@material-ui/icons/Restore';
import { processPageRefreshContext } from 'bpm/components/ProcessDetail/processPageRefreshContext';
import useHasMinimalHeader from 'bpm/hooks/useHasMinimalHeader';
import useHideBackToCaseButton from 'bpm/hooks/useHideBackToCaseButton';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import OpenInEditorButton from './OpenInEditorButton';
import DownloadOfflineButton from 'offline_app/offline_stateful_tasks/download/components/DownloadOfflineButton';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import isOffline from 'util/isOffline';
import isTheInstalledApp from 'util/isTheInstalledApp';
import Message from 'i18n/components/Message';

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        title: {
            order: 0,
            fontSize: 22,
            marginTop: spacing(3),
            marginLeft: spacing(3),
            marginBottom: spacing(1),
        },
    });

const TaskHeader = ({
    processId,
    title,
    children,
    taskId,
    task,
    redirect,
    isAdmin,
    classes,
    formDefinition,
    disabled,
    entityFormContextRef,
}) => {
    const hasMinimalHeader = useHasMinimalHeader();
    const hideBackToCaseButton = useHideBackToCaseButton();
    const translatedTitle = useEvaluatedFormattedMessage(title ?? '');
    const processKey = useAppSelector((state: RootState) => state.bpm.processInstances.byId[processId]?.businessKey);
    const printMode = useAppSelector((state: RootState) => state.printMode);
    if (hasMinimalHeader) {
        return <div>{translatedTitle && <h1>{translatedTitle}</h1>}</div>;
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                {translatedTitle && <h1>{translatedTitle}</h1>}
                {!printMode && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {task && !task.endDate && isAdmin && !isOffline() && (
                            <Popup
                                renderToggler={({ openDialog }) => (
                                    <div style={{ order: 2, margin: '.5em' }}>
                                        <Button color="primary" onClick={openDialog()}>
                                            Cancel Task
                                        </Button>
                                    </div>
                                )}
                                renderDialogContent={({ closeDialog }) => (
                                    <Card>
                                        <CardContent>Cancel task?</CardContent>
                                        <CardActions>
                                            <Button onClick={closeDialog}>Close</Button>
                                            {cancelTaskButton(taskId)(() =>
                                                redirect(processId ? `/processes/${processId}/tasks` : '/tasks'),
                                            )}
                                        </CardActions>
                                    </Card>
                                )}
                            />
                        )}
                        {!disabled && !isOffline() && isTheInstalledApp() ? (
                            <div style={{ margin: '.5em' }}>
                                <DownloadOfflineButton
                                    entityFormContextRef={entityFormContextRef}
                                    taskKey={task.key}
                                    processKey={processKey}
                                    taskId={taskId}
                                />
                            </div>
                        ) : null}
                        {!isOffline() && (
                            <UserIsSuper>
                                <div style={{ margin: '.5em' }}>
                                    <OpenInEditorButton
                                        taskId={taskId}
                                        processId={processId}
                                        taskKey={formDefinition?.key}
                                    />
                                </div>
                            </UserIsSuper>
                        )}
                        {!isOffline() && task && task.endDate && (
                            <UserIsSuper>
                                <Popup
                                    renderToggler={({ openDialog }) => (
                                        <div style={{ margin: '.5em' }}>
                                            <IconButton aria-label="reopen" onClick={openDialog()}>
                                                <Restore />
                                            </IconButton>
                                        </div>
                                    )}
                                    renderDialogContent={({ closeDialog }) => (
                                        <Card>
                                            <CardContent>Reopen task?</CardContent>
                                            <CardActions>
                                                <Button onClick={closeDialog}>Close</Button>
                                                <processPageRefreshContext.Consumer>
                                                    {({ refresh }) =>
                                                        reopenTaskButton(taskId)(() =>
                                                            !processId ? redirect('/tasks') : refresh(),
                                                        )
                                                    }
                                                </processPageRefreshContext.Consumer>
                                            </CardActions>
                                        </Card>
                                    )}
                                />
                            </UserIsSuper>
                        )}
                        {!isOffline() && task && task.endDate && isAdmin && (
                            <Popup
                                renderToggler={({ openDialog }) => (
                                    <div style={{ margin: '.5em' }}>
                                        <IconButton aria-label="delete" onClick={openDialog()}>
                                            <Delete />
                                        </IconButton>
                                    </div>
                                )}
                                renderDialogContent={({ closeDialog }) => (
                                    <Card>
                                        <CardContent>Delete task?</CardContent>
                                        <CardActions>
                                            <Button onClick={closeDialog}>Close</Button>
                                            {deleteTaskButton(taskId)(() =>
                                                redirect(processId ? `/processes/${processId}/tasks` : '/tasks'),
                                            )}
                                        </CardActions>
                                    </Card>
                                )}
                            />
                        )}
                        {hideBackToCaseButton || isOffline() ? null : (
                            <div style={{ order: 2, margin: '.5em' }}>
                                <Button
                                    color="primary"
                                    to={processId ? `/processes/${processId}` : '/tasks'}
                                    component={Link}
                                >
                                    <Message id="processes.backToCase" dm="Back to Case" />
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

TaskHeader.defaultProps = {
    processId: null,
    title: '',
    children: undefined,
    formDefinition: null,
};

const enhance = compose(
    connect(null, {
        redirect: push,
    }),
    withStyles(styles),
);

export default enhance(TaskHeader);
