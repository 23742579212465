import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { View } from '../reducers/ViewConfigType';
import { ResourceRouteDescription, ViewComponentType, getViewComponent } from '../util/getResourceListFromConfig';
import GenericUnsplit from 'components/generics/genericUnsplit';

const AdminRoutes: React.ComponentType<{
    customRoutes: React.ReactElement<RouteProps>[];
    nonDefaultResourceViews: View[];
    resources: ResourceRouteDescription[];
    dashboard: any;
}> = ({ customRoutes, nonDefaultResourceViews, resources = [], dashboard }) => {
    const defaultRoutes = resources.reduce(
        (prev, { name, create, edit, show, list, possibleMatchView, specificMatchView }) => {
            const commonProps = {
                resource: name,
                hasList: !!list,
                hasEdit: !!edit,
                hasShow: !!show,
                hasCreate: !!create,
                hasPossibleMatchView: !!possibleMatchView,
                hasSpecificMatchView: !!specificMatchView,
            };
            const withProps =
                (component: ViewComponentType): ViewComponentType =>
                (routeProps) =>
                    React.createElement(component, { ...commonProps, ...routeProps });
            /**
             * The ordering of the <Route /> components really matters.
             * The reason we don't mind that this is a dict is because we are going to sort by route length,
             * and it _just so happens_ that that gets us the correct precedence we want.
             *
             * If we changed /:id to /:somethinglonger
             *
             * it will _ruin_ our routing, because "<resource-name>/create" will never be hit (since "<resource-name>/:somethinglonger" would come first)
             */
            if (list) {
                prev[`/${name}`] = withProps(list);
            }
            if (create) {
                prev[`/${name}/create`] = withProps(create);
            }
            if (edit) {
                prev[`/${name}/:id`] = withProps(edit);
                prev[`/${name}/:id/split`] = (props) => {
                    return <GenericUnsplit entityType={name} id={props.match.params.id} />;
                };
            }
            if (show) {
                prev[`/${name}/:id/show`] = withProps(show);
            }

            if (possibleMatchView) {
                prev[`/${name}/:id/matches`] = withProps(possibleMatchView);
            }
            if (specificMatchView) {
                prev[`/${name}/:id/merge/:id2`] = withProps(specificMatchView);
            }
            return prev;
        },
        {} as {
            [route: string]: ViewComponentType;
        },
    );
    const nonDefaultViews = nonDefaultResourceViews.reduce(
        (prev, v) => {
            if (v.route) {
                const route = v.route.startsWith('/') ? v.route : `/${v.route}`;
                const Component = getViewComponent(v);
                // Below is important: it means we don't overwrite the precedence of the
                if (defaultRoutes[route]) {
                    defaultRoutes[route] = Component;
                } else {
                    prev[route] = Component;
                }
            }
            return prev;
        },
        {} as {
            [route: string]: ViewComponentType;
        },
    );
    return (
        <Switch>
            {customRoutes &&
                customRoutes.map((route, index) => (
                    <Route
                        key={index}
                        exact={route.props.exact}
                        path={route.props.path}
                        component={route.props.component}
                        render={route.props.render}
                        children={route.props.children}
                    />
                ))}
            )
            {Object.entries(nonDefaultViews)
                .sort(([a], [b]) => b.length - a.length) // longest first DO NOT CHANGE
                .map(([route, Component]) => {
                    return <Route path={route} exact={true} key={route} render={Component as any} />;
                })}
            {Object.entries(defaultRoutes)
                .sort(([a], [b]) => b.length - a.length) // longest first DO NOT CHANGE
                .map(([route, Component]) => {
                    return <Route path={route} exact={true} key={route} render={Component as any} />;
                })}
            {dashboard ? (
                <Route path="/" render={() => React.createElement(dashboard)} />
            ) : (
                resources[0] && <Route exact={true} path="/" render={() => <Redirect to={`/${resources[0].name}`} />} />
            )}
        </Switch>
    );
};

export default AdminRoutes;
