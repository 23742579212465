import * as React from 'react';
import createGetInitialFormValues from '../form/getFormInitial/createGetInitialValues2';
import { EditProps } from './EditProps';

export const useGetData = () => {
    const getData = React.useMemo(
        () =>
            createGetInitialFormValues<EditProps>({
                getIdFromProps: ({ id }) => id,
            }),
        [],
    );
    return getData;
};
