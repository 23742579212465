import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import {
    profileSelected,
    profilesLoaded,
    setStartingUserId,
    promptPickProfile,
    closeProfilePrompt,
    ProfileEntry,
} from './actions';

export type ProfileState =
    | {
          state: 'no_profiles';
      }
    | {
          state: 'pick_profile';
          currentUserId?: string;
          profiles: ProfileEntry[];
          initial: boolean;
      }
    | {
          state: 'profile_selected';
          currentUserId: string;
          profiles: ProfileEntry[];
      };

const noProfiles = { state: 'no_profiles' } as const;
const profileReducer = (state: ProfileState = noProfiles, action: RootAction): ProfileState => {
    switch (action.type) {
        case getType(profilesLoaded):
            if (action.payload.profiles.length === 1) {
                return {
                    state: 'profile_selected',
                    currentUserId: action.payload.profiles[0].userId,
                    profiles: action.payload.profiles,
                };
            }
            return {
                state: 'pick_profile',
                currentUserId: action.payload.defaultUserId,
                profiles: action.payload.profiles,
                initial: true,
            };
        case getType(setStartingUserId):
            if (state.state === 'no_profiles') {
                throw new Error('called setStartingUserId when no profiles exist');
            }
            return {
                ...state,
                currentUserId: action.payload.userId,
            };
        case getType(promptPickProfile):
            if (state.state === 'no_profiles') {
                throw new Error('open profile prompt if none are loaded.');
            }
            return {
                ...state,
                state: 'pick_profile',
                initial: false,
            };
        case getType(closeProfilePrompt):
            if (state.state !== 'pick_profile') {
                throw new Error('closeProfilePrompt should only be called if prompt is open');
            }
            if (!state.currentUserId) {
                throw new Error('user should not be able to call closeProfilePrompt if there is no current profile.');
            }
            return {
                ...state,
                currentUserId: state.currentUserId,
                state: 'profile_selected',
            };
        case getType(profileSelected):
            if (state.state === 'no_profiles') {
                throw new Error('cannot select a profile if none are loaded.');
            }
            return {
                state: 'profile_selected',
                profiles: state.profiles,
                currentUserId: action.payload.userId,
            };
        default:
            return state;
    }
};
export default profileReducer;
