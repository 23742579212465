import buildHeaders from 'sideEffect/buildHeaders';
import { v4 as uuidv4 } from 'uuid';
import { ChatResponseWrapper } from 'chatbot/components/ResponseBox';
import { useEffect, useState } from 'react';
import { BACKEND_BASE_URL } from 'config';
import { Box, IconButton, LinearProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { REPORT_BUILDER } from 'chatbot/constants';
import PossibleInputDisplay from './PossibleInputDisplay';
import { useChatBot } from 'chatbot/ChatbotProvider';
import { CurrentReportType } from 'chatbot/chatbotTypes';
import { Close } from '@mui/icons-material';

interface SuggestBody {
    message: {
        assistant: string;
        assistantCategory?: string;
        threadId: string;
        messageId: string;
        parameters: {
            input: string; //query from user,
            customParams?: any;
        };
    };
    config?: {
        llm_model: string;
    };
}
interface AiChatResponseProps {
    payload: { message: string; updateState: (message: any) => void };
    state: { assistant: string; threadId: string };
}

export const postToAiService = async (
    input: string,
    toolName: string,
    additionalParams: Record<string, any> = {},
    threadId: string,
    abortController: AbortSignal,
) => {
    const url = `${BACKEND_BASE_URL}api/admin/compass/suggest`;
    const messageId = uuidv4();
    const body: SuggestBody = {
        message: {
            assistant: toolName,
            threadId: threadId,
            messageId: messageId,
            parameters: {
                input: input,
                ...additionalParams,
            },
        },
    };

    try {
        const request = new Request(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: buildHeaders({
                includeCredentials: true,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(body),
            signal: abortController,
        });

        const response = await fetch(request);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

interface AiResponseType {
    output: string;
    newReport?: { definition: string };
}

const AiChatResponse: React.FC<AiChatResponseProps> = ({ payload, state }) => {
    const [response, setResponse] = useState<AiResponseType | null>(null);

    const { enableInputSendButton, disableInputSendButton, state: chatState, setPrevValue } = useChatBot();
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    useEffect(() => {
        const controller = new AbortController();
        setAbortController(controller);

        if (payload?.message && state.threadId) {
            let additionalParams: CurrentReportType;

            switch (chatState.assistant) {
                case REPORT_BUILDER:
                    additionalParams = chatState.reportParameters;
                    setPrevValue(additionalParams?.currentReport?.definition);
                    break;
                default:
                    break;
            }
            disableInputSendButton();
            postToAiService(payload?.message, chatState.assistant, additionalParams, state.threadId, controller.signal)
                .then((data) => {
                    setResponse(data?.message?.parameters || { output: 'No response' });

                    //lets update the internal chatkit message log
                    payload.updateState({
                        id: data?.message?.id,
                        message: data?.message?.parameters?.output,
                        type: `${chatState.assistant}-response`,
                    });
                })
                .catch((error) => {
                    setResponse({ output: 'Canceled or otherwise failed to fetch response from AI service.' });
                    console.error(`Error fetching data from ${chatState.assistant} :`, error);
                })
                .finally(() => {
                    setAbortController(null);
                    enableInputSendButton();
                });
        } else {
            setResponse({ output: 'I need more information than that.' });
            console.error('Message or threadId are missing.');
        }
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Let's only run this one time per message

    const handleCancel = () => {
        if (abortController) {
            abortController.abort();
            enableInputSendButton();
        }
    };
    const additionalResponseMap = {
        [REPORT_BUILDER]: response?.newReport?.definition,
    };

    const additionalResponse = additionalResponseMap[chatState.assistant] || null;
    return (
        <ChatResponseWrapper>
            {response ? (
                <ReactMarkdown>{response.output}</ReactMarkdown>
            ) : (
                <Box display="flex" alignItems="center" width={'100%'}>
                    <LinearProgress sx={{ minWidth: 200, mr: 1 }} />
                    <IconButton
                        onClick={handleCancel}
                        size="small"
                        aria-label="cancel"
                        sx={(theme) => ({ color: theme.palette.primary.contrastText })}
                    >
                        <Close />
                    </IconButton>
                </Box>
            )}
            {additionalResponse ? <PossibleInputDisplay additionalParams={additionalResponse} /> : null}
        </ChatResponseWrapper>
    );
};

export default AiChatResponse;
