import React, { useCallback, useMemo, useState } from 'react';
import Reorder from 'react-reorder';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import classnames from 'classnames';
import { makeStyles, Theme, createStyles, IconButton, Typography, Button, CardActions } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import Add from '@material-ui/icons/Add';
import EditActionButton from './EditActionButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import DemoActions from './DemoActions';
import Popup from 'components/Popup';
import { fromPredicate } from 'fp-ts/lib/Option';
import Actions from 'components/generics/viewActions/ActionsWithOverrides/Component';
import getActionButtonDisplays from 'viewConfigCalculations/actionButtonDisplayExpressions/getActionButtonDisplays';
import ViewConfig from 'reducers/ViewConfigType';
import useViewConfig from 'util/hooks/useViewConfig';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            display: 'inline-block',
        },
        list: {
            display: 'inline-block',
        },
        reorderableListItem: {
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.grey[300],
            },
        },
    }),
);

interface ReorderableActionsProps {
    renderItem: (item: EntityViewConfig['entityActions'][0]) => JSX.Element;
    entityActionsConfig: EntityViewConfig['entityActions'];
    changeEntityActionsConfig: (entityActionsConfig: EntityViewConfig['entityActions']) => void;
}
const ReorderableActions: React.FunctionComponent<ReorderableActionsProps> = (props) => {
    const { renderItem, entityActionsConfig, changeEntityActionsConfig } = props;
    const classes = useStyles(props);
    const [openForEditIx, setOpenForEditIx] = useState<number | 'newButton'>(-1);
    return (
        <span style={{ width: '100%' }}>
            <Dialog maxWidth={false} fullWidth={true} open={openForEditIx !== -1} onClose={() => setOpenForEditIx(-1)}>
                {openForEditIx !== -1 && (
                    <div style={{ margin: '1em', padding: '1em' }}>
                        <SplitPane split="vertical">
                            <Pane initialSize="50%">
                                <div style={{ marginRight: '1em' }}>
                                    <EditActionButton
                                        initialValues={
                                            openForEditIx === 'newButton'
                                                ? ({} as any)
                                                : props.entityActionsConfig[openForEditIx]
                                        }
                                        onSubmit={(data) => {
                                            if (openForEditIx === 'newButton') {
                                                props.changeEntityActionsConfig([
                                                    ...(props.entityActionsConfig || []),
                                                    data,
                                                ]);
                                            } else {
                                                props.changeEntityActionsConfig([
                                                    ...props.entityActionsConfig.slice(0, openForEditIx),
                                                    data,
                                                    ...props.entityActionsConfig.slice(openForEditIx + 1),
                                                ]);
                                            }
                                            setOpenForEditIx(-1);
                                        }}
                                    />
                                </div>
                            </Pane>
                            <div>
                                <div style={{ textAlign: 'center', margin: '1em' }}>
                                    <Typography variant="h5">Configuration Examples</Typography>
                                </div>
                                <DemoActions />
                            </div>
                        </SplitPane>
                    </div>
                )}
            </Dialog>
            <Reorder
                itemKey="key"
                list={props.entityActionsConfig || []}
                template={(props) => (
                    <span
                        className={classnames(classes.reorderableListItem)}
                        style={{
                            margin: '2px 5px',
                            height: '50px',
                            // height: '100%',
                            verticalAlign: 'middle',
                            display: 'flex',
                            alignItems: 'center',
                            outline: '1px dotted black',
                        }}
                    >
                        <DragIndicatorIcon
                            style={{
                                opacity: 0.5,
                                verticalAlign: 'middle',
                            }}
                        />
                        {renderItem(props.item)}
                        <IconButton
                            color="primary"
                            size="small"
                            onMouseDown={(e) => {
                                e.stopPropagation();
                            }}
                            onClick={(e) => {
                                setOpenForEditIx((entityActionsConfig || []).indexOf(props.item));
                            }}
                        >
                            <Edit />
                        </IconButton>
                        <Popup
                            renderDialogContent={({ closeDialog }) => {
                                return (
                                    <div style={{ padding: '1em' }}>
                                        <p>Delete action button "{props.item.label}"?</p>
                                        <CardActions>
                                            <Button
                                                onMouseDown={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                onClick={closeDialog}
                                            >
                                                Close
                                            </Button>
                                            <Button
                                                onMouseDown={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    const filtered = (entityActionsConfig || []).filter(
                                                        (item) => item.key !== props.item.key,
                                                    );
                                                    changeEntityActionsConfig(filtered);
                                                    closeDialog();
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </CardActions>
                                    </div>
                                );
                            }}
                            renderToggler={({ openDialog }) => {
                                return (
                                    <IconButton
                                        size="small"
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onClick={openDialog()}
                                    >
                                        <Delete color="error" />
                                    </IconButton>
                                );
                            }}
                        />
                    </span>
                )}
                // Function that is called once a reorder has been performed
                callback={(event, itemThatHasBeenMoved, itemsPreviousIndex, itemsNewIndex, reorderedArray) => {
                    props.changeEntityActionsConfig(reorderedArray);
                }}
                listClass={classes.list}
                itemClass={classes.listItem}
                selectedKey="tabKey"
                disableReorder={false}
                disableDragClass="no-drag"
            />
            <IconButton
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
                onClick={(e) => {
                    setOpenForEditIx('newButton');
                }}
            >
                <Add />
            </IconButton>
        </span>
    );
};

interface EditReorderableActionsProps {
    viewName: string;
    recordId?: string;
    configStringRef: React.MutableRefObject<string>;
    type: 'SHOW' | 'EDIT';
    overrideViewConfig?: ViewConfig;
    // call this as a way of saying "configStringRef.current has changed - do what you gotta do"
    onChange?: () => void;
}
export const EditReorderableActions: React.FC<EditReorderableActionsProps> = ({
    viewName,
    recordId,
    configStringRef,
    type,
    overrideViewConfig,
    onChange,
}) => {
    const getActionButtonConfig = useCallback(() => {
        if (!configStringRef.current) {
            return undefined;
        }
        try {
            return JSON.parse(configStringRef.current).entityActions;
        } catch (e) {
            return undefined;
        }
    }, [configStringRef]);

    const _viewConfig = useViewConfig();
    const viewConfig = overrideViewConfig ?? _viewConfig;
    const overriddenActionButtonExp = useMemo(() => {
        return getActionButtonDisplays(
            viewName,
            viewConfig.views[viewName].entity,
            viewConfig,
            fromPredicate<string>(Boolean)(getActionButtonConfig()),
        ).fold(undefined, (entrypoint) => entrypoint[viewName]);
    }, [viewConfig, viewName, getActionButtonConfig]);

    const renderActions = (type: 'SHOW' | 'EDIT') => (
        <div>
            <Actions
                disableDefaultActions
                hasShow={type === 'EDIT'}
                hasDelete={type === 'EDIT'}
                save={type === 'EDIT' ? () => alert('Save intercepted.') : undefined}
                overrideActionButtonExp={overriddenActionButtonExp as any}
                filter={(_item, _i) => false}
                viewName={viewName}
                id={recordId}
            />
            <ReorderableActions
                renderItem={(item) => {
                    return (
                        <Actions
                            overrideActionButtonExp={overriddenActionButtonExp as any}
                            filter={(_item, _i) => _item.key === item.key}
                            viewName={viewName}
                            id={recordId}
                            hasEdit={false}
                            hasShow={false}
                            hasDelete={false}
                        />
                    );
                }}
                changeEntityActionsConfig={(entityActionsConfig) => {
                    const newConfig = (() => {
                        const config = JSON.parse(configStringRef.current);
                        return JSON.stringify({
                            ...config,
                            entityActions: entityActionsConfig,
                        });
                    })();
                    configStringRef.current = newConfig;
                    onChange?.();
                }}
                entityActionsConfig={getActionButtonConfig()}
            />
        </div>
    );
    return renderActions(type);
};

export default ReorderableActions;
