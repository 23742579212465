import React, { forwardRef } from 'react';
import { Box, ButtonBase, ButtonBaseProps, Typography } from '@mui/material';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { useAppSelector } from 'reducers/rootReducer';

export const CenteredIcon: React.FC<{
    children: JSX.Element;
    outerStyle?: React.CSSProperties;
    innerStyle?: React.CSSProperties;
}> = ({ children, outerStyle, innerStyle }) => {
    return (
        <span style={{ position: 'relative', paddingRight: '2rem', ...outerStyle }}>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '1rem',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    ...innerStyle,
                }}
            >
                {children}
            </div>
        </span>
    );
};

export const SortLabel: React.FC<{
    label: React.ReactNode;
    active?: boolean;
    direction?: 'asc' | 'desc';
    ButtonProps?: ButtonBaseProps;
    ref?: React.Ref<HTMLButtonElement>;
}> = forwardRef(({ label, active, direction, ButtonProps }, ref) => {
    const printMode = useAppSelector((state) => state.printMode);
    const nextDirection = !active ? 'asc' : direction;
    return (
        <ButtonBase
            ref={ref}
            sx={(theme) => ({
                position: 'relative',
                '&:hover': {
                    '.label': { opacity: 0.7 },
                    '.hide-arrow': { opacity: 0 },
                    '.highlight-arrow': { color: theme.palette.text.secondary, opacity: 1 },
                },
                '&:focus .label': {
                    color: 'text.secondary',
                },
            })}
            {...ButtonProps}
        >
            <Typography
                fontWeight="bold"
                sx={(theme) => ({
                    fontSize: printMode ? '11px' : theme.typography.fontSize,
                })}
                className="label"
            >
                {label}
            </Typography>
            <CenteredIcon>
                {!printMode &&
                    (!active ? (
                        <Box sx={{ display: 'inline-grid' }}>
                            <ArrowDropUp
                                className={nextDirection === 'desc' ? 'hide-arrow' : 'highlight-arrow'}
                                viewBox="1 -9 24 24"
                                color="disabled"
                            />
                            <ArrowDropDown
                                className={nextDirection === 'asc' ? 'hide-arrow' : 'highlight-arrow'}
                                viewBox="1 9 24 24"
                                color="disabled"
                            />
                        </Box>
                    ) : direction === 'asc' ? (
                        <ArrowDropUp />
                    ) : (
                        <ArrowDropDown />
                    ))}
            </CenteredIcon>
        </ButtonBase>
    );
});
