import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import { getModelPath } from 'components/generics/utils/viewConfigUtils';
import createPathTree, { PathTrie, buildTrie, setNullOnMissingPaths } from './createPathTreeFromGraph';

const emptyObj = {};
const expandMinimalDataFromDataPaths = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityType: string,
    entityId: string,
    entities: any,
    dataPaths: string[],
    pathTrie?: PathTrie, // should be the same as dataPaths. This is just in case we happen to have a pre-built one to pass in
) => {
    const expansions = dataPaths.map((p) => p.split('._ALL_').join(''));
    const exps = expansions
        .map((path) =>
            // filter out *Code, *Codes, *Id, *Ids based on viewConfig check.
            getModelPath(viewConfig, entityType, path),
        )
        .filter(Boolean);
    const _d =
        !entityType || !entityId || !dataPaths.length
            ? emptyObj
            : denormalizeEntitiesByPaths(
                  entities,

                  exps,
                  viewConfig,
                  entityType,
                  entityId,
              );

    const dataPathTrie = pathTrie ?? buildTrie(dataPaths);
    const pt = createPathTree(_d, dataPathTrie);
    const res = setNullOnMissingPaths(pt, dataPathTrie);
    return res;
};
export default expandMinimalDataFromDataPaths;
