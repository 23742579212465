import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import Popup from 'components/Popup';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import React, { useCallback } from 'react';

const EditDefaultValuesExpressions: React.FC<{
    viewDefConfig: EntityViewConfig;
    setViewDefConfig: (viewDefConfig: EntityViewConfig) => void;
    renderForm: (props: {
        initialValues: { expression: string; fieldPath: string };
        onSubmit: (data: { expression: string; fieldPath: string }) => void;
    }) => JSX.Element;
}> = ({ viewDefConfig, setViewDefConfig, renderForm }) => {
    const { defaultValueExpressions } = viewDefConfig;
    const setDefaultValueExpressions = useCallback(
        (defaultValueExpressions: Record<string, string>) => {
            setViewDefConfig({
                ...viewDefConfig,
                defaultValueExpressions,
            });
        },
        [setViewDefConfig, viewDefConfig],
    );

    const getRenderDialogContent =
        (initialValues?: { expression: string; fieldPath: string }) =>
        ({ closeDialog }) => {
            const onSubmit = ({ expression, fieldPath }) => {
                const prevExpressions = defaultValueExpressions ?? {};
                const remainingExpressions = initialValues
                    ? Object.fromEntries(
                          Object.entries(prevExpressions).filter(([key]) => key !== initialValues.fieldPath),
                      )
                    : prevExpressions;
                const newData = {
                    ...remainingExpressions,
                    [fieldPath]: expression.trim(),
                };
                setDefaultValueExpressions(newData);
                closeDialog();
            };
            return (
                <div style={{ margin: '1em', padding: '1em', minWidth: '70vw' }}>
                    <h2>{initialValues ? 'Edit' : 'Add'} Default Value</h2>
                    {renderForm({ onSubmit, initialValues })}
                </div>
            );
        };

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Expression</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(defaultValueExpressions ?? {}).map(([fieldPath, expression]) => {
                        return (
                            <TableRow key={fieldPath}>
                                <TableCell>{fieldPath}</TableCell>
                                <TableCell>{expression}</TableCell>
                                <TableCell>
                                    <Popup
                                        renderDialogContent={getRenderDialogContent({
                                            expression,
                                            fieldPath,
                                        })}
                                        renderToggler={({ openDialog }) => (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                onClick={openDialog()}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    ></Popup>
                                    <span style={{ marginLeft: '1em' }}>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                const newExpressions = Object.fromEntries(
                                                    Object.entries(defaultValueExpressions).filter(
                                                        ([key]) => key !== fieldPath,
                                                    ),
                                                );
                                                setDefaultValueExpressions(newExpressions);
                                            }}
                                        >
                                            <Clear color="error" />
                                        </IconButton>
                                    </span>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <br />
            <Popup
                renderDialogContent={getRenderDialogContent()}
                renderToggler={({ openDialog }) => (
                    <Button
                        style={{ float: 'right' }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={openDialog()}
                    >
                        &nbsp;Add&nbsp;
                        <Add />
                    </Button>
                )}
            />
        </div>
    );
};

export default EditDefaultValuesExpressions;
