import React, { useContext } from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { createStyles, Theme, useTheme, makeStyles } from '@material-ui/core/styles';
import { HashLink as Link } from 'react-router-hash-link';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import useViewConfig from 'util/hooks/useViewConfig';
import { useDispatch } from 'react-redux';
import generateMenuElements from './generateMenuElements';
import { useAppSelector } from 'reducers/rootReducer';
import useViewConfigKey from './util/useViewConfigKey';
import useAppTitleElem from './util/useAppTitleElem';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { overrideAppbarTitleSelector } from './overrideAppbarTitleSelector';
import { DefaultNavMenuItems } from 'templatePage/templatableComponents/DefaultNavMenu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hiddenSkipNav: {
            position: 'absolute',
            left: '-10000px',
            top: 'auto',
            overflow: 'hidden',
            '&$focused': {
                color: theme.palette.primary.contrastText,
                position: 'unset',
                flex: 1,
                'text-decoration': 'underline',
                outline: '1px dotted ' + theme.palette.primary.contrastText,
            },
        },
        focused: {},
        root: { flexGrow: 1 },
        toolbar: { flexWrap: 'wrap', paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) },
    }),
);

const LinkButton = React.forwardRef((props, ref) => <Link to="#maincontent" {...props} ref={ref} />);

const ToolbarMenuComponent: React.FunctionComponent<{}> = React.memo(() => {
    const classes = useStyles();
    const { invertMenu, denseUI, flatUI } = useContext(themeOverrideContext);
    const theme = useTheme();
    const viewConfig = useViewConfig();
    const { hideAllMenuItems, toolbarStyles } = useAppSelector(overrideAppbarTitleSelector);

    const dispatch = useDispatch();
    const TitleElem = useAppTitleElem();
    const applyTranslations = useEvaluateFormattedMessage().evaluateFormattedMessage;
    return (
        <div className={classes.root}>
            <AppBar
                position="static"
                style={
                    invertMenu
                        ? {
                              color: theme.palette.primary.dark,
                              backgroundColor: theme.palette.background.paper,
                              borderBottom: flatUI ? `1px solid ${theme.palette.divider}` : undefined,
                          }
                        : undefined
                }
                elevation={flatUI ? 0 : undefined}
            >
                <Toolbar
                    disableGutters
                    variant={denseUI ? 'dense' : 'regular'}
                    style={toolbarStyles}
                    className={classes.toolbar}
                >
                    <Button
                        // use this when we move to using BrowserHistory
                        component={LinkButton}
                        disableFocusRipple={true}
                        classes={{
                            root: classes.hiddenSkipNav,
                            focusVisible: classes.focused,
                        }}
                        aria-label="Skip to main content"
                    >
                        Skip to main content
                    </Button>
                    {TitleElem}
                    {!hideAllMenuItems && (
                        <>
                            {generateMenuElements({ viewConfig, dispatch, applyTranslations })(viewConfig.menus)}
                            <DefaultNavMenuItems />
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
});

// remount the ToolbarMenuComponent when viewConfig changes.
// because $[currentUserHasRole(...)] expressions won't rerun when the viewConfig changes.
// we just need to remount everything on a different viewConfig.
const ToolbarMenu: React.FC<{}> = () => {
    const key = useViewConfigKey();
    return <ToolbarMenuComponent key={key} />;
};

export default ToolbarMenu;
