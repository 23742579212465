import React from 'react';
import { Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import Themed from 'components/Themed';
import {
    getServerValidationErrors,
    translateAjaxError,
    constructSubmissionValidationsTree,
} from 'sideEffect/other/notificationEpic';
import { AjaxError } from 'rxjs/ajax';
import { ErrorList } from '../form/SubmissionFailureDialog';
import Dialog from '@mui/material/Dialog';

interface ErrorDialogProps {
    alertError: AjaxError | null;
    clearAlert: () => void;
    resource: string;
}

const SplitErrorDialog = (props: ErrorDialogProps) => {
    const { clearAlert, alertError } = props;
    const serverValidationErrorsLeft =
        alertError && constructSubmissionValidationsTree(getServerValidationErrors(alertError, 'primaryRecord'));

    const serverValidationErrorsRight =
        alertError && constructSubmissionValidationsTree(getServerValidationErrors(alertError, 'secondaryRecord'));

    console.log({
        serverValidationErrorsLeft,
        serverValidationErrorsRight,
    });
    return (
        <Dialog
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
            onClose={clearAlert}
            open={Boolean(alertError)}
        >
            <div>
                {alertError && (
                    <Themed>
                        {({ theme }) => (
                            <Card>
                                <CardHeader title="Submission Failed" />
                                <CardContent style={{ color: theme.palette.error.main }}>
                                    <div style={{ display: 'flex' }}>
                                        {!serverValidationErrorsLeft &&
                                            !serverValidationErrorsRight &&
                                            translateAjaxError(alertError)}
                                        {serverValidationErrorsLeft && (
                                            <ErrorList
                                                headerText="Existing record errors"
                                                baseEntity={props.resource}
                                                validationTree={serverValidationErrorsLeft}
                                            />
                                        )}
                                        {serverValidationErrorsRight && (
                                            <ErrorList
                                                headerText="New record errors"
                                                baseEntity={props.resource}
                                                validationTree={serverValidationErrorsRight}
                                            />
                                        )}
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        style={{ margin: '2px' }}
                                        variant="contained"
                                        color="primary"
                                        onClick={clearAlert}
                                    >
                                        Close
                                    </Button>
                                </CardActions>
                            </Card>
                        )}
                    </Themed>
                )}
            </div>
        </Dialog>
    );
};

export default SplitErrorDialog;
