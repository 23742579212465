import React, { FunctionComponent, useMemo } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, IconButton, useTheme } from '@material-ui/core';
import ControlledTextField from 'react-hook-form-utils/ControlledTextField';
import { TaskForm } from '../../../reducers/taskFormType';
import Popup from 'components/Popup';
import get from 'lodash/get';
import SpelExpressionForm from '../SpelExpressionForm';
import EditIcon from '@material-ui/icons/Edit';
import { Box } from '@mui/material';

interface EditOutcomeProps {
    initialValues?: TaskForm['outcomes'][0];
    onSubmit: (data: TaskForm['outcomes'][0]) => void;
    disabled?: boolean;
    taskForm: TaskForm;
}
const EditOutcome: FunctionComponent<EditOutcomeProps> = (props) => {
    const methods = useForm({
        defaultValues: props.initialValues,
        mode: props.disabled ? 'onSubmit' : 'onBlur',
    });

    const renderExpressionField = (name: string, label: string) => (
        <Controller
            name={name}
            control={methods.control}
            defaultValue={get(props.initialValues, name) ?? ''}
            render={({ onChange, value }) => {
                return (
                    <Popup
                        paperStyle={{
                            minWidth: 'min(600px, 90vw)',
                        }}
                        renderDialogContent={({ closeDialog }) => (
                            <div onSubmit={(e) => e.stopPropagation()} style={{ padding: '1em' }}>
                                <SpelExpressionForm
                                    optional
                                    taskForm={props.taskForm}
                                    initialExpression={value ?? ''}
                                    onSubmit={(params) => {
                                        const { expression } = params;
                                        onChange(expression);
                                        closeDialog();
                                    }}
                                    renderActions={({ SaveButton }) => (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button variant="contained" onClick={closeDialog}>
                                                Cancel
                                            </Button>
                                            {SaveButton}
                                        </div>
                                    )}
                                />
                            </div>
                        )}
                        renderToggler={({ openDialog }) => (
                            <div>
                                <i>{label}</i>
                                <span style={{ marginLeft: '1em' }}>{value}</span>
                                <span style={{ marginLeft: '1em' }}>
                                    <IconButton aria-label={`Edit ${label} expression`} onClick={openDialog()}>
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </div>
                        )}
                    />
                );
            }}
        />
    );
    const uiConfig = methods.watch('configs.ui') as string;
    const uiConfigIsValid = useMemo(() => {
        if (!uiConfig?.trim()) {
            return true;
        }
        try {
            JSON.parse(uiConfig);
            return true;
        } catch (e) {
            // console.error(e);
            return false;
        }
    }, [uiConfig]);
    const theme = useTheme();
    const commentStyle: React.CSSProperties = { color: theme.palette.grey[500] };
    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit((data) => {
                    props.onSubmit(data);
                })}
            >
                <ControlledTextField
                    disabled={props.disabled}
                    rules={{ required: 'All outcomes need names' }}
                    label="Name"
                    name="name"
                    defaultValue={props.initialValues?.['name']}
                    helperText="Use names '_save' and '_cancel' to override the default save and cancel buttons"
                />
                {renderExpressionField('configs.visibility', 'Visibility Expression')}
                {renderExpressionField('configs.editable', 'Editable Expression')}
                {renderExpressionField('configs.display', 'Display Expression')}
                <ControlledTextField
                    disabled={props.disabled}
                    label="UI config JSON"
                    name="configs.ui"
                    defaultValue={get(props.initialValues, 'configs.ui', '')}
                    multiline
                />
                {!uiConfigIsValid ? (
                    <Box sx={(theme) => ({ color: theme.palette.error.dark })}>
                        <pre>Invalid JSON</pre>
                    </Box>
                ) : null}
                <div>
                    UI Options:
                    <p>
                        <code>ButtonProps</code> comes from Material-UI. Some useful properties are listed below.
                    </p>
                    <pre>
                        <code>
                            {`{
    ButtonProps?: { `}
                            <span style={commentStyle}>{`// applies properties to the button`}</span>
                            {`
        className?: string;`}
                            <span
                                style={commentStyle}
                            >{`// CSS class(es). E.g. with "muiButton": false, you may use 'casetivity-linkbutton' to style it as a link.`}</span>
                            {`
        variant?: 'contained' | 'outlined' | 'text'; `}
                            <span style={commentStyle}>{`// applies only when muiButton is true`}</span>
                            {`
        size?: 'small' | 'large' | 'medium'; `}
                            <span style={commentStyle}>{`// applies only when muiButton is true`}</span>
                            {`
        color?: 'primary' | 'secondary'; `}
                            <span style={commentStyle}>{`// applies only when muiButton is true`}</span>
                            {`
        style?: React.CSSProperties; `}
                            <span
                                style={commentStyle}
                            >{`// camel-case CSS as JSON. E.g. { "fontSize:" "16px;" }`}</span>
                            {`
    };
    muiButton?: boolean;`}
                            <span
                                style={commentStyle}
                            >{`// defaults to true - displays a plain html button if false, allowing full customization of the button styles`}</span>
                            {`
}`}
                        </code>
                    </pre>
                </div>

                {!props.disabled && uiConfigIsValid && (
                    <Button color="primary" variant="contained" type="submit">
                        Save
                    </Button>
                )}
            </form>
        </FormProvider>
    );
};
export default EditOutcome;
