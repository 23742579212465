import { Field } from '../../../translation/types';
import { isFieldFromEntity, isEntityDataField, simpleGetConfProperty } from '../../fieldUtils';
import { GenericProps, FormFieldItem } from '../../props';
import InlineManyMany, { InlineManyManyProps } from './InlineManyMany';
import { getRefEntityName } from 'components/generics/utils/viewConfigUtils';
import ViewConfig from 'reducers/ViewConfigType';
import { getViewName } from 'fieldFactory/input/fieldUtils/reference';
import getRenderUnpersistedInlineList, { RenderExistingListComponent } from './renderUnpersistedInlineManyList';
import { useMemo } from 'react';
import { WrappedFieldInputProps } from 'redux-form';
import get from 'lodash/get';
import { Typography } from '@material-ui/core';
import getNoResultsElement from 'components/generics/genericList/getNoResultsTextElement';
import Message from 'i18n/components/Message';
import EvaluateInEntityContext from 'expressions/components/EvaluateExpressionInEntityContext';

const getReference = (fieldDefinition: Field, viewConfig: ViewConfig) => {
    if (isFieldFromEntity(fieldDefinition) && fieldDefinition.unpersistedEntity) {
        return fieldDefinition.unpersistedEntity;
    }
    if (isEntityDataField(fieldDefinition) && fieldDefinition.configuredEntity) {
        return getRefEntityName(viewConfig, fieldDefinition.configuredEntity, fieldDefinition.name, 'POP_LAST');
    }
    console.error('Failed to look up reference for INLINE_MANYMANY widget.');
    return null;
};

const getInlineManyMany = (
    fieldDefinition: Field,
    propConfiguration: GenericProps,
    viewConfig: ViewConfig,
    liveProps?: {
        record?: {
            id?: string;
            entityType?: string;
        };
    },
): FormFieldItem<InlineManyManyProps & { record?: {}; resource?: string }> => {
    const useCreateView = simpleGetConfProperty<{ useCreateView?: boolean }>('useCreateView', false)(fieldDefinition);
    const createViewName = simpleGetConfProperty<{ createViewName?: string }>(
        'createViewName',
        undefined,
    )(fieldDefinition);
    const defineSPELVariables = simpleGetConfProperty<{ defineSPELVariables?: string }>(
        'defineSPELVariables',
        null,
    )(fieldDefinition);

    const config = {
        viewName: getViewName(fieldDefinition) as string,
        id: liveProps?.record?.id,
        source: propConfiguration.source,
        entityType: liveProps?.record?.entityType,
        useCreateView,
        createViewName,
        label: propConfiguration.label,
    };

    const Component = (props: InlineManyManyProps) => {
        return (
            <EvaluateInEntityContext expression={defineSPELVariables || null} defaultOnException={false} useLiveValues>
                {({ expressionResult: evaluatedAdhocSPELVariables }) => (
                    <InlineManyMany {...props} evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables} />
                )}
            </EvaluateInEntityContext>
        );
    };
    return {
        Component,
        props: {
            ...propConfiguration,
            source: fieldDefinition.name,
            reference: getReference(fieldDefinition, viewConfig),
            overrideRenderList: getRenderUnpersistedInlineList(config),
        },
    };
};

export const getInlineManyManyDisplay = (
    fieldDefinition: Field,
    propConfiguration: GenericProps,
    viewConfig: ViewConfig,
    liveProps?: {
        record?: {
            id?: string;
            entityType?: string;
        };
    },
): FormFieldItem<{
    source: string;
    record?: {};
    reference?: string;
    resource?: string;
    addLabel?: boolean;
    input: WrappedFieldInputProps;
}> => {
    const defineSPELVariables = simpleGetConfProperty('defineSPELVariables', null)(fieldDefinition);
    const viewName = getViewName(fieldDefinition) as string;
    const reference = getReference(fieldDefinition, viewConfig);
    return {
        Component: (props) => {
            const input = useMemo(() => {
                return {
                    value: get(props.record, props.source) ?? [],
                };
            }, [props.record, props.source]);
            return (
                <EvaluateInEntityContext expression={defineSPELVariables || null} defaultOnException={false}>
                    {({ expressionResult: evaluatedAdhocSPELVariables }) => (
                        <div>
                            {fieldDefinition.label && <Typography variant="h6">{fieldDefinition.label}</Typography>}
                            {input.value.length > 0 ? (
                                <RenderExistingListComponent
                                    reference={reference}
                                    viewName={viewName}
                                    input={input as any}
                                    evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                                />
                            ) : (
                                getNoResultsElement(<Message id="list.noResults" dm="No results found" />)
                            )}
                        </div>
                    )}
                </EvaluateInEntityContext>
            );
        },
        props: {
            ...propConfiguration,
            source: fieldDefinition.name,
            reference,
            addLabel: false,
        },
    };
};
export default getInlineManyMany;
