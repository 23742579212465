import React, { useCallback } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import MenuRoot from './MenuRoot';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useAppSelector } from 'reducers/rootReducer';
import { getUseEmailAsAccountDisplaySelector } from 'util/applicationConfig';
import { promptPickProfile } from 'auth/profiles/actions';
import { useDispatch } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

export const AccountMenuButton = () => {
    const viewConfig = useViewConfig();
    const dispatch = useDispatch();
    const useEmailAsAccountDisplay = useAppSelector(getUseEmailAsAccountDisplaySelector);
    const profiles = useAppSelector((state) => state.profiles);
    const logout = useCallback(() => {
        dispatch(pushAction('/logout-redirect'));
    }, [dispatch]);
    if (!viewConfig?.user?.login || viewConfig.user.login === 'anonymousUser') {
        return null;
    }
    return (
        <MenuRoot
            renderTrigger={({ getTriggerProps }) => (
                <Button
                    {...getTriggerProps({
                        color: 'inherit',
                        id: 'accountdropdown',
                    })}
                >
                    Account <ArrowDropDown color="inherit" /> <AccountCircle color="inherit" />
                </Button>
            )}
        >
            {({ getMenuProps, Menu }) => {
                return (
                    <Menu {...getMenuProps({ placement: 'bottom-end' })}>
                        <MenuItem role="presentation">
                            {useEmailAsAccountDisplay
                                ? viewConfig.user?.email ?? viewConfig.user?.login
                                : viewConfig.user?.login}
                        </MenuItem>
                        {profiles.state === 'profile_selected' && profiles.profiles.length > 1 ? (
                            <MenuItem
                                onClick={() => {
                                    dispatch(promptPickProfile());
                                }}
                            >
                                Switch Profile
                            </MenuItem>
                        ) : null}
                        <MenuItem onClick={logout}>Logout</MenuItem>
                    </Menu>
                );
            }}
        </MenuRoot>
    );
};
