import React, { useContext } from 'react';
import { getOutcomeUiConfigs } from 'bpm/components/TaskDetail/TaskForm/Outcomes2';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';
import { useEvaluatorInFormContext } from 'expressions/hooks/allForms/useEvaluateExpression';
import { ButtonProps, useTheme } from '@material-ui/core';
import { createCompleteButtonContext } from 'bpm/components/TaskDetail/TaskForm/TaskForm/Form/Layout/createCompleteButtonContext';
import { processContext } from 'bpm/components/processContext';
import useTaskOption from 'bpm/components/TaskDetail/TaskForm/hooks/useTaskOption';
import useFormHasErrors from 'bpm/components/TaskDetail/TaskForm/hooks/useFormHasErrors';

const TaskOutcomeButton: React.FC<{
    outcome: string;
    displayExpression?: string;
    clickableExpression?: string;
    ButtonProps?: Partial<ButtonProps>;
    muiButton?: boolean;
}> = ({ outcome, displayExpression, clickableExpression, ButtonProps, muiButton: _muiButton }) => {
    const { renderCancelButton, renderSaveButton, createCompleteButton } =
        React.useContext(createCompleteButtonContext);
    const { taskId } = useContext(processContext) ?? {};

    const entityFormHasErrors = useFormHasErrors('record-form');
    const taskOption = useTaskOption(taskId);

    const taskLoaded = taskOption.isSome();
    const taskOutcome = taskOption.map((t) => t.outcome).toUndefined();
    const { formDefinition } = useContext(stagedFormDefinitionContext);
    const outcomeUIConfigs = React.useMemo(() => getOutcomeUiConfigs(formDefinition), [formDefinition]);
    const uiConfig = outcomeUIConfigs[outcome];

    const getDisplayTextExpressionResult = useEvaluatorInFormContext(displayExpression ?? '', {
        defaultOnException: '',
    });
    const displayTextExpressionResult =
        getDisplayTextExpressionResult?.({
            entityFormHasErrors,
        }) ?? '';

    const getClickableExpressionResult = useEvaluatorInFormContext(clickableExpression ?? true, {
        defaultOnException: true,
    });
    const clickableExpressionResult = getClickableExpressionResult({
        entityFormHasErrors,
    });
    const buttonLabel = displayTextExpressionResult?.trim() || outcome;
    const muiButton = _muiButton ?? uiConfig?.muiButton ?? true;
    const theme = useTheme();

    if (!taskLoaded || taskOption.value.endDate) {
        // hide for closed tasks (or, for example, if loading)
        return null;
    }

    return (
        <span
            style={{
                border: taskLoaded && taskOutcome === outcome ? `1px solid ${theme.palette.primary.dark}` : undefined,
            }}
        >
            {outcome === '_save'
                ? renderSaveButton?.(
                      displayTextExpressionResult?.trim(),
                      Object.assign({ color: 'primary' } as Partial<ButtonProps>, uiConfig?.ButtonProps, ButtonProps),
                      uiConfig?.muiButton,
                  )
                : outcome === '_cancel'
                ? renderCancelButton?.(
                      displayTextExpressionResult?.trim(),
                      Object.assign({}, uiConfig?.ButtonProps, ButtonProps),
                      uiConfig?.muiButton,
                  )
                : createCompleteButton?.(
                      buttonLabel,
                      outcome,
                      !clickableExpressionResult,
                      Object.assign({ color: 'primary' } as Partial<ButtonProps>, uiConfig?.ButtonProps, ButtonProps),
                      muiButton,
                  )}
        </span>
    );
};

export default TaskOutcomeButton;
