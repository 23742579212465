import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Card, CardHeader, Button } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { withStyles, createStyles, WithStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { RootState } from 'reducers/rootReducer';
import compose from 'recompose/compose';
import isOffline from 'util/isOffline';
import useRootTheme from 'components/layouts/hooks/useRootTheme';
import { createTheme as createThemeV4 } from '@material-ui/core/styles';
import { Theme as V4Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert/Alert';
import { useMessage } from 'i18n/components/Message';

const ProvideTheme = (props) => {
    const theme = useRootTheme(createThemeV4);
    return <MuiThemeProvider theme={theme as V4Theme}>{props.children}</MuiThemeProvider>;
};

interface FormSaveNotifierProps {
    when: boolean;
}
const styles = createStyles({
    dialogContainer: {
        zIndex: 5000,
    },
});
const mapStateToProps = (state: RootState, props: FormSaveNotifierProps) => {
    return {
        enabled: state.disableFormSaveNotifier === 0,
    };
};
interface FormSaveNotifierComponentProps
    extends FormSaveNotifierProps,
        ReturnType<typeof mapStateToProps>,
        WithStyles<typeof styles> {}

const FormSaveNotifierComponent = (props: FormSaveNotifierComponentProps) => {
    const { when, classes, enabled } = props;
    // don't notify if offline
    const prompt = Boolean(enabled && when && !isOffline());

    useEffect(() => {
        if (prompt) {
            window.onbeforeunload = () => 'You have unsaved data. Are you sure you would like to continue?';
        }
        return () => {
            if (prompt) {
                window.onbeforeunload = null;
            }
        };
    }, [prompt]);

    const unsavedChangesHeader = useMessage({ id: 'unsavedChanges.header', dm: 'Leave page?' });
    const unsavedChangesText = useMessage({
        id: 'unsavedChanges.text',
        dm: "Are you sure that you want to leave the current page? The changes that you made won't be saved.",
    });
    const cancelText = useMessage({ id: 'unsavedChanges.cancelButton', dm: 'Cancel' });
    const confirmText = useMessage({ id: 'unsavedChanges.confirmButton', dm: 'Leave' });

    return (
        <NavigationPrompt
            when={(crntLocation, nextLocation) => {
                return prompt;
            }}
        >
            {({ isActive, onCancel, onConfirm }) => (
                <ProvideTheme>
                    <Dialog
                        TransitionProps={
                            {
                                // https://github.com/dequelabs/axe-core/issues/146
                                role: 'presentation',
                            } as any
                        }
                        className={classes.dialogContainer}
                        open={isActive}
                    >
                        <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <CardHeader title={unsavedChangesHeader} sx={{ textAlign: 'left', padding: '20px' }} />
                            <Alert style={{ margin: '0 auto', width: '90%' }} severity="warning">
                                <span>{unsavedChangesText}</span>
                            </Alert>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    padding: '20px',
                                }}
                            >
                                <Button color="default" variant="contained" onClick={onCancel}>
                                    {cancelText}
                                </Button>
                                <Button color="primary" variant="contained" onClick={onConfirm}>
                                    {confirmText}
                                </Button>
                            </div>
                        </Card>
                    </Dialog>
                </ProvideTheme>
            )}
        </NavigationPrompt>
    );
};

const FormSaveNotifier: React.SFC<FormSaveNotifierProps> = compose(
    withStyles(styles),
    connect(mapStateToProps),
)(FormSaveNotifierComponent);
export default FormSaveNotifier;
