import { useContext, useMemo } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import { casetivityViewContext } from 'util/casetivityViewContext';
import useEntities from 'util/hooks/useEntities';
import useValueSets from 'util/hooks/useValueSets';
import { useIntl } from 'react-intl';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { buildContext } from 'spelContext/buildContext';

const useEvalContext = (overrideEntities?: any) => {
    const viewConfig = useViewConfig();
    const entities = useEntities() || overrideEntities;
    const { rootViewContext } = useContext(casetivityViewContext);
    const valueSets = useValueSets();
    const intl = useIntl();
    const printMode = useAppSelector((state: RootState) => state.printMode);
    const profileState = useAppSelector((state: RootState) => state.profiles);

    const context = useMemo(
        () =>
            buildContext({
                intl,
                viewConfig,
                entities,
                valueSets,
                viewContext: rootViewContext,
                extra: {
                    isPrintMode: () => !!printMode,
                },
                profileState,
            }),
        [viewConfig, entities, valueSets, rootViewContext, intl, printMode, profileState],
    );
    return context;
};
export default useEvalContext;
