import { useCallback, useEffect, useMemo, useState } from 'react';
import { pubSubStore } from './pubSubStore';

export const usePubSubKey = <V>(key: string, initialValue: V) => {
    useMemo(() => {
        if (!pubSubStore.isModified(key)) {
            pubSubStore.setValue(key, initialValue, true); // sneak the value
        }
    }, [initialValue, key]);
    const [value, setValue] = useState<V>(pubSubStore.getValue(key));

    useEffect(() => {
        const unsubscribe = pubSubStore.subscribe(key, setValue);
        return () => unsubscribe();
    }, [key]);

    const setPubSubValue = useCallback(
        (newValue: V) => {
            pubSubStore.setValue(key, newValue);
        },
        [key],
    );

    return [value, setPubSubValue] as const;
};
