import React, { useCallback, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from '../../../../src/reducers/rootReducer';
import {
    createStyles,
    Theme,
    Button,
    IconButton,
    DialogContent,
    DialogContentText,
    DialogActions,
    makeStyles,
    Typography,
    CircularProgress,
    DialogTitle,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { crudDelete as crudDeleteAction } from 'sideEffect/crud/delete/actions';
import Popup from 'components/Popup';
import { fromNullable } from 'fp-ts/lib/Option';
import { allowsDelete } from 'components/generics/utils/viewConfigUtils';
import { AjaxError } from 'rxjs/ajax';
import { RemoteData, initial, failure, success, pending } from '@devexperts/remote-data-ts';
import { createGetEntities } from '../form/EntityFormContext/util/getEntities';
import { EntityBase } from 'sideEffect/services';
import useViewConfig from 'util/hooks/useViewConfig';
import { EvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import Alert from '@material-ui/lab/Alert/Alert';
import { useMessage } from 'i18n/components/Message';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        del: {
            color: palette.error.dark,
        },
        delNegative: {
            backgroundColor: palette.error.dark,
            color: palette.error.contrastText,
        },
    }),
);

interface DeleteClasses {
    del: string;
    delNegative: string;
}

export interface InlineDeleteButtonProps {
    resource: string;
    id: string;
    title?: string;
    renderIcon?: (args: {
        classes: DeleteClasses;
        handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    }) => JSX.Element;
    onDeleteSuccess?: () => void;
    sameEntityExistsBelowInTheStack?: () => boolean;
}

const defaultRenderIcon: InlineDeleteButtonProps['renderIcon'] = ({ handleClick, classes }) => (
    <IconButton className={classes.del} aria-label="delete" onClick={handleClick}>
        <DeleteIcon />
    </IconButton>
);

const hasPermissionSelector = (state: RootState, resource: string) => {
    return fromNullable(state.viewConfig)
        .map((vc) => vc.entities)
        .chain(fromNullable)
        .map((e) => e[resource])
        .chain(fromNullable)
        .map((e) => e.accessLevel)
        .chain(fromNullable)
        .map(allowsDelete)
        .getOrElse(false);
};
const InlineDeleteButton: React.SFC<InlineDeleteButtonProps> = (props) => {
    const { resource, id, onDeleteSuccess, renderIcon = defaultRenderIcon } = props;
    const attemptedOnce = useRef(false);
    const classes = useStyles(props);
    const viewConfig = useViewConfig();
    const dispatch = useDispatch();
    const getEntities = useMemo(createGetEntities, []);
    const record: (EntityBase & { title?: string }) | null = useAppSelector((state: RootState) => {
        return fromNullable(getEntities(state))
            .mapNullable((e) => e[resource])
            .mapNullable((re) => re[id])
            .getOrElse(null);
    });
    const hasPermission = useAppSelector((state: RootState) => hasPermissionSelector(state, resource));
    const [state, setState] = useState<RemoteData<AjaxError, undefined>>(initial);

    const deleteCb = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            setState(pending);
            dispatch(
                crudDeleteAction({
                    resource,
                    id,
                    cb: () => {
                        setState(success(undefined));
                        if (onDeleteSuccess) {
                            onDeleteSuccess();
                        }
                    },
                    errorsCbs: {
                        '*': (e) => {
                            attemptedOnce.current = true;
                            setState(failure(e));
                        },
                    },
                }),
            );
        },
        [resource, id, onDeleteSuccess, setState, dispatch],
    );

    const deleteHeader = useMessage({ id: 'deleteModal.header', dm: 'Delete' });
    const deleteInitialText = useMessage({
        id: 'deleteModal.initialText',
        dm: "Are you sure? You can't undo this action.",
    });
    const deleteFailedText = useMessage({ id: 'deleteModal.failText', dm: 'Failed. Please try again.' });
    const deleteSuccessText = useMessage({ id: 'deleteModal.successText', dm: 'Success!' });
    const confirmText = useMessage({ id: 'deleteModal.confirmButton', dm: 'Delete' });
    const cancelText = useMessage({ id: 'deleteModal.cancelButton', dm: 'Cancel' });
    const retryText = useMessage({ id: 'deleteModal.retryButton', dm: 'Retry' });

    const title =
        props.title ||
        `${deleteHeader} ${viewConfig.entities[resource].displayName}${record ? `: ${record.title}` : ''}`;
    const titleElem = typeof title === 'string' ? <EvaluatedFormattedMessage message={title} /> : title;

    const getContent = state.fold(
        () => (
            <DialogContentText>
                <Alert severity="info">{deleteInitialText}</Alert>
            </DialogContentText>
        ),
        () => <CircularProgress />,
        (e) => () => {
            const message = (() => {
                if (e.response && e.response.description) {
                    return e.response.description;
                }
                return deleteFailedText;
            })();
            return <DialogContentText>{<div className={classes.del}>{message}</div>}</DialogContentText>;
        },
        () => () => <DialogContentText>{deleteSuccessText}</DialogContentText>,
    );

    if (!hasPermission) {
        return null;
    }
    return (
        <Popup
            onClose={() => {
                setState(initial);
                attemptedOnce.current = false;
            }}
            renderDialogContent={({ closeDialog }) => {
                const blockDeletion = props.sameEntityExistsBelowInTheStack?.();
                if (blockDeletion) {
                    return (
                        <>
                            <DialogTitle>Warning</DialogTitle>
                            <DialogContent>
                                This record is currently open in a page below. Please close the current popup and delete
                                it from there.
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={(e) => {
                                        if (e) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }
                                        closeDialog();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </>
                    );
                }
                return (
                    <>
                        <DialogTitle>
                            <Typography variant="h5">{titleElem}</Typography>
                        </DialogTitle>
                        <DialogContent>{getContent()}</DialogContent>
                        <DialogActions>
                            <Button
                                onClick={(e) => {
                                    if (e) {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }
                                    closeDialog();
                                }}
                            >
                                {cancelText}
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.delNegative}
                                onClick={deleteCb}
                                disabled={state.isPending()}
                            >
                                {attemptedOnce.current ? retryText : confirmText}
                            </Button>
                        </DialogActions>
                    </>
                );
            }}
            renderToggler={({ openDialog }) => {
                const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
                    if (e) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                    openDialog()();
                };
                return renderIcon({ classes, handleClick });
            }}
        />
    );
};
export default InlineDeleteButton;
