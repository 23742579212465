import { createSelector } from 'reselect';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { useMemo } from 'react';
import { Permission } from 'reducers/ViewConfigType';

const emptyArr = [];

const getUserPermissionsSelector = () =>
    createSelector(
        (state: RootState, permission: string) => state.viewConfig?.user?.permissions || emptyArr,
        (state: RootState, permission: string) => permission,
        (permissions, permission) => permissions.includes(permission),
    );

const useUserHasPermission = (permission: Permission): boolean => {
    const userPermissionSelector = useMemo(getUserPermissionsSelector, []);
    return useAppSelector((state) => userPermissionSelector(state, permission));
};

export default useUserHasPermission;
