import React from 'react';
import getInitialV5Theme from '../getMuiThemeV5';
import { getPrimaryColor, getSecondaryColor, getErrorColor } from 'components/layouts/getThemeColors';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { themeOverrideContext } from '../ThemeOverrideProvider';
import {
    getFontFamilySelector,
    getOfflinePrimaryColorSelector,
    getOfflineSecondaryColorSelector,
    getPrimaryColorSelector,
    getSecondaryColorSelector,
} from 'util/applicationConfig';
import isOffline from 'util/isOffline';
import { Theme as ThemeV5, ThemeOptions as V5ThemeOptions } from '@mui/system';
import { useChatBot } from 'chatbot/ChatbotProvider';

// todo: allow injection of other colors
const useRootTheme = (themeCreator: (themeInit: V5ThemeOptions) => ThemeV5): ThemeV5 => {
    const { overrides, darknessOverrides, darkMode, forceLabelShrink, fieldVariant, useStripedLists, denseUI, flatUI } =
        React.useContext(themeOverrideContext);
    const adhocConfiguredPrimaryColor = useAppSelector(getPrimaryColorSelector);
    const adhocConfiguredSecondaryColor = useAppSelector(getSecondaryColorSelector);

    const adhocConfiguredOfflinePrimaryColor = useAppSelector(getOfflinePrimaryColorSelector);
    const adhocConfiguredOfflineSecondaryColor = useAppSelector(getOfflineSecondaryColorSelector);
    const { state: chatState } = useChatBot();
    const { isOpen: chatbotIsOpen } = chatState;

    const fontFamily = useAppSelector(getFontFamilySelector);
    const appColor = useAppSelector((state: RootState) => state.basicInfo && state.basicInfo.applicationColor);
    const theme = React.useMemo(() => {
        const configuredPrimaryColor = isOffline() ? adhocConfiguredOfflinePrimaryColor : adhocConfiguredPrimaryColor;
        const configuredSecondaryColor = isOffline()
            ? adhocConfiguredOfflineSecondaryColor
            : adhocConfiguredSecondaryColor;

        const theme = getInitialV5Theme(
            themeCreator,
            {
                primary: (overrides.primary as any) || getPrimaryColor(configuredPrimaryColor || appColor),
                secondary: (overrides.secondary as any) || getSecondaryColor(configuredSecondaryColor),
                error: (overrides.error as any) || getErrorColor(adhocConfiguredSecondaryColor || appColor),
                darknessOverrides,
                darkMode,
                forceLabelShrink,
                fieldVariant,
                fontFamily,
                useStripedLists,
                denseUI,
                flatUI,
                chatbotIsOpen,
            },
            true, // DOM sideEffect: rebuild the stylesheet for React-Web-Tabs
        );
        // set the document background color
        return theme;
    }, [
        flatUI,
        denseUI,
        fontFamily,
        appColor,
        overrides,
        darknessOverrides,
        darkMode,
        forceLabelShrink,
        fieldVariant,
        adhocConfiguredPrimaryColor,
        adhocConfiguredSecondaryColor,
        adhocConfiguredOfflinePrimaryColor,
        adhocConfiguredOfflineSecondaryColor,
        themeCreator,
        useStripedLists,
        chatbotIsOpen,
    ]);
    return theme;
};

export default useRootTheme;
