import React from 'react';
import LoginButton from 'components/LoginButton';
import ImportExportViewConfigBtn from 'components/importExportViewConfig';
import SwipeableTemporaryThemeDrawer from 'components/layouts/TogglableThemeEditor';
import { AccountMenuButton } from 'components/layouts/menu2/AccountMenuButton';
import useUserIsSuper from 'util/hooks/useUserIsSuper';
import useViewConfig from 'util/hooks/useViewConfig';
import RefreshViewConfigButton from 'viewConfig/components/Refresh';

export const DefaultNavMenuItems = () => {
    const viewConfig = useViewConfig();
    const isSuper = useUserIsSuper();

    return (
        <>
            <AccountMenuButton />
            {viewConfig?.user?.login === 'super' && <ImportExportViewConfigBtn mobile={false} />}
            <RefreshViewConfigButton mobile={false} />
            {viewConfig?.user?.login === 'anonymousUser' && <LoginButton />}
            {isSuper && <SwipeableTemporaryThemeDrawer />}
        </>
    );
};
