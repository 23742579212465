import { CircularProgress } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import { HandlePrintStatusChange, getPrintController } from 'print/PrintController';
import { useEffect, useState } from 'react';

const PrintSpinner = () => {
    const [pending, setPending] = useState(false);
    useEffect(() => {
        const cb: HandlePrintStatusChange = (status) => {
            setPending(status === 'pending');
        };
        getPrintController().subscribeToPrint(cb);
        return () => getPrintController().unsubscribeToPrint(cb);
    });
    if (!pending) {
        return null;
    }
    return (
        <Dialog open>
            <div style={{ padding: '.5em', overflow: 'hidden', textAlign: 'center' }}>
                <CircularProgress style={{ height: '100px', width: '100px' }} />
            </div>
        </Dialog>
    );
};

export default PrintSpinner;
