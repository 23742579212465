import React, { FunctionComponent, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, IconButton, TextField } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { TaskForm } from '../../../../reducers/taskFormType';
import get from 'lodash/get';
import Popup from 'components/Popup';
import SpelExpressionForm from 'bpm/task-form-editor/SpelExpressionForm';
import EditIcon from '@material-ui/icons/Edit';

export interface OptionData {
    id: string;
    name?: string;
    configs?: {
        visibility?: string;
    };
}

interface OptionFormProps {
    initialData?: Partial<OptionData>;
    taskForm?: Partial<TaskForm>;
    onSubmit: (data: OptionData) => void;
}

const OptionForm: FunctionComponent<OptionFormProps> = (props) => {
    const { onSubmit, taskForm: taskFormPreview, initialData } = props;
    const methods = useForm<OptionData>({
        defaultValues: initialData,
        mode: 'onChange',
    });
    const { errors } = methods;
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);

    return (
        <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Controller
                InputLabelProps={getInputLabelProps({ shrink: true })}
                label="id"
                variant={fieldVariant}
                name="id"
                fullWidth
                control={methods.control}
                defaultValue={get(initialData, 'id') ?? ''}
                margin="normal"
                as={TextField}
                rules={{ required: 'An id is Required' }}
                error={Boolean(get(errors, 'id'))}
                helperText={get(errors, 'id')?.message}
            />
            <Controller
                InputLabelProps={getInputLabelProps({ shrink: true })}
                label="name"
                name="name"
                variant={fieldVariant}
                fullWidth
                control={methods.control}
                defaultValue={get(initialData, 'name') ?? ''}
                margin="normal"
                as={TextField}
                rules={{ required: 'An name is Required' }}
                error={Boolean(get(errors, 'name'))}
                helperText={get(errors, 'name')?.message}
            />
            <Controller
                name="configs.visibility"
                control={methods.control}
                defaultValue={get(initialData, 'configs.visibility') ?? ''}
                render={({ onChange, value }) => {
                    return (
                        <Popup
                            paperStyle={{
                                minWidth: 'min(1000px, 60vw)',
                            }}
                            renderDialogContent={({ closeDialog }) => (
                                <div onSubmit={(e) => e.stopPropagation()} style={{ padding: '1em' }}>
                                    <SpelExpressionForm
                                        taskForm={taskFormPreview}
                                        initialExpression={value ?? ''}
                                        onSubmit={(params) => {
                                            const { expression } = params;
                                            onChange(expression);
                                            closeDialog();
                                        }}
                                    />
                                </div>
                            )}
                            renderToggler={({ openDialog }) => (
                                <div>
                                    <span>
                                        <b>Visibility expression:</b>&nbsp;
                                        {!value ? <em>None</em> : value}&nbsp;
                                        <IconButton aria-label={`Edit Visibility expression`} onClick={openDialog()}>
                                            <EditIcon />
                                        </IconButton>
                                    </span>
                                </div>
                            )}
                        />
                    );
                }}
            />
            <Button color="primary" variant="contained" disabled={Object.keys(errors).length > 0} type="submit">
                Save
            </Button>
        </form>
    );
};
export default OptionForm;
