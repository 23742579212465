import React, { KeyboardEventHandler, useRef, useState, useCallback, FC } from 'react';
import { MenuItemProps, MenuItem, Menu, MenuProps } from '@mui/material';
import { uniqueId } from 'lodash';
import { ArrowRight } from '@mui/icons-material';

// https://codesandbox.io/p/sandbox/material-ui-submenu-sz73h7?file=%2Fsrc%2FMoreMenuItem.tsx%3A1%2C1-78%2C1
// this is an adapted version of mui 5 nested menu from user Dattaya
// https://github.com/mui/material-ui/issues/11723

type NestedMenuBaseProps = {
    button?: true;
    label: string;
    MenuProps?: Omit<MenuProps, 'open' | 'onClose' | 'anchorEl' | 'onKeyDown'>;
} & Omit<MenuItemProps, 'onKeyDown' | 'onMouseEnter' | 'onMouseLeave'>;

const NestedMenuBase: FC<NestedMenuBaseProps> = ({ label, children, id, MenuProps, ...other }) => {
    const [isOpen, setIsOpen] = useState(false);
    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);

    const menuItemRef = useRef<HTMLLIElement>(null);
    const menuItemId = uniqueId('more-menu-item-');
    const normMenuItemId = id ?? menuItemId;

    const handleItemKeyDown: KeyboardEventHandler<HTMLLIElement> = (ev) => {
        if ((ev.key !== 'ArrowRight' && ev.key !== 'Enter') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey)
            return;
        ev.preventDefault();
        ev.stopPropagation();
        setIsOpen(true);
    };

    const handleMenuKeyDown: KeyboardEventHandler<HTMLDivElement> = (ev) => {
        ev.stopPropagation();
        if ((ev.key !== 'ArrowLeft' && ev.key !== 'Escape') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey)
            return;
        ev.preventDefault();
        setIsOpen(false);
    };

    return (
        <MenuItem
            {...other}
            onKeyDown={handleItemKeyDown}
            ref={menuItemRef}
            sx={[{ justifyContent: 'space-between' }, isOpen && { backgroundColor: 'action.hover' }]}
            onMouseEnter={open}
            onMouseLeave={close}
            id={normMenuItemId}
        >
            <span>{label}</span>
            <ArrowRight style={{ right: 0, position: 'absolute' }} />
            <Menu
                TransitionProps={{ onExited: () => menuItemRef.current?.focus() }}
                disableRestoreFocus
                onKeyDown={handleMenuKeyDown}
                sx={{
                    pointerEvents: 'none',
                    '& .MuiList-root': {
                        pointerEvents: 'auto',
                    },
                }}
                MenuListProps={{
                    ...MenuProps?.MenuListProps,
                    'aria-labelledby': normMenuItemId,
                }}
                anchorEl={menuItemRef.current}
                open={isOpen}
                onClose={close}
                anchorOrigin={MenuProps?.anchorOrigin ?? { vertical: 'center', horizontal: 'right' }}
                transformOrigin={MenuProps?.transformOrigin ?? { vertical: 'center', horizontal: 'left' }}
            >
                {children}
            </Menu>
        </MenuItem>
    );
};

export default NestedMenuBase;
