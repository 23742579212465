import MenuItem from '@mui/material/MenuItem';
import React, { forwardRef } from 'react';

const ListboxComponent = forwardRef<
    HTMLUListElement,
    React.HTMLAttributes<HTMLUListElement> & { count: number | undefined; total: number | undefined }
>((props, ref) => {
    const { children, count, total, ...other } = props;
    return (
        <ul {...other} role="listbox" ref={ref}>
            {children}
            <MenuItem component="div" aria-live="polite" key="status" disabled>
                Showing {count} out of {total} result{total === 1 ? '' : 's'}.
            </MenuItem>
        </ul>
    );
});
export default ListboxComponent;
