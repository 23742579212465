import React, { useMemo } from 'react';
import { Fab } from '@mui/material';
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import 'react-chatbot-kit/build/main.css';
import { useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './index.css';
import useBasicInfo from 'util/hooks/useBasicInfo';
import { useResizable } from './utils/useResizable';
import useUserHasPermission from 'util/hooks/useUserHasPermission';
import Chatbot from './LazyChatbot';
import CasetivityCompassIcon from './assets/CasetivityCompassLogo';
import { useChatBot } from './ChatbotProvider';

const initialSize = { width: 650, height: 500 };

const ChatBotFab = () => {
    const theme = useTheme();
    const { size, startResizing } = useResizable(initialSize);

    const { state: chatState, toggleChat } = useChatBot();
    const configuration = useMemo(() => config(theme, chatState), [theme, chatState]);

    return (
        <div>
            <div
                style={{
                    position: 'fixed',
                    bottom: 90,
                    right: 20,
                    zIndex: 3000,
                    width: size.width,
                    height: size.height,
                    visibility: chatState.isOpen ? 'visible' : 'hidden',
                    opacity: chatState.isOpen ? 1 : 0,
                    transition: 'opacity 0.3s ease',
                }}
            >
                <ArrowBackIosIcon
                    onMouseDown={(e) => {
                        startResizing(e);
                    }}
                    sx={{
                        color: theme.palette.primary.contrastText,
                        cursor: 'nw-resize',
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        margin: ` ${theme.spacing(0.5)} 0 0 ${theme.spacing(0.5)} `,
                        zIndex: 4000,
                        transform: 'rotate(45deg)',
                    }}
                />
                <Chatbot
                    key={`chatbot-${chatState.refreshKey}`}
                    config={configuration}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                />
            </div>

            <Fab
                color="primary"
                aria-label="chat"
                onClick={toggleChat}
                sx={{
                    position: 'fixed',
                    bottom: theme.spacing(3),
                    right: theme.spacing(3),
                    boxShadow: 'none',
                    transition: 'box-shadow 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                }}
            >
                {chatState.isOpen ? (
                    <KeyboardArrowDownIcon />
                ) : (
                    <CasetivityCompassIcon
                        pointRight={true}
                        size="large"
                        customColor={theme.palette.primary.main}
                        backgroundColor={theme.palette.primary.contrastText}
                    />
                )}
            </Fab>
        </div>
    );
};

export const EnabledAndPermissionedWrapper = ({ children }) => {
    const { compassEnabled } = useBasicInfo();
    const hasPermission = useUserHasPermission('GET_COMPASS_SUGGESTIONS');

    if (!compassEnabled || !hasPermission) {
        return null;
    }

    return <>{children}</>;
};
const PermissionedChatBotFab = () => (
    <EnabledAndPermissionedWrapper>
        <ChatBotFab />
    </EnabledAndPermissionedWrapper>
);

export default PermissionedChatBotFab;
