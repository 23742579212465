import React from 'react';
import { SimpleTabs } from 'components/tabUtils';

const FormBuilderTabs: React.FC<{
    layoutEditorElement: JSX.Element;
    previewElement: JSX.Element;
    keepTabsMounted?: boolean;
    disabled?: boolean;
}> = (props) => {
    return (
        <SimpleTabs
            keepTabsMounted={props.keepTabsMounted}
            aria-label="TaskForm builder tabs"
            tabGroupName="FormBuilder"
            tabLabels={props.disabled ? ['Preview Form'] : ['Edit Layout', 'Preview Form']}
            renderTab={({ label }) => {
                switch (label) {
                    case 'Edit Layout': {
                        return props.layoutEditorElement;
                    }
                    case 'Preview Form': {
                        return props.previewElement;
                    }
                    default:
                        throw new Error('uncaught label ' + label);
                }
            }}
        />
    );
};
export default FormBuilderTabs;
