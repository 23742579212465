import React from 'react';

interface CasetivityCompassIconProps extends React.SVGProps<SVGSVGElement> {
    pointRight?: boolean;
    size?: 'small' | 'medium' | 'large';
    customColor?: string;
    backgroundColor?: string;
}

const CasetivityCompassIcon: React.FC<CasetivityCompassIconProps> = ({
    pointRight = true,
    size = 'medium',
    customColor = '#231f20',
    backgroundColor = '#fff',
}) => {
    const sizeDictionary = {
        small: 24,
        medium: 30,
        large: 56,
    };
    const iconSize = sizeDictionary[size];

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 30 30"
            width={iconSize}
            height={iconSize}
            style={{
                transform: pointRight ? 'scaleX(-1)' : 'none',
            }}
        >
            <g>
                <g id="Layer_1">
                    <rect x="1.7" y="20.6" width="7.7" height="7.7" fill={customColor} />
                    <g>
                        <circle cx="15.2" cy="15.1" r="13.5" fill={backgroundColor} />
                        <path
                            d="M15.2,29.6C7.2,29.6.7,23.1.7,15.1S7.2.5,15.2.5s14.5,6.5,14.5,14.5-6.5,14.5-14.5,14.5ZM15.2,2.5c-6.9,0-12.5,5.6-12.5,12.5s5.6,12.5,12.5,12.5,12.5-5.6,12.5-12.5S22.1,2.5,15.2,2.5Z"
                            fill={customColor}
                        />
                    </g>
                    <circle cx="15.2" cy="15.1" r="11.5" fill={customColor} />
                    <g>
                        <polygon
                            points="20.1 20 15.2 17.7 10.2 20 12.6 15.1 10.3 10.1 15.2 12.5 20.1 10.2 17.8 15.1 20.1 20"
                            fill={customColor}
                        />
                        <path
                            d="M20.8,20.8l-5.7-2.7-5.7,2.6,2.7-5.7-2.6-5.7,5.7,2.7,5.7-2.6-2.7,5.7,2.6,5.7ZM15.2,17.3l4.1,2-1.9-4.1,2-4.1-4.1,1.9-4.1-2,1.9,4.1-2,4.1,4.2-1.9Z"
                            fill={backgroundColor}
                        />
                    </g>
                    <polygon
                        points="15.1 27.5 12.8 17.4 2.8 14.9 12.8 12.7 15.3 2.6 17.6 12.7 27.6 15.2 17.5 17.5 15.1 27.5"
                        fill={backgroundColor}
                    />
                    <g>
                        <circle cx="15.2" cy="15.1" r="4.7" fill={customColor} />
                        <path
                            d="M15,13.3v-1.8s0-.4-.3-.4-1.9,0-2,.8c0,.5.3.5.9.5.3,0,.5.2.5.6,0,.1-.2.1-.2,0,0-.5-.8-.3-1.2-.4s-.4-.5-.9,0c-.3.3-.8,1.4-.8,2.5s.2.2.3,0c0-.5.5-.7,1-.7s.4,0,.4-.8.2-.2.2,0c0,.6,0,1-.6,1-.4,0-.9.1-.8,1.1,0,.8.2,1.3.2,1.5.3.7.6.6.8.3.3-.5.6-.5.9-.6.4,0,.5.3,0,.2-.4,0-.8.3-.7,1,.1.5,1.2.8,1.4.8.5,0,1,0,1-.8,0-1-.2-1.2-.4-1.4-.3-.2-.2-.4-.5-.8-.2-.4-1.1-.3-1.3-.3s-.1-.3,0-.3c0,0,.9,0,1.2.1.3.1.4.6.5.8s.5.3.5-.4,0-1.1,0-1.4,0-.5-.3-.6c-.4,0-.7.1-.7-.1,0-.4.9.1,1.1-.5h0Z"
                            fill={backgroundColor}
                        />
                        <path
                            d="M15.3,13.3v-1.8s0-.4.3-.4,1.9,0,2,.8c0,.5-.3.5-.9.5-.3,0-.5.2-.5.6,0,.1.2.1.2,0,0-.5.8-.3,1.2-.4s.4-.5.9,0c.3.3.8,1.4.8,2.5s-.2.2-.3,0c0-.5-.5-.7-1-.7s-.4,0-.4-.8-.2-.2-.2,0c0,.6,0,1,.6,1,.4,0,.9.1.8,1.1,0,.8-.2,1.3-.2,1.5-.3.7-.6.6-.8.3-.3-.5-.6-.5-.9-.6-.4,0-.5.3,0,.2.4,0,.8.3.7,1-.1.5-1.2.8-1.4.8-.5,0-1,0-1-.8,0-1,.2-1.2.4-1.4.3-.2.2-.4.5-.8.2-.4,1.1-.3,1.3-.3s.1-.3,0-.3c0,0-.9,0-1.2.1-.3.1-.4.6-.5.8s-.5.3-.5-.4,0-1.1,0-1.4,0-.5.3-.6c.4,0,.7.1.7-.1,0-.4-.9.1-1.1-.5h0Z"
                            fill={backgroundColor}
                        />
                    </g>
                    <path
                        d="M12.8,24.8c-3.6-.9-6.5-3.8-7.3-7.5l1-.2c.8,3.3,3.3,5.9,6.6,6.7l-.2,1h0Z"
                        fill={backgroundColor}
                    />
                    <path
                        d="M17.3,24.9l-.2-1c3.3-.7,6-3.3,6.8-6.5l1,.2c-1,3.6-3.9,6.5-7.6,7.3h0Z"
                        fill={backgroundColor}
                    />
                    <path
                        d="M24,12.9c-.8-3.2-3.4-5.9-6.6-6.7l.2-1c3.7.9,6.5,3.7,7.4,7.4,0,0-1,.2-1,.2Z"
                        fill={backgroundColor}
                    />
                    <path
                        d="M6.3,13.1l-1-.2c.8-3.7,3.7-6.7,7.4-7.6l.2,1c-3.3.8-5.9,3.5-6.7,6.9Z"
                        fill={backgroundColor}
                    />
                </g>
            </g>
        </svg>
    );
};

export default CasetivityCompassIcon;
