import React from 'react';
import { useTheme } from '@mui/material';

interface ChatResponseWrapperProps {
    children: React.ReactNode;
}

export const ChatResponseWrapper: React.FC<ChatResponseWrapperProps> = ({ children }) => {
    const theme = useTheme();

    return (
        <div
            style={{
                maxWidth: '90%',
                width: 'fit-content',
                marginRight: theme.spacing(3),
                padding: theme.spacing(1, 1),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                backgroundColor: theme.palette.primary.main,
                borderRadius: '5px',
                border: '1px solid #ccc',
                color: theme.palette.primary.contrastText,
                boxShadow: '0 3px 5px rgba(0,0,0,0.1)',
            }}
        >
            {children}
        </div>
    );
};
