import React, { useMemo } from 'react';
import { isTableFieldErrorMessage } from 'fieldFactory/input/components/EditableTable/util/utils';
import { FormFieldUnion, TableFormField } from 'fieldFactory/translation/fromFlowable/types';
import trimEnd from 'lodash/trimEnd';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import createErrorTableFromMessages from 'fieldFactory/input/components/EditableTable/util/createErrorTableFromMessages';
import { useEvaluateTemplateInFormContext } from 'expressions/hooks/allForms/useEvaluateTemplate';

export const isTableField = (f: FormFieldUnion): f is TableFormField => {
    return f && f.type === 'table';
};

interface FormErrors {
    [key: string]: string[];
}

/**
 *
 * Maps arrays of field keys to arrays of messages - all keys to the same set of messages are grouped together.
 */
function groupFormErrors(formErrors: FormErrors): [string[], string[]][] {
    const errorMap = new Map<string, string[]>();

    for (const field in formErrors) {
        const errors = formErrors[field].join('|');
        if (errorMap.has(errors)) {
            errorMap.get(errors)!.push(field);
        } else {
            errorMap.set(errors, [field]);
        }
    }

    const result: [string[], string[]][] = [];
    for (const [errors, fields] of errorMap) {
        result.push([fields, errors.split('|')]);
    }

    return result;
}

export const stripAsteriskFromLabel = (label) => trimEnd(`${label}`, ' *');

interface ErrorsListProps {
    formErrors: {
        [field: string]: string[];
    } | null;
    fields: FormFieldUnion[];
    classes?: {
        li?: string;
    };
}
const TemplatedLabel = (props: { label: string; children: (label: string) => React.ReactNode }) => {
    const res = useEvaluateTemplateInFormContext(props.label);
    return <>{props.children(res)}</>;
};
const ErrorsListItems = ({ formErrors, fields, classes = {} }: ErrorsListProps) => {
    const fieldsById = useMemo(() => {
        return (fields || []).reduce((prev, curr) => {
            return {
                ...prev,
                [curr.id]: curr,
            };
        }, {});
    }, [fields]);

    return (
        <React.Fragment>
            {groupFormErrors(formErrors ?? {}).map(([keys, e]: [string[], string[]]) => {
                const isTable = (f): f is TableFormField => {
                    const messageIsTopLevelOnly = e.length === 1 && !isTableFieldErrorMessage(e[0]);
                    return f && isTableField(f) && !messageIsTopLevelOnly;
                };
                const fields = keys.map((k) => fieldsById[k] as FormFieldUnion);
                const tableErrors = fields.filter(isTable);
                const expressionErrors = fields.filter((f) => f && f.type === 'expression');
                const nonTableOrExpressionErrors = fields.filter((f) => !isTable(f) && f?.type !== 'expression');

                const labelMessage = (fields: FormFieldUnion[], message: string[] | string | JSX.Element) => {
                    const labels = fields.map((f) => f?.name?.trim()).filter(Boolean);
                    return (
                        <li className={classes.li}>
                            {labels.map((label, i, arr) => {
                                const isLast = i === arr.length - 1;
                                return (
                                    <React.Fragment key={label}>
                                        <TemplatedLabel label={label}>
                                            {(label) => stripAsteriskFromLabel(label)}
                                        </TemplatedLabel>
                                        {!isLast ? (
                                            <>
                                                {', '}
                                                {/* <br /> */}
                                            </>
                                        ) : null}
                                    </React.Fragment>
                                );
                            })}
                            {labels.length > 0 ? ': ' : ''}
                            {message}
                        </li>
                    );
                };
                const TableEl =
                    tableErrors.length === 0
                        ? null
                        : labelMessage(
                              tableErrors,
                              <>
                                  {tableErrors.map((f) => {
                                      const fieldMessages = createErrorTableFromMessages(
                                          e.filter(isTableFieldErrorMessage),
                                      );
                                      const fieldMessageEntries = Object.entries(fieldMessages);
                                      return (
                                          <div>
                                              {fieldMessageEntries.map(([row, fieldErrors], i) => {
                                                  return (
                                                      <div key={row}>
                                                          {`Row ${
                                                              (typeof row === 'string' ? parseInt(row, 10) : row) + 1
                                                          }: `}
                                                          <div>
                                                              <ul>
                                                                  <ErrorsListItems
                                                                      formErrors={fieldErrors}
                                                                      fields={f.params.columnObj}
                                                                      classes={classes}
                                                                  />
                                                              </ul>
                                                          </div>
                                                      </div>
                                                  );
                                              })}
                                          </div>
                                      );
                                  })}
                              </>,
                          );

                const MaybeExpressionEl =
                    expressionErrors.length === 0 ? null : (
                        <EvaluateFormattedMessage>
                            {({ evaluateFormattedMessage }) => {
                                let message: string[] | string | JSX.Element = evaluateFormattedMessage(
                                    Array.isArray(e) ? e.join(',') : e,
                                );
                                return <li className={classes.li}>{message}</li>;
                            }}
                        </EvaluateFormattedMessage>
                    );

                const MaybeLabelledEl =
                    nonTableOrExpressionErrors.length === 0 ? null : (
                        <EvaluateFormattedMessage>
                            {({ evaluateFormattedMessage }) => {
                                let message: string[] | string | JSX.Element = evaluateFormattedMessage(
                                    Array.isArray(e) ? e.join(', ') : e,
                                );
                                return labelMessage(nonTableOrExpressionErrors, message);
                            }}
                        </EvaluateFormattedMessage>
                    );

                return (
                    <React.Fragment key={keys.join(',')}>
                        {MaybeLabelledEl}
                        {TableEl}
                        {MaybeExpressionEl}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default ErrorsListItems;
