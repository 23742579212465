import * as React from 'react';
import { SFC } from 'react';
import { Card, Typography } from '@material-ui/core';
import CommentList from '../Comments';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { fromNullable, fromPredicate, tryCatch } from 'fp-ts/lib/Option';
import { useSingleKeyCachingExpression } from 'expressions/Provider/hooks/useKeyCachingEval';

interface ProcessConfigConfig {
    displayNotes?: string | boolean;
}
const CommentPanel: SFC<{
    processId?: string;
    taskId?: string;
}> = ({ processId, taskId }) => {
    const caseDefinition = useAppSelector((state: RootState) => {
        return fromNullable(state.bpm.processInstances.byId[processId])
            .mapNullable((process) => process.businessKey)
            .mapNullable((bk) => state.bpm.processDefinitions.byId[bk])
            .getOrElse(null);
    });
    const displayExpression: string | boolean | undefined = useAppSelector((state: RootState) => {
        return fromNullable(state.viewConfig.processes)
            .mapNullable((pis) => caseDefinition && pis[caseDefinition.key])
            .mapNullable((pi) => pi.config)
            .chain(fromPredicate<string>(Boolean))
            .chain((configStr) => tryCatch(() => JSON.parse(configStr) as ProcessConfigConfig))
            .mapNullable((config) => config.displayNotes)
            .toUndefined();
    });
    const injectContext = React.useMemo(
        () => ({
            caseDefinition,
        }),
        [caseDefinition],
    );
    const displayNotes = useSingleKeyCachingExpression(displayExpression, injectContext, true);
    const printMode = useAppSelector((state: RootState) => state.printMode);
    const Comments = (
        <CommentList
            title={
                <Typography component="h1" style={{ fontSize: '2em', fontWeight: 'bolder' }}>
                    Notes
                </Typography>
            }
            processId={processId}
            taskId={taskId}
            showFloatingButtons={false}
        />
    );
    if (!displayNotes) {
        return null;
    }
    if (printMode) {
        return Comments;
    }
    return (
        <Card style={{ marginBottom: '1em' }}>
            <div>{Comments}</div>
        </Card>
    );
};
export default CommentPanel;
