import React, { useCallback } from 'react';
import DisplayStatus from '../DisplayStatus';
import { NetworkUnavailable, ServerError } from '../pages';
import DeferredSpinner from 'components/DeferredSpinner';
import ErrorPage from '../pages/BaseErrorPage';
import { RemoteError, RemoteLoading, RemoteMoved, RemoteSuccess } from 'remoteStatus/one/reducer';
import { RootState, useAppSelector, useAppStore } from 'reducers/rootReducer';
import { useOfflineTaskOtherProfile } from 'offline_app/offline_stateful_tasks/back_online/components/OfflineWorkBanner';
import loadProfile from 'auth/profiles/util/loadProfile';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Button } from '@material-ui/core';
import isTheInstalledApp from 'util/isTheInstalledApp';
import useIsAnonymous from 'util/hooks/useIsAnonymous';
import Warning from '@mui/icons-material/Warning';

const TaskInstance404Handler: React.FC<{
    id: string;
    doesNotExistOffline: () => JSX.Element;
    doesExistOffline: (otherProfile: { display: string; userId: string }) => JSX.Element;
}> = ({ id, doesExistOffline, doesNotExistOffline }) => {
    const existsOffline = useAppSelector((state: RootState) => Boolean(state.offlineTasks?.[id]));
    const offlineTaskOtherProfile = useOfflineTaskOtherProfile(id, -1);
    if (offlineTaskOtherProfile === -1) {
        return <DeferredSpinner />;
    }
    if (existsOffline && offlineTaskOtherProfile) {
        return doesExistOffline(offlineTaskOtherProfile);
    }
    return doesNotExistOffline();
};

const SwitchProfile: React.FunctionComponent<{ display: string; userId: string }> = ({ userId, display }) => {
    const store = useAppStore();
    const switchProfile = useCallback(() => {
        loadProfile(store, userId, window.location.pathname);
    }, [store, userId]);
    const isAnon = useIsAnonymous();
    if (isAnon) {
        return (
            <Alert severity="warning">
                <AlertTitle>You must be logged in to view this task in online mode.</AlertTitle>
                Either log in to continue, or switch to offline mode to continue working.
            </Alert>
        );
    }
    return (
        <div>
            <Alert severity="warning">
                <AlertTitle>You do not have permission to view this task as the current profile.</AlertTitle>
                This task was previously downloaded as <em>{display}</em>. Would you like switch to that profile?
                <div style={{ marginTop: '1em' }} />
                <Button variant="outlined" color="inherit" onClick={switchProfile}>
                    Switch to {display}
                </Button>
            </Alert>
        </div>
    );
};

interface FormDisplayStatusProps {
    resource: string;
    id: string;
    showSuccessOffline: boolean;
    renderSuccess: () => JSX.Element;
    renderSpinner?: () => JSX.Element;
    refresh?: (e: any) => void;
    showPrevWhileLoading?: boolean;
}
const FormDisplayStatus: React.FunctionComponent<FormDisplayStatusProps> = React.memo(
    ({
        showPrevWhileLoading = true,
        resource,
        id,
        showSuccessOffline,
        renderSuccess,
        refresh,
        renderSpinner = () => <DeferredSpinner />,
    }) => {
        const renderUnhandledError = React.useCallback(() => {
            return (
                <ErrorPage headingText="Unhandled Error" Icon={Warning} subText={'Check console for error details'} />
            );
        }, []);
        const renderLoading = React.useCallback(
            (prevStatus: RemoteError | RemoteSuccess | RemoteLoading | RemoteMoved | 'initial') => {
                if (
                    prevStatus === 'initial' ||
                    prevStatus._type === 'error' ||
                    (prevStatus._type === 'success' && !showPrevWhileLoading)
                ) {
                    return renderSpinner();
                }
                return renderSuccess();
            },
            [renderSpinner, renderSuccess, showPrevWhileLoading],
        );
        const renderMoved = React.useCallback((newId: string) => <div>Element moved to id: {newId}</div>, []);
        const renderNetworkError = React.useCallback(() => {
            // render any existing/cached data if possible
            return showSuccessOffline ? renderSuccess() : <NetworkUnavailable retry={refresh} />;
        }, [showSuccessOffline, refresh, renderSuccess]);

        const renderServerError = React.useCallback(
            (code: number, message: string) => {
                if (resource === 'TaskInstance' && isTheInstalledApp()) {
                    return (
                        <TaskInstance404Handler
                            id={id}
                            doesExistOffline={(otherProfile) => {
                                return <SwitchProfile display={otherProfile.display} userId={otherProfile.userId} />;
                            }}
                            doesNotExistOffline={() => <ServerError code={code} message={message} />}
                        />
                    );
                }
                return <ServerError code={code} message={message} />;
            },
            [id, resource],
        );
        return (
            <DisplayStatus
                resource={resource}
                id={id}
                renderUnhandledError={renderUnhandledError}
                renderSuccess={renderSuccess}
                renderLoading={renderLoading}
                renderMoved={renderMoved}
                renderNetworkError={renderNetworkError}
                renderServerError={renderServerError}
            />
        );
    },
);
export default FormDisplayStatus;
