import React from 'react';
import b64ToFile, { saveFile } from '../../util/b64ToFile';
import buildHeaders from 'sideEffect/buildHeaders';
import { arrayBufferToBase64 } from 'util/fetch';
import { useAppSelector } from 'reducers/rootReducer';

const config = require('../../../config.js');

interface DownloadFileProps {
    url: string;
    fileName: string;
    contentType: string;
}

const getContent = (url) =>
    fetch(`${config.BACKEND_BASE_URL}${url}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
        }),
    });
export const processContent = (contentType) => (response) => {
    if (response && response.status !== 404 && response.status !== 500) {
        return response.arrayBuffer().then((buffer) => {
            const base64Flag = ''; // `data:${contentType};base64,`; No flag!
            const b64data = arrayBufferToBase64(buffer);
            return base64Flag + b64data;
        });
    }
    if (response && response.status === 404) {
        alert('File not found.');
    }
    if (response && response.status === 500) {
        alert('Internal server error.');
    }
    throw new Error(`Failed request for content: ${JSON.stringify(response)}`);
};

// TODO refactor to function component and make just text if in print mode.
const DownloadFromLink: React.FC<DownloadFileProps> = (props) => {
    const getFile = (url = props.url, fileName = props.fileName, contentType = props.contentType) => {
        getContent(url)
            .then(processContent(contentType))
            .then((b64) => b64ToFile(b64, fileName, contentType))
            .then(saveFile)
            .catch((e) => console.error(e));
    };

    const { fileName } = props;
    const printMode = useAppSelector((state) => state.printMode);
    if (printMode) {
        return (
            <div>
                <span style={{ textDecorationLine: 'underline' }}>{fileName}</span>
            </div>
        );
    }
    return (
        <div>
            {fileName && (
                <a // eslint-disable-line
                    href="javascript:;" // eslint-disable-line
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        getFile();
                    }}
                >
                    {fileName}
                </a>
            )}
        </div>
    );
};

export default DownloadFromLink;
