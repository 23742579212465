import {
    FormFieldUnion,
    EntityTypeaheadField,
    EntityLookupField,
    EntityMultipleTypeaheadField,
    ListField,
} from 'fieldFactory/translation/fromFlowable/types/index';
import { EvaluationFactors } from 'expressions/expressionArrays/formValuesInDynamicContext';
import {
    getValueset1Fields,
    wrapEachValueInArray,
    fieldsToForceInsertion,
    getExpressionsFromFields,
    getConceptsAvailableExpressionsFromFields,
    getOptionAvailabilityExpressionsFromFields,
} from 'bpm/form-context-utils';
import { ValueSets } from 'valueSets/reducer';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';
import ViewConfig from 'reducers/ViewConfigType';
import { EXTERNALGISID } from 'components/generics/form/EntityFormContext/util/getAllFieldsExpected';
import { ReportDefinitionParam } from 'report2/ReportDefinition';
import { fromPairs, groupBy, map, takeWhile } from 'lodash';
import decodeEventConfig from 'fieldFactory/input/components/Event/util/decodeEventConfig';
import getAdhocVariablesContextSelector from 'components/generics/form/EntityFormContext/util/getVariablesContextSelector';

export const getUseBackingValuesRegardlessOfDisplayStatus = (fields: FormFieldUnion[], persistAll: boolean = false) =>
    fields.reduce(
        (prev, curr) => {
            if (curr.type === 'event' && curr.params.event.configs) {
                const eventConf = decodeEventConfig(curr.params.event.configs, 'RETURN_NULL');
                Object.values(eventConf?.fieldMapping ?? {}).forEach((fn) => {
                    prev[fn] = true;
                });
                return prev;
            }

            // if we're in a wizard, just persist everything.
            if (persistAll) {
                prev[curr.id] = true;
                return prev;
            }
            // don't "correct" values of linked-fields because they can be set externally
            if (curr.params && (curr.params as any).entityField) {
                prev[curr.id] = true;
            }
            if (curr.params?.configs?.fieldConfig) {
                try {
                    const config = JSON.parse(curr.params.configs.fieldConfig);
                    if (config.persistValueWhenDisabled) {
                        prev[curr.id] = true;
                    }
                } catch {
                    return prev;
                }
            }
            return prev;
        },
        {
            [EXTERNALGISID]: true,
        } as {
            [field: string]: true;
        },
    );

export const getNullIfHidden = (fields: FormFieldUnion[]) => {
    return fields.reduce((prev, curr) => {
        if (curr.params?.configs?.fieldConfig) {
            try {
                const config = JSON.parse(curr.params.configs.fieldConfig);
                if (config.nullIfHidden) {
                    prev[curr.id] = true;
                }
            } catch {
                return prev;
            }
        }
        return prev;
    }, {} as { [field: string]: true });
};
export const getNullIfDisabled = (fields: FormFieldUnion[]) => {
    return fields.reduce((prev, curr) => {
        if (curr.params?.configs?.fieldConfig) {
            try {
                const config = JSON.parse(curr.params.configs.fieldConfig);
                if (config.nullIfDisabled) {
                    prev[curr.id] = true;
                }
            } catch {
                return prev;
            }
        }
        return prev;
    }, {} as { [field: string]: true });
};

export const getBypassFilterConsistency = (fields: (FormFieldUnion | ReportDefinitionParam)[]) => {
    return fields.reduce((prev, curr) => {
        if ((curr as FormFieldUnion).params?.configs?.fieldConfig) {
            try {
                const config = JSON.parse((curr as FormFieldUnion).params.configs.fieldConfig);
                if (config.bypassFilterConsistency) {
                    prev[curr.id] = true;
                }
            } catch {
                return prev;
            }
        }
        return prev;
    }, {} as { [field: string]: true });
};

export const getFilteredRef1FilterExpressions = (fields: FormFieldUnion[] | ReportDefinitionParam[]) => {
    const bypassFilters = getBypassFilterConsistency(fields || []);
    return Object.fromEntries(
        Object.entries(getRef1FilterExpressions(fields || [])).filter(([source]) => !bypassFilters[source]),
    );
};

export function orderVariablesByAssumedExecutionOrder(variables: [string, string][]) {
    const groupedByDollarCount = groupBy(variables, ([key]) => {
        const dollarCount = takeWhile(key, (c) => c === '$').length;
        return dollarCount > 1 ? dollarCount : 0;
    });

    const objects = map(groupedByDollarCount, (group) => fromPairs(group));
    return objects as {
        [$variableName: string]: string;
    }[];
}

export const getTableExpressions = (fields: FormFieldUnion[]): EvaluationFactors['tableExpressions'] => {
    return fields.reduce((prev, f): EvaluationFactors['tableExpressions'] => {
        if (f.type === 'table' && f.params && f.params.columnObj) {
            prev[f.id] = {
                variables: orderVariablesByAssumedExecutionOrder(
                    f.params.columnObj
                        .filter(
                            (f) =>
                                f.type === 'expression' &&
                                ((f.value && typeof f.value === 'string' && f.value.startsWith('$=[')) ||
                                    (f.expression &&
                                        typeof f.expression === 'string' &&
                                        f.expression.startsWith('$=['))),
                        )
                        .map((f) => [f.id, (f.value || f['expression']).slice(3, -1)]),
                ),
                fieldWidgets: f.params.columnObj
                    .map((c) => c.id)
                    .reduce((prev, curr) => {
                        prev[curr] = [curr];
                        return prev;
                    }, {}),
                tableExpressions: getTableExpressions(f.params.columnObj),
                visibilityExpressions: wrapEachValueInArray(
                    getExpressionsFromFields('visibility')(f.params.columnObj) || {},
                ),
                editabilityExpressions: wrapEachValueInArray(
                    getExpressionsFromFields('editable')(f.params.columnObj) || {},
                ),
                valueset1AvailableConceptsExpressions:
                    getConceptsAvailableExpressionsFromFields(f.params.columnObj) || {},
                dropdownAvailableOptionsExpressions:
                    getOptionAvailabilityExpressionsFromFields(f.params.columnObj) || {},
                valueset1Fields: getValueset1Fields(f.params.columnObj),
                reference1EntityFilterExpressions: getFilteredRef1FilterExpressions(f.params.columnObj || []),
                useBackingValuesRegardlessOfDisplayStatus: getUseBackingValuesRegardlessOfDisplayStatus(
                    f.params.columnObj,
                ),
            };
        }
        return prev;
    }, {});
};

export const getRef1FilterExpressions = (
    fields: (FormFieldUnion | ReportDefinitionParam)[],
): EvaluationFactors['reference1EntityFilterExpressions'] => {
    const isDynamicFilterableRefOneField = (
        field: FormFieldUnion | ReportDefinitionParam,
    ): field is EntityTypeaheadField | EntityLookupField => {
        return field.type === 'entity-lookup' || field.type === 'entity-typeahead';
    };
    return fields.reduce((prev: EvaluationFactors['reference1EntityFilterExpressions'], f) => {
        if (isDynamicFilterableRefOneField(f) && f.params && f.params.filter) {
            prev[f.id] = {
                entityType: f.params.entity,
                expression: f.params.filter,
            };
        }
        return prev;
    }, {});
};
export const getRefManyFilterExpressions = (
    fields: FormFieldUnion[],
    viewConfig: ViewConfig,
): EvaluationFactors['referenceManyEntityFilterExpressions'] => {
    const isDynamicFilterableRefManyField = (
        field: FormFieldUnion,
    ): field is EntityMultipleTypeaheadField | ListField => {
        return (
            field.type === 'entity-multi-select-chip' ||
            field.type === 'multiple-entity-typeahead' ||
            field.type === 'list-view'
        );
    };
    return fields.reduce((prev: EvaluationFactors['referenceManyEntityFilterExpressions'], f) => {
        if (isDynamicFilterableRefManyField(f) && f.params && f.params.filter) {
            if (f.type === 'list-view' && !viewConfig.views[f.params.viewName]) {
                console.error('view "' + f.params.viewName + '" does not exist in viewConfig.');
            } else {
                prev[f.id] = {
                    entityType: f.type === 'list-view' ? viewConfig.views[f.params.viewName].entity : f.params.entity,
                    expression: f.params.filter,
                };
            }
        }
        return prev;
    }, {});
};
type AdhocVariablesContext = ReturnType<ReturnType<typeof getAdhocVariablesContextSelector>>;
const combiner = (
    evaluator: FormContextEvaluator,
    fields: FormFieldUnion[],
    values: {},
    entities: {},
    valueSets: ValueSets,
    initialValues: {},
    extraContext?: {},
    adhocVariablesContext?: AdhocVariablesContext,
): ReturnType<FormContextEvaluator['evaluate']> & { adhocVariablesContext?: AdhocVariablesContext } => {
    const result = evaluator.evaluate(values || initialValues || {}, valueSets, initialValues || {}, entities, {
        ...extraContext,
        ...adhocVariablesContext,
    });
    // could just return the above 'result', however formValues need to include fields that are 'faked'
    // for example address widget fields.
    // TODO: can also do this for FileUpload fields (mimeType + filename)
    return {
        ...result,
        fieldValues: Object.assign(
            {},
            result.fieldValues,
            // override with contents of address widget
            ...(values ? fieldsToForceInsertion(fields).map((f) => ({ [f]: values[f] })) : []),
        ),
        adhocVariablesContext,
    };
};
export default combiner;
