import React, { useCallback, useEffect, useState } from 'react';
import { Button, CardActions, CardHeader, useTheme } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import PinForm from 'offline_app/offline_stateful_tasks/download/components/PinForm';
import { getPromptController } from './promptController';

const OfflinePinEntryPopup = () => {
    const [open, setOpen] = useState<false | { message?: string; onPinCb: (pin: string) => void }>(false);
    useEffect(() => {
        getPromptController().subscribe((onPinCb, message) => {
            setOpen({
                onPinCb,
                message,
            });
        });
        return () => {
            getPromptController().unSubscribe();
        };
    }, []);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    const theme = useTheme();
    if (!open) {
        return null;
    }
    return (
        <Dialog open onClose={handleClose}>
            <CardHeader title="Enter PIN" />
            <div>
                {open.message ? (
                    <div style={{ paddingLeft: '2em', color: theme.palette.error.dark }}>{open.message}</div>
                ) : null}
                <PinForm
                    onSubmit={(pin) => {
                        setOpen(false);
                        open.onPinCb(pin);
                    }}
                    renderActions={({ submitBtn }) => (
                        <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={handleClose}>Cancel</Button>
                            {submitBtn}
                        </CardActions>
                    )}
                />
            </div>
        </Dialog>
    );
};

export default OfflinePinEntryPopup;
