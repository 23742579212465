import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { Button } from '@material-ui/core';
import ContentSend from '@material-ui/icons/Send';
import { buttonStyle } from '../LinkWidget/Component';
import { RootState } from 'reducers/rootReducer';
import { startProcess } from 'bpm/create-process-instance/actions';
import { makeStyles } from '@material-ui/core/styles';
import { getDashboardLinkWidgetIconSelector } from 'util/applicationConfig';
import { useAppSelector } from 'reducers/rootReducer';

// Overrides the global theme which was capitalizing letters after dash
const useStyles = makeStyles((theme) => ({
    noCapitalize: {
        textTransform: 'none',
    },
}));

interface StartProcessWidgetProps {
    processDefinitionKey: string;
    startVariables?: {} | string;
    title: string;
}
const mapStateToProps = (state: RootState, ownProps: StartProcessWidgetProps) => ({
    processDefinition: state.bpm?.processDefinitions?.byId?.[ownProps.processDefinitionKey],
});

const dispatches = {
    createProcessInstance: startProcess,
    pushAction,
};
type Dispatches = typeof dispatches;

const StartProcessWidgetComponent = (
    props: StartProcessWidgetProps & ReturnType<typeof mapStateToProps> & Dispatches,
) => {
    const { title, processDefinition, startVariables, createProcessInstance } = props;
    const createAndRedirectOnSuccess = useCallback(() => {
        if (!processDefinition) {
            return;
        }
        const values =
            typeof startVariables === 'string'
                ? (() => {
                      if (!startVariables) {
                          return undefined;
                      }
                      try {
                          return JSON.parse(startVariables);
                      } catch (e) {
                          return undefined;
                      }
                  })()
                : startVariables;
        createProcessInstance(
            {
                processDefinitionId: processDefinition.id,
                values,
            },
            () => null,
            {},
            true,
        );
    }, [processDefinition, startVariables, createProcessInstance]);
    const classes = useStyles();
    const dashboardLinkWidgetIcon = useAppSelector(getDashboardLinkWidgetIconSelector);

    return (
        <Button
            classes={{ label: classes.noCapitalize }}
            variant="contained"
            style={buttonStyle}
            onClick={createAndRedirectOnSuccess}
            color="primary"
            endIcon={dashboardLinkWidgetIcon && <ContentSend />}
        >
            {title}
        </Button>
    );
};

const enhance = connect(mapStateToProps, dispatches);

const StartProcessWidget: React.ComponentType<StartProcessWidgetProps> = enhance(StartProcessWidgetComponent);
export default StartProcessWidget;
