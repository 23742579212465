import React from 'react';
import { Widget } from 'dashboard2/dashboard-config/types';
import Widgetize from 'dashboard2/widgets/widgetize';
import MyActiveTasksWidget from 'dashboard2/widgets/components/MyActiveTasksWidget';
import ClaimableTasksWidget from 'dashboard2/widgets/components/ClaimableTasksWidget';
import MyReportsWidget from 'dashboard2/widgets/components/MyReportsWidget';
import EntitySearchWidget from 'dashboard2/widgets/components/EntitySearchWidget';
import LinkWidget from 'dashboard2/widgets/components/LinkWidget';
import StartProcessWidget from 'dashboard2/widgets/components/StartProcessWidget';
import ReportWidget from 'dashboard2/widgets/report/components';
import GetPushAction from './components/GetPushAction';
import { Typography } from '@material-ui/core';
import HtmlWidget from 'dashboard2/widgets/components/HtmlWidget';
import TextTemplatePage from 'templatePage/components';
import DashboardLinkWidget from 'dashboard2/widgets/components/DashboardLinkWidget';
import OfflineTasksWidget from 'dashboard2/widgets/components/OfflineTasksWidget';
import Quicksight from 'dashboard2/widgets/components/Quicksight';
export default class WidgetFactory {
    static getComponent = (widgetConfiguration: Widget, dashConfigId?: string) => {
        const { title } = widgetConfiguration;
        switch (widgetConfiguration.type) {
            case 'Quicksight': {
                const { Component } = Quicksight;
                return <Component id={dashConfigId} qsConfigName={widgetConfiguration.definition.qsConfigName} />;
            }
            case 'OfflineTasksWidget': {
                const { Component } = OfflineTasksWidget;
                return (
                    <Widgetize
                        title={{
                            title: 'Offline Tasks',
                        }}
                        layout={{}}
                    >
                        <Component />
                    </Widgetize>
                );
            }
            case 'DashboardLinkWidget': {
                const { Component } = DashboardLinkWidget;
                const {
                    title,
                    definition: { toDashboard },
                } = widgetConfiguration;
                return (
                    <Widgetize title="none" layout={{}}>
                        <Component toDashboard={toDashboard} title={title} />
                    </Widgetize>
                );
            }
            case 'MyActiveTasksWidget': {
                const { GetHandleTitleClick, Component } = MyActiveTasksWidget;
                const {
                    definition: { drillToMyTasks },
                } = widgetConfiguration;
                return (
                    <Widgetize
                        title={{
                            title,
                            GetHandleTitleClick: drillToMyTasks ? GetHandleTitleClick : undefined,
                        }}
                        layout={{}}
                    >
                        <Component useTableView={widgetConfiguration.definition.useTaskListView !== false} />
                    </Widgetize>
                );
            }
            case 'ClaimableTasksWidget': {
                const { Component } = ClaimableTasksWidget;
                const {
                    processKey,
                    taskKey,
                    roles,
                    viewName,
                    onlyUnassigned,
                    onlyAssignedToUser,
                    claimTaskOnSelect,
                    filter,
                } = widgetConfiguration.definition;
                return (
                    <Widgetize
                        title={{
                            title,
                        }}
                        layout={{}}
                    >
                        <Component
                            filter={filter}
                            claimTaskOnSelect={claimTaskOnSelect}
                            processKey={processKey}
                            taskKey={taskKey}
                            roles={roles}
                            viewName={viewName}
                            onlyUnassigned={onlyUnassigned}
                            onlyAssignedToUser={onlyAssignedToUser}
                        />
                    </Widgetize>
                );
            }
            case 'EntitySearchWidget': {
                const { Component } = EntitySearchWidget;
                const { entity, viewName, filterConfig, navTo, showSearchFields } = widgetConfiguration.definition;
                return (
                    <Widgetize
                        title={{ title }}
                        layout={{
                            contentStyle: {
                                height: undefined,
                                flexGrow: 1,
                                overflow: 'auto',
                            },
                            cardStyle: {
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            },
                        }}
                    >
                        <Component
                            entity={entity}
                            viewName={viewName}
                            navToTemplate={navTo}
                            filterConfig={filterConfig}
                            showSearchFields={showSearchFields}
                        />
                    </Widgetize>
                );
            }
            case 'TemplatePageWidget': {
                return (
                    <TextTemplatePage
                        mobileAppbar={false}
                        padding={false}
                        textTemplateName={widgetConfiguration.definition.textTemplateName}
                    />
                );
            }
            case 'HtmlWidget': {
                const { Component } = HtmlWidget;
                return (
                    <Widgetize title="none" layout={{}}>
                        <Component html={widgetConfiguration.definition.html} />
                    </Widgetize>
                );
            }
            case 'LinkWidget': {
                const { Component } = LinkWidget;
                const {
                    title,
                    definition: { url },
                } = widgetConfiguration;
                return (
                    <Widgetize title="none" layout={{}}>
                        <Component url={url} title={title} />
                    </Widgetize>
                );
            }
            case 'StartProcessWidget': {
                const { Component } = StartProcessWidget;
                const { title, definition } = widgetConfiguration;
                return (
                    <Widgetize title="none" layout={{}}>
                        <Component
                            startVariables={definition.startVariables}
                            processDefinitionKey={definition.processDefinitionKey}
                            title={title}
                        />
                    </Widgetize>
                );
            }
            case 'ReportWidget': {
                const { Component } = ReportWidget;
                const { title, definition } = widgetConfiguration;
                const titleUrl = definition.titleUrl;
                if (definition.type === 'singleValue') {
                    return (
                        <Widgetize title="none" layout={{}} prefetchValuesets={definition.prefetchValuesets}>
                            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        top: '50%',
                                        msTransform: 'translateY(-50%)',
                                        transform: 'translateY(-50%)',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        verticalAlign: 'middle',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography variant="h5" component="span" style={{ padding: 16 }}>
                                        {title}
                                    </Typography>
                                    <Typography variant="h5" component="span" style={{ padding: 16 }}>
                                        <Component definition={definition} />
                                    </Typography>
                                </div>
                            </div>
                        </Widgetize>
                    );
                }
                return (
                    <Widgetize
                        prefetchValuesets={definition.prefetchValuesets}
                        title={{
                            title,
                            GetHandleTitleClick: titleUrl ? GetPushAction : undefined,
                            GetHandleTitleClickProps: titleUrl ? { url: definition.titleUrl } : undefined,
                        }}
                        layout={{}}
                    >
                        <Component definition={definition} />
                    </Widgetize>
                );
            }
            case 'MyReportsWidget': {
                const { Component } = MyReportsWidget;
                const { reportName, outputContentType, viewName, running, longRunning, startTime } =
                    widgetConfiguration.definition;
                return (
                    <Widgetize
                        title={{
                            title,
                        }}
                        layout={{}}
                    >
                        <Component
                            reportName={reportName}
                            outputContentType={outputContentType}
                            viewName={viewName}
                            running={running}
                            longRunning={longRunning}
                            startTime={startTime}
                        />
                    </Widgetize>
                );
            }
        }
    };
    static create = (
        widgetConfiguration: Widget,
        dashConfigId?: string,
    ): React.ReactElement<{ key: string; id: string; 'data-originaldefinition': string }> => {
        return (
            <div
                data-originaldefinition={JSON.stringify(widgetConfiguration)}
                style={{
                    height: '100%',
                }}
                key={widgetConfiguration.id}
                id={widgetConfiguration.id}
            >
                {WidgetFactory.getComponent(widgetConfiguration, dashConfigId)}
            </div>
        );
    };

    static createAllWidgets(widgets: Widget[], dashConfigId?: string) {
        return widgets.map((widgetConfig) => WidgetFactory.create(widgetConfig, dashConfigId));
    }
}
