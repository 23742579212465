import React, { Suspense } from 'react';

const Component = React.lazy(() => import('./ReportBuilder'));

const LazyReportBuilder = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    );
};
export default LazyReportBuilder;
