import * as startProcess from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { Epic, combineEpics } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, flatMap, tap, catchError, withLatestFrom, map } from 'rxjs/operators';
import { of, concat, Observable, empty } from 'rxjs';
import { getResponseAndThrowErrorForNon200 } from 'sideEffect/crud/util/epics/CoreCrud/shared';
import { crudGetList } from 'sideEffect/crud/getList/actions';
import { push } from 'connected-react-router';
import { getSendBusinessKeyAlongWithProcessIdForEasyAttributeSecuritySelector } from 'util/applicationConfig';

const startProcessFromKeyFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(startProcess.startProcessFromKey)),
        withLatestFrom(state$.pipe(map((state) => state.bpm.processDefinitions.byId))),
        flatMap(([action, processDefinitionsById]) => {
            const { processDefinitionKey, ...restOfPayload } = action.payload;
            const pdId =
                processDefinitionsById[processDefinitionKey] && processDefinitionsById[processDefinitionKey].id;
            if (pdId) {
                return of(
                    startProcess.startProcess(
                        {
                            ...restOfPayload,
                            processDefinitionId: pdId,
                        },
                        action.successCb,
                        action.errorsCbs,
                        action.handleRedirect,
                    ),
                );
            }
            return of(action).pipe(
                tap((action) => {
                    action.keyNotFoundCb();
                }),
                flatMap((action) => empty()),
            );
        }),
    );

const startProcessFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(startProcess.startProcess)),
        flatMap(({ payload, successCb, errorsCbs = {}, handleRedirect }) =>
            services.startProcessInstance(payload).pipe(
                (r) => getResponseAndThrowErrorForNon200<startProcess.StartProcessResponse>(r),
                tap((response) => successCb && successCb(response)),
                withLatestFrom(
                    state$.pipe(map((state) => state.viewConfig.user.id)),
                    state$.pipe(
                        map((state) => getSendBusinessKeyAlongWithProcessIdForEasyAttributeSecuritySelector(state)),
                    ),
                ),
                flatMap(([response, currentUserId, sendBusinessKeyAlongWithProcessIdForEasyAttributeSecurity]) =>
                    handleRedirect
                        ? concat(
                              of(startProcess.startProcessSuccess(response, payload)),
                              Observable.create((observer) => {
                                  const filter = {
                                      endTime__NOT_EMPTY: false,
                                      'processInstance.id': response.id,
                                  };
                                  if (sendBusinessKeyAlongWithProcessIdForEasyAttributeSecurity) {
                                      filter['processInstance.businessKey'] = response.businessKey;
                                  }
                                  observer.next(
                                      crudGetList(
                                          {
                                              resource: 'TaskInstance',
                                              pagination: {
                                                  page: 1,
                                                  perPage: 1,
                                              },
                                              sort: {
                                                  field: 'endTime',
                                                  order: 'ASC',
                                              },
                                              filter,
                                              view: null,
                                              cb: ({ response: tiResponse, total }) => {
                                                  if (
                                                      total === 1 &&
                                                      (tiResponse[0].assigneeId === currentUserId ||
                                                          tiResponse[0].assignee?.login === 'anonymousUser')
                                                  ) {
                                                      const initialTaskId = tiResponse[0].id;
                                                      observer.next(
                                                          push(
                                                              `/processes/${response.id}/tasks/${initialTaskId}/start`,
                                                          ),
                                                      );
                                                  } else {
                                                      observer.next(push(`/processes/${response.id}`));
                                                  }
                                                  observer.complete();
                                              },
                                              appendExpansions: ['assignee.login'],
                                              errorsCbs: {
                                                  '*': () => {
                                                      observer.next(push(response.id));
                                                      observer.complete();
                                                  },
                                              },
                                          },
                                          false,
                                      ),
                                  );
                              }) as Observable<RootAction>,
                          )
                        : concat(
                              of(startProcess.startProcessSuccess(response, payload)),
                              of(
                                  enqueueSnackbarAction({
                                      message: 'Process Started',
                                      options: { variant: 'success' },
                                  }),
                              ),
                          ),
                ),
                catchError((err) => {
                    const forAllErrorsCb = errorsCbs['*'];
                    if (forAllErrorsCb) {
                        forAllErrorsCb();
                    }
                    const maybeErrorCb = errorsCbs[err.status];
                    if (maybeErrorCb) {
                        maybeErrorCb();
                    }
                    return concat(
                        of(
                            enqueueSnackbarAction({
                                message: 'Failed to start Process Instance',
                                options: { variant: 'error' },
                            }),
                        ),
                        of(startProcess.startProcessFailure(err, payload)),
                    );
                }),
            ),
        ),
    );

const startProcessFlows = combineEpics(startProcessFlow, startProcessFromKeyFlow);
export default startProcessFlows;
