import React from 'react';
import { FormControl, IconButton, MenuItem, Paper, Select, useTheme } from '@mui/material';
import { AssistantType, REPORT_BUILDER, assistants } from 'chatbot/constants';
import { ArrowDropDown, Close } from '@mui/icons-material';
import { Refresh } from '@material-ui/icons';
import { useChatBot } from 'chatbot/ChatbotProvider';

const ModeSelectorHeader = () => {
    const theme = useTheme();
    const modes = assistants;
    const { state: chatState, updateAssistantContext, refresh, toggleChat } = useChatBot();
    const { assistant: activeMode } = chatState;
    const disabledAssistants = [REPORT_BUILDER];
    return (
        <Paper
            sx={{
                backgroundColor: theme.palette.primary.main,
                padding: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <FormControl fullWidth>
                <Select
                    disabled={modes.length === 1}
                    IconComponent={modes.length === 1 ? null : ArrowDropDown}
                    id="mode-selector"
                    value={activeMode}
                    onChange={(e) => {
                        updateAssistantContext(e.target.value as AssistantType);
                    }}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        border: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        '.MuiSelect-select': {
                            padding: theme.spacing(1),
                            fontSize: '1.2rem',
                            display: 'flex',
                            alignItems: 'center',
                            '&.Mui-disabled': {
                                color: theme.palette.primary.contrastText,
                                WebkitTextFillColor: theme.palette.primary.contrastText,
                            },
                        },
                        '.MuiSvgIcon-root': {
                            color: theme.palette.primary.contrastText,
                        },
                    }}
                    MenuProps={{
                        sx: {
                            width: 'fit-content',
                            zIndex: 2147483648, // number from app.tsx +1
                        },
                        disableScrollLock: true,
                    }}
                >
                    {modes.map((mode) => (
                        <MenuItem
                            key={mode.assistant}
                            value={mode.assistant}
                            disabled={disabledAssistants.includes(mode.assistant)}
                        >
                            {mode.displayName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                <IconButton
                    onClick={refresh}
                    sx={{
                        padding: theme.spacing(0.3),
                        color: theme.palette.primary.contrastText,
                    }}
                    aria-label="Refresh"
                >
                    <Refresh />
                </IconButton>
                <IconButton
                    onClick={toggleChat}
                    sx={{
                        padding: theme.spacing(0.3),
                        color: theme.palette.primary.contrastText,
                    }}
                    aria-label="Close"
                >
                    <Close />
                </IconButton>
            </div>
        </Paper>
    );
};

export default React.memo(ModeSelectorHeader);
