if (typeof window !== 'undefined' && !window.alert) {
    window.alert = (...args) => console.log(...args);
}
export const alertOnce = (expression: string, err: Error) => {
    console.error(err);
    const key = `__alerted_casetivity_${expression}`;
    if (typeof window !== 'undefined' && !window[key]) {
        const stringifiedErr = JSON.stringify(err);
        window.alert(
            `Failed expression "${expression}"
            ${stringifiedErr !== '{}' ? stringifiedErr : 'Please check the console for error details'}
            We have set the result to 'true' by default.
            `,
        );
        window[key] = true;
    }
};
