import React, { useEffect, useMemo, useState } from 'react';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import CasetivityIdleTimer from 'idle-timer/components/IdleTimer';
import useIsAnonymous from 'util/hooks/useIsAnonymous';
import { storageController } from 'storage';

// 1 hour
const DEFAULT_TIME_TO_WARNING = 59 * 60 * 1000;
const DEFAULT_WARNING_TIME = 60 * 1000;

const ConnectedIdleTimer: React.SFC<{}> = (props) => {
    const isAnonymous = useIsAnonymous();
    const initialHasToken = useMemo(() => Boolean(storageController.getToken()), []);
    const [receivedToken, setReceivedToken] = useState(initialHasToken);
    useEffect(() => {
        storageController.subscribeToSetToken(() => {
            setReceivedToken(true);
        });
        storageController.subscribeToClearToken(() => {
            setReceivedToken(false);
        });
        return () => {
            storageController.clearSubscriptions();
        };
    }, []);
    const warningTime = (window as any).CASETIVITY_OVERRIDE_WARNING_TIME || DEFAULT_WARNING_TIME;
    const _timeToWarning = useAppSelector((state: RootState) => {
        if (state.viewConfig?.application?.timeout) {
            return parseInt(state.viewConfig.application.timeout) - warningTime;
        }
        return DEFAULT_TIME_TO_WARNING;
    });
    const timeToWarning = (window as any).CASETIVITY_OVERRIDE_TIME_TO_WARNING || _timeToWarning;
    // idle-timer and 'children' need to be wrapped in the same parent div to work.
    return (
        <div style={{ height: '100%' }}>
            {!isAnonymous || receivedToken ? (
                <CasetivityIdleTimer timeToWarning={timeToWarning} warningTime={warningTime} />
            ) : null}
            {props.children}
        </div>
    );
};
export default ConnectedIdleTimer;
