import * as React from 'react';
import { useData } from 'fieldFactory/offline/XMany';
import useViewConfig from 'util/hooks/useViewConfig';
import { FieldViewField } from 'reducers/ViewConfigType';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from '../../../translation/types/DataSource';
import getFields from 'components/generics/genericList/getFields';
import Table from './CustomDatagrid/CustomDatagrid';
import { getAccessLevelForEntityField, getLabelForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import { Button, IconButton, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Field } from 'redux-form';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import ControlledValidatedForm from './ControlledValidatedRowForm';
import orderBy from 'lodash/orderBy';
import { v4 as uuidv4 } from 'uuid';
import { useAppStore } from 'reducers/rootReducer';
import { crudGetList } from 'sideEffect/crud/getList/actions';
import { refetchAllXManysContext } from 'fieldFactory/display/components/RefmanyMultiselect/util/refetchAllXManysContext';

interface InlineDatagridProps {
    source: string;
    id: string; // coming from props.record.id
    rootEntityType: string;
    resource: string;
    filter?: string;
    overrideViewName?: string;
    referencedByField?: string;
    handleSelectExistingRecord: (id: string) => void;
    showCreate?: boolean;
    disabled?: boolean;
}

interface FieldProps {
    input: {
        onChange: (value: {}[]) => void;
        value?: {}[];
    };
    meta?: {
        submitFailed?: boolean;
        initial?: {}[];
    };
}

interface InlineDatagridFieldProps extends InlineDatagridProps, FieldProps {}

export const useFetchRowData = (rows: any[], resource: string) => {
    const store = useAppStore();
    const refetchKey = React.useContext(refetchAllXManysContext);
    const ids = React.useMemo(
        () =>
            rows
                .map((r) => r['id'])
                .filter(Boolean)
                .sort()
                .join(','),
        [rows],
    );

    const initialFetchedIdsSet = React.useMemo(() => new Set<string>(), []);
    const fetchedIdsSetRef = React.useRef<Set<string>>(initialFetchedIdsSet);

    /*
     * 1. Keep a set of ids previously fetched
     * 2. add new 'idsToFetch' to that set
     * 2. Fetch everything in the new set
     * 3. remove everything in that set which then is not in our current result set.
     */

    React.useEffect(() => {
        ids.split(',').forEach((id) => {
            fetchedIdsSetRef.current.add(id);
        });
        store.dispatch(
            crudGetList({
                cb: ({ response }) => {
                    const foundIds = response.reduce((prev, { id }) => {
                        prev[id] = true;
                        return prev;
                    }, {} as { [id: string]: true });
                    Array.from(fetchedIdsSetRef.current.values()).forEach((fetchedId) => {
                        if (!foundIds[fetchedId]) {
                            fetchedIdsSetRef.current.delete(fetchedId);
                        }
                    });
                },
                resource,
                filter: {
                    id__IN: Array.from(fetchedIdsSetRef.current.values()).join(','),
                },
                view: null,
                pagination: {
                    page: 1,
                    perPage: ids.length,
                },
                sort: {
                    field: 'id',
                    order: 'ASC',
                },
            }),
        );
    }, [ids, store, resource, refetchKey]);
};

interface InlineDatagridCoreProps {
    id: string; // coming from props.record.id
    rootEntityType: string;
    resource: string;
    overrideViewName?: string;
    referencedByField?: string;
    handleSelectExistingRecord: (id: string) => void;
    showCreate?: boolean;
    disabled?: boolean;
    rows?: {
        id: string;
        [key: string]: unknown;
    }[];
    accessLevel: number;
}
const emptyObject = {};
function InlineDatagridCore({
    id,
    rootEntityType,
    resource,
    overrideViewName,
    referencedByField,
    input: { value, onChange },
    showCreate,
    handleSelectExistingRecord,
    disabled,
    meta,
    rows,
    accessLevel,
}: InlineDatagridCoreProps & FieldProps) {
    // adding sorting
    const [sort, setSort] = React.useState<[string, 'asc' | 'desc']>(null);

    // if we 'clear' a set of relationships by setting e.g. 'field': []
    // we still haven't removed the links back to this root record on those individual entities.
    // to make sure 'data' returns what's relevant, we should refetch anything which points to our root record,
    // to ensure that's still the case.

    const viewConfig = useViewConfig();

    const listViewName = overrideViewName ?? viewConfig.entities[resource]?.defaultViews?.LIST?.name;

    const fieldFactory = React.useContext(FieldFactoryContext);
    const fieldElemDefinitionTuples: [any, FieldViewField, any][] = React.useMemo(() => {
        const fields = getFields(viewConfig, listViewName, true, referencedByField);
        const inputFields = fieldFactory({
            // TODO: figure out validation.
            dataSource: DataSource.ENTITY,
            mode: Mode.INPUT_NOWARN,
            validate: true,
            connected: 'ff',
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
                ff: true,
            },
        })({ record: { id, entityType: rootEntityType }, resource, basePath: '/' + resource })(fields);
        return fieldFactory({
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
            },
        })({ record: { id, entityType: rootEntityType }, resource, basePath: '/' + resource, isForSearch: true })(
            fields,
        ).map((f, i) => [f, fields[i], inputFields[i]] as [any, FieldViewField, any]);
    }, [viewConfig, listViewName, referencedByField, id, rootEntityType, resource, fieldFactory]);

    const initialValue = React.useMemo(() => {
        if (Array.isArray(meta.initial)) {
            return meta.initial.filter((e) => !e['id']).map((e) => ({ ...e, _key: uuidv4() }));
        }
        return [];
    }, [meta.initial]);

    const [additionalRows, setAdditionalRows] = React.useState(initialValue);

    React.useEffect(() => {
        onChange([...rows.map(({ id }) => ({ id })), ...additionalRows.map(({ _key, ...rest }) => rest)]);
    }, [rows, additionalRows, onChange]);

    const submitFailed = meta?.submitFailed;
    const additionalRowElements = React.useMemo(() => {
        return additionalRows.map((r, i) => (
            <tr key={r._key}>
                <ControlledValidatedForm
                    submitFailed={submitFailed}
                    resource={resource}
                    registeredFields={fieldElemDefinitionTuples.map(([, f]) => f.field)}
                    initialValues={initialValue[i] ?? emptyObject}
                    values={r}
                    setValues={(r) => {
                        setAdditionalRows([
                            ...(i > 0 ? additionalRows.slice(0, i) : []),
                            r as { _key: string },
                            ...(i !== additionalRows.length - 1 ? additionalRows.slice(i + 1) : []),
                        ]);
                    }}
                >
                    {(props) => {
                        return (
                            <>
                                {fieldElemDefinitionTuples.map(([displayE, f, inputE]) => (
                                    <td
                                        style={{
                                            paddingTop: '16px',
                                            paddingBottom: '16px',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                        key={f.field}
                                    >
                                        {React.cloneElement(inputE, { record: r })}
                                    </td>
                                ))}
                                <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <div
                                        style={{
                                            display: 'inline-flex',
                                            flex: '0 0 auto',
                                            textAlign: 'center',
                                            alignItems: 'center',
                                            verticalAlign: 'middle',
                                            height: '100%',
                                            marginLeft: '1em',
                                        }}
                                    >
                                        <div style={{ margin: '-14px' }}>
                                            <IconButton
                                                aria-label="Remove Row"
                                                onClick={() =>
                                                    setAdditionalRows([
                                                        ...additionalRows.slice(0, i),
                                                        ...additionalRows.slice(i + 1),
                                                    ])
                                                }
                                            >
                                                <Clear />
                                            </IconButton>
                                        </div>
                                    </div>
                                </td>
                            </>
                        );
                    }}
                </ControlledValidatedForm>
            </tr>
        ));
    }, [additionalRows, fieldElemDefinitionTuples, resource, submitFailed, initialValue]);

    const sortedRows = React.useMemo(() => {
        if (!sort) {
            return rows;
        }
        const [field, dir] = sort;
        return orderBy(rows, [field], [dir]);
    }, [rows, sort]);
    const TableContent = (
        <TableBody>
            {sortedRows.map((r, i) => (
                <TableRow key={'existing -' + i}>
                    {fieldElemDefinitionTuples.map(([e, f]) => (
                        <TableCell
                            component="td"
                            className="td-data"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            key={f.field}
                        >
                            <div
                                style={{
                                    display: 'inline-flex',
                                    flex: '0 0 auto',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    verticalAlign: 'middle',
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'block',
                                        textAlign: 'left',
                                        marginLeft: '1em',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                    }}
                                >
                                    {React.cloneElement(e, { record: r })}
                                </div>
                            </div>
                        </TableCell>
                    ))}
                    <TableCell component="td" style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div
                            style={{
                                display: 'inline-flex',
                                flex: '0 0 auto',
                                textAlign: 'center',
                                alignItems: 'center',
                                verticalAlign: 'middle',
                                height: '100%',
                                marginLeft: '1em',
                            }}
                        >
                            <div style={{ margin: '-14px' }}>
                                <IconButton
                                    aria-label="Edit Row"
                                    onClick={() => {
                                        handleSelectExistingRecord(r['id']);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
            ))}
            {!disabled && additionalRowElements}
        </TableBody>
    );

    return (
        <div>
            <Table
                currentSort={sort}
                setSort={setSort}
                headers={[
                    ...fieldElemDefinitionTuples.map(([, f]) => ({
                        label: f.label ?? getLabelForFieldExpr(viewConfig, resource, f.field, 'TRAVERSE_PATH'),
                        field: f.field,
                    })),
                    {
                        label: '',
                    },
                ]}
                minCellWidth={120}
                tableContent={TableContent}
                renderBelow={({ updateInternalTableHeight }) => {
                    if (disabled || showCreate === false || accessLevel <= 2) {
                        return null;
                    }
                    return (
                        <Button
                            color="primary"
                            endIcon={<Add />}
                            onClick={() => {
                                setAdditionalRows([...additionalRows, { _key: uuidv4() }]);
                                setImmediate(() => updateInternalTableHeight());
                            }}
                        >
                            Add Row
                        </Button>
                    );
                }}
            />
        </div>
    );
}

function InlineDatagrid({
    id,
    source,
    rootEntityType,
    resource,
    filter,
    overrideViewName,
    referencedByField,
    input,
    showCreate,
    handleSelectExistingRecord,
    disabled,
    meta,
}: InlineDatagridFieldProps) {
    // adding sorting
    const [sort, setSort] = React.useState<[string, 'asc' | 'desc']>(null);

    const data = useData(rootEntityType, source, id, filter);
    const rows = React.useMemo(() => Object.values(data) as any[], [data]);
    // if we 'clear' a set of relationships by setting e.g. 'field': []
    // we still haven't removed the links back to this root record on those individual entities.
    // to make sure 'data' returns what's relevant, we should refetch anything which points to our root record,
    // to ensure that's still the case.
    useFetchRowData(rows, resource);
    const viewConfig = useViewConfig();
    const accessLevel = React.useMemo(
        () => getAccessLevelForEntityField(viewConfig, rootEntityType, source, 'TRAVERSE_PATH'),
        [viewConfig, rootEntityType, source],
    );

    return (
        <InlineDatagridCore
            accessLevel={accessLevel}
            rows={rows}
            id={id}
            rootEntityType={rootEntityType}
            resource={resource}
            overrideViewName={overrideViewName}
            referencedByField={referencedByField}
            input={input}
            showCreate={showCreate}
            handleSelectExistingRecord={handleSelectExistingRecord}
            disabled={disabled}
            meta={meta}
        />
    );
}

const renderField = (props) => {
    return <InlineDatagrid {...props} />;
};
const InlineDatagridField = (props: InlineDatagridProps) => {
    return <Field {...props} name={props.source} component={renderField} />;
};

// "Core" means source doesn't necessarily map onto data. Currently it's used for unpersisted fields (e.g. _field)
const CoreField = (props) => {
    const rows = React.useMemo(() => props.meta.initial ?? [], [props.meta.initial]);
    return <InlineDatagridCore {...props} rows={rows} />;
};
export const InlineDatagridCoreField = (props: InlineDatagridCoreProps & { source }) => {
    return <Field {...props} name={props.source} component={CoreField} />;
};

export default InlineDatagridField;
