import React, { useCallback, useContext, useMemo } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { change } from 'redux-form';
import get from 'lodash/get';
import { IconButton, Tooltip } from '@material-ui/core';
import DoubleArrow from '@material-ui/icons/DoubleArrow';
import { Height } from '@material-ui/icons';
import { columnFormContextsContext } from '../contextManagement/formContexts';
import { columnReduxFormContextsContext } from '../contextManagement/reduxFormContexts';
import { useAppStore } from 'reducers/rootReducer';

export const MiddleButtons = ({
    leftColumnId,
    rightColumnId,
    field,
}: {
    leftColumnId: string;
    rightColumnId: string;
    field: string;
}) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TransferButton fromColumnId={leftColumnId} toColumnId={rightColumnId} field={field}>
                <DoubleArrow />
            </TransferButton>
            <SwapButton leftColumnId={leftColumnId} rightColumnId={rightColumnId} field={field}>
                <Height style={{ transform: 'rotate(90deg)' }} />
            </SwapButton>
            <TransferButton fromColumnId={rightColumnId} toColumnId={leftColumnId} field={field}>
                <DoubleArrow style={{ transform: 'scaleX(-1)' }} />
            </TransferButton>
        </div>
    );
};

const TransferButton = ({
    fromColumnId,
    toColumnId,
    field,
    children,
}: {
    fromColumnId: string;
    toColumnId: string;
    field: string;
    children: React.ReactNode;
}) => {
    const existingColumnFormContextsContext = useContext(columnFormContextsContext);
    const existingColumnReduxFormContextsContext = useContext(columnReduxFormContextsContext);
    const fromFc = existingColumnFormContextsContext[fromColumnId];
    const toFc = existingColumnFormContextsContext[toColumnId];
    const equalValues = useMemo(
        () => isEqual(get(fromFc.fieldValues, field), get(toFc.fieldValues, field)),
        [fromFc, toFc, field],
    );

    const toReduxForm = existingColumnReduxFormContextsContext[toColumnId];
    const store = useAppStore();
    const ourValue = get(fromFc.fieldValues, field);
    const transfer = useCallback(() => {
        store.dispatch(change(toReduxForm.form, field, ourValue, true));
    }, [toReduxForm, field, store, ourValue]);
    const hide = equalValues || isEmpty(ourValue);
    const tooltipText = `Transfer to ${toColumnId}`;

    return (
        <div>
            <Tooltip title={tooltipText}>
                <IconButton
                    size="small"
                    aria-label={tooltipText}
                    onClick={transfer}
                    style={{
                        visibility: hide ? 'hidden' : undefined,
                    }}
                >
                    {children}
                </IconButton>
            </Tooltip>
        </div>
    );
};

const SwapButton = ({
    leftColumnId,
    rightColumnId,
    field,
    children,
}: {
    leftColumnId: string;
    rightColumnId: string;
    field: string;
    children: React.ReactNode;
}) => {
    const existingColumnFormContextsContext = useContext(columnFormContextsContext);
    const existingColumnReduxFormContextsContext = useContext(columnReduxFormContextsContext);
    const leftFc = existingColumnFormContextsContext[leftColumnId];
    const rightFc = existingColumnFormContextsContext[rightColumnId];

    const leftValue = get(leftFc.fieldValues, field, null);
    const rightValue = get(rightFc.fieldValues, field, null);

    const equalValues = useMemo(
        () =>
            isEqual(leftValue, rightValue) ||
            (isEmpty(leftValue) && isEmpty(rightValue) && rightValue !== 0 && leftValue !== 0),
        [leftValue, rightValue],
    );

    const rightReduxForm = existingColumnReduxFormContextsContext[rightColumnId];
    const leftReduxForm = existingColumnReduxFormContextsContext[leftColumnId];
    const store = useAppStore();

    const swap = useCallback(() => {
        store.dispatch(change(rightReduxForm.form, field, leftValue, true));
        store.dispatch(change(leftReduxForm.form, field, rightValue, true));
    }, [leftReduxForm, rightReduxForm, field, store, leftValue, rightValue]);

    return (
        <div>
            <Tooltip title="Swap">
                <IconButton
                    size="small"
                    aria-label="swap value"
                    onClick={swap}
                    style={{
                        visibility: equalValues ? 'hidden' : undefined,
                    }}
                >
                    {children}
                </IconButton>
            </Tooltip>
        </div>
    );
};
