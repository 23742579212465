import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import ContentAdd from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import useWidth from 'util/hooks/useWidth';

interface CreateButtonProps {
    resource: string;
    label?: string;
}
interface CreateButtonComponentProps extends CreateButtonProps {}
const CreateButtonComponent: React.SFC<CreateButtonComponentProps> = (props) => {
    const { resource, label = 'Create' } = props;
    const width = useWidth();
    const viewConfig = useAppSelector((state: RootState) => state.viewConfig);
    const createRoute = fromNullable(viewConfig.entities[resource])
        .mapNullable((e) => e.defaultViews.CREATE)
        .mapNullable((c) => c.name)
        .mapNullable((n) => viewConfig.views[n])
        .mapNullable((v) => v.route)
        .map((r) => (r.startsWith('/') ? r : `/${r}`))
        .getOrElse(`/${resource}/create`);
    return width === 'xs' ? (
        <Fab
            color="primary"
            to={createRoute}
            sx={{ margin: 0, top: 'auto', right: 20, bottom: 60, left: 'auto', position: 'fixed' }}
            component={Link}
        >
            <ContentAdd />
        </Fab>
    ) : (
        <Button variant="text" color="primary" to={createRoute} component={Link} sx={{ overflow: 'inherit' }}>
            {label} <ContentAdd />
        </Button>
    );
};

const CreateButton: React.ComponentType<CreateButtonProps> = CreateButtonComponent;
export default CreateButton;
