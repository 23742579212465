import React, { useMemo } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { UploadFile } from '../components/custom';
import UploadDeployment2 from '../bpm/components/UploadDeployment2';
import BulkUpload from '../components/custom/BulkUpload';
import ProcessView from '../bpm/components/ProcessDetail';
import MyReports from '../report2/components/MyReports';
import SsgAppBarMobile from '../components/SsgAppBarMobile';
import AdhocTask from '../bpm/components/TaskDetail/Task';
import ValidateViewConfigEntities from '../viewConfigValidation/ValidateViewConfigEntities';
import ValidateViewConfigViews from '../viewConfigValidation/ValidateViewConfigViews';
import ProcessList from '../bpm2/ProcessList';
import TasksList, { ContainedTaskList } from '../bpm2/TaskList';
import RoleShow from '../components/custom/RoleEditor';
import UserLogoutRedirectRoute from 'components/custom/UserLogoutRedirectRoute';
import MishasTest from 'temp_testAjaxCookies/TestComponents';
import PrintTemplatePlayground from 'printTemplate/playground/LazyPlayground';
import LazyPrintTemplateTester from 'printTemplate/tester/LazyTester';
import AdminPasswordReset from 'auth/password-reset/components/AdminReset';
import TextTemplatePage from 'templatePage/components/index';
import UploadPage from 'bpm/upload-definitions/components';
import ProcessStartPage from 'bpm/start-form/components';
import { Helmet } from 'react-helmet';
import { parse } from 'query-string';
import StartProcessRoute from 'bpm/start-process-route';
import LoadLayout from 'layout-editor/build-layout/LoadLayout';
import DeferredSpinner from 'components/DeferredSpinner';
import DiffViewsFromViewDefs from 'layout-editor/diff-viewsdefs-from-views';
import MigrateEntityViews from 'layout-editor/generate-viewdefs-from-views';
import MultiPageViewDefEditor from 'layout-editor/viewdef-editor';
import ViewDefinitionManagement from 'layout-editor/ViewDefinitionManagement';
import Report from 'report2/components/Report';
import { Card } from '@material-ui/core';
import JobsList from 'components/adhoclist/Jobs';
import LazyTransformForm from 'bpm/transform-form/LazyTransformTaskForm';
import DashEditorController from 'dash-editor';
import DashCreatorController from 'dash-editor/DashCreator';
import ProcessAdminPage from '../bpm/components/ProcessDetail/ProcessAdminPage';
import ReviewViewDefs from 'layout-editor/review-viewdefs';
import MergeBackendConf from 'diff-viewconfig-migration2/LazyDiffPage';
import MergeBackendConf2 from 'diff-viewconfig-migration2/LazyDiffPage2';
import DiffViewsImport from 'layout-editor/diff-viewsdefs-from-views/DiffViewConfigWithImport';
import TaskFormBuilder from 'bpm/task-form-editor/TaskFormBuilder';
import ModelerPortal from 'bpm-modeler/ModelerPortal';
import DashboardPage from 'dashboard2/components/DashboardPage';
import ListOfOfflineTasksPage from 'offline_app/presentation/ListOfOfflineTasksPage';
import GenerateDefaultViews from 'layout-editor/generateDefaultViews/GenerateDefaultViews';
import JsonSchemaFormBuilderPage from 'components/JsonSchemaFormBuilderPage';
import AceSpelEditor from 'ace-editor/AceSpelEditor';
import BranchExplorer from 'branches/BranchExplorer';
import Reports from 'custom-reports/reports/Reports';
import ExtendReport from 'custom-reports/extend-report/ExtendReport';
import EditPersonalizedReport from 'custom-reports/extend-report/EditReport';
import RunReport from 'custom-reports/run-report/RunReport';
import DataModelExplorer from 'data-model-explorer';
import CannedDateDemo from 'fieldFactory/input/components/RelativeDate/CannedDateDemo';
import CasetivityManagedBpmRoute from 'bpm-casetivity-managed/Route';
import ValuesetAdminPage from 'valueset-admin/ValuesetAdminPage';
import ViewConfigManager from '../components/viewConfigManager';
import ResolveConflict from 'branches/ResolveConflict';
import DocumentationPage from 'spelContext/DocumentationPage';
import { useAppSelector } from 'reducers/rootReducer';
import * as playgrounds from './playground';
import LazyReportBuilder from 'report-builder/LazyReportBuilder';

export const ProcessTaskViewComponent = ({ match, location }) => {
    const queryParams = useMemo(() => {
        return parse(location.search);
    }, [location.search]);
    return (
        <ProcessView
            title={match.params.tkey}
            process={match.params.pkey}
            task={match.params.tkey}
            queryParams={queryParams}
        />
    );
};
const customRoutes: React.ReactElement<RouteProps>[] = [
    <Route path="/admin/report-builder/:id" component={({ match }) => <LazyReportBuilder id={match.params.id} />} />,
    <Route path="/admin/docs" component={() => <DocumentationPage />} />,
    <Route path="/admin/resolve-conflict" component={() => <ResolveConflict />} />,
    <Route path="/admin/value-set" component={() => <ValuesetAdminPage />} />,
    <Route path="/admin/bpm" component={() => <CasetivityManagedBpmRoute />} />,
    <Route path="/canned-date-demo" component={() => <CannedDateDemo />} />,
    <Route path="/admin/data-model" component={() => <DataModelExplorer />} />,
    <Route path="/reports" component={() => <Reports />} />,
    <Route path="/run-personalized-report/:id" component={({ match }) => <RunReport id={match.params.id} />} />,
    <Route path="/personalize-report/:name" component={({ match }) => <ExtendReport name={match.params.name} />} />,
    <Route
        path="/edit-personalized-report/:id"
        component={({ match }) => <EditPersonalizedReport id={match.params.id} />}
    />,
    <Route path="/branch-explorer" component={() => <BranchExplorer />} />,
    <Route path="/ace-test" component={() => <AceSpelEditor />} />,
    <Route path="/json-schema-formbuilder" component={JsonSchemaFormBuilderPage} />,
    <Route path="/my-offline-work" component={ListOfOfflineTasksPage} />,
    <Route path="/generate-default-views" component={GenerateDefaultViews} />,
    <Route path="/home" component={DashboardPage} />,
    <Route path="/bpm-modeler" component={ModelerPortal} />,
    <Route path="/diff-viewconfig-migration" component={MergeBackendConf} />,
    <Route path="/diff-viewconfig-migration2" component={MergeBackendConf2} />,
    <Route path="/dash-creator" component={DashCreatorController} />,
    <Route
        path="/dash-editor2/:id"
        component={({ match }) => <DashEditorController initialDashId={match.params.id} />}
    />,
    <Route path="/dash-editor2" component={DashEditorController} />,
    <Route
        path="/dash-editor/:id"
        component={({ match }) => <DashEditorController initialDashId={match.params.id} />}
    />,
    <Route path="/dash-editor" component={DashEditorController} />,
    <Route path="/transform-form" component={LazyTransformForm} />,
    <Route
        path="/load-layout/:viewName"
        component={({ match }) => {
            return <LoadLayout viewName={match.params.viewName} />;
        }}
    />,
    <Route path="/view-definition-management" component={ViewDefinitionManagement} />,
    <Route path="/view-definition-editor" component={MultiPageViewDefEditor} />,
    <Route path="/task-form-editor" component={TaskFormBuilder} />,
    <Route path="/migrate-entityviews" component={MigrateEntityViews} />,
    <Route path="/diff-viewdefs" component={DiffViewsFromViewDefs} />,
    <Route path="/diff-views-import" component={DiffViewsImport} />,
    <Route path="/review-viewdefs" component={ReviewViewDefs} />,
    <Route
        path="/start-process/:processDefinitionKey"
        component={({ match, location }) => (
            <StartProcessRoute processDefinitionKey={match.params.processDefinitionKey} searchStr={location.search} />
        )}
    />,
    <Route
        path="/process-definition/:processKey/start-form/:taskKey"
        component={({ match, location }) => {
            const queryParams = useMemo(() => {
                return parse(location.search);
            }, [location.search]);
            return (
                <>
                    <SsgAppBarMobile title={null} />
                    <ProcessStartPage
                        taskFormKey={match.params.taskKey}
                        businessKey={match.params.processKey}
                        queryParams={queryParams}
                    />
                </>
            );
        }}
    />,
    <Route
        path="/process-definition/:processKey/start-form"
        component={({ match, location }) => {
            const queryParams = useMemo(() => {
                return parse(location.search);
            }, [location.search]);
            return (
                <>
                    <SsgAppBarMobile title={null} />
                    <ProcessStartPage businessKey={match.params.processKey} queryParams={queryParams} />
                </>
            );
        }}
    />,
    <Route
        path="/pages/:textTemplateName"
        component={({ match, location }) => {
            return <TextTemplatePage textTemplateName={match.params.textTemplateName} search={location.search} />;
        }}
    />,
    <Route path="/tasks/:tkey" component={({ match }) => <AdhocTask taskId={match.params.tkey} />} />,
    <Route path="/processes/:pkey/tasks/:tkey/start/" component={ProcessTaskViewComponent} />,
    <Route
        path="/processes/:pkey"
        component={({ match }) => <ProcessView title={match.params.pkey} process={match.params.pkey} />}
    />,
    <Route
        path="/processes"
        component={(props) => (
            <>
                <SsgAppBarMobile title={null} />
                <ProcessList {...props} />
            </>
        )}
    />,
    <Route path="/testCookies" component={MishasTest} />,
    <Route path="/tasks" component={TasksList} />,
    <Route path="/controlledTasks" component={(props) => <ContainedTaskList {...props} processId={2474} />} />,
    <Route
        path="/run-report/:key/"
        component={(props) => (
            <div>
                <SsgAppBarMobile title={props.match.params.key} />
                <Report key={props.match.params.key} reportName={props.match.params.key} />
            </div>
        )}
    />,
    <Route
        path="/report-logs-by-current-user"
        component={(props) => (
            <div>
                <SsgAppBarMobile title="My Reports" />
                <MyReports />
            </div>
        )}
    />,
    <Route path="/admin/update-definitions" component={UploadPage} />,
    <Route path="/admin/upload-deployment/" component={UploadDeployment2} />,
    <Route
        path="/admin/upload-config-pack/"
        component={(props) => (
            <UploadFile title="Import ConfigPack" dropMessage="ConfigPack File Here" resourceUrl="config-pack/import" />
        )}
    />,
    <Route path="/admin/application-information/" component={(props) => <ViewConfigManager />} />,
    <Route
        path="/admin/analyze-addresses/"
        component={(props) => (
            <React.Fragment>
                <Helmet>
                    <title>Analyze Addresses</title>
                </Helmet>
                <UploadFile
                    title="Upload Addresses to Analyze"
                    dropMessage="Address File Here"
                    resourceUrl="util/analyze-addresses"
                />
            </React.Fragment>
        )}
    />,
    <Route
        path="/import/demo-data/"
        component={(props) => (
            <React.Fragment>
                <Helmet>
                    <title>Import Demo Data</title>
                </Helmet>
                <UploadFile
                    title="Import Demo Data"
                    dropMessage="Entity Excel sheet here"
                    resourceUrl="demo-data/import"
                />
            </React.Fragment>
        )}
    />,
    <Route path="/reset-user-password" component={(props) => <AdminPasswordReset />} />,
    <Route
        path="/import/demo-links/"
        component={(props) => (
            <React.Fragment>
                <Helmet>
                    <title>Import Demo Data Linkage</title>
                </Helmet>
                <UploadFile
                    title="Import Demo Data linkage file"
                    dropMessage="Entity Excel sheet here"
                    resourceUrl="demo-data/import-links"
                />
            </React.Fragment>
        )}
    />,
    <Route
        path="/import/:entity/"
        component={(props) => (
            <div>
                <BulkUpload entityType={props.match.params.entity} />
            </div>
        )}
    />,
    <Route path="/admin/validation/entities" component={(props) => <ValidateViewConfigEntities />} />,
    <Route path="/admin/validation/views" component={(props) => <ValidateViewConfigViews />} />,
    <Route path="/role-editor" component={(props) => <RoleShow {...props} />} />,
    <Route path="/ptp" component={PrintTemplatePlayground} />,
    <Route path="/print-template-tester" component={LazyPrintTemplateTester} />,
    <Route
        path="/_temp"
        component={() => {
            const viewConfigIsLoading = useAppSelector((state) => state.viewConfigIsLoading);
            if (!viewConfigIsLoading) {
                return null;
            }
            return (
                <div style={{ height: '600px', width: '100%' }}>
                    <div style={{ textAlign: 'center', width: 200, height: 200, margin: 'auto' }}>
                        <div style={{ height: '50px' }} />
                        <h2>Please wait...</h2>
                        <div style={{ height: '50px' }} />
                        <DeferredSpinner />
                    </div>
                </div>
            );
        }}
    />,
    <Route
        path="/admin/jobs"
        component={() => {
            return (
                <Card style={{ width: '100%' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            flexDirection: 'row',
                        }}
                    >
                        <div style={{ width: '100%', flexShrink: 1, padding: '1em' }}>
                            <JobsList type="active" />
                        </div>
                        <div style={{ width: '100%', flexShrink: 1, padding: '1em' }}>
                            <JobsList type="dead" />
                        </div>
                    </div>
                </Card>
            );
        }}
    />,
    <Route
        path="/admin/process/:processKey"
        component={(props) => (
            <div>
                <ProcessAdminPage key={props.match.params.processKey} />
            </div>
        )}
    />,
    <Route path="/logout-redirect" component={(props) => <UserLogoutRedirectRoute {...props} />} />,
    ...Object.values(playgrounds).map(({ route, Component }) => <Route path={route} component={Component} />),
];

export default customRoutes;
