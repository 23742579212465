import React, { useMemo, useCallback } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { evaluateFormattedText } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { useIntl } from 'react-intl';
import { sortConceptsBySortOrder } from 'fieldFactory/input/components/ValueSelectDownshift';

interface ValueSetListFieldComponentProps {
    concepts: {};
    valueSetCode: number;
    addLabel: boolean;
    elStyle: React.CSSProperties;
    label: string;
    source: string;
    record?: {};
    crudGetOne: Function;
}

export const ValueSetListFieldComponent: React.FunctionComponent<ValueSetListFieldComponentProps> = ({
    concepts = {},
    valueSetCode,
    addLabel,
    elStyle,
    label,
    record,
    source,
}) => {
    const list: string[] = React.useMemo(
        () => sortConceptsBySortOrder((get(record, source) || []).map((id) => concepts[id])).map(({ id }) => id),
        [record, source, concepts],
    );
    const getDisplay = useCallback(
        (value: string | { id?: string }) => {
            if (typeof value !== 'string') {
                console.error('unexpected type of valuesetmany item: ' + JSON.stringify(value));
                return concepts[value?.id]?.display;
            }
            return concepts[value]?.display;
        },
        [concepts],
    );
    const joinedDisplayTexts = useMemo(
        () => list.reduce((acc, curr, i) => `${acc}${i !== 0 ? ', ' : ' '}${getDisplay(curr)}`, ''),
        [list, getDisplay],
    );
    const intl = useIntl();
    const internationalizedDisplayText = useMemo(
        () => evaluateFormattedText(intl, joinedDisplayTexts),
        [intl, joinedDisplayTexts],
    );
    return <span style={elStyle}>{internationalizedDisplayText}</span>;
};

ValueSetListFieldComponent.defaultProps = {
    addLabel: true,
};

const mapStateToProps = (state, ownProps) => ({ concepts: state.admin.entities.Concept });
const ValueSetListField = compose(
    connect(mapStateToProps, { crudGetOne: crudGetOneAction }),
    lifecycle({
        componentDidMount() {
            if (this.props.fetchOwnData) {
                this.getMissingConcepts(this.props);
            }
        },
        componentWillReceiveProps(nextProps: ValueSetListFieldComponentProps) {
            if (
                this.props.fetchOwnData &&
                get(this.props.record, this.props.source) !== get(nextProps.record, nextProps.source)
            ) {
                this.getMissingConcepts(nextProps);
            }
        },
        getMissingConcepts(props: ValueSetListFieldComponentProps) {
            if (props.record && props.source) {
                const conceptIds = get(props.record, props.source);
                if (conceptIds) {
                    conceptIds.forEach((id) => {
                        if (!props.concepts || typeof props.concepts[id] === 'undefined') {
                            props.crudGetOne({
                                resource: 'Concept',
                                id,
                                view: null,
                            });
                        }
                    });
                }
            }
        },
    }),
    (BaseComponent) => (props) => <BaseComponent {...props} />,
)(ValueSetListFieldComponent);
export default ValueSetListField;
