import React, { useMemo, useState, useEffect } from 'react';
import { parse } from 'query-string';
import lifecycle from 'recompose/lifecycle';
import GenericList, { GenericListWithDefaultProps } from '../../components/generics/genericList';
import XDate from 'xdate';
import { push, push as pushAction, RouterState } from 'connected-react-router';
import compose from 'recompose/compose';
import { fromNullable, fromEither } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { connect, useDispatch } from 'react-redux';
import { useTheme, Button, CardActions, withStyles, Theme, Tooltip } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import { merge } from '../replaceInLocation';
import { RootState, useAppSelector } from '../../reducers/rootReducer';
import { CaseTypeDropdown, AssignedDropdown, StateDropdown } from './components/dropdowns';
import PopoverCreateTask from '../../bpm/components/TaskList/adhoctasks/PopoverCreateTask';
import memoizeOne from 'memoize-one';
import { CurrentState } from './types';
import { stringify } from 'querystring';
import getRenderer from '../../components/generics/genericList/renderList';
import { toggleBulkActionsButton } from '../../components/ToggleBulkActionButton';
import { RenderListArguments } from '../../components/generics/genericList/List';
import ProcessSelectActionDialog from '../ProcessSelectActionDialog';
import * as config from '../../config';
import {
    getProcDefFromProcInstId,
    stripDelaySearchFromQueryString,
    getForceDatagridNotReady,
    getTodayISO,
} from '../util';
import { idbKeyval } from 'IndexedDB/offlineTasksDb';
import { Helmet } from 'react-helmet';
import { getPotentialUsers } from 'bpm/potentialUsers/actions';
import { GetComponentProps } from 'util/typeUtils';
import { getDefaultSort } from 'components/generics/utils/viewConfigUtils';
import Storage from '@material-ui/icons/Storage';
import { createSelector } from 'reselect';
import buildHeaders from 'sideEffect/buildHeaders';
import { deserialize } from 'reducers/lists/list/serializeDeserialize';
import { offlineTaskExpirationDatesKeyVal } from 'IndexedDB/offlineTaskExpirationDates';
import moment from 'moment';
import makeCancelable, { CancellablePromise } from 'util/makeCancelable';
import isTheInstalledApp from 'util/isTheInstalledApp';
import AllActivitiesArea from 'bpm/components/TaskList/adhoctasks/AllActivities';
import useOverrideCloseButtonLocation from 'bpm/hooks/useOverrideCloseButtonLocation';
import { Box } from '@mui/material';
import { getSendBusinessKeyAlongWithProcessIdForEasyAttributeSecuritySelector } from 'util/applicationConfig';

const ClosePageButton = () => {
    const dispatch = useDispatch();
    const lastProcessSearch = useAppSelector((state) => state.bpm.currentProcessSearch.query);
    const override = useOverrideCloseButtonLocation();
    if (override === '') {
        return null;
    }
    const location = typeof override === 'string' ? override : `/processes${lastProcessSearch || ''}`;
    return (
        <Button variant="text" color="primary" onClick={() => dispatch(push(location))}>
            Close
            <Clear />
        </Button>
    );
};

const OfflineTaskIconComponent = ({
    offlineTaskKeys,
    record,
}: {
    offlineTaskKeys?: string[];
    record?: {
        id?: string;
    };
}) => {
    const theme = useTheme();
    const id = record?.id;
    const hasOfflineWork = id && offlineTaskKeys?.includes(id);
    const [expirationDate, setExpirationDate] = useState<Date>();
    useEffect(() => {
        (async () => {
            if (!id || !hasOfflineWork) {
                return;
            }
            const entry = await offlineTaskExpirationDatesKeyVal.get(id);
            const expires = entry?.expires;
            if (expires) {
                setExpirationDate(expires);
            }
        })();
    }, [hasOfflineWork, id]);
    const expiresText = useMemo(() => {
        return ' expiring ' + moment(expirationDate).calendar();
    }, [expirationDate]);
    if (!hasOfflineWork) {
        return null;
    }
    return (
        <div
            style={{
                display: 'inline-flex',
                flex: '0 0 auto',
                textAlign: 'center',
                alignItems: 'center',
                verticalAlign: 'middle',
            }}
        >
            <Tooltip title={'You have unsubmitted offline work for this task' + expiresText}>
                <Storage
                    titleAccess="Includes pending offline submissions"
                    fontSize="small"
                    style={{ color: theme.palette.warning.main }}
                />
            </Tooltip>
        </div>
    );
};

const emptyObj = {};
export const createGetPotentialUsersByIdSelector = () =>
    createSelector(
        (state: RootState) => state.bpm.potentialUsers,
        (state: RootState) => state.viewConfig.user,
        (potentialUsers, currentUser) => {
            const byId = deserialize(potentialUsers)
                .map((pu) => pu.byId)
                .getOrElse(emptyObj);
            if (!byId[currentUser.id]) {
                return { ...byId, [currentUser.id]: currentUser };
            }
            return byId;
        },
    );
const makeMapStateToProps = () => {
    const potentialUsersByIdSelector = createGetPotentialUsersByIdSelector();
    return (state: RootState, ownProps) => ({
        users: potentialUsersByIdSelector(state),
    });
};

const ConnectedAssignedDropdown: React.ComponentType<GetComponentProps<typeof AssignedDropdown>> = compose(
    connect(makeMapStateToProps, {
        getPotentialUsers,
    }),
    lifecycle({
        componentDidMount() {
            this.props.getPotentialUsers();
        },
    }),
)(AssignedDropdown);

const getLazyR = (type: 'copy' | 'move') => (appCaseId: number | string, selectedData: {}) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/app-cases/${appCaseId}/${type}-tasks `, {
        method: type === 'copy' ? 'POST' : 'PUT',
        body: JSON.stringify({
            taskIds: Object.keys(selectedData),
        }),
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
    });

const styles = (theme: Theme) =>
    ({
        headerCell: {
            position: 'sticky',
            zIndex: 3,
            backgroundColor: theme.palette.background.paper,
            top: 0,
        },
        listResults: {
            paddingLeft: 16,
            paddingRight: 16,
            position: 'relative',
            overflowX: 'auto',
            /*
            overflowY: 'auto',
            overflowX: 'auto',
            maxHeight: 380,
            '@media print': {
                overflowX: 'unset',
                overflowY: 'unset',
                maxHeight: 'unset',
            },
            */
        },
    } as const);

const getViewName = () => '_TASK_LIST';
interface TaskFilter {}

interface TaskListProps {
    useCard?: boolean;
    overrideViewName?: string;
    lastProcessSearch?: string;
    fakePush?: (search: string) => void;
    redirect: (newLocation: string) => void;
    readOnly?: boolean;
    processId?: string;
    businessKey?: string | null;
    location: RouterState['location'];
    changeProcessDefinitionKey: (pdk: string) => void;
    changeAssignee: (assignee: string) => void;
    changeState: (state: CurrentState) => void;
    mergeFilterIntoLocation: (filter: TaskFilter) => void;
    bulkActionsOpen: boolean;
    toggleBulkActions?: () => void;
}

interface TaskListState {
    selectedData: null | {};
    offlineTaskKeys?: string[];
}

interface TaskListComponentProps extends TaskListProps {
    printMode: boolean;
    classes: {
        [k in keyof ReturnType<typeof styles>]: string;
    };
}

const currentFilterContext: React.Context<{}> = React.createContext<{}>({});

class TaskList extends React.Component<TaskListComponentProps, TaskListState> {
    private cancelableOfflinePromise: CancellablePromise<string[]>;
    _getFilterFromLocation = memoizeOne((search) => fromNullable((parse(search) || {}).filter).map(JSON.parse));
    _getLocationWithoutDelaySearch = memoizeOne(({ search, pathname }) => ({
        pathname,
        search: stripDelaySearchFromQueryString(search),
    }));

    constructor(props: TaskListComponentProps) {
        super(props);
        this.state = {
            selectedData: this.props.bulkActionsOpen ? {} : null,
        };
    }
    componentDidMount() {
        if (isTheInstalledApp()) {
            this.cancelableOfflinePromise = makeCancelable(idbKeyval.keys());
            this.cancelableOfflinePromise.promise.then((keys) => {
                this.setState({
                    offlineTaskKeys: keys,
                });
            });
        }
    }
    componentWillUnmount() {
        this.cancelableOfflinePromise?.cancel?.();
    }

    componentWillReceiveProps(nextProps: TaskListComponentProps) {
        if (nextProps.bulkActionsOpen && !this.props.bulkActionsOpen && !this.state.selectedData) {
            this.setState({
                selectedData: {},
            });
        }
        if (!nextProps.bulkActionsOpen && this.props.bulkActionsOpen && this.state.selectedData) {
            this.setState({
                selectedData: null,
            });
        }
    }
    getProcessDefinitionKey = (props = this.props) =>
        this._getFilterFromLocation(props.location.search).chain((filter) =>
            fromNullable(filter['processInstance.businessKey']),
        );
    getAssignee = (props = this.props) =>
        this._getFilterFromLocation(props.location.search).chain((filter) => fromNullable(filter['assignee_~_id']));
    getTaskName = (props = this.props) =>
        this._getFilterFromLocation(props.location.search).chain((filter) => fromNullable(filter.name));

    getState = (props = this.props) =>
        this._getFilterFromLocation(props.location.search).fold<CurrentState>('All', (filter) =>
            fromNullable(filter.endTime__NOT_EMPTY)
                .map((isEnded: boolean) =>
                    isEnded
                        ? 'Closed'
                        : fromNullable(filter.dueDate__LESS)
                              .chain((lt) => fromEither(tryCatch(() => new XDate(lt))))
                              .fold('Open', (lt) => {
                                  const diffDays = lt.diffDays(new XDate());
                                  return diffDays > 0 ? 'Overdue' : 'Open';
                              }),
                )
                .fold('All', (res) => res),
        );
    clearSelectedData = () => {
        this.setState({
            selectedData: {},
        });
    };
    renderFilter: GenericListWithDefaultProps['renderFilter'] = ({ defaultRenderer, ...renderFilterArgs }) => {
        const props = this.props;
        if (props.printMode) {
            return null;
        }
        return (
            <div style={{ width: '100%' }}>
                <div style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <div
                        style={{
                            marginLeft: 19,
                            marginTop: 26,
                            marginRight: 18,
                            fontWeight: 'bold',
                            fontSize: 15,
                        }}
                    >
                        Search
                    </div>
                    <div>
                        <div
                            style={{
                                display: 'inline-flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                            }}
                        >
                            {props.processId ? null : (
                                <React.Fragment>
                                    <CaseTypeDropdown
                                        processDefinitionKey={this.getProcessDefinitionKey().toNullable()}
                                        onChange={props.changeProcessDefinitionKey}
                                    />
                                    <span key="divider0" style={{ width: 32 }} />
                                    <ConnectedAssignedDropdown
                                        assigneeId={this.getAssignee().toNullable()}
                                        onChange={props.changeAssignee}
                                    />
                                </React.Fragment>
                            )}
                            <span key="divider1" style={{ width: 32 }} />
                            <StateDropdown currentState={this.getState()} onChange={props.changeState} />
                        </div>
                    </div>
                </div>
                <div style={{ paddingLeft: 6, paddingBottom: 6 }}>
                    <currentFilterContext.Consumer>
                        {(f) =>
                            defaultRenderer({
                                ...renderFilterArgs,
                                filterValues: f,
                                displayHeader: false,
                            })
                        }
                    </currentFilterContext.Consumer>
                </div>
            </div>
        );
    };
    rowsSelected = () => Object.keys(this.state.selectedData || {}).length;
    someDataSelected = () => this.rowsSelected() > 0;
    render() {
        if (isTheInstalledApp() && !this.state.offlineTaskKeys) {
            return null;
        }
        const props = this.props;
        const { selectedData } = this.state;
        return (
            <currentFilterContext.Provider
                key={getForceDatagridNotReady(this.props.location.search) ? 'NR' : ''}
                value={this._getFilterFromLocation(this.props.location.search).getOrElse(undefined)}
            >
                <Helmet>
                    <title>Search Tasks</title>
                </Helmet>
                <ProcessSelectActionDialog<'copy' | 'move'>
                    disableCaseTypeChange={true}
                    itemName="task"
                    initialSearch={
                        props.businessKey
                            ? `?filter=%7B"assignee_ANY"%3Atrue%2C"processInstance.businessKey"%3A"${props.businessKey}"%7D`
                            : undefined
                    }
                    getLazyRequests={{
                        copy: getLazyR('copy'),
                        move: getLazyR('move'),
                    }}
                    selectedData={selectedData}
                    onSuccess={this.clearSelectedData}
                    selectedDataStaysOnSuccess={false}
                    render={({ dataKey, getOpenPSDialog, closePSDialog }) => (
                        <div>
                            <GenericList
                                useCard={this.props.useCard}
                                key={dataKey}
                                forceDatagridNotReady={getForceDatagridNotReady(this.props.location.search)}
                                hasCreate={false}
                                renderActions={(actionProps) =>
                                    this.props.printMode ? null : (
                                        <CardActions style={{ zIndex: 2, display: 'inline-block', float: 'right' }}>
                                            {props.toggleBulkActions
                                                ? toggleBulkActionsButton(
                                                      props.toggleBulkActions,
                                                      props.bulkActionsOpen,
                                                      this.someDataSelected(),
                                                  )
                                                : null}
                                            {props.bulkActionsOpen && this.someDataSelected() ? (
                                                <React.Fragment>
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        onClick={getOpenPSDialog('move')}
                                                    >
                                                        Move ({this.rowsSelected()})
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        onClick={getOpenPSDialog('copy')}
                                                    >
                                                        Copy ({this.rowsSelected()})
                                                    </Button>
                                                </React.Fragment>
                                            ) : null}
                                            <PopoverCreateTask processId={props.processId || null} />
                                            {!props.readOnly && props.processId ? <ClosePageButton /> : null}
                                        </CardActions>
                                    )
                                }
                                renderBelowActions={({ refresh }) =>
                                    this.props.printMode ? null : (
                                        <AllActivitiesArea refresh={refresh} processId={props.processId} />
                                    )
                                }
                                updateUrlFromFilter={!props.fakePush}
                                fakePush={props.fakePush}
                                onRowSelect={([task]: { processInstanceId?: string; id: string }[]) => {
                                    props.redirect(
                                        task.processInstanceId
                                            ? `/processes/${task.processInstanceId}/tasks/${task.id}/start`
                                            : `/tasks/${task.id}`,
                                    );
                                }}
                                multiSelectable={this.props.bulkActionsOpen}
                                isPopover={false}
                                renderFilter={this.renderFilter}
                                {...props}
                                location={this._getLocationWithoutDelaySearch(this.props.location)}
                                renderList={(r1: RenderListArguments) =>
                                    getRenderer(
                                        {
                                            root: this.props.printMode ? undefined : props.classes.listResults,
                                            headerCell: props.classes.headerCell,
                                        },
                                        {},
                                    )({
                                        ...r1,
                                        onRowSelectBulk: this.props.bulkActionsOpen
                                            ? (selected, allData) => {
                                                  this.setState({
                                                      selectedData: Object.assign(
                                                          {},
                                                          ...selected.map((data) => ({ [data.id]: data })),
                                                      ),
                                                  });
                                              }
                                            : undefined,
                                        isBulkSelectableRecord: (record: { endTime?: string }) => !!record.endTime,
                                        fields: this.state.offlineTaskKeys?.length
                                            ? r1.fields.concat([
                                                  <OfflineTaskIconComponent
                                                      offlineTaskKeys={this.state.offlineTaskKeys}
                                                  />,
                                              ])
                                            : r1.fields,
                                    })
                                }
                                selectedData={this.state.selectedData || undefined}
                                customTitleElement={
                                    <Box sx={{ paddingLeft: 1 }}>
                                        <h1>Task Summary</h1>
                                    </Box>
                                }
                                resource={'TaskInstance'}
                                viewName={props.overrideViewName || getViewName()}
                                formId={props.overrideViewName || getViewName()}
                                perPage={props.printMode ? '1000' : '25'}
                            />
                        </div>
                    )}
                />
            </currentFilterContext.Provider>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const mergeFilterIntoLocation = (filter: TaskFilter) => {
        const { location, fakePush } = ownProps;
        const newSearch = merge(
            stripDelaySearchFromQueryString(location.search),
            filter,
            /* ['processInstance.businessKey', 'endTime__NOT_EMPTY', 'dueDate__LESS', 'processInstance.id',
             'assignee.login', 'name'],
            true */
            undefined,
            'STRIP_PAGE',
        );
        // if this is a controlled component, call the handler
        if (fakePush) {
            fakePush(newSearch);
        } else {
            // otherwise we are storing our search state in the window location
            dispatch(pushAction(newSearch));
        }
    };
    return {
        redirect: (newLocation: string) => {
            if (ownProps.overrideRedirect) {
                ownProps.overrideRedirect(newLocation);
            } else {
                dispatch(pushAction(newLocation));
            }
        },
        changeProcessDefinitionKey: (pdKey: string) =>
            mergeFilterIntoLocation({
                'processInstance.businessKey': pdKey,
            }),
        changeAssignee: (assigneeId: string) =>
            mergeFilterIntoLocation({
                'assignee_~_id': assigneeId,
            }),
        changeState: (state: CurrentState) => {
            let filter;
            switch (state) {
                case 'Closed':
                    filter = { endTime__NOT_EMPTY: true, dueDate__LESS: undefined };
                    break;
                case 'Open':
                    filter = { endTime__NOT_EMPTY: false, dueDate__LESS: undefined };
                    break;
                case 'Overdue':
                    filter = {
                        endTime__NOT_EMPTY: false,
                        dueDate__LESS: getTodayISO(),
                    };
                    break;
                default:
                    filter = {
                        endTime__NOT_EMPTY: undefined,
                        dueDate__LESS: undefined,
                    };
            }
            mergeFilterIntoLocation(filter);
        },
    };
};

const enhance = compose(
    connect(
        (state: RootState, ownProps) => ({
            printMode: state.printMode,
            lastProcessSearch: state.bpm.currentProcessSearch.query,
        }),
        mapDispatchToProps,
    ),
    withStyles(styles),
);
const WindowTaskList = enhance(TaskList);
export default WindowTaskList;

interface ContainedTaskListProps {
    readOnly?: boolean;
    processId?: string;
    requireProcessId: boolean;
    overrideViewName?: string;
    initialSearch?: string | null;
    useCard?: boolean;
    overrideRedirect?: (redirectTo: string) => void;
}
interface ContainedTaskListState {
    search: string;
    showBulkActions: boolean;
}

const mapStateToProps = (state: RootState, ownProps: ContainedTaskListProps) => {
    const processDefinition = getProcDefFromProcInstId(state, ownProps.processId);
    const isAdmin = processDefinition.fold(false, (pd) => !!pd.adminUser);
    const initialSort = fromNullable(state.viewConfig)
        .mapNullable((vc) => getDefaultSort(vc, '_TASK_LIST_FOR_PROCESS', 'ASC'))
        .getOrElse({ field: 'endTime', order: 'ASC' });

    return {
        isAdmin,
        processDefinition: processDefinition.fold(null, (pd) => pd),
        initialSort,
        sendBusinessKeyAlongWithProcessIdForEasyAttributeSecurity:
            getSendBusinessKeyAlongWithProcessIdForEasyAttributeSecuritySelector(state),
    };
};

interface ContainedTaskListComponentProps extends ContainedTaskListProps, ReturnType<typeof mapStateToProps> {}

const createContainedLocation = (processId: string | undefined, businessKey: string | undefined, search?: string) => {
    if (!processId) {
        return {
            search,
        };
    }
    const filter = {
        'processInstance.id': processId,
    };
    if (businessKey) {
        filter['processInstance.businessKey'] = businessKey;
    }
    return {
        search: merge(search, filter),
    };
};
class ContainedTaskListComponent extends React.Component<ContainedTaskListComponentProps, ContainedTaskListState> {
    memoizedCreateContainedLocation = memoizeOne(createContainedLocation);
    constructor(props: ContainedTaskListComponentProps) {
        super(props);
        this.state = {
            search:
                this.props.initialSearch ||
                `?${stringify({
                    sort: this.props.initialSort.field,
                    order: this.props.initialSort.order,
                })}`,
            showBulkActions: false,
        };
    }
    fakePush = (search: string | { search: string }) => {
        this.setState(typeof search === 'string' ? { search } : search);
    };
    toggleBulkActions = () => {
        this.setState((state) => ({
            ...state,
            showBulkActions: !state.showBulkActions,
        }));
    };
    render() {
        const {
            readOnly,
            processId,
            requireProcessId,
            overrideViewName,
            isAdmin,
            processDefinition,
            overrideRedirect,
            useCard,
            sendBusinessKeyAlongWithProcessIdForEasyAttributeSecurity,
        } = this.props;
        const { showBulkActions } = this.state;
        if (requireProcessId) {
            if (!processId || (sendBusinessKeyAlongWithProcessIdForEasyAttributeSecurity && !processDefinition?.key)) {
                // don't fetch until we have the processDefinition.key if we say we want it on the fetches
                // (otherwise we might double-fetch as the processDefinition loads after we render, so we fetch once without and once with.)
                return <div>Loading...</div>;
            }
        }
        return (
            <WindowTaskList
                useCard={useCard}
                overrideRedirect={overrideRedirect}
                location={this.memoizedCreateContainedLocation(
                    processId,
                    sendBusinessKeyAlongWithProcessIdForEasyAttributeSecurity ? processDefinition?.key : undefined,
                    this.state.search,
                )}
                fakePush={this.fakePush}
                overrideViewName={overrideViewName}
                readOnly={readOnly}
                processId={processId}
                businessKey={processDefinition?.key}
                bulkActionsOpen={showBulkActions}
                toggleBulkActions={isAdmin && this.toggleBulkActions}
            />
        );
    }
}

export const ContainedTaskList: React.ComponentType<ContainedTaskListProps> =
    connect(mapStateToProps)(ContainedTaskListComponent);
