import React, { useState, FunctionComponent, useCallback, useMemo } from 'react';
import { Button, Card, CardContent, CardActions } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';
import useViewConfig from 'util/hooks/useViewConfig';
import SearchSelectDialog from './SearchSelectDialog';
import { crudUpdate } from 'sideEffect/crud/update/actions';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import Dialog from '@mui/material/Dialog';

interface PopoverAddButton {
    parentId: string;
    parentEntityName: string;
    parentFieldInChild: string;
    resource: string;
    viewName?: string;
    onAddCb: () => void;
    hideCreate?: boolean;
    filter?: string;
}

const PopoverAddButton: FunctionComponent<PopoverAddButton> = ({
    filter,
    resource,
    parentId,
    parentEntityName,
    parentFieldInChild,
    viewName,
    onAddCb,
    hideCreate,
}) => {
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState<string | false>(false);
    const getEntities = useMemo(createGetEntities, []);
    type SelectorProps = {
        resource: string;
        id: string | false;
        backrefField: string;
        parentEntityName: string;
    };
    const confirmRecordSelector = useMemo(
        () =>
            createSelector(
                getEntities,
                (state: RootState, props: SelectorProps) => props.resource,
                (state: RootState, props: SelectorProps) => props.id,
                (entities, resource, id) => {
                    return id && entities[resource] && entities[resource][id];
                },
            ),
        [getEntities],
    );
    const confirmRecordBackrefIdSelector = useMemo(
        () =>
            createSelector(
                confirmRecordSelector,
                (state: RootState, props: SelectorProps) =>
                    props.backrefField.endsWith('Id') ? props.backrefField : `${props.backrefField}Id`,
                (record, backrefField) => record[backrefField],
            ),
        [confirmRecordSelector],
    );
    const confirmRecordBackrefId = useAppSelector((state: RootState) =>
        confirmRecordBackrefIdSelector(state, {
            resource,
            id: confirmOpen,
            backrefField: parentFieldInChild,
            parentEntityName,
        }),
    );
    const confirmRecordCurrentBackrefRecordSelector = useMemo(
        () =>
            createSelector(
                confirmRecordBackrefIdSelector,
                getEntities,
                (state: RootState, props: SelectorProps) => props.parentEntityName,
                (backrefId, entities, parentEntityName) =>
                    backrefId && entities[parentEntityName] && entities[parentEntityName][backrefId],
            ),
        [confirmRecordBackrefIdSelector, getEntities],
    );
    const confirmRecordCurrentBackrefExists = useAppSelector((state: RootState) =>
        Boolean(
            confirmRecordCurrentBackrefRecordSelector(state, {
                resource,
                id: confirmOpen,
                backrefField: parentFieldInChild,
                parentEntityName,
            }),
        ),
    );
    const confirmRecordCurrentBackrefTitleSelector = useMemo(
        () => createSelector(confirmRecordCurrentBackrefRecordSelector, (record) => record && record.title),
        [confirmRecordCurrentBackrefRecordSelector],
    );
    const confirmRecord = useAppSelector((state: RootState) =>
        confirmRecordSelector(state, { resource, id: confirmOpen, backrefField: parentFieldInChild, parentEntityName }),
    );
    const currentBackrefTitle = useAppSelector((state: RootState) =>
        confirmRecordCurrentBackrefTitleSelector(state, {
            resource,
            id: confirmOpen,
            backrefField: parentFieldInChild,
            parentEntityName,
        }),
    );
    const handleConfirmClose = useCallback(() => {
        setConfirmOpen(false);
    }, [setConfirmOpen]);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    const viewConfig = useViewConfig();
    const resourceDisplayName = viewConfig.entities[resource].displayName;
    const backrefField = parentFieldInChild.endsWith('Id') ? parentFieldInChild : `${parentFieldInChild}Id`;
    const dispatch = useDispatch();
    const performReverseAdd = useCallback(
        (selectedId: string) => {
            dispatch(
                crudUpdate({
                    resource,
                    data: {
                        id: selectedId,
                        partialUpdate: true,
                        [backrefField]: parentId,
                    },
                    cb: onAddCb,
                }),
            );
        },
        [backrefField, parentId, dispatch, onAddCb, resource],
    );
    const alreadyAdded = confirmRecordCurrentBackrefExists && confirmRecordBackrefId === parentId;
    const isUs = confirmOpen === parentId;
    return (
        <>
            <Button color="primary" onClick={handleOpen} aria-label={`Add ${resourceDisplayName}`}>
                Add&nbsp;
                <Search />
            </Button>
            {confirmOpen && (
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    open={Boolean(confirmOpen)}
                    onClose={handleConfirmClose}
                    maxWidth={false}
                >
                    <Card>
                        <CardContent>
                            {isUs ? (
                                <div>Cannot make record its own child.</div>
                            ) : alreadyAdded ? (
                                <div>Record already added.</div>
                            ) : confirmRecordCurrentBackrefExists ? (
                                <div>
                                    Selected Record:
                                    <br />
                                    <strong>{confirmRecord && confirmRecord.title}</strong>
                                    <br />
                                    has an existing{' '}
                                    {
                                        viewConfig.entities[resource].fields[
                                            parentFieldInChild.endsWith('Id')
                                                ? parentFieldInChild.slice(0, -2)
                                                : parentFieldInChild
                                        ].label
                                    }
                                    :<br />
                                    <strong>{currentBackrefTitle}</strong>.<br />
                                    Performing this add will overwrite it. Continue?
                                </div>
                            ) : (
                                <div>
                                    Selected Record:
                                    <br />
                                    <strong>{confirmRecord && confirmRecord.title}</strong>
                                    <br />
                                    Continue?
                                </div>
                            )}
                        </CardContent>
                        <CardActions>
                            <Button onClick={handleConfirmClose}>Cancel</Button>{' '}
                            {!isUs && !alreadyAdded && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        confirmOpen && performReverseAdd(confirmOpen);
                                    }}
                                >
                                    Ok
                                </Button>
                            )}
                        </CardActions>
                    </Card>
                </Dialog>
            )}
            <SearchSelectDialog
                viewName={viewName || viewConfig.entities[resource].defaultViews.LIST.name}
                injectCreateValues={{
                    [backrefField]: parentId,
                }}
                reference={resource}
                isOpen={open}
                handleClose={handleClose}
                // unfortunately, notEquals really means 'not null, and not equal' which doesn't match our need here,
                // which is 'available to be added'
                // filter={{
                //     [backrefField + '__NOT_EQUALS']: parentId,
                // }}
                filter={getFilterFromFilterString(filter)}
                values={[]}
                hideCreate={hideCreate}
                setReference={({ id }, update) => {
                    setConfirmOpen(id);
                    // performReverseAdd(id);
                }}
                onCreateCb={({ id }) => {
                    performReverseAdd(id);
                }}
            />
        </>
    );
};

export default PopoverAddButton;
