import React, { useCallback, useMemo, useState } from 'react';
import getFields from '../getFields';
import useViewConfig from 'util/hooks/useViewConfig';
import removeNullAndUndefinedKeys from 'util/removeNullAndUndefinedKeys';
import { crudGetList } from 'sideEffect/crud/getList/actions';
import { RootState, useAppSelector, useAppStore } from 'reducers/rootReducer';
import ViewConfig, { ViewField } from 'reducers/ViewConfigType';
import get from 'lodash/get';
import {
    getLabelForFieldExpr,
    isExpressionViewField,
    isFieldViewField,
} from 'components/generics/utils/viewConfigUtils';
import { Button } from '@material-ui/core';
import { Result, parseTemplateString } from 'viewConfigCalculations/util/parseTemplateString';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import { ValueSets } from 'valueSets/reducer';
import { entityPreprocessValuesForEval } from 'expressions/formValidation';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import { purifyHtmlMoreStrict } from 'fieldFactory/display/components/HtmlDisplay';
import translateViewFields from 'fieldFactory/translation/fromEntity/translateViewFields';
import { IntlShape, useIntl } from 'react-intl';
import { FieldFromEntity } from 'fieldFactory/translation/types';
import { Mode } from 'fieldFactory/Mode';
import { Sort } from '../List';
import { Store } from 'redux';
import { saveAs } from 'file-saver';
import useResourceDisplayName from 'util/hooks/useResourceDisplayName';
import { getListExportLimitSelector } from 'util/applicationConfig';
import f from 'f';
import { evaluateFormattedText } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { AjaxError } from 'rxjs/ajax';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Download from '@material-ui/icons/GetApp';

const getHeaders = (fields: ViewField[], viewConfig: ViewConfig, resource: string, intl: IntlShape) => {
    return fields
        .map(
            (f) =>
                f.label ??
                (isFieldViewField(f) ? getLabelForFieldExpr(viewConfig, resource, f.field, 'TRAVERSE_PATH') : ''),
        )
        .map((label) => evaluateFormattedText(intl, label));
};

// Extract text from HTML string
function extractText(htmlString: string): string {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = htmlString;
    return tempDivElement.textContent || '';
}

const templateCell = (
    expression: string,
    parsed: Result,
    entities: Record<string, unknown>,
    valueSets: ValueSets,
    viewConfig: ViewConfig,
    rootEntityType: string,
    rootEntityId: string,
) => {
    const expandedEntities = denormalizeEntitiesByPaths(
        entities,
        parsed.dataPaths, // possibly filter like .flatMap((p) => (p.includes('.') ? [p.slice(0, p.lastIndexOf('.'))] : [])
        viewConfig,
        rootEntityType,
        rootEntityId,
    );
    const values = entityPreprocessValuesForEval(
        expandedEntities,
        parsed.dataPaths,
        parsed.valuesetFieldsRequired,
        entities,
        { viewContext: 'ENTITY' },
        valueSets,
        viewConfig,
    );
    const str = evaluateFilterString(expression, values, purifyHtmlMoreStrict);
    const textContent = extractText(str);
    return textContent;
};

const useGetExpressionEvaluators = (entityType: string, fields: ViewField[]) => {
    const viewConfig = useViewConfig();
    const store = useAppStore();
    return useMemo(
        () =>
            fields.filter(isExpressionViewField).reduce(
                (prev, curr) => {
                    prev[curr.config] = (id: string) => {
                        const entities = store.getState().admin.entities;
                        const valueSets = store.getState().valueSets;
                        return templateCell(
                            curr.config,
                            parseTemplateString(curr.config, viewConfig, entityType),
                            entities,
                            valueSets,
                            viewConfig,
                            entityType,
                            id,
                        );
                    };
                    return prev;
                },
                {} as {
                    [expression: string]: (id: string) => string;
                },
            ),
        [viewConfig, store, fields, entityType],
    );
};

const processRow = (
    row: Record<string, unknown>,
    fields: FieldFromEntity[],
    expressionEvaluators: {
        [expression: string]: (id: string) => string;
    },
    store: Store<RootState>,
): string => {
    const csvRow = fields.map((field) => {
        const path = field.name
            ?.split('.')
            .map((sp) => {
                if (sp.startsWith('linked') && store.getState().viewConfig.entities[sp.slice('linked'.length)]) {
                    return 'linkedEntity';
                }
                return sp;
            })
            .join('.');
        switch (field.type) {
            case 'date':
            case 'UST_DAY': {
                const isoValue = get(row, path, '');
                if (!isoValue || typeof isoValue !== 'string') return '';
                try {
                    if (isoValue.includes('T')) {
                        // timestamp - parse it as such and print without the time part
                        // Perhaps f().date should be graceful if passed an instantlike?
                        return f().instant.of(isoValue).format('MM/dd/YYYY');
                    }
                    return f().date.of(isoValue).format('MM/dd/YYYY');
                } catch (e) {
                    console.log({ isoValue, path, row });
                    console.error(e);
                    return '';
                }
            }
            case 'date-time': {
                const isoValue = get(row, path, '');
                if (!isoValue || typeof isoValue !== 'string') return '';

                try {
                    return f().instant.of(isoValue).format('MM/dd/YYYY h:mm a');
                } catch (e) {
                    console.log({ isoValue, path, row });
                    console.error(e);
                    return '';
                }
            }
            case 'html-expression':
                return expressionEvaluators[field.htmlConfig]?.(row['id'] as string) ?? '';
            case 'refone_select':
            case 'entitytypeahead':
                return get(row, [path, 'title'].join('.'), '');
            case 'multiple-entity-typeahead':
            case 'refmanychip':
            case 'refmanymultiselect':
            case 'refmanymultiselect-idlist':
                return (get(row, path.endsWith('Ids') ? path.slice(0, 'Ids'.length * -1) : path, []) as any[])
                    .map((el) => el.title)
                    .join(',');
            case 'valueset-multi-box':
            case 'valueset_multiselect':
                return (get(row, path.endsWith('Ids') ? path.slice(0, 'Ids'.length * -1) : path, []) as any[])
                    .map((el) => el.display)
                    .join(',');
            case 'valueset-single-box':
            case 'valueset_select':
                return get(row, [path, 'display'].join('.'), '');
            default:
                return get(row, path, '');
        }
    });

    return csvRow
        .map((cell) => (typeof cell !== 'string' || cell.includes(',') ? JSON.stringify(cell) : cell))
        .join(',');
};

const ExportViewButton: React.FC<{
    listViewName: string;
    filters: Record<string, unknown>;
    referencedFromEntity?: boolean;
    referencedByField?: string;
    currentSort: Sort;
    currentTotal: false | number;
}> = ({ listViewName, filters, referencedByField, referencedFromEntity, currentSort, currentTotal }) => {
    const store = useAppStore();
    const viewConfig = useViewConfig();
    const fieldDefinitions = getFields(viewConfig, listViewName, referencedFromEntity, referencedByField);

    const resource = viewConfig.views[listViewName].entity;
    const expressionEvaluators = useGetExpressionEvaluators(resource, fieldDefinitions);
    const intl = useIntl();
    const translatedFields = useMemo(
        () => translateViewFields(intl, viewConfig, resource, Mode.DISPLAY)(fieldDefinitions),
        [intl, viewConfig, resource, fieldDefinitions],
    );
    const resourceDisplayName = useResourceDisplayName(resource);
    const exportLimit = useAppSelector(getListExportLimitSelector);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<AjaxError>(null);
    const exportView = useCallback(() => {
        setIsLoading(true);
        store.dispatch(
            crudGetList(
                {
                    resource,
                    pagination: {
                        page: 1,
                        perPage: exportLimit ?? 2000,
                    },
                    filter: removeNullAndUndefinedKeys(filters),
                    view: listViewName,
                    sort: currentSort,
                    cb: ({ response, total }) => {
                        setIsLoading(false);
                        setTimeout(() => {
                            const csv = [
                                getHeaders(fieldDefinitions, viewConfig, resource, intl),
                                ...response.map((row) =>
                                    processRow(row, translatedFields, expressionEvaluators, store),
                                ),
                            ].join('\n');
                            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                            saveAs(blob, `${resourceDisplayName} Export.csv`);
                        }, 100);
                    },
                    errorsCbs: {
                        '*': (e) => {
                            setIsLoading(false);
                            setError(e);
                        },
                    },
                },
                false,
            ),
        );
    }, [
        store,
        resource,
        filters,
        fieldDefinitions,
        listViewName,
        expressionEvaluators,
        intl,
        currentSort,
        exportLimit,
        resourceDisplayName,
        translatedFields,
        viewConfig,
    ]);

    return (
        <>
            {/* Loading dialog */}
            <Dialog open={isLoading}>
                <DialogTitle>Please wait...</DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            {/* Error dialog */}
            <Dialog open={!!error} onClose={() => setError(null)}>
                <DialogTitle>A{error?.status ? ` ${error.status}` : 'n'} error occurred</DialogTitle>
                <DialogContent>
                    <DialogContentText>You may wish to check the console</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setError(null)}>Close</Button>
                </DialogActions>
            </Dialog>
            {/* Download button */}
            <Button
                onClick={() => {
                    if (typeof currentTotal === 'number' && currentTotal > exportLimit) {
                        // eslint-disable-next-line
                        const ok = confirm(
                            `The number of results (${currentTotal}) will exceed the export limit (${exportLimit}). If you continue, only the first ${exportLimit} rows will be returned.`,
                        );
                        if (!ok) return;
                    }
                    exportView();
                }}
                color="primary"
                endIcon={<Download />}
            >
                Export {referencedFromEntity ? 'Current View' : 'Search Results'}
            </Button>
        </>
    );
};
export default ExportViewButton;
