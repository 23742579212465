import { createSelector } from 'reselect';
import {
    taskClosed,
    getFormDefinition,
    isEntityLinked,
    pickBetween,
    parseInitialValue,
} from 'bpm/components/TaskDetail/TaskForm/getInitialValues';
import createGetInitialFormValues from '../form/getFormInitial/createGetInitialValues2';
import ViewConfig from 'reducers/ViewConfigType';

const createGetTaskFieldsToOverrideWith = () =>
    createSelector(taskClosed, getFormDefinition, (taskIsClosed, formDefinition) => {
        return taskIsClosed // if the task is closed, lets not read values in to the entity form.
            ? {}
            : formDefinition &&
                  formDefinition.fields &&
                  formDefinition.fields.filter(isEntityLinked).reduce((formObj, field) => {
                      const value = pickBetween(field, taskIsClosed)(undefined, parseInitialValue(field));
                      if (typeof value !== 'undefined') {
                          return {
                              ...formObj,
                              [field.id]: value,
                          };
                      }
                      return formObj;
                  }, {});
    });

const createGetDefaultValuesEditForm = () => {
    const getTaskFieldsToOverrideWith = createGetTaskFieldsToOverrideWith();
    const getInitial = createGetInitialFormValues<{
        viewName: string;
        overrideViewConfig?: ViewConfig;
        id: string;
    }>({
        getIdFromProps: ({ id }) => id,
    });
    return createSelector(getInitial, getTaskFieldsToOverrideWith, (initialData, taskFieldsToOverrideWith) => {
        return { ...initialData, ...taskFieldsToOverrideWith };
    });
};

export default createGetDefaultValuesEditForm;
