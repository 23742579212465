import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardActions, Button, CircularProgress, CardContent, CardHeader } from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import { login } from '../actions';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { CLEAR_STORE_BUT_KEEP_LOCATION } from 'actions/constants';
import { SnackbarProvider } from 'notistack';
import Notifier from 'notistack/components/Notifier';
import { AuthPayload } from 'auth/definitions';
import AttemptRequest, { AttemptRequestState } from 'components/AttemptRequest';
import * as config from 'config';
import Themed from 'components/Themed';
import { fromNullable } from 'fp-ts/lib/Option';
import { PasswordResetTextField } from 'auth/password-reset/components/shared';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';
import PublicGlobalAlerts from 'global-alerts/public/components/PublicGlobalAlerts';
import { translateError } from 'auth/epic';
import { getCustomAuthPageHtmlSelector } from 'util/applicationConfig';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { getResetPageTextSelector } from 'util/applicationConfig';
import IEBannerComponent from 'components/layouts/IEAlert';
import Mfa from './Mfa';
import AuthForm from './LoginForm';
import { RenderFormProps } from './LoginFormProps';
import HtmlToReact from 'html-to-react';
import createProcessingInstructions from 'templatePage/createProcessingInstructions';
import DefaultLoginForm from './DefaultLoginForm';
import { useEvaluateTemplate } from 'expressions/Provider/hooks/useKeyCachingEval';
import useTemplateMessagesAndPurifyHtml from 'templatePage/hooks/templateMessagesAndPurifyHtml';
import buildHeaders from 'sideEffect/buildHeaders';
import { useTheme } from '@material-ui/core';
import { useRedirectTo } from './useRedirectTo';
import Dialog from '@mui/material/Dialog';
import { Location } from 'history';

const renderDialog =
    (closeNewPasswordDialog: () => void, resetPageText: string) => (newPasswordDialog: NewPasswordDialog) => {
        return (
            <Dialog
                open={newPasswordDialog.open}
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
            >
                {newPasswordDialog.open ? (
                    <AttemptRequest
                        type="internal"
                        renderer={({ attemptAction }) =>
                            (state) =>
                                (
                                    <Formik<{ password1: string; password2: string }>
                                        initialValues={{ password1: '', password2: '' }}
                                        validate={(values) => {
                                            let errors: Partial<typeof values> = {};
                                            if (!values.password1) {
                                                errors.password1 = 'Required';
                                            }
                                            if (!values.password2) {
                                                errors.password2 = 'Required';
                                            }
                                            if (
                                                values.password1 &&
                                                values.password2 &&
                                                values.password1 !== values.password2
                                            ) {
                                                errors.password2 = 'Password must match';
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            attemptAction({
                                                lazyRequest: getNewPasswordLazyR({
                                                    login: newPasswordDialog.data.login,
                                                    currentPassword: newPasswordDialog.data.currentPassword,
                                                    password: values.password1,
                                                }),
                                            });
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            /* and other goodies */
                                        }) => (
                                            <Card>
                                                <CardHeader title="Password Expired" />
                                                <form autoComplete="off" onSubmit={handleSubmit}>
                                                    <CardContent>
                                                        Your password has expired. Please enter a new password.
                                                        <PasswordResetTextField
                                                            disabled={
                                                                state._tag === 'success' || state._tag === 'pending'
                                                            }
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            value={values.password1}
                                                            name="password1"
                                                            label="New Password"
                                                            error={errors.password1}
                                                            touched={touched.password1}
                                                            type="password"
                                                        />
                                                        <PasswordResetTextField
                                                            disabled={
                                                                state._tag === 'success' || state._tag === 'pending'
                                                            }
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            value={values.password2}
                                                            name="password2"
                                                            label="Please re-enter password"
                                                            error={errors.password2}
                                                            touched={touched.password2}
                                                            type="password"
                                                        />
                                                        {state._tag === 'failure' ? (
                                                            <Themed>
                                                                {({ theme }) => (
                                                                    <div
                                                                        style={{
                                                                            color: theme.palette.error.dark,
                                                                        }}
                                                                    >
                                                                        {state.body && typeof state.body === 'object'
                                                                            ? Object.values(state.body).map(
                                                                                  (msg, i) => <p key={i}>{msg}</p>,
                                                                              )
                                                                            : 'There was a problem: Failed to update password.'}
                                                                    </div>
                                                                )}
                                                            </Themed>
                                                        ) : state._tag === 'success' ? (
                                                            <span>
                                                                <Done /> Success: Password updated.
                                                            </span>
                                                        ) : null}
                                                        <SafeHtmlAsReact html={resetPageText} />
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button variant="contained" onClick={closeNewPasswordDialog}>
                                                            Close
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            type="submit"
                                                            disabled={
                                                                state._tag === 'pending' ||
                                                                state._tag === 'success' ||
                                                                Object.keys(errors).length > 0
                                                            }
                                                        >
                                                            Submit
                                                            {state._tag === 'pending' ? (
                                                                <CircularProgress style={{ height: 15, width: 15 }} />
                                                            ) : null}
                                                        </Button>
                                                    </CardActions>
                                                </form>
                                            </Card>
                                        )}
                                    </Formik>
                                )}
                    />
                ) : null}
            </Dialog>
        );
    };

export const renderActionButtons =
    (args: { attemptAction: () => void; closeDialog: () => void; disallowSubmission: boolean }) =>
    <T extends any>(state: AttemptRequestState<T>) => {
        return (
            <CardActions>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={args.disallowSubmission || state._tag === 'pending'}
                    onClick={args.attemptAction}
                >
                    Send password reset email{' '}
                    {state._tag === 'pending' ? (
                        <CircularProgress
                            style={{
                                height: 20,
                                width: 20,
                            }}
                        />
                    ) : (
                        state._tag === 'success'
                    )}
                </Button>
                <Button onClick={args.closeDialog} aria-label="close">
                    Close
                </Button>
            </CardActions>
        );
    };

export const getLazyR = (login: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/account/forgot-password/init`, {
        method: 'POST',
        body: JSON.stringify({
            login,
        }),
        credentials: 'same-origin',
        headers: buildHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            includeCredentials: false,
        }),
    });

const getNewPasswordLazyR = (data: { currentPassword: string; password: string; login: string }) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/account/user-expired-password`, {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'same-origin',
        headers: buildHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            includeCredentials: false,
        }),
    });

type NewPasswordDialog =
    | {
          open: false;
      }
    | {
          open: true;
          data: {
              login: string;
              currentPassword: string;
          };
      };
interface LoginComponentState {
    newPasswordDialog: NewPasswordDialog;
    errorMessage: string;
}

interface CustomLoginProps {
    location: Location;
    renderForm: (
        props: RenderFormProps & {
            ErrorMessageElement: JSX.Element;
        },
    ) => JSX.Element;
}

export const CustomLogin: React.FC<CustomLoginProps> = (props) => {
    const [loginState, setLoginState] = React.useState<LoginComponentState>({
        errorMessage: '',
        newPasswordDialog: {
            open: false,
        },
    });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: CLEAR_STORE_BUT_KEEP_LOCATION });
    }, []); // eslint-disable-line
    const redirectTo = useRedirectTo();
    const submitLogin = React.useCallback(
        (auth: AuthPayload) => {
            dispatch(
                login(
                    auth,
                    (error) => {
                        if (
                            fromNullable(error.response)
                                .mapNullable((r) => r.AuthenticationException)
                                .fold(false, (msg) => msg && msg.includes('password expired'))
                        ) {
                            const initialRequest = JSON.parse(error.request.body);
                            setLoginState({
                                errorMessage: '',
                                newPasswordDialog: {
                                    open: true as const,
                                    data: {
                                        currentPassword: initialRequest.password,
                                        login: initialRequest.username,
                                    },
                                },
                            });
                        } else {
                            const errorMessage = translateError(error);
                            setLoginState((state) => ({
                                ...state,
                                errorMessage,
                            }));
                        }
                        return false;
                    },
                    redirectTo,
                ),
            );
        },
        [dispatch, redirectTo],
    );
    const closeNewPasswordDialog = React.useCallback(() => {
        setLoginState((state) => ({
            ...state,
            newPasswordDialog: {
                open: false,
            },
        }));
    }, [setLoginState]);
    const isSecureEnvironment = useAppSelector((state: RootState) =>
        state.basicInfo ? !state.basicInfo.debugFeaturesEnabled : true,
    );
    const resetPageText = useAppSelector(getResetPageTextSelector);
    const errorMessage = useEvaluateTemplate(loginState.errorMessage ?? '');
    const theme = useTheme();
    const ErrorMessageElement = (
        <p
            style={{
                marginBottom: 0,
                color: theme.palette.error.dark,
            }}
        >
            <SafeHtmlAsReact html={errorMessage({})} />
        </p>
    );
    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <SnackbarProvider>
                <>
                    {!isSecureEnvironment && <Notifier />}
                    <IEBannerComponent />
                    <PublicGlobalAlerts />
                    {renderDialog(closeNewPasswordDialog, resetPageText)(loginState.newPasswordDialog)}
                    {/* HTML templatable area here. */}
                    <AuthForm
                        renderForm={(formProps) =>
                            props.renderForm({
                                ...formProps,
                                ErrorMessageElement,
                            })
                        }
                        onSubmit={submitLogin}
                    />
                    <Mfa />
                </>
            </SnackbarProvider>
        </>
    );
};

interface TemplatableLoginProps {
    location: Location<unknown>;
}

const htmlToReactParser = new HtmlToReact.Parser();

const isValidNode = function () {
    return true;
};

const Template: React.FC<
    RenderFormProps & {
        ErrorMessageElement: JSX.Element;
    }
> = (props) => {
    const {
        ErrorMessageElement,
        UsernameElement,
        PasswordResetButtonElement,
        SubmitButtonElement,
        PasswordElement,
        Logo,
        BelowLoginFormElement,
    } = props;
    const processingInstructions = useMemo(() => {
        return createProcessingInstructions([
            { tag: 'data-auth-email-passwordreset', Element: PasswordResetButtonElement },
            { tag: 'data-auth-errormessage', Element: ErrorMessageElement },
            { tag: 'data-auth-emaillogin', Element: UsernameElement },
            { tag: 'data-auth-username', Element: UsernameElement },
            { tag: 'data-auth-password', Element: PasswordElement },
            { tag: 'data-auth-passwordreset', Element: PasswordResetButtonElement },
            { tag: 'data-auth-submit', Element: SubmitButtonElement },
            { tag: 'data-auth-logo', Element: Logo },
            { tag: 'data-below-login', Element: BelowLoginFormElement },
        ]);
    }, [
        ErrorMessageElement,
        UsernameElement,
        PasswordResetButtonElement,
        SubmitButtonElement,
        BelowLoginFormElement,
        PasswordElement,
        Logo,
    ]);

    const customAuthPageHtml = useAppSelector(getCustomAuthPageHtmlSelector);
    const templatedHtml = useTemplateMessagesAndPurifyHtml(customAuthPageHtml);
    const TemplatedReactElement = useMemo(() => {
        if (!templatedHtml) {
            return null;
        }
        return htmlToReactParser.parseWithInstructions(templatedHtml, isValidNode, processingInstructions);
    }, [processingInstructions, templatedHtml]);

    if (!TemplatedReactElement) {
        return <DefaultLoginForm {...props} />;
    }
    return TemplatedReactElement;
};

export const TemplatableLogin: React.FC<TemplatableLoginProps> = ({ location }) => {
    const renderForm = useCallback(
        ({
            ErrorMessageElement,
            UsernameElement,
            PasswordResetButtonElement,
            SubmitButtonElement,
            PasswordElement,
            Logo,
            BelowLoginFormElement,
        }) => (
            <Template
                ErrorMessageElement={ErrorMessageElement}
                UsernameElement={UsernameElement}
                PasswordResetButtonElement={PasswordResetButtonElement}
                SubmitButtonElement={SubmitButtonElement}
                PasswordElement={PasswordElement}
                Logo={Logo}
                BelowLoginFormElement={BelowLoginFormElement}
            />
        ),
        [],
    );
    return <CustomLogin location={location} renderForm={renderForm} />;
};
