import ViewConfig, { FieldViewField, View } from '../../../../reducers/ViewConfigType';
import { fromNullable, option, Option } from 'fp-ts/lib/Option';
import {
    isFieldViewField,
    getAdjustedFieldSource,
    isADirectRefOneField,
    isInlineManyViewField,
    expandComponentFields,
    getAllFieldEntriesFromView,
    getLongestPathUntilMany,
} from '../../../../components/generics/utils/viewConfigUtils';
import { getAdhocFieldsForView } from '../EntityFormContext/util/getAllFieldsExpected';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { traverse } from 'fp-ts/lib/Array';
import uniq from 'lodash/uniq';
import getFieldPathsAlongPath from './getAllPathsAlongPath';
import expandMinimalDataFromDataPaths from '../EntityFormContext/util/expandMinimalDataFromPaths';

const getViewTupleFromView = (v: View) => {
    const fields: View['fields'] = Object.assign({}, v.fields, ...Object.values(v.tabs || {}).map((t) => t.fields));
    return [v, fields] as [typeof v, typeof fields];
};
type ViewTuple = ReturnType<typeof getViewTupleFromView>;

const alwaysExpandOnBase = ['id', 'entityVersion', 'entityType', 'title', 'hasPossibleMatches'];

const getViewO = (viewConfig: ViewConfig, viewName: string) => {
    return fromNullable(viewConfig)
        .map((vc) => vc.views[viewName])
        .chain(fromNullable);
};

const getRefOnePathsNeededAlongPath = (viewConfig: ViewConfig, rootEntity: string) => (fp: string) => {
    const refOnesAlongPaths = getFieldPathsAlongPath(fp, 'EXCLUDE_ORIGINAL').flatMap((subPath) => {
        try {
            if (isADirectRefOneField(viewConfig, rootEntity, subPath)) {
                return [`${subPath}.id`, `${subPath}.entityVersion`, `${subPath}.entityType`];
            }
        } catch (e) {
            return [];
        }
        return [];
    });
    return refOnesAlongPaths;
};

export const mapFieldPathsToFieldsNecessary = (viewConfig: ViewConfig, viewName: string, fieldPaths: string[]) => {
    return getViewO(viewConfig, viewName)
        .map(getViewTupleFromView)
        .map(([view, fields]) => {
            return fieldPaths.flatMap((fp) => {
                const refOnesAlongPaths = getRefOnePathsNeededAlongPath(viewConfig, view.entity)(fp);
                // we have to construct the correct ids an entityVersions for the path
                try {
                    if (isADirectRefOneField(viewConfig, view.entity, fp)) {
                        return refOnesAlongPaths;
                    }
                } catch (e) {
                    return [fp, ...refOnesAlongPaths];
                }
                return [fp, ...refOnesAlongPaths];
            });
        })
        .getOrElse([]);
};
const getFormInitial = (
    viewConfig: ViewConfig,
    viewName: string,
    dataPaths: string[],
    entities: {},
    entityId: string,
) => {
    const viewO = getViewO(viewConfig, viewName);
    const recordEntry = viewO.mapNullable((v) => entities[v.entity]).mapNullable((e) => e[entityId]);
    if (recordEntry.isNone() || viewO.isNone()) {
        return undefined;
    }
    const entityType = viewO.value.entity;

    const fields = viewO
        .map((view) => getViewTupleFromView(view))
        .map((t) => {
            const [view, flds] = t;
            const fields = expandComponentFields(
                viewConfig,
                // inserting unique rows is necessary to prevent issues where multiple component fields on the same row mess things up.
                // (also probably makes it faster because it doesn't have to fix issues related to layout)
                Object.entries(flds).map(([fp, field], i) => [fp, { ...field, row: i }]),
                viewConfig.views[viewName].entity,
                { rebaseExpressionsWithinFields: false },
            )
                .expandedFieldsByRow.flat()
                .map((t) => t[1]);

            const fieldPaths = fields
                .flatMap((f) => {
                    if (isInlineManyViewField(f)) {
                        return [];
                    }
                    if (f.widgetType === 'FILEUPLOAD') {
                        return [`${f.field}Identifier`, `${f.field}FileName`, `${f.field}ContentType`];
                    }
                    if (isFieldViewField(f)) {
                        return [getAdjustedFieldSource(viewConfig)(view)(f)];
                    }
                    return [];
                })
                // filter out stuff like contacts.stiContactInfo.stdTreatments
                // and subject.labReports.labRequests.labResults
                // from refmany fields.
                // If we actually need the base, like contacts or subject.labReports,
                // it would be included elsewhere
                // so lets just strip it out
                .filter((p) => getLongestPathUntilMany(viewConfig, view.entity, p) === p);
            const inlineManySubpaths = fields.flatMap((f) => {
                if (!isInlineManyViewField(f) || !isFieldViewField(f)) {
                    return [];
                }
                return getRefOnePathsNeededAlongPath(viewConfig, view.entity)(f.field);
            });

            const allPaths = [...fieldPaths, ...dataPaths, ...alwaysExpandOnBase];
            return [
                view,
                uniq([...mapFieldPathsToFieldsNecessary(viewConfig, viewName, allPaths), ...inlineManySubpaths]),
            ] as [typeof view, typeof fieldPaths];
        })
        .map(([view, fieldPaths]) => {
            return [view, uniq([...fieldPaths, ...getAdhocFieldsForView(view)])] as [typeof view, string[]];
        })
        .map(([view, fieldPaths]) => fieldPaths)
        .getOrElse([]);

    const inlineManyPaths = viewO
        .map((view) => {
            const allViewFieldEntries = expandComponentFields(
                viewConfig,
                getAllFieldEntriesFromView(viewConfig, viewName),
                view.entity,
                { rebaseExpressionsWithinFields: false },
            ).expandedFieldsByRow.flat();
            return [view, Object.fromEntries(allViewFieldEntries)];
        })
        .chain((d) => traverse(option)(d, fromNullable) as Option<ViewTuple>)
        .map(([view, flds]) => {
            const fieldPaths = Object.values(flds)
                .filter((f) => !f.unpersistedField && f.widgetType === 'INLINE_MANY')
                .flatMap((f: FieldViewField) => {
                    const [pathUntilLast, last] = (() => {
                        const splitPath = f.field.split('.');
                        return [splitPath.slice(0, -1).join('.'), f.field.split('.').pop()];
                    })();
                    const maybeSearchEntity = traverseGetData(
                        viewConfig,
                        pathUntilLast,
                        { entityType: view.entity, id: entityId },
                        entities,
                        false,
                    );
                    if (maybeSearchEntity.isNone()) {
                        return [];
                    }
                    const searchEntity = maybeSearchEntity.value;
                    const searchForId = searchEntity?.['id'];
                    const searchEntityType = searchEntity?.['entityType'];
                    if (!searchForId || !searchEntityType) {
                        return [];
                    }
                    return [`${f.field}._ALL_.id`];
                });
            return fieldPaths;
        })
        .getOrElse([]);

    const allPaths = [...dataPaths, ...fields, ...inlineManyPaths];

    return expandMinimalDataFromDataPaths(viewConfig, entityType, entityId, entities, allPaths);
};
export default getFormInitial;
