import { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { delViewDetailByUuid, pushViewDetail } from './viewDetailsSlice';
import { RootState, useAppStore } from 'reducers/rootReducer';

function useRegisterViewDetails(formId: string, type: 'entity'): [_internal_uuid: string];
function useRegisterViewDetails(
    formId: string,
    type: 'entity',
    entityId: string,
): [_internal_uuid: string, sameEntityExistsBelowInStack: () => boolean];
function useRegisterViewDetails(formId: string, type: 'start-form'): [_internal_uuid: string];
function useRegisterViewDetails(
    formId: string,
    type: 'entity' | 'start-form',
    entityId?: string,
): [string] | [string, () => boolean] {
    const store = useAppStore();
    const _internal_uuid: string = useMemo(() => {
        return uuidv4();
    }, []);
    useEffect(() => {
        store.dispatch(
            pushViewDetail({
                uuid: _internal_uuid,
                formId,
                type,
                entityId,
            }),
        );
        return () => {
            store.dispatch(delViewDetailByUuid(_internal_uuid));
        };
    }, [_internal_uuid, store, formId, type, entityId]);

    const sameEntityExistsBelowInStack = useCallback(() => {
        if (!entityId) {
            return false;
        }

        const { someEntityNotOursWasOpenedFromOurs } = (store.getState() as RootState).viewDetailsStack.reduceRight(
            (prev, curr, ci, arr) => {
                if (curr.uuid === _internal_uuid) {
                    // this is us (ignore)
                    return prev;
                }
                if (curr.type !== 'entity') {
                    // we only care about entities
                    return prev;
                }
                if (curr.entityId !== entityId) {
                    // this was pushed by some other entity layer.
                    prev.notOursFound = true;
                }
                if (curr.entityId === entityId && prev.notOursFound) {
                    // some other entity layer exists between us and ourself
                    prev.someEntityNotOursWasOpenedFromOurs = true;
                }

                return prev;
            },
            {
                notOursFound: false,
                someEntityNotOursWasOpenedFromOurs: false,
            },
        );
        return someEntityNotOursWasOpenedFromOurs;
    }, [store, entityId, _internal_uuid]);
    if (!entityId) {
        return [_internal_uuid];
    }
    return [_internal_uuid, sameEntityExistsBelowInStack];
}

export default useRegisterViewDetails;
