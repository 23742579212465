import React from 'react';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { createSelector } from 'reselect';

const selectCurrentProfile = createSelector(
    (state: RootState) => state.profiles,
    (state: RootState) => state.viewConfig?.user?.id,
    (profiles, currentUserId) => {
        if (profiles.state !== 'profile_selected') {
            return null;
        }
        return profiles.profiles.find((profile) => profile.userId === currentUserId) || null;
    },
);
export const useCurrentProfile = () => {
    return useAppSelector(selectCurrentProfile);
};

export const ProfileName = () => {
    const currentProfile = useCurrentProfile();
    return currentProfile ? <span>{currentProfile.name}</span> : null;
};

export default ProfileName;
