import React from 'react';
import Typography from '@mui/material/Typography';
import useWidth from 'util/hooks/useWidth';
import { SvgIconProps } from '@mui/material/SvgIcon';

interface ErrorPageProps {
    retry?: (e?: any) => void;
    Icon: React.ComponentType<SvgIconProps>;
    headingText?: string;
    subText?: string;
}

const ErrorPageComponent: React.SFC<ErrorPageProps> = (props) => {
    const { retry, Icon, headingText, subText } = props;
    const width = useWidth();
    const isMobile = width === 'sm' || width === 'xs';
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div
                style={{
                    paddingTop: '25px',
                    textAlign: 'center',
                }}
            >
                <Icon
                    style={
                        isMobile
                            ? {
                                  height: 50,
                                  width: 50,
                              }
                            : {
                                  height: 200,
                                  width: 200,
                              }
                    }
                />
                {headingText && (
                    <Typography variant={isMobile ? 'h5' : 'h3'} component="div">
                        {headingText}
                    </Typography>
                )}
                {subText && (
                    <React.Fragment>
                        <br />
                        <Typography>{subText}</Typography>
                    </React.Fragment>
                )}
                <br />
                {retry ? (
                    <button type="reset" onClick={retry}>
                        Retry
                    </button>
                ) : null}
            </div>
        </div>
    );
};
export default ErrorPageComponent;
