import {
    getAccessLevelForEntityField,
    allowsEdit,
    allowsCreate,
} from '../../../components/generics/utils/viewConfigUtils';
import * as fieldTypes from '../../fieldTypes';
import ViewConfig from '../../../reducers/ViewConfigType';
import { FormFieldConfigs } from '../../translation/fromFlowable/types';
import { Field, FieldFromEntity, FieldFromFlowable, EntityDataField } from '../../translation/types';
import { DataSource } from '../../translation/types/DataSource';
import { fromNullable, tryCatch, fromPredicate, some, none } from 'fp-ts/lib/Option';

export const isFieldFromEntity = (field: Field): field is FieldFromEntity => field._dataSource === DataSource.ENTITY;

export const isEntityDataField = (field: Field): field is EntityDataField =>
    isFieldFromEntity(field) && field.type !== fieldTypes.HTML_EXPRESSION && !field.unpersisted;

export const isFieldFromFlowable = (field: Field): field is FieldFromFlowable =>
    field._dataSource === DataSource.FLOWABLE;

export const hasConfiguredEntity = (fieldDefinition: Field): fieldDefinition is Field & { configuredEntity: string } =>
    !!(isFieldFromEntity(fieldDefinition) && isEntityDataField(fieldDefinition) && fieldDefinition.configuredEntity);

export const isDisabledEntityField = (fieldDefinition: Field, liveProps, viewConfig: ViewConfig): boolean => {
    if (hasConfiguredEntity(fieldDefinition)) {
        // access levels can be looked up for fields relating to crud entities.
        const accessLevel = getAccessLevelForEntityField(
            viewConfig,
            fieldDefinition.configuredEntity,
            fieldDefinition.name,
        );
        const isDisabledByPermission = liveProps.isForCreate ? !allowsCreate(accessLevel) : !allowsEdit(accessLevel);
        return isDisabledByPermission && !liveProps.neverDisabled;
    }
    return false;
};

export const isRequired = (viewConfig: ViewConfig, fieldDefinition: Field): boolean | undefined => {
    if (isFieldFromEntity(fieldDefinition)) {
        if (hasConfiguredEntity(fieldDefinition)) {
            return (
                viewConfig.entities[fieldDefinition.configuredEntity] &&
                viewConfig.entities[fieldDefinition.configuredEntity].fields &&
                viewConfig.entities[fieldDefinition.configuredEntity].fields[fieldDefinition.name] &&
                viewConfig.entities[fieldDefinition.configuredEntity].fields[fieldDefinition.name].required
            );
        }
    }
    if (isFieldFromFlowable(fieldDefinition)) {
        return fieldDefinition.required;
    }
    return false;
};

export const getHtmlId = (fieldDefinition: Field) =>
    isEntityDataField(fieldDefinition)
        ? `${fieldDefinition.configuredEntity}:${fieldDefinition.name}`
        : fieldDefinition.name;

export const ensureEndswithId = (name: string) => {
    if (name.endsWith('Id')) {
        return name;
    }
    return `${name}Id`;
};
const getFlowableParamsConfigProperty = (property: keyof FormFieldConfigs) => (fieldDefinition: FieldFromFlowable) => {
    return fromNullable(fieldDefinition.params)
        .chain((params) => fromNullable(params.configs))
        .chain((configs) => fromNullable<string | undefined>(configs[property]));
};

export const getFlowableFieldConfigProperty =
    <T>(property: keyof T) =>
    (fieldDefinition: FieldFromFlowable) => {
        return getFlowableParamsConfigProperty('fieldConfig')(fieldDefinition)
            .chain((conf) => (conf ? tryCatch(() => JSON.parse(conf) as T) : none))
            .chain((conf) => fromNullable(conf[property]));
    };

export const getConfigProperty =
    <T>(property: keyof T) =>
    (fieldDefinition: FieldFromEntity) => {
        return fromPredicate<string>((value) => Boolean(typeof value === 'string' ? value.trim() : value))(
            fieldDefinition['config'],
        )
            .chain((conf) => tryCatch(() => JSON.parse(conf) as T))
            .chain((conf) => fromNullable(conf[property]));
    };

interface SuppressLabelConf {
    suppressLabel?: boolean;
}
export const simpleGetConfProperty =
    <T>(configProperty: keyof T, defaultValue: T[keyof T]) =>
    (fieldDefinition: Field) => {
        if (isFieldFromEntity(fieldDefinition)) {
            return getConfigProperty<T>(configProperty)(fieldDefinition).getOrElse(defaultValue);
        }
        if (isFieldFromFlowable(fieldDefinition)) {
            return getFlowableFieldConfigProperty<T>(configProperty)(fieldDefinition).getOrElse(defaultValue);
        }
        return defaultValue;
    };

export const getLabel = (fieldDefinition: Field) => {
    if (isEntityDataField(fieldDefinition)) {
        return getConfigProperty<SuppressLabelConf>('suppressLabel')(fieldDefinition)
            .chain<string | null>((suppressLabel) => (suppressLabel ? some('') : none))
            .getOrElse(fieldDefinition.label);
    }
    if (isFieldFromFlowable(fieldDefinition)) {
        return getFlowableFieldConfigProperty<SuppressLabelConf>('suppressLabel')(fieldDefinition)
            .chain<string | null>((suppressLabel) => (suppressLabel ? some('') : none))
            .getOrElse(fieldDefinition.label);
    }
    return fieldDefinition.label;
};

interface LabelledByConf {
    labelledBy?: string;
}
export const getLabelledBy = simpleGetConfProperty<LabelledByConf>('labelledBy', undefined);

interface ImageConf {
    width?: string;
}
export const getImageWidth = simpleGetConfProperty<ImageConf>('width', undefined);

interface AuthCodeConf {
    numChars?: number;
}
export const getNumChars = simpleGetConfProperty<AuthCodeConf>('numChars', undefined);
