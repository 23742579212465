import uniq from 'lodash/uniq';
import range from 'lodash/range';
import flatMap from 'lodash/flatMap';
import { schema } from 'normalizr';
import ViewConfig, { ViewField, FieldViewField } from '../../reducers/ViewConfigType';
import {
    isFieldViewField,
    getViewIndexAndAdditionalConfigFields,
    adjustLinkedXToLinkedEntityWithRes,
} from '../../components/generics/utils/viewConfigUtils/index';
// import { /* forceAlwaysExpand, /* forceSchemaIgnore */ } from '../../components/generics/overrides';
import { EntityValidations } from '../../reducers/entityValidationsReducer';
import createUnionSchema, {
    WILDCARD_ENTITY,
} from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/createUnionSchema';

export function isString(data: undefined | string): data is string {
    return typeof data === 'string';
}
export const getEntityRelationships = (
    viewConfig: ViewConfig,
    baseEntity: string,
    baseFieldExpr: string,
    exceptionEntities: string[] = [],
) => {
    const relationships: {
        [entityName: string]: {
            [fieldName: string]: string;
        };
    } = {};
    /*
        [entityName]: {
            [fieldName]: '[otherentityName]?relationshiptype'
        }
    }
    */
    const getEntityRelForExpr = (entity: string, fieldExpr: string) => {
        if (!viewConfig.entities[entity]) {
            throw Error(`Entity "${entity}" does not exist`);
        }
        if (exceptionEntities.indexOf(entity) !== -1) {
            return;
        }

        const viewFieldRefPath = fieldExpr.split('.');
        const [subField1, linkedEntity] = adjustLinkedXToLinkedEntityWithRes(viewFieldRefPath[0]);
        const entityField = viewConfig.entities[entity].fields[subField1];

        if (!entityField) {
            throw Error(`field "${subField1}" in "${fieldExpr}" not found on entity "${entity}"`);
        }

        if (viewFieldRefPath.length === 1) {
            const thisField = viewConfig.entities[entity].fields[fieldExpr];
            const relEntity: string | undefined = thisField.relatedEntity;
            if (isString(relEntity)) {
                if (!relationships[entity]) {
                    relationships[entity] = {};
                }
                relationships[entity][fieldExpr] = `${relEntity}?${thisField.dataType}`;
            }
        } else {
            const nextField = viewConfig.entities[entity].fields[subField1];
            const nextEntity = nextField.relatedEntity;
            if (isString(nextEntity)) {
                if (!relationships[entity]) {
                    relationships[entity] = {};
                }
                relationships[entity][subField1] = `${nextEntity}?${nextField.dataType}`;
                getEntityRelForExpr(
                    // essentially nextEntity === WILDCARD_ENTITY ? linkedEntity : nextEntity
                    linkedEntity || nextEntity,
                    range(1, viewFieldRefPath.length)
                        .map((i) => viewFieldRefPath[i])
                        .join('.'),
                );
            } else {
                throw Error(`relatedEntity not found on the path "${fieldExpr}":
                "${subField1}": "${viewConfig.entities[entity].fields[subField1]}"
                has no relatedEntity field`);
            }
        }
    };
    getEntityRelForExpr(baseEntity, baseFieldExpr);
    return relationships;
};

export const getFieldEntityRelationshipsFromView = (
    viewConfig: ViewConfig,
    _viewName: string,
    entityValidations: EntityValidations[0] = [],
) => {
    const [viewName, bpmConfigFields] = getViewIndexAndAdditionalConfigFields(
        _viewName,
        viewConfig,
        'KEEP_LINKEDX_TYPE',
    );
    const view = viewConfig.views[viewName];
    const entity = view.entity;
    const viewFields: FieldViewField[] = [
        ...Object.values(view.fields),
        ...([] as ViewField[]).concat(...Object.values(view.tabs || {}).map((tab) => Object.values(tab.fields || {}))),
        ...bpmConfigFields,
    ].filter((f) => isFieldViewField(f)) as FieldViewField[];

    const entities = {};
    [
        ...viewFields.map((vf) => vf.field),

        /* get all fields required for validations.
         We need to make sure this contains valid fields only valid entity traversals on input validations,
         but for now we can strip them out if they fail the traversal, and just log an error to the console.
         */
        ...entityValidations.flatMap((ev) =>
            ev.expansionsRequired.filter((fexp) => {
                // Remove this if we decide we want hard failure..
                try {
                    getEntityRelationships(viewConfig, entity, fexp);
                } catch (e) {
                    console.log(
                        `Error getting entity relationships for "${fexp}" on ${entity} EntityConfig validation
                        "${ev.expression}"
                        For now the field will simply be missing on the view's data expansion. Error below:
                        ${e.toString()}`,
                    );
                    return false;
                }
                return true;
            }),
        ),
    ].forEach((fexp) => {
        const mappings = getEntityRelationships(viewConfig, entity, fexp);
        Object.keys(mappings).forEach((entityName) => {
            if (!entities[entityName]) {
                entities[entityName] = {};
            }

            // field -> related entity]
            entities[entityName] = {
                ...entities[entityName],
                ...mappings[entityName],
            };
        });
    });

    /*
    For special cases (e.g. RelatedCase -> appCase)
    we can insert our own hardcoded expansions that are always present.

    */
    /*
    if (forceAlwaysExpand) {
        uniq([...Object.keys(entities), entity]).forEach((entityName) => {
            if (forceAlwaysExpand[entityName]) {
                entities[entityName] = {
                    ...forceAlwaysExpand[entityName],
                    ...entities[entityName] // let it be overwritten if already exists
                };
            }
        });
    }
    */
    // to create any circular relationships we need,
    /* go over otherentityname's relationships at 1 level of depth
    and add only if they contain direct references to an entity we already added
    /* entities // {
        entityname: {
            fieldName: otherentityname?relationshiptype
        }
    }
    */
    const relatedEntities = flatMap(Object.values(entities), (fieldSet) =>
        Object.values(fieldSet).map((exp) => exp.split('?')[0]),
    );
    relatedEntities.forEach((relE) => {
        /*
        if (relE === WILDCARD_ENTITY) {
            return; // wildcard entities have no guarantees about what fields are on them. backreferences can't be set.
        }
        */
        const fieldsInRelE = Object.values(viewConfig.entities[relE].fields);
        const relExists = fieldsInRelE.filter((f) => f.relatedEntity && entities[f.relatedEntity]); // fields relating to an existing entity
        relExists.forEach((f) => {
            entities[relE] = {
                ...entities[relE],
                [f.name]: `${f.relatedEntity}?${f.dataType}`,
            };
        });
    });
    return entities;
};

export const buildSchema = (
    entityMappings: { [key: string]: { [field: string]: string } },
    viewConfig: ViewConfig,
    includeXtoMany: boolean,
) => {
    const entitySchemas: { [key: string]: schema.Entity } = {};
    let entitySet: string[] = [];
    Object.keys(entityMappings).forEach((n) => {
        entitySet.push(n);
    });
    Object.values(entityMappings).forEach((fieldSet: { [field: string]: string }) => {
        Object.values(fieldSet).forEach((exp: string) => {
            const entity = exp.split('?')[0];
            // const relType = exp.split('?')[1];
            entitySet.push(entity);
        });
    });
    entitySet = uniq(entitySet).filter((e) => e !== WILDCARD_ENTITY); // dedupe

    entitySet.forEach((entityName) => {
        entitySchemas[entityName] = new schema.Entity(entityName);
    });

    // now we have our base entities
    // next, we describe relationships between them.
    const unionSchemaRef: {
        schema?: schema.Union;
    } = {};
    const getUnionSchema = () => {
        if (!unionSchemaRef.schema) {
            unionSchemaRef.schema = createUnionSchema([
                ...Object.keys(viewConfig.entities)
                    .filter((ename) => !entitySchemas[ename])
                    .map((ename) => new schema.Entity(ename)),
                ...Object.values(entitySchemas),
            ]);
        }
        return unionSchemaRef.schema;
    };

    Object.entries(entityMappings)
        // .filter(([entityName, fields]) => !fields['*']) // skip all entities that have * relationships.
        .forEach(([entityName, fields]: [string, { [key: string]: string }]) => {
            const definition = Object.assign(
                {},
                ...Object.entries(fields).map(([fieldName, relExpression]) => {
                    const relatedEntity = relExpression.split('?')[0];
                    const type = relExpression.split('?')[1];
                    if (type === 'REFONE' || type === 'REFONE_JOIN' || type === 'VALUESET') {
                        if (relatedEntity === WILDCARD_ENTITY) {
                            return { [fieldName]: getUnionSchema() };
                        }
                        return { [fieldName]: entitySchemas[relatedEntity] };
                    } else if (
                        type === 'REFMANYMANY' ||
                        type === 'REFMANY' ||
                        type === 'REFMANY_JOIN' ||
                        type === 'VALUESETMANY'
                    ) {
                        if (!includeXtoMany) {
                            return {};
                        }
                        if (relatedEntity === WILDCARD_ENTITY) {
                            return { [fieldName]: new schema.Array(getUnionSchema()) };
                        }
                        return { [fieldName]: new schema.Array(entitySchemas[relatedEntity]) };
                    }
                    throw Error(
                        'Unknown relationship type ' +
                            type +
                            ', expected REFONE | REFONE_JOIN | REFMANY | REFMANYMANY | REFMANY_JOIN',
                    );
                }),
            );
            entitySchemas[entityName].define(definition);
        });
    return entitySchemas;
};

const buildSchemaFromView = (
    viewConfig: ViewConfig,
    _viewName: string,
    entityValidations: EntityValidations[0] = [],
    includeXtoMany: boolean = false,
) => {
    const schemadef = getFieldEntityRelationshipsFromView(viewConfig, _viewName, entityValidations);

    const builtSchema = buildSchema(schemadef, viewConfig, includeXtoMany);

    // if there are no references in the view, the schema will be empty.
    const [viewName] = getViewIndexAndAdditionalConfigFields(_viewName, viewConfig, 'ALWAYS_LINKEDENTITY');
    const entity = viewConfig.views[viewName].entity;
    if (!builtSchema[entity]) {
        builtSchema[entity] = new schema.Entity(entity); // at least have a base entity in this case.
    }
    return builtSchema;
};

export default buildSchemaFromView;
