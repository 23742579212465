import React from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { Button } from '@material-ui/core';
import ContentSend from '@material-ui/icons/Send';
import { Dispatch } from 'redux';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import ViewConfig from '../../../../reducers/ViewConfigType';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import { currentUserProperties } from '../../../../expressions/contextUtils/currentUser';
import { javaEquivPrimative } from '@mkanai/casetivity-shared-js/lib/spel/evaluate/SpelCompiledExpression';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { getDashboardLinkWidgetIconSelector } from 'util/applicationConfig';

export const buttonStyle = {
    height: '100%',
    width: '100%',
    fontSize: '20px',
    lineHeight: '1em',
};

const mapStateToProps = (state: RootState) => {
    return {
        viewConfig: state.viewConfig,
    };
};

interface LinkWidgetProps {
    title: string;
    url: string;
    viewConfig: ViewConfig;
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        go: (loc: javaEquivPrimative) => {
            if (typeof loc === 'string' && loc.startsWith('http')) {
                window.location.href = loc;
            } else {
                dispatch(pushAction(loc as unknown));
            }
        },
    };
};

const LinkWidgetComponent = (props: LinkWidgetProps & ReturnType<typeof mapDispatchToProps>) => {
    const { title, go, url } = props;
    const dashboardLinkWidgetIcon = useAppSelector(getDashboardLinkWidgetIconSelector);

    const result: javaEquivPrimative = React.useMemo(() => {
        try {
            return evaluateFilterString(url, {
                ...currentUserProperties(props.viewConfig),
            });
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [url, props.viewConfig]);
    return result != null ? (
        <Button
            variant="contained"
            onClick={() => go(result)}
            color="primary"
            style={buttonStyle}
            endIcon={dashboardLinkWidgetIcon && <ContentSend />}
        >
            <SafeHtmlAsReact html={title} />
        </Button>
    ) : (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'rgba(0,0,0,0.6)',
            }}
        >
            <span style={{ flexShrink: 0 }}>Incorrect url specified</span>
        </div>
    );
};

const LinkWidget: React.ComponentType<any> = connect(mapStateToProps, mapDispatchToProps)(LinkWidgetComponent);

export default LinkWidget;
