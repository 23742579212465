import { useMemo, useState } from 'react';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import get from 'lodash/get';

import BpmFormBuilderField from 'fieldFactory/input/components/bpmFormBuilder/BpmFormBuilder';

const useBpmFormConfigFields = ({ initialData }: { initialData: Partial<FormFieldUnion> }) => {
    const initialFieldConfig = get(initialData, 'params.configs.fieldConfig');
    const initialBpmFormConfig: string = useMemo(() => {
        try {
            return initialFieldConfig && JSON.parse(initialFieldConfig)?.bpmFormDefinition;
        } catch (e) {
            console.error(e);
            return '';
        }
    }, [initialFieldConfig]);

    const initialReadBpmDefFromField: string = useMemo(() => {
        try {
            return initialFieldConfig && JSON.parse(initialFieldConfig)?.readSchemasFromField;
        } catch (e) {
            console.error(e);
            return '';
        }
    }, [initialFieldConfig]);

    const initialSPELVariablesFormConfig: string = useMemo(() => {
        try {
            return initialFieldConfig && JSON.parse(initialFieldConfig)?.defineSPELVariables;
        } catch (e) {
            console.error(e);
            return '';
        }
    }, [initialFieldConfig]);

    const [bpmFormConfig, setBpmFormConfig] = useState<string>(initialBpmFormConfig);
    const [bpmReadDefFromField, setBpmReadDefFromField] = useState<string>(initialReadBpmDefFromField);

    const [bpmFormDefMode, setBpmFormDefMode] = useState<'fromData' | 'staticDef'>(
        initialReadBpmDefFromField ? 'fromData' : 'staticDef',
    );
    const [defineSPELVariablesConfig, setDefineSPELVariablesConfig] = useState<string>(initialSPELVariablesFormConfig);

    const ConfigElement = (
        <div>
            <FormControlLabel
                checked={bpmFormDefMode === 'fromData'}
                control={
                    <Checkbox
                        color="primary"
                        onChange={(e) => {
                            setBpmFormDefMode(e.target.checked ? 'fromData' : 'staticDef');
                        }}
                    />
                }
                label="Read definition from data field"
                labelPlacement="start"
            />
            {bpmFormDefMode === 'fromData' ? (
                <TextField
                    defaultValue={initialReadBpmDefFromField}
                    value={bpmReadDefFromField}
                    onChange={(e) => {
                        setBpmReadDefFromField(e.target.value);
                    }}
                    label="Read definition from data field"
                />
            ) : null}
            <p>
                <b>{`Static form definition:`}</b>
                {` (fallback if data field provided)`}
            </p>
            <BpmFormBuilderField
                input={{
                    value: bpmFormConfig,
                    onChange: setBpmFormConfig,
                }}
            />
            <div style={{ height: '1em' }} />
            <TextField
                defaultValue={initialSPELVariablesFormConfig}
                value={defineSPELVariablesConfig}
                onChange={(e) => {
                    setDefineSPELVariablesConfig(e.target.value);
                }}
                multiline
                label="Define SPEL Variables"
                helperText="A SPEL dictionary. Accessed via #getVar('variableName') or #getVars()?.variableName."
            />
        </div>
    );

    const producedBpmFieldConfig = useMemo(() => {
        const config: {
            readSchemasFromField?: string;
            bpmFormDefinition?: string;
            defineSPELVariables?: string;
        } = {};
        if (bpmFormDefMode === 'fromData' && bpmReadDefFromField) {
            config.readSchemasFromField = bpmReadDefFromField;
        }
        if (bpmFormConfig) {
            config.bpmFormDefinition = bpmFormConfig;
        }
        if (defineSPELVariablesConfig?.trim()) {
            config.defineSPELVariables = defineSPELVariablesConfig.trim();
        }
        return JSON.stringify(config);
    }, [bpmFormConfig, bpmReadDefFromField, bpmFormDefMode, defineSPELVariablesConfig]);

    return {
        producedBpmFieldConfig,
        ConfigElement,
    };
};

export default useBpmFormConfigFields;
