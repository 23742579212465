import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Theme, createStyles } from '@material-ui/core/styles';
import { createSelector } from 'reselect';
import { Chip, Tooltip } from '@material-ui/core';
import { RootState, useAppSelector } from '../../../../reducers/rootReducer';
import { getValueSetForFieldExpr } from '../../../../components/generics/utils/viewConfigUtils/index';
import { getConceptsByValueSetCode } from '../../../../components/generics/utils/valueSetsUtil';
import { loadValueSet as loadValueSetAction, loadValueSetGroup as loadValueSetGroupAction } from 'valueSets/actions';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import uniqueId from 'lodash/uniqueId';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { useIntl } from 'react-intl';
import { evaluateFormattedText } from 'i18n/hooks/useEvaluatedFormattedMessage';
import useTextFieldUtils from '../../hooks/useTextFieldUtils';
/* eslint-disable no-use-before-define */
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import renderTextFieldLabel from 'fieldFactory/util/renderTextFieldLabel';
import { sortConceptsBySortOrder } from '../ValueSelectDownshift';

interface Concept {
    code?: string;
    description?: string;
    display: string;
    id: string;
    sortOrder?: number;
    subtitle?: string;
    title?: string;
    valueSetId?: string;
    active?: boolean;
}

const getValueSetGroup = (state, props) => props.group || null;

const makeMapStateToProps = () => {
    const emptyObj = {};
    const getEntities = createGetEntities();
    const getConcepts = createSelector(
        getEntities,
        (entities) => ((entities as any).Concept || emptyObj) as { [id: string]: Concept },
    );
    return createSelector(
        (state: RootState) => state.valueSets,
        getValueSetGroup,
        (state: RootState, props) =>
            props.valueSet ||
            getValueSetForFieldExpr(
                state.viewConfig,
                props.resource,
                props.source.slice(0, -3), // pop off 'Ids'
                'TRAVERSE_PATH',
            ),
        getConcepts,
        (state: RootState, props) => props.intl,
        (valueSets, group, valueSetCode, concepts, intl) => {
            const applyIntlToConcept = (concept) => {
                if (concept?.display?.includes('%{')) {
                    return {
                        ...concept,
                        display: evaluateFormattedText(intl, concept.display),
                    };
                }
                return concept;
            };
            const conceptsList = sortConceptsBySortOrder(
                getConceptsByValueSetCode(valueSets, valueSetCode, concepts, group).map((concept) =>
                    applyIntlToConcept(concept),
                ),
            );
            const dataTableById: { [id: string]: Concept } = (() => {
                const dt = {};
                conceptsList.forEach((o) => (dt[o.id] = o));
                return dt;
            })();
            const dataTableByDisplay: { [display: string]: Concept } = (() => {
                const dt = {};
                conceptsList.forEach((o) => (dt[o.display.toLowerCase()] = o));
                return dt;
            })();
            return {
                dataSource: conceptsList,
                dataTableById,
                dataTableByDisplay,
                valueSetCode,
            };
        },
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            border: '1px solid rgba(0, 0, 0, 0.12)',
        },
        root: {
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
    }),
);

type Input = WrappedFieldInputProps;
type Meta = WrappedFieldMetaProps;

// const PopperDown = React.forwardRef((props: PopperProps, ref: React.Ref<HTMLDivElement>) => <Popper {...props} ref={ref} placement='bottom-start' />)
// const PopperUp = React.forwardRef((props: PopperProps, ref: React.Ref<HTMLDivElement>) => <Popper {...props} ref={ref} placement='top-start' />);

interface ValuesetMany2Props {
    isPopover?: boolean;
    options?: {
        id: string;
    };
    disabled?: boolean;
    label?: string;
    input: Input;
    dataSource: Concept[];
    dataTableById: { [id: string]: Concept };
    dataTableByDisplay: { [display: string]: Concept };
    meta: Meta;
    tooltipText?: string;
    dropdownPosition?: 'above' | 'below';
    ariaInputProps: {};
    renderLabel: boolean;
    conceptIds?: string[];
    group?: string;
    valueSet?: string;
    resource: string;
    source: string;
    shouldFetchValueset?: boolean;
    isRequired?: boolean;
}
const emptyArray = [];
export default function ValuesetMany2(props: ValuesetMany2Props) {
    const {
        shouldFetchValueset = true,
        valueSet,
        resource,
        source,
        group,
        renderLabel = true,
        conceptIds,
        input,
        disabled,
        meta,
        label,
        options: { id: inputId } = { id: 'valueset-multiple' },
        dropdownPosition = 'below', // we only use this if using tooltips,
        // because in that scenario we can't have it autopositioning the popper.
        // because we need the tooltip going the opposite way.
    } = props;
    const intl = useIntl();
    const mapStateToProps = useMemo(makeMapStateToProps, []);
    const { dataSource, dataTableByDisplay, dataTableById, valueSetCode } = useAppSelector((state: RootState) =>
        mapStateToProps(state, { intl, valueSet, resource, source, group }),
    );
    const dispatch = useDispatch();
    const loadValueset = useCallback((valuesetCode: string) => dispatch(loadValueSetAction(valuesetCode)), [dispatch]);
    const loadValuesetGroup = useCallback(
        (valuesetCode: string, group: string) => dispatch(loadValueSetGroupAction(valuesetCode, group)),
        [dispatch],
    );
    useEffect(() => {
        if ((valueSet || valueSetCode) && shouldFetchValueset) {
            if (group) {
                group.split(',').forEach((g) => {
                    loadValuesetGroup(valueSet || valueSetCode, g);
                });
            } else {
                loadValueset(valueSet || valueSetCode);
            }
        }
    }, [group, shouldFetchValueset, valueSet, valueSetCode, loadValueset, loadValuesetGroup]);

    // above this line - everything that could be extracted to a 'useValuesetMany' hook.

    // now start adding stuff from the class
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialValue = useMemo(() => props.input?.value ?? emptyArray, []);
    const value = useMemo(() => props.input?.value ?? emptyArray, [props.input?.value]);
    const errorMessageId = useMemo(() => uniqueId('valuesetmany-errormsg'), []);
    const [inputValue, setInputValue] = useState('');
    const conceptAllowedByExpression = useCallback(
        (conceptId: string) => {
            return conceptIds ? conceptIds.indexOf(conceptId) !== -1 : true;
        },
        [conceptIds],
    );

    const getSelectedIds = useCallback((): string[] => {
        const currentlySelectedInForm = input?.value || emptyArray;
        // sort initial values, otherwise items are pushed onto the end (latest last)
        const sort = currentlySelectedInForm === initialValue;

        let ids = conceptIds ? currentlySelectedInForm.filter(conceptAllowedByExpression) : currentlySelectedInForm;

        if (sort) {
            return sortConceptsBySortOrder(ids.map((id) => dataTableById[id]).filter(Boolean))
                .map((c) => c?.id)
                .filter(Boolean);
        }
        return ids;
    }, [conceptAllowedByExpression, conceptIds, dataTableById, initialValue, input?.value]);

    const getSuggestions = useCallback(
        (inputValue: null | string = null) => {
            let count = 0;
            return dataSource
                .filter((suggestion) => conceptAllowedByExpression(suggestion.id))
                .filter((suggestion) => !input.value?.includes(suggestion))
                .filter((suggestion) => {
                    const matchesInputValue =
                        !inputValue || suggestion.display.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
                    const notIncludedAlready = getSelectedIds().indexOf(suggestion.id) === -1;
                    const keep = matchesInputValue && notIncludedAlready && suggestion.active && count < 1000;

                    if (keep) {
                        count += 1;
                    }

                    return keep;
                });
        },
        [conceptAllowedByExpression, dataSource, getSelectedIds, input.value],
    );

    const classes = useStyles();
    const { fieldVariant, getInputLabelProps } = useContext(themeOverrideContext);
    const { muiErrorProp, InputPropsClasses, helperText, createFormHelperTextProps } = useTextFieldUtils(props.meta);

    const InputProps = {
        label: props.label,
        placeholder: disabled ? '' : 'Select values',
        id: inputId,
        disabled,
        'aria-haspopup': true,
        'aria-roledescription': 'Type to filter results',
        'aria-errormessage': meta.touched && meta.error ? errorMessageId : undefined,
    };
    const El = (
        <div className={classes.root}>
            <Autocomplete<Concept, true>
                classes={classes}
                multiple
                disabled={disabled}
                onChange={(event, concepts) => {
                    const newValue = concepts.map((c) => (c as Concept).id);
                    input.onChange?.(newValue);
                    input.onBlur?.(newValue);
                }}
                // autocomplete does the filtering itself, so no need to filter by input value.
                options={getSuggestions()}
                value={getSelectedIds()
                    .map((id) => dataTableById[id])
                    .filter(Boolean)}
                getOptionLabel={(option) => option?.display ?? ''}
                defaultValue={initialValue}
                // PopperComponent={!props.tooltipText ? undefined : dropdownPosition === 'above' ? PopperUp : PopperDown}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            deleteIcon={disabled ? <span style={{ width: '.5em' }} /> : undefined}
                            {...getTagProps({ index })}
                            tabIndex={0}
                            label={option.display || option.title}
                            aria-label={`${label}: Entry ${index + 1} of ${tagValue.length}: ${
                                option.display || option.title
                            }`}
                            aria-roledescription="Button Press Delete key to delete"
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={renderTextFieldLabel(fieldVariant, renderLabel, props.isRequired, props.label)}
                        error={muiErrorProp}
                        helperText={helperText}
                        disabled={props.disabled}
                        FormHelperTextProps={createFormHelperTextProps(InputProps)}
                        InputLabelProps={getInputLabelProps({ ...params.InputLabelProps })}
                        fullWidth
                        variant={fieldVariant}
                        InputProps={{
                            ...params.InputProps,
                            inputProps: {
                                ...params.inputProps,
                                ...InputProps,
                                'aria-label': label,
                                placeholder: InputProps.placeholder || 'None selected',
                            },
                            classes: InputPropsClasses,
                        }}
                    />
                )}
            />
        </div>
    );
    if (props.tooltipText) {
        return (
            <Tooltip title={props.tooltipText} placement={dropdownPosition === 'below' ? 'top' : 'bottom'}>
                <div>{El}</div>
            </Tooltip>
        );
    }
    return El;
}
