import React, { FunctionComponent, useState, useCallback, useContext, useEffect } from 'react';
import { DashboardConfig, Dashboard, AttributeSecurity } from 'dashboard2/dashboard-config/types';
import PopoverRefInput from 'fieldFactory/popovers/PopoverRefInput';
import {
    TextField,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Button,
    CircularProgress,
    useTheme,
} from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Check from '@material-ui/icons/Check';
import Warning from '@material-ui/icons/Warning';
import { useDispatch } from 'react-redux';
import { crudGetOne } from 'sideEffect/crud/getOne/actions';
import AttributeSecurityForm from './attributeSecurity/AttributeSecurityForm';
import useSubmitDash from './util/useSubmitDash';
import DashEditor from './util/DashGridEditor';
import FormSaveNotifier from 'formSaveNotifier/components/FormSaveNotifier';
import Alert from '@material-ui/lab/Alert/Alert';

const startingDash = require('./startingDash.json');

interface DashEditorControllerProps {
    initialDashId?: string;
}
const DashEditorController: FunctionComponent<DashEditorControllerProps> = ({ initialDashId }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [dashConfig, setDashConfig] = useState<DashboardConfig | null>(startingDash);
    const [attributeSecurityConfig, setAttributeSecurityConfig] = useState<AttributeSecurity>();
    const [displayName, setDisplayName] = useState<string>('');
    const [pickedDashId, setPickedDashId] = useState<string | null>(null);
    useEffect(() => {
        if (initialDashId) {
            // in order for the Dash to load properly from the data, we need to
            // unmount and mount it again when the dash loads
            setDashConfig(null);
            dispatch(
                crudGetOne({
                    id: initialDashId,
                    view: -1,
                    resource: 'Dashboard',
                    cb: (id: string, _result: any) => {
                        const result: {
                            id: string;
                            config: string;
                            displayName: string;
                        } = _result;
                        if (result) {
                            setPickedDashId(id);
                            if (result.config) {
                                const dashboard = JSON.parse(result.config) as Dashboard;
                                setDashConfig(dashboard.dashboardConfig);
                                setAttributeSecurityConfig(dashboard.attributeSecurity);
                            }
                            setDisplayName(result.displayName);
                        }
                    },
                    errorsCbs: {
                        '*': () => {
                            alert('Dashboard not found.');
                        },
                    },
                }),
            );
        }
    }, []); // eslint-disable-line
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const [submissionState, submit] = useSubmitDash();
    const save = useCallback(() => {
        let dashToSubmit: any = {
            name: dashConfig.name,
            displayName: displayName,
            config: JSON.stringify({
                dashboardConfig: dashConfig,
                attributeSecurity: attributeSecurityConfig,
                loaded: false,
            }),
            // 'displayName'
            // 'hideConfigChange'
        };
        if (pickedDashId) {
            dashToSubmit.id = pickedDashId;
        }
        submit(dashToSubmit);
    }, [dashConfig, pickedDashId, displayName, submit, attributeSecurityConfig]);
    return (
        <div style={{ margin: '1em', padding: '1em' }}>
            <FormSaveNotifier when={submissionState.type !== 'SUCCESS'} />
            <div style={{ marginBottom: '1em', paddingBottom: '1em' }}>
                <PopoverRefInput
                    label="Select Dashboard"
                    resource="Dashboard"
                    reference="Dashboard"
                    source="pickedDashId"
                    options={{}}
                    input={{
                        value: pickedDashId,
                        onBlur: (v, data) => {
                            if (typeof v !== 'undefined') {
                                setPickedDashId(v || null);
                                if (v && data.config) {
                                    const dashboard = JSON.parse(data.config);
                                    setDashConfig(dashboard.dashboardConfig);
                                    setAttributeSecurityConfig(dashboard.attributeSecurity);
                                    setDisplayName(data.displayName || '');
                                } else {
                                    setDashConfig(startingDash);
                                    setDisplayName('');
                                }
                            }
                        },
                    }}
                    meta={{}}
                />
                <div style={{ height: '2em' }} />
                <TextField
                    label="Dashboard Name"
                    fullWidth
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({
                        shrink: true,
                        disabled: false,
                    })}
                    value={(dashConfig && dashConfig.name) || ''}
                    onChange={(e) => {
                        setDashConfig({
                            ...dashConfig,
                            name: e.target.value,
                        });
                    }}
                    onBlur={(e) => {
                        setDashConfig({
                            ...dashConfig,
                            name: e.target.value,
                        });
                    }}
                />
                <div style={{ height: '2em' }} />
                <TextField
                    label="Dashboard (Display) Name"
                    fullWidth
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({
                        shrink: true,
                        disabled: false,
                    })}
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    onBlur={(e) => setDisplayName(e.target.value)}
                />
                <div style={{ height: '2em' }} />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Inspect JSON</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <pre>{dashConfig && JSON.stringify(dashConfig, null, 1)}</pre>
                    </AccordionDetails>
                </Accordion>
                <div style={{ height: '2em' }} />
                <AttributeSecurityForm value={attributeSecurityConfig} onChange={setAttributeSecurityConfig} />
            </div>
            {dashConfig && <DashEditor key={pickedDashId} dashConfig={dashConfig} setDashConfig={setDashConfig} />}
            {submissionState.type === 'ERROR' ? (
                <pre style={{ color: theme.palette.error.main }}>
                    {submissionState.error.name}
                    <br />
                    {submissionState.error.message} <br />
                    {typeof submissionState.error.response === 'object' &&
                        JSON.stringify(submissionState.error.response, null, 1)}
                </pre>
            ) : null}
            <div style={{ height: '2em' }}>
                {submissionState.type === 'SUCCESS' && (
                    <Alert style={{ flex: 1, marginBottom: '1em' }} severity="info">
                        Your changes have been saved successfully and will be visible shortly. Please refresh to view
                        the latest updates.
                    </Alert>
                )}
                <Button
                    disabled={!dashConfig?.name?.trim() || submissionState.type === 'SUBMITTING'}
                    color="primary"
                    variant="contained"
                    onClick={save}
                >
                    Save{' '}
                    {submissionState.type === 'SUBMITTING' ? (
                        <CircularProgress style={{ height: 15, width: 15 }} />
                    ) : submissionState.type === 'SUCCESS' ? (
                        <Check />
                    ) : submissionState.type === 'ERROR' ? (
                        <Warning />
                    ) : null}
                </Button>
            </div>
        </div>
    );
};

export default DashEditorController;
