import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import formTypeContext from '../form/formTypeContext';
import FormDisplayStatus from 'remoteStatus/one/components/implementations/FormDisplayStatus';
import useDataLoaded from '../form/hooks/useDataLoaded';
import { Provider } from '../form/refreshContext';
import { crudGetOne } from 'sideEffect/crud/getOne/actions';
import useViewConfig from 'util/hooks/useViewConfig';
import { diff } from 'jsondiffpatch';
import { push } from 'connected-react-router';
import DeferredSpinner from 'components/DeferredSpinner';
import { isPlainObject, sortBy, uniq } from 'lodash';
import { loadValueSets } from 'valueSets/actions';
import {
    allowsEdit,
    getAdjustedFieldSource,
    getDataTypeForFieldExpr,
    getDefaultListViewName,
    getPathBackFromFieldPath,
    getRestUrl,
    isFieldViewField,
} from '../utils/viewConfigUtils';
import { CasetivityViewContextProvider } from 'util/casetivityViewContext';
import { EntityFormContextProvider, formContext } from './UnsplitFormContextProvider';
import { reduxForm, ConfigProps, FormErrors, touch, InjectedFormProps } from 'redux-form';
import useEntities from 'util/hooks/useEntities';
import { FieldViewField, ViewField } from 'reducers/ViewConfigType';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import { Mode } from 'fieldFactory/Mode';
import { hiddenLabelStyle } from '../genericMerge/hoc/injectDisplayFieldsForRecord';
import convertFieldDefinitionForMergeView from '../genericMerge/utilities/convertFieldDefinitionForMergeView';
import WithErrorBoundary from '../fields/WithErrorBoundary';
import { Button, Card, Typography, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useValidation from '../form/validate/useValidation';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import { services } from 'sideEffect/services';
import { useAppSelector } from 'reducers/rootReducer';
import useService from 'util/hooks/useService';
import {
    ApplyFormContextWithId,
    useAddFormContextToDict,
    columnFormContextsContext,
} from './contextManagement/formContexts';
import {
    ApplyReduxFormContextWithId,
    useAddReduxFormContextToDict,
    columnReduxFormContextsContext,
} from './contextManagement/reduxFormContexts';
import { MiddleButtons } from './TransferButtons/TransferButtons';
import CasetivitySelect from 'components/CasetivitySelect';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ArrowBack } from '@material-ui/icons';
import { AjaxError } from 'rxjs/ajax';
import SplitErrorDialog from './ErrorDialog';
import { getSubmissionError } from 'sideEffect/other/notificationEpic';
import getExpansionsSelector from 'sideEffect/crud/getOne/getExpansions';
import { useAppStore } from 'reducers/rootReducer';

const toEmptyStringsNull = (data: unknown) => {
    if (!isPlainObject(data)) {
        if (data === '') return null;
        return data;
    }
    return Object.fromEntries(Object.entries(data).map(([k, v]) => [k, toEmptyStringsNull(v)]));
};

const useThrowSubmissionError = (
    state: RemoteDataState,
    key: 'primaryRecord' | 'secondaryRecord',
    handleSubmit: (cb: () => void) => () => void,
) => {
    const contexts = useContext(columnFormContextsContext);
    const fc = contexts[key === 'primaryRecord' ? 'left' : 'right'];
    const registeredFields = useMemo(
        () =>
            Object.keys(fc.registeredValues).reduce((prev, curr) => {
                prev[curr] = true;
                return prev;
            }, {}),
        [fc],
    );
    const submissionError = useMemo(() => {
        const error = state.status === 'error' && state.error;
        if (!error) {
            return null;
        }
        const submissionError = getSubmissionError(error, registeredFields, key);

        return submissionError || null;
    }, [state, key, registeredFields]);

    useEffect(() => {
        if (submissionError) {
            handleSubmit(() => {
                throw submissionError;
            })();
        }
    }, [submissionError, handleSubmit]);
};

const should = (prop: '_validate' | '_warn') => {
    return ({ values, props, nextProps, initialRender }) => {
        const res = initialRender || nextProps.fieldValues !== props.fieldValues || nextProps[prop] !== props[prop];

        return res;
    };
};

const ReduxForm = reduxForm<
    {},
    {
        _validate: () => FormErrors<Record<string, unknown>, string>;
        _warn: () => FormErrors<Record<string, unknown>, string>;
        children: (props: InjectedFormProps) => JSX.Element;
    }
>({
    enableReinitialize: true,
    updateUnregisteredFields: true,
    keepDirtyOnReinitialize: true,
    shouldError: should('_validate'),
    shouldWarn: should('_warn'),
    warn: (values, props) => {
        const { _warn } = props;
        return _warn();
        // we have to pass these to make sure we are still in-sync with our warning callback
    },
    validate: (values, props) => {
        const { _validate } = props;
        // we have to pass these to make sure we are still in-sync with our validation callback
        return _validate();
    },
})((props) => {
    return props.children(props);
});

const Form: React.FC<
    ConfigProps<{}, {}, string> & {
        entityType: string;
        registeredFields: string[];
        children: (props: InjectedFormProps) => JSX.Element;
    }
> = (props) => {
    const fc = useContext(formContext);

    const validateErrors = useValidation({
        type: 'error',
        values: fc.fieldValues,
        resource: props.entityType,
        registeredFields: props.registeredFields,
    });

    const validateWarnings = useValidation({
        type: 'warn',
        values: fc.fieldValues,
        resource: props.entityType,
        registeredFields: props.registeredFields,
    });
    return <ReduxForm {...props} _validate={validateErrors} _warn={validateWarnings} />;
};

const Unsplit = ({
    id,
    entityType,
    overrideViewName,
}: {
    entityType: string;
    id: string;
    overrideViewName?: string;
}) => {
    const officialViewConfig = useViewConfig(false);
    const viewConfig = useViewConfig();
    const store = useAppStore();
    const appendExpansions = useMemo(() => {
        return uniq(
            Object.values(viewConfig.entities[entityType].fields).flatMap((entityField) => {
                if (entityField.calcType || entityField.expensive) {
                    return [];
                }
                if (!allowsEdit(entityField.accessLevel)) {
                    return [];
                }
                const ret: string[] = [entityField.name];
                if (entityField.dataType !== 'REFMANY' && entityField.dataType !== 'REFMANYMANY') {
                    return ret;
                }
                const viewName = getDefaultListViewName(viewConfig, entityField.relatedEntity);
                if (!viewName) {
                    return ret;
                }
                const pathBack = getPathBackFromFieldPath(viewConfig, entityType, entityField.name);

                const expansions = getExpansionsSelector(store.getState())(viewName, {
                    overrideViewConfig: viewConfig,
                })
                    .split(',')
                    .filter((p) => !pathBack || !p.startsWith(pathBack))
                    .map((exp) => [entityField.name, exp].join('.'));
                expansions.forEach((exp) => ret.push(exp));

                return ret;
            }),
        );
    }, [viewConfig, entityType, store]);
    const updateData = React.useCallback(() => {
        store.dispatch(
            crudGetOne({
                monitorRequest: true,
                resource: entityType,
                id,
                view: -1,
                appendExpansions,
                override:
                    officialViewConfig !== viewConfig
                        ? {
                              patchViewConfig: diff(officialViewConfig, viewConfig),
                          }
                        : undefined,
                cb: (responseId, responseData) => {
                    if (`${id}` !== `${responseId}`) {
                        console.log('Merge Occured.\n ourId: ', id, ', responseId: ', responseId);

                        store.dispatch(push(`/admin/split-record/${entityType}/${responseId}`));
                    }
                },
            }),
        );
    }, [store, entityType, id, officialViewConfig, viewConfig, appendExpansions]);

    const updateAllData = React.useCallback(() => {
        updateData();
        const valueSetCodes = uniq(
            Object.values(viewConfig.entities[entityType].fields).flatMap((entityField) => {
                switch (entityField.dataType) {
                    case 'VALUESET':
                    case 'VALUESETMANY':
                        return [entityField.valueSet];
                    default:
                        return [];
                }
            }),
        ).map((valueset) => ({ valueSet: valueset }));
        if (valueSetCodes.length > 0) {
            store.dispatch(loadValueSets(valueSetCodes));
        }
    }, [updateData, viewConfig, entityType, store]);

    React.useEffect(() => {
        updateAllData();
    }, []); // eslint-disable-line

    const refresh = React.useCallback(
        (event?: Event | React.SyntheticEvent<any, any>, fullRefresh = false) => {
            // If we need to refresh parent stuff, see Edit2.tsx for direction
            if (event) {
                event.stopPropagation();
            }
            updateAllData();
        },
        [updateAllData],
    );

    const dataLoaded = useDataLoaded(entityType, id);

    const entities = useEntities();
    const initialValues = useMemo(() => {
        return entities[entityType]?.[id];
    }, [entityType, entities, id]);

    const maybeSplitView = (() => {
        const splitViewName = overrideViewName || viewConfig.entities[entityType]?.defaultViews?.SPLIT?.name;
        return splitViewName && viewConfig.views?.[splitViewName];
    })();
    const viewFields: FieldViewField[] = useMemo(() => {
        const allBaseFields = Object.values(viewConfig.entities[entityType].fields).flatMap<FieldViewField>((f) => {
            if (f.calcType || f.expensive) {
                return [];
            }
            if (!allowsEdit(f.accessLevel)) {
                return [];
            }
            const widgetType: ViewField['widgetType'] = (() => {
                switch (f.dataType) {
                    case 'STRING':
                        return 'TEXTBOX';
                    case 'TEXTBLOB':
                        return 'TEXTAREA';
                    case 'BIGDECIMAL':
                    case 'DOUBLE':
                    case 'FLOAT':
                        return 'FLOAT';
                    case 'BOOLEAN':
                        if (f.required) return 'CHECKBOX';
                        else return 'NULLABLE_BOOLEAN';
                    case 'DATE':
                        return 'CALENDAR';
                    case 'INSTANT':
                        return 'CALENDARTIME';
                    case 'INTEGER':
                    case 'LONG':
                        return 'INTEGER';
                    case 'REFMANYMANY':
                        return 'MULTISELECT';
                    case 'REFMANY':
                        // return 'MULTIPLE_ENTITY_TYPEAHEAD';
                        return 'FORCE_IDS_LIST';
                    case 'VALUESETMANY':
                        return 'MULTISELECT';
                    case 'VALUESET':
                        return 'SELECT';
                    case 'REFONE':
                        return 'SELECT';
                    case 'ANYBLOB':
                    case 'BLOB':
                    case 'IMAGEBLOB':
                        return 'FILEUPLOAD';
                    default:
                        // If I don't know how to handle it, skip it.
                        return null;
                }
            })();
            if (!widgetType) return [];
            return [
                {
                    field: f.name,
                    entity: entityType,
                    widgetType,
                },
            ];
        });

        // Sort and filter according to default SPLIT view, if exists.

        if (!maybeSplitView) {
            return allBaseFields;
        }
        const allBaseFieldsObj = allBaseFields.reduce(
            (prev, curr) => {
                prev[curr.field] = curr;
                return prev;
            },
            {} as {
                [field: string]: FieldViewField;
            },
        );

        return Object.values(maybeSplitView.fields)
            .filter(isFieldViewField)
            .map((f) => {
                if (!allBaseFieldsObj[f.field]) {
                    console.log({
                        allBaseFields,
                        f,
                    });
                    console.error(`Field "${f.field}" is not allowed`);
                }
                return (
                    allBaseFieldsObj[f.field] && {
                        ...f,
                        ...allBaseFieldsObj[f.field],
                    }
                );
            })
            .filter(Boolean);
    }, [viewConfig, entityType, maybeSplitView]);

    const fieldFactory = useContext(FieldFactoryContext);
    const theme = useTheme();
    const createFieldColumn = useMemo(() => {
        const config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.INPUT_NOWARN,
            validate: true,
            connected: true,
            options: {},
        };

        const liveConfig = {
            resource: entityType,
            basePath: `/${entityType}`,
            commitChanges: false,
        };
        const generateInputFields = fieldFactory(config)(liveConfig);
        const createFieldColumn = (column: string) =>
            (
                generateInputFields(
                    viewFields
                        .filter(isFieldViewField)
                        .filter((vf) => vf.field !== 'id')
                        .map((vf) => convertFieldDefinitionForMergeView(vf)),
                ) as React.ReactElement<{ label: string; source: string }>[]
            ).map((f, i) => {
                const dataType = (() => {
                    try {
                        const path = f.props.source.endsWith('Ids')
                            ? f.props.source.slice(0, -3)
                            : f.props.source.endsWith('Id')
                            ? f.props.source.slice(0, -2)
                            : f.props.source;

                        return getDataTypeForFieldExpr(viewConfig, entityType, path, 'TRAVERSE_PATH');
                    } catch (e) {
                        return null;
                    }
                })();
                const isRefMany = dataType === 'REFMANY' || dataType === 'REFMANYMANY';
                const id = `split-input-${i}-${column}`;
                return (
                    <div
                        data-datatype={dataType}
                        data-source={f.props.source}
                        data-label={f.props.label}
                        style={{
                            marginTop: '1em',
                            border: isRefMany ? '1px solid gray' : undefined,
                            backgroundColor: isRefMany ? theme.palette.background.default : undefined,
                        }}
                        key={i}
                        id={id}
                        data-originaldefinition={f.props['data-originaldefinition']}
                    >
                        <label htmlFor={id}>
                            <span style={hiddenLabelStyle}>{`${column} ${f.props.label}`}</span>
                        </label>
                        <WithErrorBoundary>
                            <ApplyFormContextWithId columnId={column}>
                                <ApplyReduxFormContextWithId columnId={column}>{f}</ApplyReduxFormContextWithId>
                            </ApplyFormContextWithId>
                        </WithErrorBoundary>
                    </div>
                );
            });
        return createFieldColumn;
    }, [viewFields, fieldFactory, entityType, viewConfig, theme.palette.background.default]);
    const rightInitialValue = useMemo(() => ({}), []);

    const registeredFields = useMemo(
        () =>
            viewFields
                .filter(isFieldViewField)
                .map((f) => getAdjustedFieldSource(viewConfig)({ entity: entityType })(f))
                .filter(Boolean),
        [viewFields, viewConfig, entityType],
    );
    const _leftFields = useMemo(() => createFieldColumn('left'), [createFieldColumn]);
    const _rightFields = useMemo(() => createFieldColumn('right'), [createFieldColumn]);
    const [fieldsSort, setFieldsSort] = useState<'LABEL' | 'TYPE'>('LABEL');

    const [leftFields, rightFields] = useMemo(() => {
        if (maybeSplitView) {
            return [_leftFields, _rightFields];
        }
        switch (fieldsSort) {
            case 'LABEL': {
                const getLabelForSort = (f) => f.props['data-label']?.toLowerCase();
                return [sortBy(_leftFields, getLabelForSort), sortBy(_rightFields, getLabelForSort)];
            }
            case 'TYPE': {
                const getTypeForSort = (f) => f.props['data-datatype'];
                return [sortBy(_leftFields, getTypeForSort).reverse(), sortBy(_rightFields, getTypeForSort).reverse()];
            }
        }
    }, [_leftFields, _rightFields, fieldsSort, maybeSplitView]);

    const renderSuccess = React.useCallback(() => {
        if (!dataLoaded) {
            return <DeferredSpinner />;
        }
        return (
            <Card style={{ padding: '1em ' }}>
                <CasetivityViewContextProvider currentViewContext="entity">
                    <EntityFormContextProvider formId="left" record={{ entityType }}>
                        <Form
                            registeredFields={registeredFields}
                            entityType={entityType}
                            form="left"
                            initialValues={initialValues}
                        >
                            {({ handleSubmit: handleSubmitLeft }) => (
                                <AddFormContextWithId columnId="left">
                                    <EntityFormContextProvider formId="right" record={{ entityType }}>
                                        <Form
                                            registeredFields={registeredFields}
                                            entityType={entityType}
                                            form="right"
                                            initialValues={rightInitialValue}
                                        >
                                            {({ handleSubmit: handleSubmitRight }) => (
                                                <AddFormContextWithId columnId="right">
                                                    <WithSubmitSplit
                                                        handleSubmitLeft={handleSubmitLeft}
                                                        handleSubmitRight={handleSubmitRight}
                                                        entityType={entityType}
                                                    >
                                                        {({ SaveButton, state, reset }) => {
                                                            if (state.status === 'success') {
                                                                return (
                                                                    <div>
                                                                        <p>
                                                                            <button
                                                                                onClick={() => window.location.reload()}
                                                                                className="casetivity-linkbutton"
                                                                            >
                                                                                <ArrowBack
                                                                                    style={{ fontSize: '.8rem' }}
                                                                                />
                                                                                &nbsp;
                                                                                <b>Split again</b>
                                                                            </button>
                                                                        </p>
                                                                        <Alert>
                                                                            <AlertTitle>
                                                                                <b>Split succeeded!</b>
                                                                            </AlertTitle>
                                                                            <p>
                                                                                Your original record is at{' '}
                                                                                <Link
                                                                                    to={`/${entityType}/${state.data.primaryRecordId}/show`}
                                                                                >
                                                                                    {state.data.primaryRecord['title']}
                                                                                </Link>
                                                                            </p>
                                                                            <p>
                                                                                The newly created record is at{' '}
                                                                                <Link
                                                                                    to={`/${entityType}/${state.data.secondaryRecordId}/show`}
                                                                                >
                                                                                    {
                                                                                        state.data.secondaryRecord[
                                                                                            'title'
                                                                                        ]
                                                                                    }
                                                                                </Link>
                                                                            </p>
                                                                        </Alert>
                                                                    </div>
                                                                );
                                                            }

                                                            return (
                                                                <>
                                                                    {state.status === 'error' && (
                                                                        <SplitErrorDialog
                                                                            alertError={state.error}
                                                                            resource={entityType}
                                                                            clearAlert={reset}
                                                                        />
                                                                    )}
                                                                    {(() => {
                                                                        const middleActions = leftFields.map((f, i) => (
                                                                            <div key={i}>
                                                                                <MiddleButtons
                                                                                    leftColumnId="left"
                                                                                    rightColumnId="right"
                                                                                    field={f.props['data-source']}
                                                                                />
                                                                            </div>
                                                                        ));
                                                                        const rows = [
                                                                            <div
                                                                                style={{ display: 'table-row' }}
                                                                                key="header"
                                                                            >
                                                                                <div style={{ display: 'table-cell' }}>
                                                                                    <div style={{ paddingLeft: '1em' }}>
                                                                                        <div>
                                                                                            <Typography
                                                                                                variant="h5"
                                                                                                component="div"
                                                                                            >
                                                                                                Original record
                                                                                            </Typography>
                                                                                        </div>

                                                                                        {initialValues['title'] ? (
                                                                                            <div>
                                                                                                <Link
                                                                                                    to={`/${entityType}/${id}/show`}
                                                                                                >
                                                                                                    {` (${initialValues['title']})`}
                                                                                                </Link>
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    style={{ display: 'table-cell' }}
                                                                                />
                                                                                <div
                                                                                    style={{
                                                                                        display: 'table-cell',
                                                                                        textAlign: 'left',
                                                                                    }}
                                                                                >
                                                                                    <div style={{ paddingLeft: '1em' }}>
                                                                                        <Typography
                                                                                            variant="h5"
                                                                                            component="div"
                                                                                        >
                                                                                            New Record
                                                                                        </Typography>
                                                                                    </div>
                                                                                </div>
                                                                            </div>,

                                                                            <div
                                                                                style={{
                                                                                    display: 'table-row',
                                                                                }}
                                                                                key="actions"
                                                                            >
                                                                                <div style={{ display: 'table-cell' }}>
                                                                                    {maybeSplitView ? null : (
                                                                                        <div
                                                                                            style={{
                                                                                                paddingTop: '.5em',
                                                                                            }}
                                                                                        >
                                                                                            <b>Sort fields by: </b>
                                                                                            <CasetivitySelect
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        'unset',
                                                                                                    minWidth: '150px',
                                                                                                    marginRight: 15,
                                                                                                    minHeight: 36,
                                                                                                }}
                                                                                                label="Sort Fields"
                                                                                                onChange={(e) => {
                                                                                                    setFieldsSort(
                                                                                                        e.target
                                                                                                            .value as
                                                                                                            | 'LABEL'
                                                                                                            | 'TYPE',
                                                                                                    );
                                                                                                }}
                                                                                                value={fieldsSort}
                                                                                            >
                                                                                                {({
                                                                                                    OptionComponent,
                                                                                                }) => {
                                                                                                    return [
                                                                                                        'LABEL',
                                                                                                        'TYPE',
                                                                                                    ].map((key) => (
                                                                                                        <OptionComponent
                                                                                                            key={key}
                                                                                                            value={key}
                                                                                                            id={key}
                                                                                                        >
                                                                                                            {key}
                                                                                                        </OptionComponent>
                                                                                                    ));
                                                                                                }}
                                                                                            </CasetivitySelect>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    style={{ display: 'table-cell' }}
                                                                                />
                                                                                <div
                                                                                    style={{ display: 'table-cell' }}
                                                                                />
                                                                            </div>,
                                                                        ];
                                                                        for (let i = 0; i < leftFields.length; i++) {
                                                                            rows.push(
                                                                                <div
                                                                                    style={{ display: 'table-row' }}
                                                                                    key={i}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'table-cell',
                                                                                        }}
                                                                                    >
                                                                                        {leftFields[i]}
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'table-cell',
                                                                                            verticalAlign: 'middle',
                                                                                        }}
                                                                                    >
                                                                                        {middleActions[i]}
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'table-cell',
                                                                                        }}
                                                                                    >
                                                                                        {rightFields[i]}
                                                                                    </div>
                                                                                </div>,
                                                                            );
                                                                        }
                                                                        rows.push(
                                                                            <div
                                                                                style={{ display: 'table-row' }}
                                                                                key="footer"
                                                                            >
                                                                                <div
                                                                                    style={{ display: 'table-cell' }}
                                                                                />
                                                                                <div style={{ display: 'table-cell' }}>
                                                                                    <div
                                                                                        style={{
                                                                                            padding: '1em',
                                                                                            textAlign: 'center',
                                                                                        }}
                                                                                    >
                                                                                        {SaveButton}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: 'table-cell' }}>
                                                                                    {state.status === 'error' ? (
                                                                                        <Alert severity="error">
                                                                                            An unexpected error
                                                                                            occurred.
                                                                                        </Alert>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>,
                                                                        );
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    display: 'table',
                                                                                    width: '100%',
                                                                                    borderCollapse: 'collapse',
                                                                                }}
                                                                            >
                                                                                {rows}
                                                                            </div>
                                                                        );
                                                                    })()}
                                                                </>
                                                            );
                                                        }}
                                                    </WithSubmitSplit>
                                                </AddFormContextWithId>
                                            )}
                                        </Form>
                                    </EntityFormContextProvider>
                                </AddFormContextWithId>
                            )}
                        </Form>
                    </EntityFormContextProvider>
                </CasetivityViewContextProvider>
            </Card>
        );
    }, [
        dataLoaded,
        entityType,
        initialValues,
        fieldsSort,
        setFieldsSort,
        leftFields,
        rightFields,
        id,
        registeredFields,
        maybeSplitView,
        rightInitialValue,
    ]);
    return (
        <Provider value={refresh}>
            <formTypeContext.Provider value="EDIT">
                <FormDisplayStatus
                    id={id}
                    resource={entityType}
                    showSuccessOffline={dataLoaded}
                    renderSuccess={renderSuccess}
                    refresh={refresh}
                />
            </formTypeContext.Provider>
        </Provider>
    );
};
export default Unsplit;

const AddFormContextWithId: React.FC<{ columnId: string }> = ({ columnId, children }) => {
    const newFormContext = useAddFormContextToDict(columnId);
    const newReduxFormContext = useAddReduxFormContextToDict(columnId);
    return (
        <columnFormContextsContext.Provider value={newFormContext}>
            <columnReduxFormContextsContext.Provider value={newReduxFormContext}>
                {children}
            </columnReduxFormContextsContext.Provider>
        </columnFormContextsContext.Provider>
    );
};

type RemoteDataState =
    | {
          status: 'pending';
      }
    | {
          status: 'initial';
      }
    | {
          status: 'error';
          error: AjaxError;
      }
    | {
          status: 'success';
          data: {
              primaryRecordId: string;
              secondaryRecordId: string;
              primaryRecord: Record<string, unknown>;
              secondaryRecord: Record<string, unknown>;
          };
      };
const WithSubmitSplit = ({
    entityType,
    children,
    handleSubmitLeft,
    handleSubmitRight,
}: {
    handleSubmitLeft: (cb: () => void) => () => void;
    handleSubmitRight: (cb: () => void) => () => void;
    entityType: string;
    children: <State extends RemoteDataState>(props: {
        SaveButton: JSX.Element;
        state: State;
        reset: () => void;
    }) => JSX.Element;
}) => {
    const rx = useContext(columnReduxFormContextsContext);
    const anyValidationError = Object.values(rx).some((form) => Object.keys(form['syncErrors']).length > 0);
    const restUrlSelector = getRestUrl(entityType);
    const restUrl = useAppSelector(restUrlSelector);
    const contexts = useContext(columnFormContextsContext);
    const { left, right } = contexts;
    const {
        revisions,
        casetivityCanEdit,
        casetivityCanDelete,
        casetivityCanMerge,
        dedupeStatusId,
        hasPossibleMatches: _leftHasPossibleMatchs,
        ...leftValues
    } = left.registeredValues as Record<string, unknown>;
    const {
        id,
        hasPossibleMatches: _rightHasPossibleMatchs,
        ...rightValues
    } = right.registeredValues as Record<string, unknown>;
    const rv = Object.fromEntries(Object.entries(rightValues).filter(([k, v]) => v !== null));
    const _service = useMemo(
        () => (leftValues: Record<string, unknown>, rightValues: Record<string, unknown>) =>
            services.split.splitRecord(restUrl, leftValues, rightValues),
        [restUrl],
    );
    const [state, request, { StateIcon, reset }] = useService(_service);

    const store = useAppStore();

    const cancelRef = useRef<() => void>();
    useEffect(() => {
        return cancelRef.current;
    }, []);

    useThrowSubmissionError(state, 'primaryRecord', handleSubmitLeft);
    useThrowSubmissionError(state, 'secondaryRecord', handleSubmitRight);
    const SaveButton = (
        <Button
            disabled={anyValidationError || state.status === 'pending'}
            variant="contained"
            color="primary"
            onClick={() => {
                const { form } = store.getState();
                ['left', 'right']
                    .map((k) => [k, form[k]])
                    .forEach(([formId, form]) => {
                        store.dispatch(touch(formId, ...Object.keys(form['registeredFields'])));
                    });

                cancelRef.current = request(toEmptyStringsNull(leftValues), toEmptyStringsNull(rv));
            }}
            endIcon={StateIcon ?? <CallSplitIcon />}
        >
            Split
        </Button>
    );
    return children({
        SaveButton,
        state,
        reset,
    });
};
