type TrieNode = {
    value?: any;
    children: Map<string, TrieNode>;
};

export class Trie {
    root: TrieNode;

    constructor() {
        this.root = { children: new Map() };
    }

    insert(path: string, value: any): void {
        const parts = path.split('.');
        let currentNode = this.root;

        for (const part of parts) {
            if (!currentNode.children.has(part)) {
                currentNode.children.set(part, { children: new Map() });
            }
            currentNode = currentNode.children.get(part)!;
        }
        currentNode.value = value;
    }

    lookup(path: string): { value?: any; children: Map<string, TrieNode> } | undefined {
        const parts = path.split('.');
        let currentNode = this.root;

        for (const part of parts) {
            if (!currentNode.children.has(part)) {
                return undefined;
            }
            currentNode = currentNode.children.get(part)!;
        }
        return { value: currentNode.value, children: currentNode.children };
    }

    traverse(path: string, callback: (node: TrieNode, path: string) => void): void {
        const parts = path.split('.').filter(Boolean);
        let currentNode = this.root;

        for (const part of parts) {
            if (!currentNode.children.has(part)) {
                return;
            }
            currentNode = currentNode.children.get(part)!;
        }

        // Perform DFS traversal from the current node
        const dfs = (node: TrieNode, currentPath: string) => {
            callback(node, currentPath);
            for (const [key, child] of node.children) {
                dfs(child, currentPath ? `${currentPath}.${key}` : key);
            }
        };

        dfs(currentNode, path);
    }
}
