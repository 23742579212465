import * as React from 'react';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { AsyncEventsInProgressContextProvider } from 'bpm/components/TaskDetail/asyncEventCountContext';
import { fromNullable } from 'fp-ts/lib/Option';
import { DisplayStatusComponent } from 'remoteStatus/one/components/DisplayStatus';
import { NetworkUnavailable, ServerError } from 'remoteStatus/one/components/pages';
import BpmFormExpressionTester from 'expression-tester/bpm-form';
import ErrorPage from 'remoteStatus/one/components/pages/BaseErrorPage';
import useViewConfig from 'util/hooks/useViewConfig';
import { useTaskFormFetchStatus } from 'bpm/components/TaskDetail/TaskForm/hooks/useFetchTaskForm';
import InternalTaskForm from './Form';
import { getDefaultViewName } from 'bpm/components/ProcessDetail/getDefaultViewName';
import { InternationalizeForm } from 'bpm/internationalizeForm/useInternationalizedForm';
import getValuesetsForFormAction from 'bpm/task-form/epic/getValuesetsForFormAction';
import { EntityFormContextRef } from './types';
import Warning from '@mui/icons-material/Warning';

interface TaskFormProps {
    processId: string;
    taskId: string;
    forceNoLinkedEntityArea?: boolean;
    relatedEntityResource?: string;
    relatedEntityId?: string;
    renderLinkedEntity?: (
        options?: {
            viewName?: string;
            actions?: JSX.Element | null;
            toolbar?: JSX.Element | null;
        },
        entityFormContextRef?: EntityFormContextRef,
    ) => JSX.Element | null;
}
const TaskFormComponent: React.FunctionComponent<TaskFormProps> = (props) => {
    const { taskId, processId, forceNoLinkedEntityArea, renderLinkedEntity: _renderLinkedEntity } = props;
    const formFetchStatus = useTaskFormFetchStatus(taskId);
    const relatedEntityId = useAppSelector((state: RootState) => {
        return fromNullable(state.bpm.tasks.byId)
            .mapNullable((byId) => byId[taskId])
            .mapNullable((task) => task.linkedEntityId)
            .getOrElse(props.relatedEntityId);
    });
    const relatedEntityResource = useAppSelector((state: RootState) => {
        return fromNullable(state.bpm.tasks.byId)
            .mapNullable((byId) => byId[taskId])
            .mapNullable((task) => task.linkedEntityType)
            .getOrElse(props.relatedEntityResource);
    });
    const viewConfig = useViewConfig();
    const entityViewName = (() => {
        if (relatedEntityResource && viewConfig.entities[relatedEntityResource]) {
            return getDefaultViewName(viewConfig, relatedEntityResource);
        }
        return null;
    })();

    const linkedEntityFormContextRef = React.useRef();
    const renderLinkedEntity = React.useMemo(() => {
        if (!_renderLinkedEntity) {
            return undefined;
        }
        return (options) => _renderLinkedEntity(options, linkedEntityFormContextRef);
    }, [_renderLinkedEntity]);

    window['xx'] = linkedEntityFormContextRef;
    const dispatch = useDispatch();
    const [key, refresh] = React.useReducer((state) => state + 1, 1);
    if (!formFetchStatus) {
        return null;
    }
    const FormPage = (
        <div>
            <AsyncEventsInProgressContextProvider>
                <BpmFormExpressionTester
                    contextType="existing-task-form"
                    taskId={taskId}
                    relatedEntityId={relatedEntityId}
                    relatedEntityResource={relatedEntityResource}
                >
                    {({ formDefinition: _formRemoteData, open }) => {
                        if (!_formRemoteData) {
                            return null;
                        }
                        return (
                            <InternationalizeForm taskForm={_formRemoteData}>
                                {({ internationalizedForm: formDefinition }) => {
                                    if (!formDefinition) {
                                        return null;
                                    }
                                    return (
                                        <>
                                            <InternalTaskForm
                                                entityFormContextRef={linkedEntityFormContextRef}
                                                key={key}
                                                taskId={taskId}
                                                processId={processId}
                                                formDefinition={formDefinition}
                                                relatedEntityId={relatedEntityId}
                                                relatedEntityResource={relatedEntityResource}
                                                renderLinkedEntity={renderLinkedEntity}
                                            />
                                            {open && (
                                                <button
                                                    onClick={() => {
                                                        dispatch(getValuesetsForFormAction(formDefinition, viewConfig));
                                                        refresh();
                                                    }}
                                                >
                                                    Reload
                                                </button>
                                            )}
                                        </>
                                    );
                                }}
                            </InternationalizeForm>
                        );
                    }}
                </BpmFormExpressionTester>
            </AsyncEventsInProgressContextProvider>
        </div>
    );
    return (
        <DisplayStatusComponent
            renderUnhandledError={() => (
                <ErrorPage headingText="Unhandled Error" Icon={Warning} subText={'Check console for error details'} />
            )}
            remoteStatus={formFetchStatus}
            renderLoading={(previousStatus) => {
                if (previousStatus !== 'initial' && previousStatus._type === 'success') {
                    return FormPage;
                }
                // return <DeferredSpinner />;
                // because the deferredSpinner might be delayed a lot (due to sync processing on task return)
                // lets not defer it. The spinner will be stuck, but the user will know something's coming.
                return <div className="spinnyloader">Loading...</div>;
            }}
            renderNetworkError={() => <NetworkUnavailable />}
            renderServerError={(code, message) => {
                return <ServerError code={code} message={message} />;
            }}
            renderSuccess={() => {
                return FormPage;
            }}
        />
    );
};

export default TaskFormComponent;
