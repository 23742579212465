import { DefaultMessageId } from 'i18n/defaultMessagePaths';
import { useMemo } from 'react';
import { TypeMap, ValuesType, useMessage } from './Message';

type MessageProps<Id extends DefaultMessageId, DefaultMessage extends TypeMap[Id]> = {
    id: Id;
    dm: DefaultMessage;
    values?: ValuesType;
    contextualMessage: string;
    render: (message: string) => JSX.Element;
};

// Use this component if there is a contextual message that is higher priority than a default intl message
// But you still want to allow the message to be configured with a custom intl message
// priority: custom configured message > contextual message > default message

export const ContextualOrI18nMessage = <Id extends DefaultMessageId, DefaultMessage extends TypeMap[Id]>({
    id,
    dm,
    values,
    contextualMessage,
    render,
}: MessageProps<Id, DefaultMessage>): JSX.Element => {
    const message = useMessage({ id, dm, values });

    const result = useMemo(() => {
        if (message === dm) {
            return contextualMessage;
        }
        return message;
    }, [message, dm, contextualMessage]);

    return render(result);
};
