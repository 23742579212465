import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { withStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import get from 'lodash/get';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useTheme } from '@mui/material';
import { useAppSelector } from 'reducers/rootReducer';

const getChecked = (record, source) => {
    return get(record, source);
};

const hideIfNoRecord = branch(
    (props) => !props.record || (!getChecked(props.record, props.source) && props.hideLabel), // record is not provided
    renderNothing,
);

const CheckBoxComponent = ({ hideLabel, record, label, disabled, source }) => {
    const theme = useTheme();
    const printMode = useAppSelector((state) => state.printMode);

    return (
        <div style={{ verticalAlign: 'center' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        inputProps={{
                            'aria-label': label,
                        }}
                        disabled={true}
                        checked={getChecked(record, source)}
                        checkedIcon={<Done style={{ fontSize: '0.875em', width: 24, height: 24 }} />}
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.875em', width: 24, height: 24 }} />}
                        style={{
                            color: printMode ? theme.palette.text.primary : theme.palette.text.secondary,
                            fontSize: '0.875em',
                        }}
                    />
                }
                label={
                    <span style={{ color: printMode ? theme.palette.text.primary : theme.palette.text.secondary }}>
                        {hideLabel ? '' : label}
                    </span>
                }
            />
        </div>
    );
};

export default withStyles({}, { withTheme: true })(hideIfNoRecord(CheckBoxComponent));
