import React, { useContext } from 'react';
import { connect } from 'react-redux';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import compose from 'recompose/compose';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import Add from '@material-ui/icons/Add';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import {
    FormControl,
    InputLabel,
    Input as StandardInput,
    IconButton,
    FormHelperText,
    FilledInput,
    OutlinedInput,
} from '@material-ui/core';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { RootState } from '../../../reducers/rootReducer';
import uniqueId from 'lodash/uniqueId';
import { allowsCreate } from 'components/generics/utils/viewConfigUtils';
import { TextFieldUtils } from 'fieldFactory/input/hooks/useTextFieldUtils';
import FieldTitle, { EvaluateLabel } from 'fieldFactory/input/components/aor/FieldTitle';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';

type Input = any;
type Meta = any;

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

export interface Record {
    id: string;
    entityType: string;
    title?: string;
}

/*
    Displays selected record, and triggers popover with onClick prop.
*/
const getFloatingButtonStyle = ({
    renderLabel,
    offsetRight = 0,
    fieldVariant,
}: {
    fieldVariant: 'standard' | 'outlined' | 'filled';
    renderLabel: boolean | undefined;
    offsetRight?: number | string;
}): React.CSSProperties => {
    const zIndex = 2;
    if (fieldVariant === 'outlined') {
        return {
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            right: offsetRight,
            zIndex,
        };
    }
    return {
        position: 'absolute',
        right: offsetRight,
        top: renderLabel ? 16 : 0,
        padding: 4,
        zIndex,
    };
};
interface ReferenceDisplayProps {
    noSearch?: boolean;
    isOpen: boolean;
    isHardDisabled?: boolean;
    label: string | null;
    input: Input;
    meta: Meta;
    openSearch: () => void;
    openAdd: () => void;
    openDetail: () => void;
    htmlId?: string;
    record: Record;
    disabled?: boolean;
    reference: string;
    ariaInputProps?: {};
    renderLabel?: boolean;
    expansions?: string[];
    fetchOwnData?: boolean;
    overrideAriaLabel?: string;
}
const mapStateToProps = (state: RootState, props: ReferenceDisplayProps) => {
    // because we might have an id pointing to a 'moved' record (with a new real id)
    const referenceId =
        (props.input.value && state.admin.entities[props.reference]?.[props.input.value]?.id) ?? props.input.value;
    return {
        viewConfig: state.viewConfig,
        referenceId,
        referenceRecord: (state.admin.entities[props.reference] || {})[referenceId],
    };
};
interface DispatchToProps {
    crudGetOne: (...args: ArgumentTypes<typeof crudGetOneAction>) => void;
}
const dispatchToProps: DispatchToProps = {
    crudGetOne: crudGetOneAction,
};
interface ReferenceDisplayConnectedProps
    extends ReferenceDisplayProps,
        ReturnType<typeof mapStateToProps>,
        DispatchToProps {
    fieldVariant: 'standard' | 'filled' | 'outlined';
}

const fetchReference =
    (props: ReferenceDisplayConnectedProps) =>
    ({ crudGetOne, reference, expansions, referenceId } = props) => {
        if (referenceId) {
            crudGetOne({
                resource: reference,
                id: referenceId,
                view: -1,
                appendExpansions: expansions,
            });
        }
    };
interface Handlers {
    fetchReference: ReturnType<typeof fetchReference>;
}
const handlers: Handlers = {
    fetchReference,
};
interface ReferenceDisplayComponentProps extends ReferenceDisplayConnectedProps, Handlers {}

class ReferenceDisplayComponent extends React.Component<ReferenceDisplayComponentProps> {
    private inputId = uniqueId('refone-input');
    private errorMessageId = uniqueId('refone-error');
    private viewButtonRef = React.createRef<HTMLButtonElement>();
    private searchButtonRef = React.createRef<HTMLButtonElement>();
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    getCurrentlySelected = () => {
        const { input } = this.props;
        return input.value !== null && typeof input.value !== 'undefined' && input.value !== '';
    };
    getDisplayText = () => {
        const { referenceRecord = null } = this.props;
        return referenceRecord ? referenceRecord.title || referenceRecord.id : '';
    };
    handleBaseClick = () => {
        const { openDetail, openSearch } = this.props;
        const currentlySelected = this.getCurrentlySelected();

        if (currentlySelected) {
            const { current } = this.viewButtonRef;
            if (current) {
                current.focus();
            }
            openDetail();
        } else {
            const { current } = this.searchButtonRef;
            if (current) {
                current.focus();
            }
            openSearch();
        }
    };
    renderInputStyle() {
        const {
            input,
            openSearch,
            openAdd,
            label,
            disabled,
            noSearch,
            renderLabel,
            reference,
            meta,
            viewConfig,
            overrideAriaLabel,
            fieldVariant,
        } = this.props;

        const renderFloatingRedEye = (templatedLabel: string, offsetRight: number) => (
            <IconButton
                ref={this.viewButtonRef}
                aria-label={`View ${
                    overrideAriaLabel ?? templatedLabel
                }: ${this.getDisplayText()} Opens a modal dialog`}
                style={getFloatingButtonStyle({
                    renderLabel,
                    offsetRight,
                    fieldVariant,
                })}
                onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    this.props.openDetail();
                }}
            >
                <RemoveRedEye />
            </IconButton>
        );

        const hasCreate = allowsCreate(viewConfig.entities[reference].accessLevel);

        const currentlySelected = this.getCurrentlySelected();
        return (
            <TextFieldUtils meta={meta}>
                {({
                    fieldVariant,
                    muiErrorProp,
                    inputLabelClasses,
                    helperText,
                    formHelperTextClasses,
                    InputPropsClasses,
                }) => {
                    const Input =
                        fieldVariant === 'filled'
                            ? FilledInput
                            : fieldVariant === 'outlined'
                            ? OutlinedInput
                            : StandardInput;
                    return (
                        <div style={{ position: 'relative', width: '100%', margin: 0 }}>
                            <EvaluateLabel label={label}>
                                {({ templatedLabel }) => (
                                    <FormControl
                                        variant={fieldVariant}
                                        style={{ zIndex: 'unset' }}
                                        fullWidth={true}
                                        error={muiErrorProp}
                                    >
                                        <div
                                            style={{
                                                // So that icons can center on textarea when in outlined mode.
                                                // instead of including helperText
                                                position: 'relative',
                                                width: '100%',
                                            }}
                                        >
                                            {renderLabel && (
                                                <InputLabel
                                                    classes={inputLabelClasses}
                                                    shrink={true}
                                                    disabled={disabled}
                                                    htmlFor={this.inputId}
                                                >
                                                    <FieldTitle label={templatedLabel} />
                                                </InputLabel>
                                            )}

                                            <Input
                                                label={templatedLabel}
                                                id={this.inputId}
                                                value={this.getDisplayText()}
                                                inputProps={{
                                                    disabled: true,
                                                    'aria-label': overrideAriaLabel,
                                                    'aria-disabled': true,
                                                    'aria-errormessage':
                                                        meta.touched && meta.error ? this.errorMessageId : undefined,
                                                    'aria-describedby':
                                                        meta.touched && meta.warning ? this.errorMessageId : undefined,
                                                    style: {
                                                        textOverflow: 'ellipsis',
                                                        marginRight: '90px',
                                                    },
                                                }}
                                                style={{ width: '100%' }}
                                                classes={InputPropsClasses}
                                            />
                                            {this.props.referenceRecord &&
                                                renderFloatingRedEye(templatedLabel, disabled ? 0 : noSearch ? 30 : 60)}
                                            {!disabled && currentlySelected && (
                                                <IconButton
                                                    aria-label="clear value"
                                                    style={getFloatingButtonStyle({
                                                        renderLabel,
                                                        offsetRight: noSearch ? 0 : 30,
                                                        fieldVariant,
                                                    })}
                                                    onClick={(evt) => {
                                                        if (!disabled) {
                                                            evt.preventDefault();
                                                            evt.stopPropagation();
                                                            input.onChange?.(null);
                                                            input.onBlur(null);
                                                        }
                                                    }}
                                                >
                                                    <Clear />
                                                </IconButton>
                                            )}
                                            {!disabled &&
                                                (this.props.noSearch ? (
                                                    !currentlySelected &&
                                                    hasCreate && (
                                                        <IconButton
                                                            aria-label={`Add ${overrideAriaLabel ?? templatedLabel}`}
                                                            aria-haspopup="true"
                                                            onClick={(evt) => {
                                                                evt.preventDefault();
                                                                evt.stopPropagation();
                                                                openAdd();
                                                            }}
                                                            style={getFloatingButtonStyle({
                                                                renderLabel,
                                                                fieldVariant,
                                                            })}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    )
                                                ) : (
                                                    <IconButton
                                                        ref={this.searchButtonRef}
                                                        aria-label={`Select ${overrideAriaLabel ?? templatedLabel}`}
                                                        aria-haspopup="true"
                                                        onBlur={() => input.onBlur()}
                                                        onClick={(evt) => {
                                                            evt.preventDefault();
                                                            evt.stopPropagation();
                                                            openSearch();
                                                        }}
                                                        style={getFloatingButtonStyle({
                                                            renderLabel,
                                                            fieldVariant,
                                                        })}
                                                    >
                                                        <Search />
                                                    </IconButton>
                                                ))}
                                        </div>
                                        {muiErrorProp ? (
                                            <FormHelperText
                                                classes={formHelperTextClasses}
                                                aria-live="assertive"
                                                style={{ zIndex: 2 }}
                                                id={this.errorMessageId}
                                            >
                                                {helperText}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                )}
                            </EvaluateLabel>
                            <div
                                // adding div to block disabled TextField from click event
                                // ('disabled' prop prevents click propagation in firefox)
                                aria-hidden={true}
                                role="button"
                                onClick={this.handleBaseClick}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    zIndex: 1,
                                    height: '100%',
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                            />
                        </div>
                    );
                }}
            </TextFieldUtils>
        );
    }
    render() {
        const { label, renderLabel } = this.props;
        if (this.props.isHardDisabled) {
            return (
                <FormControl fullWidth={true}>
                    {label && renderLabel && (
                        <InputLabel shrink={true} disabled={false}>
                            {label}
                        </InputLabel>
                    )}
                    <span
                        style={{
                            height: 48,
                            borderBottomStyle: 'dotted',
                            borderBottom: '1px dotted rgba(0, 0, 0, 0.42)',
                            position: 'relative',
                        }}
                    >
                        {/* eslint-disable-next-line */}
                        <a
                            href="javascript:;" // eslint-disable-line
                            style={{ cursor: 'pointer', width: '100%', position: 'absolute', bottom: 4 }}
                            role="button"
                            onClick={this.props.openDetail}
                        >
                            {this.getDisplayText()}
                            <span className="casetivity-off-screen">Opens a modal dialog</span>
                        </a>
                    </span>
                </FormControl>
            );
        }
        return this.renderInputStyle();
    }
}

const enhanceRefDisplay = compose(
    connect(mapStateToProps, dispatchToProps),
    withHandlers(handlers),
    lifecycle({
        componentDidMount() {
            const props: ReferenceDisplayComponentProps = this.props;
            if (props.fetchOwnData !== false) {
                props.fetchReference();
            }
        },
        componentWillReceiveProps(nextProps: ReferenceDisplayComponentProps) {
            const props: ReferenceDisplayComponentProps = this.props;
            if (
                (!props.record && nextProps.record && nextProps.record.id) || // record is instantiated
                (nextProps.record && props.record && props.record.id !== nextProps.record.id) || // record is changed
                (nextProps.referenceRecord &&
                    // referenceRecord changed
                    (!props.referenceRecord || nextProps.referenceRecord.id !== props.referenceRecord.id)) ||
                // no referenceRecord for the incoming (changed) value
                (!nextProps.referenceRecord && nextProps.input.value !== props.input.value)
            ) {
                props.fetchReference(nextProps);
            }
        },
    }),
    (BaseComponent) => (props) => <BaseComponent {...props} />,
    (BaseComponent) => (props) => {
        const { fieldVariant } = useContext(themeOverrideContext);
        return <BaseComponent {...props} fieldVariant={fieldVariant} />;
    },
);
const ReferenceDisplay: React.SFC<ReferenceDisplayProps> = enhanceRefDisplay(ReferenceDisplayComponent);

export default ReferenceDisplay;
