import React, { useCallback, useEffect, useState } from 'react';
import useService from 'util/hooks/useService';
import {
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Paper,
    Typography,
} from '@material-ui/core';
import { services } from 'sideEffect/services';
import { saveAs } from 'file-saver';
import FileDownload from '@material-ui/icons/SaveAlt';
import DropzoneUpload from 'components/custom/DropzoneUpload';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ValueSetFromMultipleVsEndpoint } from 'valueSets/domain';
import Combined from 'fieldFactory/input/components/EntityTypeahead/Multiple';
import VirtualizedTable from 'layout-editor/build-layout/steps/components/PickViewDef/VirtualizedTableComponent';
import Star from '@material-ui/icons/Star';
import { useAppStore } from 'reducers/rootReducer';
import Popup from 'components/Popup';
import Help from '@material-ui/icons/Help';
import getIndexOfNextSymbol from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getIndexOfNextSymbol';

type Concept = {
    code: string;
    display: string;
    active: boolean;
    group?: string;
    sortOrder?: number;
    description?: string;
};

type ValueSet = {
    code: string;
    display: string;
    secure: boolean;
    concepts: Concept[];
    description?: string;
};

function splitByCommas(str: string): string[] {
    const acc: string[] = [];
    let remainingString = str;
    const getIndexOfNextComma = getIndexOfNextSymbol(',', -1);
    let ixOfNextComma: number = getIndexOfNextComma(remainingString);
    while (ixOfNextComma !== -1) {
        acc.push(remainingString.slice(0, ixOfNextComma));
        remainingString = remainingString.slice(ixOfNextComma + 1);

        ixOfNextComma = getIndexOfNextComma(remainingString);
    }
    // final one (no trailing commas)
    acc.push(remainingString);
    return acc;
}

function findDuplicateCodes(arr: Concept[]): string[] {
    const codeMap: { [key: string]: number } = {};
    const duplicates: string[] = [];

    arr.forEach((item) => {
        if (codeMap[item.code]) {
            codeMap[item.code]++;
        } else {
            codeMap[item.code] = 1;
        }
    });

    for (const code in codeMap) {
        if (codeMap[code] > 1) {
            duplicates.push(code);
        }
    }

    return duplicates;
}

function parseCSVtoJSON(csvData: string): ValueSet[] {
    const lines = csvData.split('\n');
    const result: ValueSet[] = [];

    const headers = lines[0].split(',').map((h) => h.trim());
    const expectedHeaders = [
        'conceptCode',
        'conceptDisplay',
        'valueSetCode',
        'conceptActive',
        'valueSetDisplay',
        'valueSetSecure',
        'group',
        'sortOrder',
        'conceptDescription',
        'valueSetDescription',
    ];

    const headersToIx = expectedHeaders.reduce((prev, curr) => {
        prev[curr] = headers.indexOf(curr);
        return prev;
    }, {} as { [header: string]: number });

    let errorMsg = '';
    Object.entries(headersToIx).forEach(([header, ix]) => {
        if (ix === -1) {
            if (errorMsg) {
                errorMsg += '\n';
            }
            errorMsg += `Header "${header}" not found in csv headers.`;
        }
    });

    headers.forEach((h) => {
        if (typeof headersToIx[h] === 'undefined') {
            if (errorMsg) {
                errorMsg += '\n';
            }
            errorMsg += `Unexpected header "${h}".`;
        }
    });

    if (errorMsg) {
        console.error(errorMsg);
        alert(errorMsg);
        throw new Error(errorMsg);
    }

    // Skip the first line of headers and begin processing from the second line.
    for (let i = 1; i < lines.length; i++) {
        if (lines[i].trim() === '') continue; // Skip empty lines
        const cells = splitByCommas(lines[i]).map((cell) => cell.trim());
        if (cells.length !== expectedHeaders.length) {
            const msg = `Cells: ${cells.join(',')} at row ${i} exceed the number of headers (${headers.length})`;
            alert(msg);
            console.error(msg);
            throw new Error(msg);
        }

        const {
            conceptCode,
            conceptDisplay,
            valueSetCode,
            conceptActive: conceptActiveString,
            valueSetDisplay,
            valueSetSecure: valueSetSecureString,
            valueSetDescription,
            group,
            sortOrder: sortOrderString,
            conceptDescription,
        } = Object.fromEntries(Object.entries(headersToIx).map(([k, ix]) => [k, cells[ix]]));
        const secure = valueSetSecureString.toLowerCase() === 'true';
        const conceptActive = conceptActiveString.toLowerCase() === 'true';

        // Find an existing valueSet or create a new one
        let valueSet = result.find((v) => v.code === valueSetCode);
        if (!valueSet) {
            valueSet = {
                code: valueSetCode,
                display: valueSetDisplay,
                description: valueSetDescription,
                secure: secure,
                concepts: [],
            };
            result.push(valueSet);
        }

        // Add the concept to the valueSet
        const sortOrder = (() => {
            if (!sortOrderString) {
                return undefined;
            }
            const _sortOrder = parseInt(sortOrderString);
            if (isNaN(_sortOrder)) {
                return undefined;
            }
            return _sortOrder;
        })();
        valueSet.concepts.push({
            code: conceptCode,
            display: conceptDisplay,
            description: conceptDescription,
            active: conceptActive,
            group,
            sortOrder,
        });
    }
    const errorMessages = [];
    for (const valueSet of result) {
        findDuplicateCodes(valueSet.concepts ?? []).forEach((code) => {
            errorMessages.push(
                `Duplicate concepts ${JSON.stringify(code)} found in valueSet ${JSON.stringify(valueSet.code)}.`,
            );
        });
    }
    if (errorMessages.length > 0) {
        const errorString = errorMessages.join('\n');
        alert(errorString);
        console.error(errorString);
        throw new Error(errorString);
    }
    return result;
}
const ImportButton = ({
    uploaded,
}: {
    uploaded: (ValueSetFromMultipleVsEndpoint & {
        deactivateMissing?: boolean;
    })[];
}) => {
    const request = useCallback(() => services.valuesetAdmin.importMany(uploaded), [uploaded]);
    const [state, fetch, { StateIcon }] = useService(request);
    return (
        <Button
            disabled={state.status === 'pending'}
            onClick={fetch}
            color="primary"
            variant="contained"
            endIcon={StateIcon}
        >
            Import
        </Button>
    );
};

const ExportButton = ({ valuesetCodes, text = 'Download' }: { valuesetCodes?: string[]; text?: string }) => {
    const exportValueset = useCallback(() => services.valuesetAdmin.exportMany(valuesetCodes), [valuesetCodes]);
    const [state, fetch, { StateIcon }] = useService(exportValueset);

    const file = state.status === 'success' ? state.data : null;

    const fileName = file?.flatMap((f) => (f.code ? [f.code] : [])).join('_') || 'export';
    useEffect(() => {
        if (file) {
            saveAs(new Blob([JSON.stringify(file, null, 2)], { type: 'application/json' }), `${fileName}.json`);
        }
    }, [fileName, file]);
    return (
        <Button
            disabled={state.status === 'pending'}
            onClick={fetch}
            color="primary"
            variant="contained"
            endIcon={StateIcon ?? <FileDownload />}
        >
            {text}
        </Button>
    );
};

const ValuesetPreview: React.FC<{
    valueSetCode: string;
    isNew: boolean;
    columns: {
        toAdd: string[];
        toUpdate: string[];
        leftover: string[];
    };
    setDeactivateMissing?: (deactivateMissing: boolean) => void;
    deactivateMissing: boolean;
}> = ({ valueSetCode, columns, deactivateMissing, setDeactivateMissing, isNew }) => {
    const rowCount = Math.max(columns.toAdd.length, columns.toUpdate.length, columns.leftover.length);
    const rows = (() => {
        const _rows: {
            toAdd?: string;
            toUpdate?: string;
            leftover?: string;
        }[] = [];
        for (let i = 0; i < rowCount; i++) {
            _rows.push({
                toAdd: columns.toAdd[i],
                toUpdate: columns.toUpdate[i],
                leftover: columns.leftover[i],
            });
        }
        return _rows;
    })();

    const ROW_HEIGHT = 48;
    const HEADER_HEIGHT = 64;
    return (
        <div style={{ marginLeft: '1em' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5" component="h3">
                    {valueSetCode}
                </Typography>
                {isNew ? (
                    <span style={{ marginLeft: '1em' }}>
                        <Chip size="small" label="New" color="primary" icon={<Star />} />
                    </span>
                ) : null}
            </div>
            <Paper style={{ height: ROW_HEIGHT * Math.min(rowCount, 10) + HEADER_HEIGHT, width: '100%' }}>
                <VirtualizedTable<{
                    toAdd?: string;
                    toUpdate?: string;
                    leftover?: string;
                }>
                    rowHeight={ROW_HEIGHT}
                    rowCount={rowCount}
                    rowGetter={({ index }) => rows[index]}
                    fullWidth
                    headerHeight={HEADER_HEIGHT}
                    columns={[
                        {
                            label: <b>To Add</b>,
                            dataKey: 'toAdd',
                        },
                        {
                            label: <b>To Update</b>,
                            dataKey: 'toUpdate',
                        },
                        {
                            label: (
                                <div>
                                    <b>Missing in uploaded file</b>
                                    {columns.leftover.length > 0 && (
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        checked={deactivateMissing}
                                                        onChange={(e) => {
                                                            setDeactivateMissing(e.target.checked);
                                                        }}
                                                        name="checked"
                                                    />
                                                }
                                                label="Deactivate"
                                            />
                                        </FormGroup>
                                    )}
                                </div>
                            ),
                            dataKey: 'leftover',
                        },
                    ]}
                />
            </Paper>
            <div style={{ height: '1em' }} />
        </div>
    );
};
const csvColumns = [
    'conceptCode',
    'conceptDisplay',
    'valueSetCode',
    'conceptActive',
    'valueSetDisplay',
    'valueSetSecure',
    'group',
    'sortOrder',
    'conceptDescription',
    'valueSetDescription',
];

const downloadCSVDefault = () => {
    const csvContent = csvColumns.join(',');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'valuesets_template.csv');
};

const ValuesetAdminPage = () => {
    const [vs, setVS] = useState<{ id: string; code: string }[]>([]);
    const store = useAppStore();
    const [dryRunResponse, setDryRunResponse] = useState<{
        uploaded: ValueSetFromMultipleVsEndpoint[];
        valueSets: {
            [valueSetCode: string]: {
                deactivateMissing?: boolean;
                toAdd: string[];
                toUpdate: string[];
                leftover: string[];
                isNew: boolean;
            };
        };
    } | null>(null);
    return (
        <div style={{ marginLeft: '2em' }}>
            <h2>Import</h2>
            <div>
                <DropzoneUpload
                    title={
                        <span>
                            Import Value Set&nbsp;
                            <Popup
                                renderDialogContent={({ closeDialog }) => (
                                    <div style={{ padding: '1em' }}>
                                        <p>The list of CSV file columns are listed below.</p>
                                        <ul>
                                            {csvColumns.map((col) => (
                                                <li key={col}>{col}</li>
                                            ))}
                                        </ul>
                                        <p>Uploads of the exported JSON are also supported</p>
                                        <Button
                                            onClick={() => {
                                                downloadCSVDefault();
                                                closeDialog();
                                            }}
                                            color="primary"
                                            variant="contained"
                                        >
                                            Download CSV With All Columns Listed Above
                                        </Button>
                                    </div>
                                )}
                                renderToggler={({ openDialog }) => (
                                    <IconButton size="small" onClick={openDialog()} aria-label="Help">
                                        <Help />
                                    </IconButton>
                                )}
                            />
                        </span>
                    }
                    dropzoneText="Drop file"
                    getLazyRequest={([file]: File[]) =>
                        async () => {
                            const text = await file.text();

                            const valueSets: ValueSetFromMultipleVsEndpoint[] = file.name.endsWith('.csv')
                                ? (() => {
                                      try {
                                          const res = parseCSVtoJSON(text);
                                          if (res.some((vs) => !vs.code)) {
                                              console.log(res);
                                              const errorMsg = 'A valueset code was missing.';
                                              alert(errorMsg);
                                              throw new Error(errorMsg);
                                          }
                                          return res;
                                      } catch (e) {
                                          console.error(e);
                                          throw e;
                                      }
                                  })()
                                : JSON.parse(text);

                            setDryRunResponse(null);

                            const res = await services.valuesetAdmin.dryRunMany(valueSets).toPromise();
                            const valueSetCodes = valueSets.flatMap((vs) => (vs.code ? [vs.code] : []));
                            const valueSetEntries = Object.fromEntries(
                                valueSetCodes.map((code) => [
                                    code,
                                    {
                                        deactivateMissing: false,
                                        toAdd: [],
                                        toUpdate: [],
                                        leftover: [],
                                        isNew: false,
                                    } as {
                                        deactivateMissing: boolean;
                                        toAdd: string[];
                                        toUpdate: string[];
                                        leftover: string[];
                                        isNew: boolean;
                                    },
                                ]),
                            );
                            const keys: (keyof typeof res)[] = ['leftover', 'toAdd', 'toUpdate'];

                            keys.forEach((key) => {
                                res[key].forEach((code) => {
                                    const [valueSetCode, conceptCode] = code.split('.');
                                    const vsEntry = valueSetEntries[valueSetCode][key];
                                    vsEntry.push(conceptCode);
                                });
                            });
                            res.newValueSets.forEach((newValuesetCode) => {
                                valueSetEntries[newValuesetCode].isNew = true;
                            });

                            setDryRunResponse({
                                uploaded: valueSets,
                                valueSets: valueSetEntries,
                            });
                            return {
                                status: 200,
                                json: () => Promise.resolve({}),
                            };
                        }}
                />
            </div>
            {Object.entries(dryRunResponse?.valueSets ?? {}).map(
                ([vsCode, { deactivateMissing, isNew, ...columns }]) => {
                    return (
                        <ValuesetPreview
                            isNew={isNew}
                            deactivateMissing={deactivateMissing}
                            setDeactivateMissing={(deactivateMissing) => {
                                setDryRunResponse((response) => ({
                                    ...response,
                                    valueSets: {
                                        ...response.valueSets,
                                        [vsCode]: {
                                            ...response.valueSets[vsCode],
                                            deactivateMissing: deactivateMissing,
                                        },
                                    },
                                }));
                            }}
                            key={vsCode}
                            valueSetCode={vsCode}
                            columns={columns}
                        />
                    );
                },
            )}

            {dryRunResponse && (
                <div>
                    <div style={{ height: '1em' }} />
                    <Alert
                        severity="info"
                        action={
                            <ImportButton
                                uploaded={dryRunResponse.uploaded.map((vs) => ({
                                    ...vs,
                                    deactivateMissing: dryRunResponse.valueSets[vs.code].deactivateMissing,
                                }))}
                            />
                        }
                    >
                        <AlertTitle>Looks good?</AlertTitle>
                        <p>You may complete the import now.</p>
                    </Alert>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <div style={{ margin: '1em' }}></div>
                    </div>
                </div>
            )}
            <Divider style={{ margin: '1em' }} />
            <h2>Export</h2>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '1em',
                }}
            >
                <div style={{ width: '100%' }}>
                    <Combined
                        fullWidth
                        isPopover={false}
                        allowEmptyQuery
                        label="Value sets to export"
                        source=""
                        meta={{} as any}
                        input={{
                            value: vs.map(({ id }) => id),
                            onBlur: (ids) => {
                                if (typeof ids === 'undefined') {
                                    return;
                                }
                                if (ids === null) {
                                    setVS([]);
                                    return;
                                }
                                const valuesets = ids.map((id) => ({
                                    id,
                                    code: store.getState().admin.entities['ValueSet']?.[id]?.['code'],
                                }));

                                setVS(valuesets);
                            },
                            onChange: (ids) => {
                                if (typeof ids === 'undefined') {
                                    return;
                                }
                                if (ids === null) {
                                    setVS([]);
                                    return;
                                }
                                const valuesets = ids.map((id) => ({
                                    id,
                                    code: store.getState().admin.entities['ValueSet']?.[id]?.['code'],
                                }));

                                setVS(valuesets);
                            },
                        }}
                        reference="ValueSet"
                    />
                </div>
                {vs.length ? (
                    <>
                        &nbsp;
                        <ExportButton valuesetCodes={vs.map(({ code }) => code)} />
                    </>
                ) : null}
            </div>
        </div>
    );
};
export default ValuesetAdminPage;
