import React from 'react';
import { CASETIVITY_CHAT, REPORT_BUILDER } from './constants';
import { useChatBot } from './ChatbotProvider';

const MessageParser = ({ children, actions }) => {
    const { state: chatState } = useChatBot();

    const parse = (message) => {
        switch (chatState.assistant) {
            case CASETIVITY_CHAT:
            case REPORT_BUILDER:
                actions.handleAppChat(message);
                break;
            default:
                console.error('unknown assistant');
                break;
        }
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions: {},
                });
            })}
        </div>
    );
};

export default MessageParser;
