type Callback = (value: any) => void;

class PubSubStore {
    private store: Map<string, any> = new Map();
    private subscribers: Map<string, Set<Callback>> = new Map();
    private modifiedKeys: Set<string> = new Set();

    subscribe(key: string, callback: Callback): () => void {
        if (!this.subscribers.has(key)) {
            this.subscribers.set(key, new Set());
        }
        const subs = this.subscribers.get(key);
        subs?.add(callback);

        // Return unsubscribe function
        return () => {
            subs?.delete(callback);
            if (subs && subs.size === 0) {
                this.subscribers.delete(key);
            }
        };
    }

    setValue(key: string, value: any, sneak = false): void {
        this.store.set(key, value);
        if (!sneak) {
            this.modifiedKeys.add(key);
        }
        const subs = this.subscribers.get(key);
        if (subs) {
            subs.forEach((callback) => callback(value));
        }
    }

    getValue(key: string): any {
        return this.store.get(key);
    }

    isModified(key: string): boolean {
        return this.modifiedKeys.has(key);
    }
}

export const pubSubStore = new PubSubStore();
