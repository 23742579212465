import React, { FunctionComponent, useMemo, useEffect } from 'react';
import * as colors from '@material-ui/core/colors';
import { TextFieldProps, InputLabelProps } from '@material-ui/core';

import {
    getEnvConfigFieldVariantSelector,
    getUseV5DatePickerSelector,
    getUseStripedListsSelector,
    getDenseUISelector,
    getRowNavButtonsHideUntilFocusSelector,
    getFlatUISelector,
} from 'util/applicationConfig';
import { RootState, useAppSelector } from 'reducers/rootReducer';

type Color = keyof typeof colors;
type Overrides = {
    primary?: Color;
    secondary?: Color;
    error?: Color;
};
type Darkness = any;
type DarknessOverrides = {
    primary?: Darkness;
    secondary?: Darkness;
    error?: Darkness;
};
interface ThemeOverrideContext {
    methods: {
        set: (attr: keyof Overrides, color: Color) => void;
        setDarkness: (attr: Darkness, color: Color) => void;
        toggleDarkMode: () => void;
        toggleInvertMenu: () => void;
        setFieldVariant: (variant: TextFieldProps['variant']) => void;
        toggleForceLabelShrink: () => void;
        toggleUseV5DatePicker: () => void;
        toggleUseStripedLists: () => void;
        toggleDenseUI: () => void;
        toggleFlatUI: () => void;
        toggleRowNavButtonsHideUntilFocus?: () => void;
    };
    overrides: Overrides;
    darkMode?: boolean;
    invertMenu?: boolean;
    fieldVariant?: TextFieldProps['variant'];
    darknessOverrides: DarknessOverrides;
    forceLabelShrink?: boolean;
    useGreyInputBackground?: boolean;
    useV5DatePicker?: boolean;
    useStripedLists?: boolean;
    denseUI?: boolean;
    flatUI?: boolean;
    rowNavButtonsHideUntilFocus?: boolean;
    getInputLabelProps: (props: Partial<InputLabelProps>) => Partial<InputLabelProps>;
}
export const themeOverrideContext = React.createContext<ThemeOverrideContext>({
    methods: {
        set: (attr: keyof Overrides) => {
            throw new Error('context not provided');
        },
        setDarkness: (attr: keyof DarknessOverrides) => {
            throw new Error('context not provided');
        },
        toggleDarkMode: () => {
            throw new Error('context not provided');
        },
        toggleInvertMenu: () => {
            throw new Error('context not provided');
        },
        setFieldVariant: () => {
            throw new Error('context not provided');
        },
        toggleForceLabelShrink: () => {
            throw new Error('context not provided');
        },
        toggleUseV5DatePicker: () => {
            throw new Error('context not provided');
        },
        toggleUseStripedLists: () => {
            throw new Error('context not provided');
        },
        toggleDenseUI: () => {
            throw new Error('context not provided');
        },
        toggleFlatUI: () => {
            throw new Error('context not provided');
        },
        toggleRowNavButtonsHideUntilFocus: () => {
            throw new Error('context not provided');
        },
    },
    overrides: {
        primary: undefined,
        secondary: undefined,
        error: undefined,
    },
    darknessOverrides: {
        primary: undefined,
        secondary: undefined,
        error: undefined,
    },
    darkMode: undefined,
    fieldVariant: undefined,
    invertMenu: undefined,
    forceLabelShrink: true,
    useGreyInputBackground: undefined,
    getInputLabelProps: (args) => {
        return args;
    },
    useV5DatePicker: false,
    useStripedLists: false,
    denseUI: false,
    flatUI: false,
    rowNavButtonsHideUntilFocus: false,
});

interface ThemeOverrideProviderProps {}
const ThemeOverrideProvider: FunctionComponent<ThemeOverrideProviderProps> = (props) => {
    const initialFieldVariant = useAppSelector(getEnvConfigFieldVariantSelector);
    const [overrides, setOverrides] = React.useState<Overrides>({
        primary: undefined,
        secondary: undefined,
        error: undefined,
    });
    const [darknessOverrides, setDarknessOverrides] = React.useState<DarknessOverrides>({
        primary: undefined,
        secondary: undefined,
        error: undefined,
    });
    const printMode = useAppSelector((state: RootState) => state.printMode);
    const [darkMode, setDarkMode] = React.useState(undefined);
    const [_fieldVariant, setFieldVariant] = React.useState<TextFieldProps['variant']>(
        initialFieldVariant || undefined,
    );
    useEffect(() => {
        setFieldVariant(initialFieldVariant);
    }, [initialFieldVariant]);
    const fieldVariant = printMode ? 'standard' : _fieldVariant;

    const [forceLabelShrink, setForceLabelShrink] = React.useState(true);

    const [invertMenu, setInvertMenu] = React.useState(undefined);
    const toggleDarkMode = React.useCallback(() => {
        setDarkMode(!darkMode);
    }, [setDarkMode, darkMode]);
    const toggleInvertMenu = React.useCallback(() => {
        setInvertMenu(!invertMenu);
    }, [setInvertMenu, invertMenu]);
    const toggleForceLabelShrink = React.useCallback(() => {
        setForceLabelShrink(!forceLabelShrink);
    }, [setForceLabelShrink, forceLabelShrink]);
    const set = React.useCallback(
        (attr: keyof Overrides, color: keyof typeof colors) => {
            setOverrides({
                ...overrides,
                [attr]: color,
            });
        },
        [overrides, setOverrides],
    );
    const setDarkness = React.useCallback(
        (attr: keyof DarknessOverrides, darkness: Darkness) => {
            setDarknessOverrides({
                ...darknessOverrides,
                [attr]: darkness,
            });
        },
        [darknessOverrides, setDarknessOverrides],
    );
    const getInputLabelProps = React.useCallback(
        (props: Partial<InputLabelProps>): Partial<InputLabelProps> => {
            return {
                ...props,
                shrink: forceLabelShrink === false ? undefined : true,
            };
        },
        [forceLabelShrink],
    );

    const useGreyInputBackground =
        !printMode && !darkMode && (!fieldVariant || fieldVariant === 'standard') && forceLabelShrink !== false;

    const initialUseV5DatePicker = useAppSelector(getUseV5DatePickerSelector);
    const [useV5DatePicker, setUseV5DatePicker] = React.useState(initialUseV5DatePicker);

    const toggleUseV5DatePicker = React.useCallback(() => {
        setUseV5DatePicker((prev) => !prev);
    }, [setUseV5DatePicker]);

    const initialUseStripedLists = useAppSelector(getUseStripedListsSelector);
    const [useStripedLists, setUseStripedLists] = React.useState(initialUseStripedLists);
    const toggleUseStripedLists = React.useCallback(() => {
        setUseStripedLists((prev) => !prev);
    }, [setUseStripedLists]);

    const initialDenseUI = useAppSelector(getDenseUISelector);
    const [denseUI, setDenseUI] = React.useState(initialDenseUI);
    const toggleDenseUI = React.useCallback(() => {
        setDenseUI((prev) => !prev);
    }, [setDenseUI]);

    const initialRowNavButtonsHideUntilFocus = useAppSelector(getRowNavButtonsHideUntilFocusSelector);
    const [rowNavButtonsHideUntilFocus, setRowNavButtonsHideUntilFocus] = React.useState(
        initialRowNavButtonsHideUntilFocus,
    );
    const toggleRowNavButtonsHideUntilFocus = React.useCallback(() => {
        setRowNavButtonsHideUntilFocus((prev) => !prev);
    }, [setRowNavButtonsHideUntilFocus]);

    const initialFlatUI = useAppSelector(getFlatUISelector);
    const [flatUI, setFlatUI] = React.useState(initialFlatUI);
    const toggleFlatUI = React.useCallback(() => {
        setFlatUI((prev) => !prev);
    }, [setFlatUI]);

    const value = useMemo(() => {
        return {
            overrides,
            darkMode,
            invertMenu,
            darknessOverrides,
            fieldVariant,
            forceLabelShrink,
            getInputLabelProps,
            useGreyInputBackground,
            useV5DatePicker,
            useStripedLists,
            denseUI,
            flatUI,
            rowNavButtonsHideUntilFocus,
            methods: {
                toggleDarkMode,
                set,
                setDarkness,
                toggleInvertMenu,
                setFieldVariant,
                toggleForceLabelShrink,
                toggleUseV5DatePicker,
                toggleUseStripedLists,
                toggleDenseUI,
                toggleFlatUI,
                toggleRowNavButtonsHideUntilFocus,
            },
        };
    }, [
        toggleFlatUI,
        flatUI,
        rowNavButtonsHideUntilFocus,
        toggleRowNavButtonsHideUntilFocus,
        toggleDenseUI,
        denseUI,
        useGreyInputBackground,
        overrides,
        fieldVariant,
        forceLabelShrink,
        setFieldVariant,
        toggleForceLabelShrink,
        darknessOverrides,
        invertMenu,
        toggleInvertMenu,
        setDarkness,
        toggleDarkMode,
        darkMode,
        set,
        getInputLabelProps,
        useV5DatePicker,
        toggleUseV5DatePicker,
        useStripedLists,
        toggleUseStripedLists,
    ]);
    return <themeOverrideContext.Provider value={value}>{props.children}</themeOverrideContext.Provider>;
};

export default ThemeOverrideProvider;
