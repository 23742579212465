import React, { ReactElement, FunctionComponent } from 'react';
import { fromPredicate } from 'fp-ts/lib/Option';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import fromEntries from 'fromentries';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import { LayoutState, LayoutStateItem } from 'layout-editor/demo/layoutReducer';
import GenericMovableGrid, { RenderFieldAdder } from 'dash-editor/GenericEditableGrid';

type FieldElemProps = {
    row?: number;
    column?: number;
    span?: number;
    source?: number;
    'data-originaldefinition': string;
};
export type FieldElem = ReactElement<FieldElemProps>;
// FieldData e.g. ViewField
interface MovableGridProps<FieldData = unknown> {
    gridChangeKey?: number;
    renderMoveButton?: (item: LayoutStateItem) => JSX.Element;
    overlapInsert?: boolean;
    refresh?: () => void;
    recalculateHeightKey?: string;
    columnStartsAt: 1 | 0;
    fields?: FieldElem[];
    onLayoutChange: (args: { layout: LayoutState }) => void;
    createField: (fieldDefinition: any) => FieldElem;
    renderFieldAdder: RenderFieldAdder<FieldData>;
    MAX_COLS?: number;
    getAddToBottomFieldWidth?: (FieldElement: FieldElem) => 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    addFieldLabel?: React.ReactNode;
}
const getRowKey = (row, defaultValue = -1) =>
    fromPredicate((n: number) => !isNaN(n))(parseInt(row, 10)).getOrElse(defaultValue);

const getLayoutFromFields = (
    fields: ReactElement<{
        row?: number;
        column?: number;
        span?: number;
        source?: number;
        'data-originaldefinition': string;
    }>[],
    options: {
        columnStartsAt: 0 | 1;
        MAX_COLS?: number;
    },
): LayoutState => {
    const { MAX_COLS = 12 } = options;
    /*
        get the number of unique rows (including 'no row' as unique))
        that number is the row index of 'no rows',
        order the rest.
    */
    const fieldsPerRow = fields
        .map((f) => f.props.row)
        .reduce(
            (prev, curr) => {
                const currRowKey = getRowKey(curr);

                if (typeof prev[currRowKey] === 'number') {
                    prev[currRowKey] += 1;
                } else {
                    prev[currRowKey] = 1;
                }

                return prev;
            },
            {} as {
                [row: number]: number;
            },
        );

    const fieldOrderingsPerRow: {
        [row: number]: {
            [fieldIndex: number]: {
                index: number;
                column: number;
                overflowedRows: number;
                span: number;
            };
        };
    } = fromEntries(
        Object.entries(
            groupBy(
                fields.map((f, i) => [f, i] as const),
                (t) => parseInt(get(t, '[0].props.row'), 10),
            ),
        ).map(([row, fieldIndexes]) => {
            return [
                row,
                sortBy(fieldIndexes, (t) => parseInt(get(t, '[0].props.column'), 10)).reduce(
                    (prev, [currField, i]) => {
                        const accumulatedSpans = Object.values(prev).reduce((_prev, { span = 4 }) => {
                            return span + _prev;
                        }, 0);
                        const overflowedRows = Math.floor(accumulatedSpans / MAX_COLS);
                        prev[i] = {
                            index: i,
                            column: accumulatedSpans % MAX_COLS,
                            overflowedRows,
                            span: currField.props.span,
                        };
                        return prev;
                    },
                    {} as {
                        [fieldIndex: number]: {
                            index: number;
                            column: number;
                            overflowedRows: number;
                            span: number;
                        };
                    },
                ),
            ] as const;
        }),
    );
    /*
    TODO: Put those without rows to the bottom.
    */

    const il = fields.map((f, index) => {
        const w = f.props.span || 12;
        return {
            i: `${index}`,
            x: fieldOrderingsPerRow[parseInt(f.props.row as any, 10)][index].column, // getRowKey((f.props.column - 1) * 4, options.columnStartsAt),
            y:
                getRowKey(
                    f.props.row,
                    Object.keys(fieldsPerRow).reduce((prev, curr) => {
                        return Math.max(prev, getRowKey(curr));
                    }, 0) + 1,
                ) + fieldOrderingsPerRow[parseInt(f.props.row as any, 10)][index].overflowedRows,
            w,
            h: 1, // Maybe read in from textArea height config? Or don't provide?
            content: f,
            'data-originaldefinition': f.props['data-originaldefinition'],
        };
    });
    const rows = uniq(il.map((e) => e.y)).sort((a, b) => a - b);
    rows.forEach((r, i) => {
        il.filter((e) => e.y === r).forEach((e) => {
            e.y = i;
        });
    });
    return il;
};

const MovableGrid: FunctionComponent<MovableGridProps> = (props) => {
    return <GenericMovableGrid {...props} getLayoutFromElements={getLayoutFromFields} />;
};
export default MovableGrid;
