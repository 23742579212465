import {
    SimpleFormField,
    ValuesetFormField,
    EntityTypeaheadField,
    ValuesetManyFormField,
    EntityMultipleTypeaheadField,
} from 'fieldFactory/translation/fromFlowable/types';

type TypesUsedInReports =
    | SimpleFormField
    | ValuesetFormField
    | EntityTypeaheadField
    | ValuesetManyFormField
    | EntityMultipleTypeaheadField;
interface ReportDefinitionParamsBase {
    id: number | string;
    name: string;
    label: string;
    type: TypesUsedInReports['type'];
    fieldNames: string[];
    required: boolean;
    valueSet: boolean; // <-- unsure what the purpose of this is
}
export type ReportDefinitionParam = ReportDefinitionParamsBase &
    (
        | {
              params: { entity: string; case?: 'upper' | 'lower' };
              valueSetType: false;
              entityLookupType: true;
          }
        | {
              params: { valueSet: string; case?: 'upper' | 'lower' };
              valueSetType: true;
              entityLookupType: false;
          }
        | {
              params?: {};
              valueSetType: false;
              entityLookupType: false;
          }
    );

export interface ReportDefinitionDTO {
    createdById: string;
    createdDate: string;
    createdDateString: string;
    lastModifiedById: string;
    lastModifiedDate: string;
    lastModifiedDateString: string;
    id: string;
    entityVersion: number;
    entityType: 'ReportDefinition';
    casetivityCanEdit: boolean;
    casetivityCanDelete: boolean;
    casetivityCanMerge: boolean;
    name: string;
    displayName: string;
    description: string;
    definition: string;
    longRunning: boolean;
    config: string; // '{\n  "availableOutputs": ["xlsx", "csv"]\n}';
    title: string;
    subtitle: string;
    hasPossibleMatches: boolean;
    dedupeStatusId: string;
    dedupeStatusCode: string;
    casetivityExtraFields: {};
    inputFormDefinition?: string;
}

export interface ReportDefinition {
    reportDefinitionDTO: ReportDefinitionDTO;
    params: ReportDefinitionParam[];
    columnMetadataMap: {};
    outputColumns: string[];
}

export const convertToFlowableTypeField = (reportParam: ReportDefinitionParam): TypesUsedInReports => {
    const { type, name, label, required, params } = reportParam;
    return {
        type,
        fieldType: 'FormField' as any, // <- I think we can get rid of this.
        id: name,
        name: label,
        value: null,
        required,
        readOnly: false,
        overrideId: false,
        params,
    } as TypesUsedInReports;
};
