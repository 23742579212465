import * as update from './actions';
import { isActionOf } from 'typesafe-actions';
import { Services } from 'sideEffect/services';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, withLatestFrom, map, flatMap } from 'rxjs/operators';
import crudCreateUpdateGetFlow from 'sideEffect/crud/util/epics/CoreCrud/createUpdate';
import recurseReplaceEmptyStringsWithNull from '../util/replaceEmptyStringsWithNull';
import getInsertIdsAndVersionNumbers from 'util/insertIdsAndVersionNumbers/v2';
import { viewConfigSelector } from 'util/hooks/useViewConfig';
import isPlainObject from 'lodash/isPlainObject';

export const recurseStripAmpIds = (value) => {
    if (isPlainObject(value)) {
        return Object.assign(
            {},
            ...Object.keys(value)
                .filter((key) => key !== '@id' && key !== '@ref')
                .map((key) => ({
                    [key]: recurseStripAmpIds(value[key]),
                })),
        );
    }
    if (Array.isArray(value)) {
        return value.map((o) => recurseStripAmpIds(o));
    }
    return value;
};

export function removeEmptyObjects(obj: Record<string, unknown>): Record<string, unknown> {
    const newObj = { ...obj };
    Object.keys(newObj).forEach((key) => {
        if (isPlainObject(newObj[key])) {
            newObj[key] = removeEmptyObjects(newObj[key] as Record<string, unknown>);
            // If after cleaning, the object is empty, delete the property
            const keys = Object.keys(newObj[key]);
            if (keys.length === 0 || (keys.length === 1 && newObj['partialUpdate'])) {
                delete newObj[key];
            }
        } else if (newObj[key] === undefined) {
            // Delete the property if it is undefined
            delete newObj[key];
        } else if (Array.isArray(newObj[key])) {
            newObj[key] = (newObj[key] as unknown[]).map((value) => {
                if (isPlainObject(value)) {
                    return removeEmptyObjects(value as Record<string, unknown>);
                }
                return value;
            });
        }
    });
    return newObj;
}

const crudCreateFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(update.crudUpdate)),
        withLatestFrom(
            state$.pipe(map((state) => viewConfigSelector(state))),
            state$.pipe(map((state) => state.admin.entities)),
        ),
        flatMap(([action, viewConfig, entities]) => {
            const { resource, data, cb, restUrl: _restUrl, errorsCbs, previousData, fieldsToSubmit } = action.payload;
            const restUrl = _restUrl || viewConfig.entities[resource].restUrl;

            let adjustedData: null | {} = null;
            if (data && previousData) {
                adjustedData = getInsertIdsAndVersionNumbers(viewConfig, entities)(
                    previousData,
                    data,
                    resource,
                    fieldsToSubmit,
                );
            }
            const submitData = removeEmptyObjects(
                recurseReplaceEmptyStringsWithNull(recurseStripAmpIds(adjustedData || data)),
            );

            return crudCreateUpdateGetFlow(
                {
                    data: submitData,
                    restUrl,
                },
                {
                    service: services.crudUpdate,
                    failureAction: update.crudUpdateFailure,
                    successAction: update.crudUpdateSuccess,
                    successCb: cb,
                    errorsCbs,
                },
                {
                    resource,
                    viewConfig,
                    initialRequestPayload: action.payload,
                    restUrl,
                },
            );
        }),
    );
export default crudCreateFlow;
