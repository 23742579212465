import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import memoizeOne from 'memoize-one';
import { customShowRedirects } from '../overrides';
import { RenderListArguments } from './List';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import ViewConfig from 'reducers/ViewConfigType';
import getViewConf from 'util/getViewConf';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import useViewConfig from 'util/hooks/useViewConfig';
import { useEvaluateTemplate } from 'expressions/Provider/hooks/useKeyCachingEval';

const getNavigationTemplate = (viewConfig: ViewConfig, listViewName?: string): string => {
    return getViewConf(viewConfig, listViewName)
        .mapNullable((pc: EntityViewConfig) => pc.navigationExpression)
        .toNullable();
};
const getNavigationTemplateSelector = <T extends { viewName: string; viewConfig?: ViewConfig }>() => {
    const hideCreateSelector = createSelector(
        (state: RootState, props: T) => props.viewConfig || state.viewConfig,
        (state: RootState, props: T) => props.viewName,
        getNavigationTemplate,
    );
    return hideCreateSelector;
};
interface OverrideableRedirectProviderProps {
    onRowSelect: RenderListArguments['onRowSelect'];
    resource: RenderListArguments['resource'];
    data: RenderListArguments['data'];
    ids: RenderListArguments['ids'];
    render: (onRowsSelect: (indexes: (string | number)[]) => void) => JSX.Element;
    viewName?: string;
}

const createAllRedirectsSelector = () =>
    createSelector(
        (state: RootState, props: OverrideableRedirectProviderProps) => state.viewConfig,
        (state: RootState, props: OverrideableRedirectProviderProps) => state.admin.entities,
        (state: RootState, props: OverrideableRedirectProviderProps) => props.resource,
        (state: RootState, props: OverrideableRedirectProviderProps) => !!props.onRowSelect,
        (state: RootState, props: OverrideableRedirectProviderProps) => props.data,
        (state: RootState, props: OverrideableRedirectProviderProps) => props.ids,
        (viewConfig, entities, resource, hasOnRowSelect, data, ids) => {
            const customRedirect = customShowRedirects[resource];
            if (customRedirect && !hasOnRowSelect) {
                const rowClickRedirect = customRedirect && customRedirect.find((r) => r._isRowClick);
                if (rowClickRedirect) {
                    return ids.map((id) => rowClickRedirect.redirectFormula(data[id], entities, viewConfig)).join(';');
                }
            }
            return null;
        },
    );
const OverrideableRedirectProvider: React.FC<OverrideableRedirectProviderProps> = (props) => {
    const { viewName } = props;
    const redirectsSelector = useMemo(createAllRedirectsSelector, []);
    const allRedirects = useAppSelector((state) => redirectsSelector(state, props));
    const dispatch = useDispatch();

    const _getRedirectList = useMemo(() => memoizeOne((redirects) => redirects.split(';')), []);

    const navigationTemplateSelector = useMemo(getNavigationTemplateSelector, []);
    const viewConfig = useViewConfig();
    const navigationTemplate = useAppSelector((state) => navigationTemplateSelector(state, { viewName, viewConfig }));
    const getOverriddenNav = useEvaluateTemplate(navigationTemplate ?? '');
    const redirect = (indexes: number[]) => {
        const { resource, onRowSelect, data, ids } = props;
        if (onRowSelect) {
            onRowSelect(
                indexes.map((ix) => data[ids[ix]]),
                Object.assign({}, ...ids.map((id) => ({ [id]: data[id] }))),
            );
        } else if (typeof indexes[0] !== 'undefined') {
            const id = ids[indexes[0]];
            if (navigationTemplate) {
                const navLocation = getOverriddenNav(data[id]);
                if (typeof navLocation === 'string' && navLocation.trim()) {
                    dispatch(push(navLocation));
                    return;
                }
            }

            dispatch(
                push(
                    allRedirects
                        ? _getRedirectList(allRedirects)[indexes[0]]
                        : `/${resource}/${id}${/* allowsEdit(accessLevel) ? '' : */ '/show'}`,
                ),
            );
        }
    };
    return props.render(redirect);
};

export default OverrideableRedirectProvider;
