import get from 'lodash/get';
import { createCopier } from 'fast-copy';

const convertToCode = (fname: string) => {
    const [key, searchModifier] = fname.split('__');
    const codef = key.endsWith('Id') ? key.slice(0, -2) + 'Code' : key + 'Code';
    return codef + (searchModifier ? `__${searchModifier}` : '');
};

const addId = (fname: string) => {
    const [key, searchModifier] = fname.split('__');
    const f = key.endsWith('Id') ? key : `${key}Id`;
    return f + (searchModifier ? `__${searchModifier}` : '');
};

const getIndexOfTableRowField = (key: string) => parseInt(key[key.length - 1], 10);

const _c_ = '_c_';

const isTableColField = (key: string): boolean => {
    return key.indexOf(_c_) !== -1;
};
const isTableRowColField = (key: string): boolean => {
    return isTableColField(key) && key[key.length - 2] === '_' && !isNaN(getIndexOfTableRowField(key));
};

const getColumnFieldName = (
    k: string,
    rowPostfix: 'ENTIRE' | 'STRIP_ROW_POSTFIX' | 'STRIP_ROW_POSTFIX_AND_ID_POSTFIX',
) => {
    return rowPostfix === 'ENTIRE'
        ? k.slice(k.indexOf(_c_) + _c_.length)
        : rowPostfix === 'STRIP_ROW_POSTFIX'
        ? k.slice(k.indexOf(_c_) + _c_.length, -2)
        : k.slice(k.indexOf(_c_) + _c_.length, -4);
};

// don't copy arrays here.
const copy = createCopier({
    array: (array) => array,
});
const addCodesForVSFields = (
    values: {
        [fieldName: string]: any;
    },
    concepts: { [id: string]: { code: string } },
    valueSetFields: { [f: string]: string }, // { fieldName: valueSet }, (although valueset isn't used here)
) => {
    const result = copy(values);
    Object.entries(valueSetFields).forEach(([key, valueSet]) => {
        if (isTableColField(key)) {
            Object.keys(values)
                .filter((fieldKey) => fieldKey.startsWith(key) && isTableRowColField(fieldKey))
                .forEach((fk) => {
                    const columnValuesToTransform = { [getColumnFieldName(fk, 'STRIP_ROW_POSTFIX')]: values[fk] };
                    const valueSetColumnFields = { [getColumnFieldName(key, 'ENTIRE')]: valueSet };
                    const res = addCodesForVSFields(columnValuesToTransform, concepts, valueSetColumnFields);
                    const codeAppendedRowKey = `${convertToCode(fk.slice(0, -2))}_${getIndexOfTableRowField(fk)}`;
                    result[codeAppendedRowKey] =
                        res[`${getColumnFieldName(fk, 'STRIP_ROW_POSTFIX_AND_ID_POSTFIX')}Code`];
                });
        } else {
            const valueId = get(values, addId(key));
            const codeValue = valueId && concepts[valueId] ? concepts[valueId].code : null;
            if (typeof valueId !== 'undefined') {
                if (key.includes('.')) {
                    const pathArray = key.split('.'); // we mutate this with pathArray.pop()!
                    const prop = pathArray.pop() || key;
                    const pathToObj = pathArray.join('.');
                    const baseObj = get(result, pathToObj);
                    baseObj[convertToCode(prop)] = codeValue;
                } else {
                    result[convertToCode(key)] = codeValue;
                }
            }
        }
    });

    return result;
};
export default addCodesForVSFields;
