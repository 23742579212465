import React from 'react';
/**
 * We use this in order to get a ref to the root 'div' of the form we are currently on.
 *
 * We might use this to e.g. insert a .blanket element which covers the popover we are currently are on,
 * in which case we need to add it to the div of the popup so it's inserted correctly into stacking context.
 *
 * Let's not do it for 'root', but only for popups.
 */
export const popupHtmlRootRefContext = React.createContext<HTMLDivElement | null>(null);
