import { createSelector } from 'reselect';
import { RootState } from '../../../../../reducers/rootReducer';
import { createGetEntities } from './getEntities';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { TemplateExpressionsGenerated } from 'viewConfigCalculations/expressionTemplates/TemplateExpressionsGenerated';
import { EntityFieldConceptExps } from 'viewConfigCalculations/ConceptAvailabilityExpressions/EntityFieldConceptExps';
import { uniq } from 'lodash';
import expandMinimalDataFromDataPaths from './expandMinimalDataFromPaths';
import { ViewCalcValueExpressionMetaData } from 'expression-tester/entity-form/CalcValueConfiguration/ViewCalcValueExpressions';

interface RecordSelectorProps {
    record: { id?: string; entityType?: string };
    viewName: string;
    overrideFieldsInExpressions?: string[];
    overrides?: {
        visibilityExps?: EntityVisibilityExps[0];
    };
}
const emptyObj = {};

export const visibilityExpressionsSelector = <
    Props extends {
        viewName: string;
        overrides?: {
            visibilityExps?: EntityVisibilityExps[0];
        };
    },
>(
    state: RootState,
    props: Props,
): EntityVisibilityExps[0] => props.overrides?.visibilityExps ?? state.entityVisibility[props.viewName] ?? emptyObj;

export const templateExpressionsSelector = <
    Props extends {
        viewName: string;
        overrides?: {
            templateExps?: TemplateExpressionsGenerated[0];
        };
    },
>(
    state: RootState,
    props: Props,
): TemplateExpressionsGenerated[0] => props.overrides?.templateExps ?? state.templateExps[props.viewName] ?? emptyObj;

export const conceptExpressionsSelector = <
    Props extends {
        viewName: string;
        overrides?: {
            conceptExps?: EntityFieldConceptExps[0];
        };
    },
>(
    state: RootState,
    props: Props,
): EntityFieldConceptExps[0] => props.overrides?.conceptExps ?? state.entityConceptExps[props.viewName] ?? emptyObj;

export const calcExpressionsSelector = <
    Props extends {
        viewName: string;
        overrides?: {
            calcExps?: ViewCalcValueExpressionMetaData[string];
        };
    },
>(
    state: RootState,
    props: Props,
): ViewCalcValueExpressionMetaData[string] =>
    props.overrides?.calcExps ?? state.viewCalcValueExpressions[props.viewName] ?? emptyObj;

const createRecordSelector = () => {
    const getEntities = createGetEntities();
    return createSelector(
        visibilityExpressionsSelector,
        conceptExpressionsSelector,
        templateExpressionsSelector,
        calcExpressionsSelector,
        (state: RootState, props: RecordSelectorProps) => props.overrideFieldsInExpressions,
        getEntities,
        (state: RootState, props: RecordSelectorProps) => state.viewConfig,
        (state: RootState, props: RecordSelectorProps) => (props.record ? props.record.id : null),
        (state: RootState, props: RecordSelectorProps) => (props.record ? props.record.entityType : null),
        (
            visConfig,
            conceptExpsConfig,
            templateConfig,
            calcExpsConfig,
            overrideFieldsInExpressions,
            entities,
            viewConfig,
            id,
            entityType,
        ) => {
            if (!id || !entityType) {
                return emptyObj;
            }

            const getDataPaths = <
                ConfEntry extends {
                    valuesetFieldsRequired: {
                        [field: string]: string;
                    };
                    dataPaths: string[];
                },
            >(
                confEntry: ConfEntry,
            ) => {
                return [
                    // below line: if traverseGetData handled e.g. field._ALL_._ALL and would do the recursive expand, we wouldn't have to truncate
                    ...(confEntry.dataPaths || []),
                    ...Object.keys(confEntry.valuesetFieldsRequired || {}).map((f) => `${f}Id`),
                ];
            };

            const allDataPaths = uniq(
                overrideFieldsInExpressions || [
                    ...Object.values(visConfig).flatMap((c) => (c as any).flatMap((co) => getDataPaths(co))),
                    ...Object.values(conceptExpsConfig).flatMap((c) => getDataPaths(c)),
                    ...Object.values(templateConfig).flatMap((c) => getDataPaths(c)),
                    ...Object.values(calcExpsConfig).flatMap((c) => getDataPaths(c)),
                ],
            );
            const expanded = expandMinimalDataFromDataPaths(viewConfig, entityType, id, entities, allDataPaths);
            expanded.entityType = entityType; // if this is not in 'dataPaths', it won't be set.
            expanded.id = id;
            return expanded;
        },
    );
};
export default createRecordSelector;
