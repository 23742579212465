import React, { useMemo, useState } from 'react';
import { Box, Button, Typography, Card, CardContent, CardActions, Snackbar } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { Input } from '@mui/icons-material';
import { AssistantType, REPORT_BUILDER } from 'chatbot/constants';
import { useChatBot } from 'chatbot/ChatbotProvider';
import { Undo } from '@material-ui/icons';

const getHeader = (assistant: AssistantType) => {
    switch (assistant) {
        case REPORT_BUILDER:
            return 'New Report Definition';
        default:
            return 'Unknown';
    }
};

const PossibleInputDisplay = ({ additionalParams }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [showRevertButton, setShowRevertButton] = useState(false);

    const { state: chatState } = useChatBot();

    const handleApproveClick = () => {
        chatState.fieldFiller(additionalParams);
        setShowRevertButton(true);
        setSnackbarOpen(true);
    };
    const handleRevertClick = () => {
        if (chatState.prevValue) {
            chatState.fieldFiller(chatState.prevValue);
            setShowRevertButton(false);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const headerText = useMemo(() => getHeader(chatState.assistant), [chatState.assistant]);

    return (
        <Card variant="outlined" sx={{ marginBottom: 1 }}>
            <CardContent>
                <Typography variant="h6">{headerText}</Typography>
                {/* report builder sends back sql which is easy to format so put in a code block
                other assistants send back unformatted spel expressions
                */}
                <Box component={'pre'} sx={{ maxHeight: 300, overflowY: 'auto', whiteSpace: 'pre-wrap' }}>
                    <ReactMarkdown>
                        {chatState.assistant === REPORT_BUILDER
                            ? `\`\`\`\n${additionalParams}\n\`\`\``
                            : additionalParams}
                    </ReactMarkdown>
                </Box>
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleApproveClick}
                    startIcon={<Input sx={{ transform: 'rotate(180deg)' }} />}
                >
                    Approve
                </Button>
                {showRevertButton && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={handleRevertClick}
                        startIcon={<Undo />}
                    >
                        Revert
                    </Button>
                )}
            </CardActions>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message="Field updated"
            />
        </Card>
    );
};

export default PossibleInputDisplay;
