import React from 'react';
import getInitialTheme from 'components/layouts/getMuiThemeV4';
import { getPrimaryColor, getSecondaryColor, getErrorColor } from 'components/layouts/getThemeColors';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { themeOverrideContext } from '../ThemeOverrideProvider';
import {
    getFontFamilySelector,
    getOfflinePrimaryColorSelector,
    getOfflineSecondaryColorSelector,
    getPrimaryColorSelector,
    getSecondaryColorSelector,
} from 'util/applicationConfig';
import isOffline from 'util/isOffline';
import { Theme as ThemeV4, ThemeOptions as V4ThemeOptions } from '@material-ui/core';

// todo: allow injection of other colors
const useRootTheme = (themeCreator: (themeInit: V4ThemeOptions) => ThemeV4): ThemeV4 => {
    const { overrides, darknessOverrides, darkMode, forceLabelShrink, fieldVariant, useStripedLists, denseUI, flatUI } =
        React.useContext(themeOverrideContext);
    const adhocConfiguredPrimaryColor = useAppSelector(getPrimaryColorSelector);
    const adhocConfiguredSecondaryColor = useAppSelector(getSecondaryColorSelector);

    const adhocConfiguredOfflinePrimaryColor = useAppSelector(getOfflinePrimaryColorSelector);
    const adhocConfiguredOfflineSecondaryColor = useAppSelector(getOfflineSecondaryColorSelector);

    const fontFamily = useAppSelector(getFontFamilySelector);
    const printMode = useAppSelector((state: RootState) => state.printMode);
    const appColor = useAppSelector((state: RootState) => state.basicInfo && state.basicInfo.applicationColor);
    const theme = React.useMemo(() => {
        const configuredPrimaryColor = isOffline() ? adhocConfiguredOfflinePrimaryColor : adhocConfiguredPrimaryColor;
        const configuredSecondaryColor = isOffline()
            ? adhocConfiguredOfflineSecondaryColor
            : adhocConfiguredSecondaryColor;
        const theme = getInitialTheme(
            themeCreator,
            {
                primary: (overrides.primary as any) || getPrimaryColor(configuredPrimaryColor || appColor),
                secondary: (overrides.secondary as any) || getSecondaryColor(configuredSecondaryColor),
                error: (overrides.error as any) || getErrorColor(adhocConfiguredSecondaryColor || appColor),
                darknessOverrides,
                darkMode,
                forceLabelShrink,
                fieldVariant,
                fontFamily,
                printMode,
                useStripedLists,
                denseUI,
                flatUI,
            },
            true, // DOM sideEffect: rebuild the stylesheet for React-Web-Tabs
        );
        // set the document background color
        return theme;
    }, [
        flatUI,
        denseUI,
        printMode,
        fontFamily,
        appColor,
        overrides,
        darknessOverrides,
        darkMode,
        forceLabelShrink,
        fieldVariant,
        adhocConfiguredPrimaryColor,
        adhocConfiguredSecondaryColor,
        adhocConfiguredOfflinePrimaryColor,
        adhocConfiguredOfflineSecondaryColor,
        themeCreator,
        useStripedLists,
    ]);
    return theme;
};

export default useRootTheme;
