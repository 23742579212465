import { RootState } from '../../../reducers/rootReducer';
import getFormInitial from '../form/getFormInitial/v2';
import ViewConfig from 'reducers/ViewConfigType';
import { createSelector } from 'reselect';
import { createGetEntities } from '../form/EntityFormContext/util/getEntities';
import { isRefOneField, isFieldViewField, isValueSetField } from './viewConfigUtils';
import filterEntityByQueryRepresentation from 'isomorphic-query-filters/filterEntityByQueryRepresentation';

interface Props {
    viewName: string;
    ids: string[];
    listFilter?: string;
}
const emptyObj = {};

const createGetListData = <T extends Props>(expandTitlesOnReferences: boolean = false) => {
    const getEntities = createGetEntities();
    const getData = (
        viewConfig: ViewConfig,
        entities: RootState['admin']['entities'],
        viewName: string,
        ids: string[] | null | undefined,
        filterObj?: {},
    ) => {
        if (!ids) {
            return emptyObj;
        }
        const toReturnData = {};

        const filterEntity = filterObj
            ? filterEntityByQueryRepresentation(viewConfig)({
                  ...(filterObj ?? {}),
              })
            : (record, entities) => true;

        ids.forEach((id) => {
            const recordData = getFormInitial(
                viewConfig,
                viewName,
                expandTitlesOnReferences
                    ? Object.entries(viewConfig.views[viewName].fields)
                          .filter(([name, vf]) => {
                              return (
                                  isFieldViewField(vf) &&
                                  (isRefOneField(viewConfig, vf.entity, vf.field, 'POP_LAST') ||
                                      isValueSetField(viewConfig, vf.entity, vf.field, 'POP_LAST'))
                              );
                          })
                          .map(([name]) => `${name}.title`)
                    : [],
                entities,
                id,
            );
            if (
                recordData &&
                recordData['id'] &&
                recordData['entityType'] &&
                filterEntity(recordData as { id: string; entityType: string }, entities)
            ) {
                toReturnData[id] = recordData;
            }
        });
        if (Object.keys(toReturnData).length === 0) {
            return emptyObj;
        }
        return toReturnData;
    };
    const dataSelector = createSelector(
        (state: RootState, props: T) => state.viewConfig,
        getEntities,
        (state: RootState, props: T) => props.viewName,
        (state: RootState, props: T) => props.ids,
        (state: RootState, props: T) => props.listFilter,
        getData,
    );
    return dataSelector;
};
export default createGetListData;
