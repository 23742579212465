import { maybeDecodeMetadataKeyVal } from 'components/generics/genericCreate/BackrefMetadata/backrefMetadata';
import { useMemo } from 'react';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';

const getBackref = (values) => {
    if (!values) {
        return undefined;
    }
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        const r = maybeDecodeMetadataKeyVal(k, values[k]);
        if (r) {
            return r;
        }
    }
    return undefined;
};

/**
 * 
 use this if we have form context
 (selectors if we have formId)
 */
export const useBackrefProperties = (formInitialValues: {}) => {
    const br = useMemo(() => getBackref(formInitialValues), [formInitialValues]);

    const path = br?.path;
    const entityType = br?.entityType;
    const id = br?.id;
    return useMemo(() => {
        return {
            path,
            entityType,
            id,
        };
    }, [path, entityType, id]);
};

const createBackrefPropertiesSelector = <P extends { formId?: string }>() => {
    return createSelector(
        (state: RootState, props: P) => state.form?.[props.formId || 'record-form']?.values,
        getBackref,
    );
};

const createBackrefSelector = <P extends { formId?: string }>() => {
    const brSelector = createBackrefPropertiesSelector<P>();
    const pathSelector = createSelector(brSelector, (br) => br?.path);
    const entityTypeSelector = createSelector(brSelector, (br) => br?.entityType);
    const idSelector = createSelector(brSelector, (br) => br?.id);
    return createSelector(pathSelector, entityTypeSelector, idSelector, (path, entityType, id) => ({
        path,
        entityType,
        id,
    }));
};
export default createBackrefSelector;
