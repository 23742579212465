import React, { FunctionComponent, useMemo, useState } from 'react';
import { View, FieldViewField } from 'reducers/ViewConfigType';
import { useFormContext } from 'react-hook-form';
import { IconButton, makeStyles, createStyles, Theme } from '@material-ui/core';
import { getLabelForFieldExpr, isFieldViewField } from 'components/generics/utils/viewConfigUtils';
import useViewConfig from 'util/hooks/useViewConfig';
import Reorder from 'react-reorder';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import AddField from 'layout-editor/add-field/components/AddViewField';
import fromEntries from 'util/fromentries';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        reorderableListItem: {
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.grey[300],
            },
        },
    }),
);

interface MergeViewEditorProps {
    rootEntity: string;
    setFields: (fields: View['fields']) => void;
    fields: View['fields'];
}
const MergeViewEditor: FunctionComponent<MergeViewEditorProps> = ({ fields, setFields, rootEntity }) => {
    const viewConfig = useViewConfig();
    const list = useMemo(() => Object.values(fields), [fields]);
    const classes = useStyles();
    const [openForEditIx, setOpenForEditIx] = useState<number | 'newButton'>(-1);
    return (
        <React.Fragment>
            <Dialog maxWidth={false} fullWidth={true} open={openForEditIx !== -1} onClose={() => setOpenForEditIx(-1)}>
                {openForEditIx !== -1 && (
                    <div style={{ margin: '1em', padding: '1em' }}>
                        <AddField
                            allowCalcs={false}
                            rootEntity={rootEntity}
                            initialValues={
                                openForEditIx === 'newButton'
                                    ? undefined
                                    : {
                                          ...list[openForEditIx],
                                          fieldPath: (list[openForEditIx] as FieldViewField).field,
                                      }
                            }
                            onAdd={(field) => {
                                setFields(
                                    fromEntries(
                                        [...list, field].flatMap((f) =>
                                            isFieldViewField(f) ? ([[f.field, f]] as [string, FieldViewField][]) : [],
                                        ),
                                    ),
                                );
                                setOpenForEditIx(-1);
                            }}
                        />
                    </div>
                )}
            </Dialog>
            <Reorder
                itemKey="key"
                list={list || []}
                template={(props) => (
                    <div className={classes.reorderableListItem}>
                        <DragIndicatorIcon
                            style={{
                                opacity: 0.5,
                                verticalAlign: 'middle',
                            }}
                        />
                        {props.item.label ||
                            (isFieldViewField(props.item) &&
                                getLabelForFieldExpr(viewConfig, props.item.entity, props.item.field, 'POP_LAST'))}
                        <IconButton
                            onMouseDown={(e) => {
                                e.stopPropagation();
                            }}
                            onClick={(e) => {
                                setOpenForEditIx((list || []).indexOf(props.item));
                            }}
                        >
                            <Edit />
                        </IconButton>
                        <IconButton
                            onMouseDown={(e) => {
                                e.stopPropagation();
                            }}
                            onClick={(e) => {
                                setFields(
                                    fromEntries(
                                        [
                                            ...list.slice(0, list.indexOf(props.item)),
                                            ...list.slice(list.indexOf(props.item) + 1),
                                        ].map((f) => [(f as FieldViewField).field, f] as [string, FieldViewField]),
                                    ),
                                );
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </div>
                )}
                // Function that is called once a reorder has been performed
                callback={(event, itemThatHasBeenMoved, itemsPreviousIndex, itemsNewIndex, reorderedArray) => {
                    setFields(
                        fromEntries(reorderedArray.map((e, i) => [(e as FieldViewField).field, { ...e, order: i }])),
                    );
                }}
                selectedKey="tabKey"
                disableReorder={false}
                lock="horizontal"
                disableDragClass="no-drag"
            />
            <IconButton
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
                onClick={(e) => {
                    setOpenForEditIx('newButton');
                }}
            >
                <Add />
            </IconButton>
        </React.Fragment>
    );
};

const MergeViewRowsController: FunctionComponent<{ rootEntity: string }> = (props) => {
    const { watch, setValue } = useFormContext();
    const fields: View['fields'] = watch('fields');
    const setFields = React.useCallback(
        (fields: View['fields']) => {
            setValue('fields', fields, {
                shouldDirty: true,
                shouldValidate: true,
            });
        },
        [setValue],
    );
    return <MergeViewEditor rootEntity={props.rootEntity} fields={fields} setFields={setFields} />;
};
export default MergeViewRowsController;
