import React, { FunctionComponent, useCallback, useState } from 'react';
import { useForm, Controller, FormProvider, Resolver } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { makeStyles, Theme, createStyles, Button, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import useViewConfig from 'util/hooks/useViewConfig';
import AutocompleteSpelEditor from 'ace-editor/LazyFullFeaturedSpelEditor';
import FieldPath from 'layout-editor/add-field/components/FieldPath';
import { useValidationResolver } from '../ViewDefinitionConfig/EditExpression';
import { UpdateMeta } from 'expression-tester/entity-form/ViewDefinitionConfig/EditExpression';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.dark,
        },
    }),
);

export interface ExpressionData {
    expression: string;
    compileSuccess: boolean;
    methodsAndFunctions: string[];
    fieldPath: string;
}

interface EditExpressionProps {
    viewName?: string;
    initialValues?: Pick<ExpressionData, 'expression' | 'fieldPath'> &
        Partial<Pick<ExpressionData, 'compileSuccess' | 'methodsAndFunctions'>>;
    onSubmit: (data: ExpressionData) => void;
    widgetMapsToSourceInsteadOfId?: boolean;
}

// for Entity forms only
const CalcValueExpressionForm: FunctionComponent<EditExpressionProps> = (props) => {
    type InternalData = ExpressionData & {
        _unpersistedCalcName?: string;
    };
    const classes = useStyles(props);
    const { viewName, onSubmit } = props;
    const viewConfig = useViewConfig();
    const expressionResolver = useValidationResolver(viewName, viewConfig);
    const isInitiallyUnpersisted = !!props.initialValues?.fieldPath?.startsWith('$');
    const [isUnpersisted, setIsUnpersisted] = useState(isInitiallyUnpersisted);
    const resolver: Resolver<InternalData> = useCallback(
        async (data: InternalData) => {
            if (isUnpersisted) {
                if (!data._unpersistedCalcName) {
                    return {
                        errors: {
                            _unpersistedCalcName: 'A calc name is required.',
                        },
                        values: data,
                    } as any;
                }
                if (!data._unpersistedCalcName.startsWith('$')) {
                    return {
                        errors: {
                            _unpersistedCalcName: 'An unpersisted calc name must being with "$"',
                        },
                        values: data,
                    } as any;
                }
            } else if (!data.fieldPath) {
                return {
                    errors: {
                        fieldPath: 'A field is required.',
                    },
                    values: data,
                } as any;
            }

            return await expressionResolver(data);
        },
        [expressionResolver, isUnpersisted],
    );
    const rootEntity = viewConfig.views[viewName].entity;
    const methods = useForm<InternalData>({
        resolver,
        defaultValues: props.initialValues,
        mode: 'onChange',
    });
    const { errors } = methods;

    const handleSubmit = ({ _unpersistedCalcName, ...data }: InternalData) => {
        if (isUnpersisted) {
            onSubmit({
                ...data,
                fieldPath: _unpersistedCalcName.trim(),
            });
            return;
        }
        onSubmit(data);
    };
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <label>
                    <b>Expression *</b>
                    <Controller
                        rules={{ required: 'Provide an expression' }}
                        as={AutocompleteSpelEditor as any}
                        defaultValue={props.initialValues?.['expression']}
                        name="expression"
                        control={methods.control as any}
                        hideDocs
                    />
                </label>
                <ErrorMessage errors={errors} name="expression" />
                <pre className={classes.error}>{errors['methodsAndFunctions']}</pre>
                <pre className={classes.error}>{errors['fieldsRequired']}</pre>

                <FormControlLabel
                    htmlFor="forUnpersisted"
                    control={
                        <Checkbox
                            id="forUnpersisted"
                            checked={isUnpersisted}
                            onChange={(e) => setIsUnpersisted(e.target.checked)}
                        />
                    }
                    label="Unpersisted calc?"
                    style={{ marginLeft: '10px' }}
                />
                {isUnpersisted ? (
                    <>
                        <Controller
                            name="_unpersistedCalcName"
                            control={methods.control}
                            defaultValue={isInitiallyUnpersisted ? props.initialValues.fieldPath : ''}
                            as={TextField}
                            fullWidth
                            margin="normal"
                            label="Unpersisted Calc Name"
                            error={Boolean(methods.errors?.['_unpersistedCalcName'])}
                            helperText={methods.errors?.['_unpersistedCalcName'] ?? null}
                        />
                    </>
                ) : (
                    <>
                        <FieldPath
                            required
                            allowPropertiesOnManys={false}
                            defaultValue={
                                [props.initialValues?.fieldPath]
                                    .filter(Boolean)
                                    .map((fp) => (fp.startsWith('$') ? '' : fp))[0]
                            }
                            resource={rootEntity}
                            depth={3}
                            appendId
                            appendIds="both"
                        />
                        <pre className={classes.error}>{errors['fieldPath']}</pre>
                    </>
                )}
                <Button color="primary" variant="contained" disabled={Object.keys(errors).length > 0} type="submit">
                    Save
                </Button>
            </form>
            <UpdateMeta />
        </FormProvider>
    );
};
export default CalcValueExpressionForm;
