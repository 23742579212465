import { ButtonProps } from '@material-ui/core';
import { createContext } from 'react';

export const createCompleteButtonContext = createContext<{
    createCompleteButton?: (
        label: string,
        outcome?: string,
        forceDisabled?: boolean,
        ButtonProps?: Partial<ButtonProps>,
        muiButton?: boolean,
    ) => JSX.Element;
    renderSaveButton?: (text?: string, ButtonProps?: Partial<ButtonProps>, muiButton?: boolean) => JSX.Element;
    renderCancelButton?: (text?: string, ButtonProps?: Partial<ButtonProps<any>>, muiButton?: boolean) => JSX.Element;
}>({});
