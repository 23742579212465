import { EvaluationOptions } from './definitions.d';
import {
    getValidationFromConfig as JSONGetValidationFromConfig,
    evaluateFormValidationConfig,
} from './evaluateFromJson';
import * as t from 'io-ts';
import { Either, left, right } from 'fp-ts/lib/Either';
import { IO } from 'fp-ts/lib/IO';
import { compose } from 'fp-ts/lib/function';
import * as eitherT from 'fp-ts/lib/EitherT';
import { array, mapOption } from 'fp-ts/lib/Array';
import { fromEither } from 'fp-ts/lib/Option';
import { setoidString } from 'fp-ts/lib/Setoid';
import { uniq } from 'fp-ts/lib/Array';
import { FormFieldUnion } from '../../fieldFactory/translation/fromFlowable/types/index';
import { SpelOptions } from '../evaluate';
import addCodesForVSFields from './addCodesForVSFields';
import { ValueSets } from 'valueSets/reducer';
import { buildContext } from 'spelContext/buildContext';
import ViewConfig from 'reducers/ViewConfigType';
import {
    PathTrie,
    setNullOnMissingPaths,
} from 'components/generics/form/EntityFormContext/util/createPathTreeFromGraph';

export const getBaseFields = (fields: string[]) =>
    uniq(setoidString)(fields.map((f) => (f.indexOf('.') !== -1 ? f.slice(0, f.indexOf('.')) : f)));

// paths might be providerContacts._ALL_.registrationCode
export const nullInitializeFieldsIfNotSet = (_rootValues: {} = {}, paths: string[] | PathTrie) => {
    return setNullOnMissingPaths(_rootValues, Array.isArray(paths) ? paths.map((p) => p.split('.')) : paths);
};

export const _flowablePreprocessValues = (values: {}, fields: FormFieldUnion[], entities: { Concept?: {} } = {}) => {
    const valueSetFields = (() => {
        let _valueSetFields = {};
        fields.forEach((f) => {
            const adjustedFieldId = f.id.endsWith('Id') ? f.id.slice(0, -2) : f.id;
            if (f.type === 'value-set-dropdown' || f.type === 'value-set-radiobox') {
                _valueSetFields[adjustedFieldId] = f.params.valueSet;
            }
            /*
                if (f.type === 'value-set-multi-select' ||
                f.type === 'value-set-multi-checkbox') {
                    _valueSetFields[adjustedFieldId] = f.params.multiSelectValueSet;
                }
            */
        });
        return _valueSetFields;
    })();
    return {
        ...addCodesForVSFields(
            nullInitializeFieldsIfNotSet(
                values,
                fields.map((f) => f.id),
            ),
            entities.Concept || {},
            valueSetFields,
        ),
    };
};
export const flowablePreprocessValuesForEval = (
    values: {},
    fields: FormFieldUnion[],
    entities: { Concept?: {} } = {},
    valueSets: ValueSets,
    viewConfig: ViewConfig,
) => {
    return {
        ...buildContext({
            viewConfig,
            valueSets,
            entities,
            viewContext: null,
        }),
        ..._flowablePreprocessValues(values, fields, entities),
    };
};

export const entityNullInitializeValues = (
    values: {},
    fieldsInExp: string[] | PathTrie,
    vsFieldsInExp: { [f: string]: string },
    entities: { Concept?: {} } = {},
) => {
    const concepts = entities.Concept || {};
    const res = addCodesForVSFields(nullInitializeFieldsIfNotSet(values, fieldsInExp), concepts, vsFieldsInExp);
    return res;
};

// TODO: this one, now.
export const entityPreprocessValuesForEval = (
    values: {},
    fieldsInExp: string[] | PathTrie,
    vsFieldsInExp: { [f: string]: string },
    entities: { Concept?: {} } = {},
    { viewContext, backref }: Pick<SpelOptions, 'viewContext' | 'backref'> = {}, // TODO - need to pass
    // #getCurrentViewType() here. pass viewName?
    valueSets: ValueSets,
    viewConfig: ViewConfig,
    // viewName?
    viewName?: string,
) => {
    return {
        ...buildContext({
            entities,
            valueSets,
            viewConfig,
            viewContext,
            backref,
        }),
        ...entityNullInitializeValues(values, fieldsInExp, vsFieldsInExp, entities),
        viewContext,
        getCurrentViewType: () => (viewName && viewConfig.views[viewName]?.viewType) ?? null,
        getBackref: () => backref ?? null,
    };
};

export const combineFieldsReq = (fieldsRequired: string[], valuesetFieldsRequired: { [f: string]: string }) =>
    uniq(setoidString)([
        ...fieldsRequired,
        ...fieldsRequired.flatMap((f) => {
            if (f.indexOf('.') !== -1) {
                const path = f.split('.');
                return path
                    .reduce((prev, curr, i) => {
                        if (i !== path.length - 1) {
                            return [prev[0] ? `${prev[0]}.${curr}` : curr, ...prev];
                        }
                        return prev;
                    }, [])
                    .map((subP) => `${subP}Id`);
            }
            return [];
        }),
        ...Object.keys(valuesetFieldsRequired).flatMap((vsf) => [vsf + 'Id', vsf + 'Code']),
    ]);

export const getEvaluator =
    (options: EvaluationOptions = { stripHashes: true }, nullInitializedFields: string[] = []) =>
    (testConfig: string) =>
    (testValues: {}, testLocals: {} = {}) =>
        JSONGetValidationFromConfig(testConfig).map((configs) =>
            evaluateFormValidationConfig(
                configs,
                nullInitializeFieldsIfNotSet(testValues, nullInitializedFields),
                testLocals,
                options,
            ),
        );

type ReturnType3<T extends () => any> = ReturnType<ReturnType<ReturnType<T>>>;
type strTup = [string, string];
type fieldValResult = [string, ReturnType3<typeof getEvaluator>];

/* returns tuples of [fieldName, evaluationResult(tree datastructure encapsulating error cases)] */
export const getEvaluatedValidations =
    (validator: ReturnType<typeof getEvaluator>, values: {}) =>
    (fieldExpressionTuples: [/* fieldName */ string, /* validationExpr */ string][]) =>
        fieldExpressionTuples.map<fieldValResult>(([fieldName, validation]) => [
            fieldName,
            validator(validation)(values),
        ]);

/*
    Utility for when we are just interested in string representations of errors
    (takes results of getEvaluatedValidations and returns
        Either<[fieldName, unexpectedErrorMessage], [fieldName, message]>[])
*/
interface DecodeValidationError {
    tag: 'ValidationErrors';
    validationErrors: t.ValidationError[];
}

interface DecodeParsingError {
    tag: 'ParsingError';
    input: string;
    errorMessage: string;
}
type evalErr = { name: string; message: string } | Error;

export const validationErrMsg = (err: DecodeValidationError) =>
    `Invalid validation config for ${JSON.stringify(err.validationErrors)}`;
const isValErr = (err: evalErr | DecodeParsingError | DecodeValidationError): err is DecodeValidationError =>
    err['tag'] === 'ValidationErrors';

export const parsingErrMsg = (err: DecodeParsingError) => `ParsingError: ${err.errorMessage} for input ${err.input}`;
const isParseErr = (err: evalErr | DecodeParsingError | DecodeValidationError): err is DecodeParsingError =>
    err['tag'] === 'ParsingError';

export const parsingOrValidationErrMsg = (err: DecodeValidationError | DecodeParsingError) =>
    err.tag === 'ValidationErrors' ? validationErrMsg(err) : parsingErrMsg(err);

export const evalErrMsg = (err: evalErr, fieldIds?: string[]) =>
    `Validation expression eval failed: ${err.name || ''} ${err.message || ''}
    ${err.toString() !== '[object Object]' ? err.toString() : ''}
    ${
        err.name === 'NullPointerException' && fieldIds
            ? `
    fields available in the current context are \n${fieldIds.join('\n')}`
            : ''
    }`;

export const errMsg = (err: evalErr | DecodeValidationError | DecodeParsingError, fieldIds?: string[]) =>
    isParseErr(err) ? parsingErrMsg(err) : isValErr(err) ? validationErrMsg(err) : evalErrMsg(err, fieldIds);

export const getErrorsAndMessages = (
    fieldEvalTuples: fieldValResult[],
    fieldIds?: string[], // Optional list of null initialized values to display as a helpertext for nullPointerExceptions
) =>
    fieldEvalTuples.flatMap(([fieldName, result]) =>
        result.fold(
            (err) => [left<strTup, strTup>([fieldName, parsingOrValidationErrMsg(err)])],
            (eithers) =>
                eithers.map((e) =>
                    e.fold(
                        (err: Error) => left<strTup, strTup>([fieldName, evalErrMsg(err)]),
                        (r) => right<strTup, strTup>([fieldName, r]),
                    ),
                ),
        ),
    );

const combineTuplesByFst = (
    tuples: [/* fst: */ string, string][],
): {
    [fst: string]: string[];
} => array.reduce(tuples, {}, (combined, [fst, snd]) => ({ ...combined, [fst]: [...(combined[fst] || []), snd] }));

/*
    This is a utility for when we don't want to treat runtime errors differently from messages

    Takes tuples of [fieldId, evaluationResult (returned by getEvaluator execution)]
    and returns all stringified errors (JSON + ConfigValidation + SpelEvaluation) and messages
    for each fieldId.
*/
export const errorsAsMessages: (
    fieldEvalTuples: fieldValResult[],
    fieldIds?: string[], // Optional list of null initialized values to display as a helpertext for nullPointerExceptions
) => {
    [fieldId: string]: string[];
} = compose(
    combineTuplesByFst,
    (errorsAndMessages) => errorsAndMessages.map((e) => e.value), // unwrap either values
    getErrorsAndMessages,
);

/*
    If we are just interested in errors or messages returned by getErrorsAndMessages
*/
export const getRights = <L, R>(errorsAndMessages: Either<L, R>[]): R[] =>
    mapOption(errorsAndMessages, (e) => fromEither(e));
export const getLefts = <L, R>(errorsAndMessages: Either<L, R>[]): L[] =>
    mapOption(errorsAndMessages, (e) => fromEither(e.swap()));

/*

Not sure if I'll keep this around.
Just in case we want to console.error/console.log data in Left

errorsToIo: (maps errors to lazy IO with the logging function)
runEitherArrayIo: (executes lazy IO returning null in place)
usage:
    const result = getEvaluator()(expressionString)(values)

    // build IO into the result data structure
    const resultWithIO = result.map(errorsToIo(console.error));

    // perform IO
    const IOExecuted = resultWithIO.map(runEitherArrayIo)
*/
export const errorsToIo: (
    logger: (message: string) => void,
) => <T>(eithers: Either<Error, T>[]) => Either<IO<any>, T>[] = (logger) => (errorOrMessageArray) =>
    errorOrMessageArray.flatMap((ei) =>
        eitherT.fromEither(array)(ei.mapLeft((err) => new IO(() => logger(err.toString())))),
    );

export const runEitherArrayIo: <T, A>(eithers: Either<IO<A>, T>[]) => Either<null, T>[] = (arr) =>
    arr.map((e1) =>
        e1.mapLeft((e) => {
            e.run();
            return null;
        }),
    );
