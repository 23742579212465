import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { NetworkUnavailable, ServerError } from 'remoteStatus/one/components/pages';
import { DisplayStatusBasicProps, DisplayStatusComponent } from 'remoteStatus/one/components/DisplayStatus';
import DeferredSpinner from 'components/DeferredSpinner';
import ErrorPage from 'remoteStatus/one/components/pages/BaseErrorPage';
import Warning from '@mui/icons-material/Warning';

const mapStateToProps = (state: RootState, props: DisplayStatusBasicProps) => {
    const remoteStatus = state.bpm.processInstances.getOneStatus[props.id] || { _type: 'initial' };
    return {
        remoteStatus,
    };
};
const ProcessInstanceDisplayStatusInternal: React.ComponentType<DisplayStatusBasicProps> =
    connect(mapStateToProps)(DisplayStatusComponent);

interface FormDisplayStatusProps {
    id: string;
    showSuccessOffline: boolean;
    renderSuccess: () => JSX.Element;
    renderSpinner?: () => JSX.Element;
    refresh?: (e: any) => void;
}
const ProcessInstanceDisplayStatus: React.SFC<FormDisplayStatusProps> = ({
    id,
    showSuccessOffline,
    renderSuccess,
    refresh,
    renderSpinner = () => <DeferredSpinner />,
}) => (
    <ProcessInstanceDisplayStatusInternal
        id={id}
        renderSuccess={renderSuccess}
        renderUnhandledError={() => (
            <ErrorPage headingText="Unhandled Error" Icon={Warning} subText={'Check console for error details'} />
        )}
        renderLoading={(prevStatus) => {
            if (prevStatus === 'initial' || prevStatus._type === 'error') {
                return renderSpinner();
            }
            return renderSuccess();
        }}
        renderMoved={(newId) => <div>Process moved to id: {newId}</div>}
        renderNetworkError={() => {
            // render any existing/cached data if possible
            return showSuccessOffline ? renderSuccess() : <NetworkUnavailable retry={refresh} />;
        }}
        renderServerError={(code, message) => {
            return <ServerError code={code} message={message} />;
        }}
    />
);
export default ProcessInstanceDisplayStatus;
