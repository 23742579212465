import React, { useState } from 'react';
import ContentAdd from '@material-ui/icons/Add';
import Toolbar from 'components/generics/form/Toolbar.aor';
import SaveButton from 'components/generics/button/SaveButton';
import CloseButton from './PopoverCloseButton';
import GenericCreate from '../../components/generics/genericCreate';
import useWidth from 'util/hooks/useWidth';
import useViewConfig from 'util/hooks/useViewConfig';
import { CreateParams } from 'sideEffect/crud/create/actions';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import Dialog from '@mui/material/Dialog';
import Button from '@material-ui/core/Button';

const styles = {
    floating: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 60,
        left: 'auto',
        position: 'fixed',
    },
    flat: {
        overflow: 'inherit',
    },
};

interface PopoverCreateButtonProps {
    parentId?: string;
    parentEntityName?: string;
    parentFieldInChild?: string;
    label?: string;
    resource: string;
    viewName?: string;
    search?: string;
    onCreateCb?: (id: string, data: {}) => void;
    interceptSubmit?: (args: CreateParams) => void;
    hideFields?: string[];
    injectCreateValues?: {};
    renderButton?: (props: {
        openWithSearch: (search?: string) => void;
        onClick: () => void;
        label: string;
        'aria-label': string;
    }) => JSX.Element;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
}
const PopoverCreateButton: React.FC<PopoverCreateButtonProps> = (props) => {
    const {
        parentId,
        parentEntityName,
        parentFieldInChild,
        label = 'Create',
        viewName,
        search: initialSearch,
        onCreateCb,
        interceptSubmit,
        hideFields,
        injectCreateValues,
        resource,
        renderButton,
        evaluatedAdhocSPELVariables,
    } = props;
    const [search, setSearch] = useState(initialSearch);
    const resourceDisplayName = useViewConfig().entities[resource]?.displayName ?? props.resource;
    const defaultViewName = useViewConfig().entities[resource]?.defaultViews?.CREATE?.name;

    const [open, setOpen] = useState(false);
    const width = useWidth();
    const ariaLabel = `Create ${resourceDisplayName}`;
    const handleClose = () => {
        setSearch(initialSearch);
        setOpen(false);
    };
    const printMode = useAppSelector((state: RootState) => state.printMode);
    if (printMode) {
        return null;
    }
    return (
        <>
            {renderButton?.({
                onClick: () => setOpen(true),
                label,
                'aria-label': ariaLabel,
                openWithSearch: (search?: string) => {
                    setSearch(search ?? initialSearch);
                    setOpen(true);
                },
            }) ?? (
                <Button
                    color="primary"
                    onClick={() => setOpen(true)}
                    aria-label={ariaLabel}
                    style={width === 'xs' ? { ...styles.flat } : styles.flat}
                >
                    {label}&nbsp;
                    <ContentAdd />
                </Button>
            )}
            <Dialog open={open} onClose={handleClose} maxWidth={false} fullWidth={true}>
                <div>
                    <GenericCreate
                        {...{
                            location: {
                                search,
                            },
                            evaluatedAdhocSPELVariables,
                            injectValues: injectCreateValues,
                            viewName: viewName || defaultViewName || `${label} Create`,
                            formId: `popover-create-form-${
                                viewName ?? resource
                            }-Parent:${parentEntityName}:${parentId}`,
                            redirect: false,
                            parentEntity: parentEntityName,
                            parentField: parentFieldInChild,
                            parentId,
                            interceptSubmit,
                            onCreateCb: (id, data) => {
                                handleClose();
                                onCreateCb?.(id, data);
                            },
                            toolbar: (
                                <Toolbar>
                                    <SaveButton />
                                    <CloseButton handleClose={handleClose} />
                                </Toolbar>
                            ),
                            hideFields,
                        }}
                    />
                </div>
            </Dialog>
        </>
    );
};

export default PopoverCreateButton;
