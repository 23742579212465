import {
    DialogTitle,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogActions,
    Button,
    Card,
} from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import produce from 'immer';
import { LayoutStateItem } from 'layout-editor/demo/layoutReducer';
import React, { useContext, useMemo, useState } from 'react';
import { View, ViewField } from 'reducers/ViewConfigType';
import { isFieldViewField } from 'components/generics/utils/viewConfigUtils';

const getLargestRow = (fields: { [key: string]: ViewField }): number => {
    const rows = Object.values(fields)
        .map((field) => field?.['row'])
        .filter((row) => typeof row === 'number');
    return rows.length > 0 ? Math.max(...rows) : 0;
};

export const moveField = (
    view: View,
    fieldToMove: { fromTab: string | -1; field: LayoutStateItem },
    destination: string | -1,
) => {
    const { field: gridItem, fromTab } = fieldToMove;
    const viewFieldDefinition: ViewField = JSON.parse(gridItem['data-originaldefinition']);
    const originalKey = isFieldViewField(viewFieldDefinition)
        ? viewFieldDefinition.field
        : viewFieldDefinition.unpersistedField ?? gridItem.content['props'].fieldInstanceIdentifier;

    const newView = produce(view, (draft) => {
        const fieldsToRemoveFrom = fromTab === -1 ? draft.fields : draft.tabs[fromTab].fields;
        delete fieldsToRemoveFrom[originalKey];
        const fieldsToAddTo = destination === -1 ? draft.fields : draft.tabs[destination].fields;
        const bottomRow = getLargestRow(fieldsToAddTo);
        viewFieldDefinition.row = bottomRow + 1;
        viewFieldDefinition.column = 1;
        let prospectiveKeyName = originalKey;
        while (fieldsToAddTo[prospectiveKeyName]) {
            prospectiveKeyName += '_';
        }
        fieldsToAddTo[prospectiveKeyName] = viewFieldDefinition;
    });
    return newView;
};

export const MoveFieldToOtherGrid = React.memo(
    (props: {
        setFieldToMove: (arg: { fromTab: string | -1; field: LayoutStateItem } | null) => void;
        fieldToMove: { fromTab: string | -1; field: LayoutStateItem };
        view: View;
        onViewChange: (arg: { view: View }) => void;
        refreshTab: (arg: string) => void;
        incrementGridChangeKey: () => void;
    }) => {
        const { view, onViewChange, fieldToMove, setFieldToMove, refreshTab, incrementGridChangeKey } = props;
        const [destination, setDestination] = useState<string | -1>(null);

        const handleClose = () => {
            setDestination(null);
            setFieldToMove(null);
        };

        const handleSubmit = () => {
            const updatedView = moveField(view, fieldToMove, destination);
            onViewChange({ view: updatedView });
            if (typeof fieldToMove.fromTab === 'string') {
                refreshTab(fieldToMove.fromTab);
            }
            if (typeof destination === 'string') {
                refreshTab(destination);
            }
            incrementGridChangeKey();
            handleClose();
        };
        const parsedDef = useMemo(() => {
            return JSON.parse(fieldToMove?.field?.['data-originaldefinition'] ?? null) ?? {};
        }, [fieldToMove]);

        const { fieldVariant } = useContext(themeOverrideContext);

        return (
            <>
                <Card>
                    {/* todo: configured labels like '$reports' do not display nicely */}
                    <DialogTitle>
                        Move "{parsedDef.label ?? parsedDef.field}" from "
                        {typeof fieldToMove.fromTab === 'string' ? view.tabs[fieldToMove.fromTab].label : view.name}"
                        {typeof fieldToMove.fromTab === 'string' ? ' Tab' : ' Main Section'} to a new grid:
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth margin="normal">
                            <InputLabel shrink={true} id="select-view-label">
                                Destination Grid:
                            </InputLabel>
                            <Select
                                variant={fieldVariant}
                                labelId="select-view-label"
                                value={destination}
                                onChange={(e) => {
                                    setDestination(e.target.value as string | -1);
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                placeholder="Select from available grids:"
                                label={'Destination Grid:'}
                            >
                                {typeof fieldToMove.fromTab === 'string' && (
                                    <MenuItem key={view.name} value={-1}>
                                        MainGrid{' '}
                                    </MenuItem>
                                )}
                                {Object.entries(view.tabs ?? {})
                                    .filter(([key, _]) => key !== fieldToMove.fromTab)
                                    .map(([key, tab]) => (
                                        <MenuItem key={key} value={key}>
                                            {tab.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" variant="contained">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!destination}>
                            Move
                        </Button>
                    </DialogActions>
                </Card>
            </>
        );
    },
);
