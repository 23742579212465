import React, { createContext, useContext, ReactNode } from 'react';
import useChatBotReducer from './chatbotReducer';
type ChatBotContextType = ReturnType<typeof useChatBotReducer>;

const ChatBotContext = createContext<ChatBotContextType | undefined>(undefined);

export const ChatBotProvider = ({ children }: { children: ReactNode }) => {
    const chatbot = useChatBotReducer();

    return <ChatBotContext.Provider value={chatbot}>{children}</ChatBotContext.Provider>;
};

export const useChatBot = (): ChatBotContextType => {
    const context = useContext(ChatBotContext);
    if (!context) {
        throw new Error('useChatBot must be used within a ChatBotProvider');
    }
    return context;
};
