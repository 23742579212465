import React, { FunctionComponent, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { ButtonProps, PopperPlacementType } from '@material-ui/core';
import { getHoverMenuSelector } from 'util/applicationConfig';
import { useAppSelector } from 'reducers/rootReducer';

interface PopperMenuListProps {
    anchorEl: HTMLButtonElement;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    placement?: PopperPlacementType;
}
const PopperMenuList: FunctionComponent<PopperMenuListProps> = ({ anchorEl, open, setOpen, children, placement }) => {
    const hoverMenu = useAppSelector(getHoverMenuSelector);

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            setOpen(false);
        }
    }

    if (!open) {
        // be unmounting the popper, we prevent any issues with tabbing to the next element, where it's captured by the transition element
        return null;
    }
    return (
        <Popper
            placement={placement || 'bottom-end'}
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 103 }}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    timeout={hoverMenu ? 0 : TransitionProps['timeout']}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {children}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

interface MenuRootProps {
    renderTrigger: (args: {
        getTriggerProps: (
            props: Omit<ButtonProps, 'ref' | 'aria-controls' | 'aria-haspopup' | 'onClick'>,
        ) => ButtonProps;
    }) => JSX.Element;
    children: (args: {
        handleMenuClose: () => void;
        rootMenuOpen: boolean;
        Menu: typeof PopperMenuList;
        focusTrigger: () => void;
        getMenuProps: (menuProps: Omit<PopperMenuListProps, 'anchorEl' | 'open' | 'setOpen'>) => PopperMenuListProps;
    }) => React.ReactElement<PopperMenuListProps>;
}
const MenuRoot: FunctionComponent<MenuRootProps> = (props) => {
    const hoverMenu = useAppSelector(getHoverMenuSelector);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const handleMenuClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    const handleToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, [setOpen]);
    const openMenu = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const focusTrigger = useCallback(() => {
        anchorRef.current?.focus();
    }, []);
    const getTriggerProps = useCallback(
        (props: Omit<ButtonProps, 'ref' | 'aria-controls' | 'aria-haspopup' | 'onClick'>): ButtonProps => {
            return {
                ref: anchorRef,
                'aria-controls': open ? 'menu-list-grow' : undefined,
                'aria-haspopup': 'true',
                onClick: hoverMenu ? openMenu : handleToggle,
                onMouseEnter: hoverMenu ? openMenu : undefined,
                ...props,
            };
        },
        [open, handleToggle, openMenu, hoverMenu],
    );
    const getMenuProps = useCallback(
        (menuProps: Omit<PopperMenuListProps, 'anchorEl' | 'open' | 'setOpen'>): PopperMenuListProps => {
            return {
                anchorEl: anchorRef.current,
                open,
                setOpen,
                ...menuProps,
            };
        },
        [open, setOpen],
    );
    return (
        <span onMouseLeave={hoverMenu ? handleMenuClose : undefined}>
            {props.renderTrigger({ getTriggerProps })}
            {props.children({ getMenuProps, focusTrigger, Menu: PopperMenuList, rootMenuOpen: open, handleMenuClose })}
        </span>
    );
};

export default MenuRoot;
