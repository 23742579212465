import React, { useMemo, useContext, useCallback } from 'react';
import { View, ViewField } from 'reducers/ViewConfigType';
import { useFormContext } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import uniq from 'lodash/uniq';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import useViewConfig from 'util/hooks/useViewConfig';
import { getDataTypeForFieldExpr, isFieldViewField } from 'components/generics/utils/viewConfigUtils';
import {
    dataTypeIsManyReference,
    dataTypeIsOneReference,
} from 'components/generics/utils/viewConfigUtils/traversePath';

interface WidgetPickerProps<FieldNameInForm extends string> {
    view: View;
    defaultValue?: string[];
    append?: boolean;
    useSourceInsteadOfId?: boolean;
    fieldNameInForm?: FieldNameInForm;
    appendIdsForUnpersisted?: boolean;
}
const WidgetPicker = <FieldNameInForm extends string>(props: WidgetPickerProps<FieldNameInForm>) => {
    const { fieldNameInForm = 'widget' } = props;
    const { append = true, useSourceInsteadOfId, appendIdsForUnpersisted } = props;
    const { register, unregister, setValue } = useFormContext<{ [key: string]: string[] }>();
    const viewConfig = useViewConfig();
    const getDataType = useCallback(
        (field: ViewField) => {
            if (field.unpersistedEntity) {
                switch (field.widgetType) {
                    case 'MULTISELECT':
                    case 'ENTITY_CHIP':
                    case 'MULTIPLE_ENTITY_TYPEAHEAD':
                        return 'REFMANYMANY';
                    case 'ENTITY_TYPEAHEAD':
                    case 'SELECT':
                        return 'REFONE';
                    default:
                        // inline-many, for example
                        return null;
                }
            }
            if (field.unpersistedValueset) {
                switch (field.widgetType) {
                    case 'CHECKBOX': {
                        if (field.unpersistedIsMany) {
                            return 'VALUESETMANY';
                        }
                        return 'VALUESET';
                    }
                    case 'MULTISELECT':
                        return 'VALUESETMANY';
                    default:
                        return 'VALUESET';
                }
            }
            if (!isFieldViewField(field)) {
                return null;
            }
            try {
                return getDataTypeForFieldExpr(viewConfig, props.view.entity, field.field, 'TRAVERSE_PATH');
            } catch (e) {
                console.error(e);
                return null;
            }
        },
        [viewConfig, props.view.entity],
    );
    const options = useMemo(() => {
        return uniq(
            [
                ...Object.entries(props.view.fields || {}),
                ...Object.values(props.view.tabs || {}).flatMap((tab) => Object.entries(tab.fields)),
            ].map(([_fieldKey, field]) => {
                if (field.overrideId) {
                    return field.overrideId;
                }
                if (field.unpersistedField && !appendIdsForUnpersisted) {
                    return field.unpersistedField;
                }
                const fieldKey = useSourceInsteadOfId && isFieldViewField(field) ? field.field : _fieldKey;
                if (!append) {
                    return fieldKey;
                }
                const dataType = getDataType(field);
                if (dataTypeIsOneReference(dataType)) {
                    return fieldKey + 'Id';
                }
                if (
                    getDataType(field) === 'REFMANY' &&
                    (field.widgetType === 'MULTISELECT' ||
                        field.widgetType === 'MULTI_CARD' ||
                        field.widgetType === 'INLINE_MANY')
                ) {
                    // we aren't operating on 'Ids' here - it's just a list where we add/remove/edit records, not our base data
                    return fieldKey;
                }
                if (dataTypeIsManyReference(dataType)) {
                    return fieldKey + 'Ids';
                }
                return fieldKey;
            }),
        );
    }, [props.view, getDataType, append, useSourceInsteadOfId, appendIdsForUnpersisted]);
    const handleChange = (e, targetName) => {
        setValue(targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    React.useEffect(() => {
        register({ name: fieldNameInForm });
        return () => {
            unregister(fieldNameInForm);
        };
    }, []); // eslint-disable-line

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <Autocomplete
            multiple={true}
            options={options}
            defaultValue={props.defaultValue}
            autoHighlight
            onChange={(e, value) => handleChange(value, fieldNameInForm)}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={fieldVariant as any}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    label="Widget"
                    margin="normal"
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        draggable: false,
                        autoComplete: 'disabled',
                    }}
                />
            )}
        />
    );
};

export default WidgetPicker;
