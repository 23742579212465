import { Button, Card, CardHeader } from '@material-ui/core';
import { Delete, Forward } from '@material-ui/icons';
import TaskFormController from 'bpm/task-form-editor/TaskFormBuilder';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TaskForm } from 'reducers/taskFormType';
import { CasetivityHandledForm, services } from 'sideEffect/services';
import useService from 'util/hooks/useService';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import memoizeOne from 'memoize-one';
import Popup from 'components/Popup';
import DeleteForm from 'bpm-casetivity-managed/Delete/DeleteForm';
import { useStyles } from 'bpm-casetivity-managed/useStyles';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import f from 'f';
import { tap } from 'rxjs/operators';
import FormSaveNotifier from 'formSaveNotifier/components/FormSaveNotifier';

const Edit = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const match = useRouteMatch<{ key: string }>();
    const formKey = match?.params?.key;

    const getFormService = useCallback(() => services.taskFormsDefinitions.get(formKey), [formKey]);
    const [taskFormState, fetchTaskForm] = useService(getFormService);
    useEffect(() => {
        return fetchTaskForm();
    }, [fetchTaskForm]);

    const saveFormService = useCallback(
        (formDefinition: Omit<TaskForm, 'id'>, asNewVersion: boolean) => {
            if (taskFormState.status !== 'success') {
                throw new Error('Unexpected save form when original form not successfully fetched.');
            }
            return services.taskFormsDefinitions
                .update(
                    {
                        ...taskFormState.data,
                        name: formDefinition.name,
                        formRepresentation: {
                            description: formDefinition.description,
                            fields: formDefinition.fields,
                            outcomes: formDefinition.outcomes,
                        },
                    },
                    asNewVersion,
                )
                .pipe(tap(() => fetchTaskForm()));
        },
        [taskFormState, fetchTaskForm],
    );

    const [saveFormState, saveForm, { StateIcon }] = useService(saveFormService);
    const [saveFormAsNewVersionState, saveFormAsNewVersion, { StateIcon: SaveFormAsNewVersionStateIcon }] =
        useService(saveFormService);

    const memoForm = useMemo(
        () =>
            memoizeOne((data: CasetivityHandledForm) => ({
                key: data.key,
                name: data.name,
                description: data.formRepresentation.description,
                fields: data.formRepresentation.fields,
                outcomes: data.formRepresentation.outcomes,
            })),
        [],
    );
    const lastTaskFormSuccess = useRef(taskFormState);
    if (taskFormState.status === 'success') {
        lastTaskFormSuccess.current = taskFormState;
    }
    return lastTaskFormSuccess.current.fold(
        () => null,
        () => null,
        (data) => (
            <div>
                <Card style={{ margin: '1em' }}>
                    <CardHeader
                        titleTypographyProps={{
                            variant: 'h5',
                        }}
                        title="Edit Task Form"
                        action={
                            <div style={{ marginTop: '1em', display: 'flex' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to="/admin/bpm/forms"
                                    startIcon={
                                        <Forward
                                            className={css`
                                                -webkit-transform: scaleX(-1);
                                                transform: scaleX(-1);
                                            `}
                                        />
                                    }
                                >
                                    Back to all forms
                                </Button>
                                <Popup
                                    renderDialogContent={({ closeDialog }) => (
                                        <DeleteForm
                                            onDelete={() => {
                                                closeDialog();
                                                dispatch(push('/admin/bpm/forms'));
                                            }}
                                            onCancel={closeDialog}
                                            formKey={formKey}
                                        />
                                    )}
                                    renderToggler={({ openDialog }) => (
                                        <Button
                                            variant="outlined"
                                            className={classes.error}
                                            style={{ marginLeft: '1em' }}
                                            endIcon={<Delete />}
                                            onClick={openDialog()}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                />
                            </div>
                        }
                    />
                </Card>
                <TaskFormController
                    key={data.lastUpdated}
                    title={<span />}
                    disableKeyField
                    disableNameField
                    hideIdField
                    initialTaskForm={memoForm(data)}
                    renderSaveButton={({ id, ...form }, isDirty) => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>
                                    <Button
                                        endIcon={StateIcon}
                                        variant="contained"
                                        color="primary"
                                        disabled={saveFormState.status === 'pending'}
                                        onClick={() => saveForm(form, false)}
                                    >
                                        Save
                                    </Button>
                                </span>
                                <span style={{ marginLeft: '1em' }}>
                                    <Button
                                        endIcon={SaveFormAsNewVersionStateIcon}
                                        variant="contained"
                                        color="primary"
                                        disabled={saveFormAsNewVersionState.status === 'pending'}
                                        onClick={() => saveFormAsNewVersion(form, true)}
                                    >
                                        Save as new version
                                    </Button>
                                </span>
                            </div>
                            <div style={{ marginLeft: '1em' }}>
                                <p>Last updated {f().instant.of(data.lastUpdated).format('MM/dd/YYYY h:mm a')}</p>
                                {/* Would be nice if last user was expanded in the API */}
                                {/* <p>By {taskFormState.data.lastUpdatedBy}</p> */}
                            </div>
                            <FormSaveNotifier when={isDirty} />
                        </div>
                    )}
                />
            </div>
        ),
        (error) => (
            <div>
                <pre>{(JSON.stringify(error), null, 1)}</pre>
            </div>
        ),
    );
};

export default Edit;
