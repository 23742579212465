import { createChatBotMessage } from 'react-chatbot-kit';
import { Theme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AiChatResponse from './widgets/AiChatResponse';
import ModeSelectorHeader from './components/Header';
import { ChatResponseWrapper } from './components/ResponseBox';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { CASETIVITY_CHAT, REPORT_BUILDER } from './constants';
import InputMessage from './components/InputComponent';
import { ChatState } from './chatbotTypes';

/*
https://github.com/FredrikOseberg/react-chatbot-kit
To add a new mode follow these steps:
    - ensure the correct assistant name and add to constants
    - register either new widget for that assistant or point to AiChatResponse with different assistant and parameters
    - set any outside parameters through ChatContextState
    - depending on assistant we can construct input and additional properties differently
*/

const getInitialMessage = (chatContextState: ChatState): string => {
    switch (chatContextState.assistant) {
        case CASETIVITY_CHAT:
            return 'Hello! Ask me anything about Casetivity!';
        case REPORT_BUILDER:
            return `Ask me about the ${
                chatContextState.reportParameters?.currentReport?.displayName || ''
            } definition.`;
        default:
            return 'Hello! How can I assist you today?';
    }
};

const config = (theme: Theme, chatContextState: ChatState) => ({
    initialMessages: [createChatBotMessage(getInitialMessage(chatContextState), {})],
    botName: 'Casetivity Compass',
    customStyles: {
        userMessageBox: { marginLeft: theme.spacing(1) },
        botMessageBox: { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText },
        chatButton: { backgroundColor: theme.palette.primary.main },
    },
    customComponents: {
        // https://github.com/UrsaMarketing/react-chatbot-kit/tree/feature/add_custom_input
        // react-chatbot-kit does not have inputMessage as a custom component out of the box
        // using this fork that is one commit ahead that has this so that we can use mui multiline TextField
        inputMessage: (props) => <InputMessage {...props} />,
        userAvatar: () => <AccountBoxIcon sx={{ marginLeft: theme.spacing(2), marginTop: theme.spacing(1.5) }} />,
        botAvatar: () => <div />,
        header: () => <ModeSelectorHeader />,
        botChatMessage: (props) => <ChatResponseWrapper {...props}>{props.message} </ChatResponseWrapper>,
    },

    state: {
        threadId: uuidv4(),
    },
    customMessages: {
        appChat: (props) => <AiChatResponse {...props} assistant={chatContextState.assistant} />,
    },
    widgets: [],
});

export default config;
