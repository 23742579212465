import React, { useRef, useEffect, useState, useCallback, useMemo, useReducer } from 'react';
import ReactGridLayout from 'react-grid-layout';
import Clear from '@material-ui/icons/Clear';
import ReactResizeDetector from 'react-resize-detector';
import deepEql from 'deep-eql';
import { correctLayout, compactLeft, removeSpaceBetweenRows } from 'layout-editor/components/correctLayout';
import { IconButton, Button, Typography, Box } from '@material-ui/core';
import layoutReducer, { LayoutState, LayoutStateItem, getNewLayout } from 'layout-editor/demo/layoutReducer';
import DraggableSource from 'layout-editor/demo/DraggableSource';
import GridElement from 'layout-editor/demo/GridElement';
import useEntitiesAreLoading from 'util/hooks/useEntitiesAreLoading';
import { PopupProps } from 'components/Popup';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import DeferredSpinner from 'components/DeferredSpinner';
import WithErrorBoundary from 'components/generics/fields/WithErrorBoundary';
import Undo from '@material-ui/icons/Undo';
import { calculateElementHeight } from './util/calculateElementHeight';

type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export type RenderFieldAdder<Item> = (args: {
    renderToggler: PopupProps['renderToggler'];
    addField: (fieldDefinition: Item) => void;
    initialValues?: Partial<Item>;
}) => JSX.Element;

const DialogLayoutFieldEdit = React.memo(
    <T extends {}>(props: { addField: (fieldDefinition: any) => void; renderFieldAdder: RenderFieldAdder<T> }) => {
        return (
            <div>
                {props.renderFieldAdder({
                    renderToggler: ({ openDialog }) => (
                        <IconButton
                            style={{ zIndex: 1000 }}
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                openDialog()();
                            }}
                        >
                            <Edit />
                        </IconButton>
                    ),
                    addField: (fieldDefinition) => {
                        props.addField(fieldDefinition);
                    },
                })}
            </div>
        );
    },
);

const fixLayout = (_layout) =>
    correctLayout(compactLeft(removeSpaceBetweenRows(_layout.map(({ content, mouseEvent, ...e }) => e))));

// can reuse above
const filterLayoutToData = (layout) =>
    layout &&
    layout.map(
        ({
            content,
            isDraggable,
            isResizable,
            isResizeable,
            maxH,
            maxW,
            minH,
            minW,
            mouseEvent,
            moved,
            static: _static,
            isBounded,
            resizeHandles,
            refreshk,
            ...rest
        }: any) => rest,
    );
const layoutsEqual = (layout1: any[], layout2: any[]) => {
    let l1: any[] = fixLayout(filterLayoutToData(layout1));
    let l2: any[] = fixLayout(filterLayoutToData(layout2));
    const res = deepEql(l1, l2);
    return res;
};

interface MovableGridProps<
    FieldDefinition,
    ItemProps extends {
        'data-originaldefinition': string;
    },
> {
    /**
     * overlapInsert is a mode where elements are only inserted when dropped
     * Because it requires dropping between rows, we insert intermediate rows (halfing row heights),
     * and double element heights.
     */
    overlapInsert?: boolean;
    rowHeight?: number;
    compactType?: 'vertical' | 'horizontal' | null;
    getLayoutFromElements: (
        elems: React.ReactElement<ItemProps>[],
        options: {
            columnStartsAt: 0 | 1;
            MAX_COLS?: number;
        },
    ) => LayoutState;
    refresh?: () => void;
    recalculateHeightKey?: string;
    columnStartsAt: 1 | 0;
    adjustLayoutLeft?: boolean;
    autoCalcHeight?: boolean;
    fields?: React.ReactElement<ItemProps>[];
    onLayoutChange: (args: { layout: LayoutState }) => void;
    createField: (fieldDefinition: FieldDefinition) => React.ReactElement<ItemProps>;
    renderFieldAdder: RenderFieldAdder<FieldDefinition>;
    MAX_COLS?: number;
    getAddToBottomFieldWidth?: (
        FieldElement: React.ReactElement<ItemProps>,
    ) => 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    addFieldLabel?: React.ReactNode;
    newFieldH?: number;
    renderMoveButton?: (item: LayoutStateItem) => JSX.Element;
    gridChangeKey?: number;
}

type HeightCalculatedState = (
    | {
          type: 'calculated';
      }
    | {
          type: 'next_update';
      }
    | {
          type: 'wait_for_entitiesloading_to_reach_0';
      }
) & { timesCalculated: number };
type HeightCalculatedAction = {
    to: HeightCalculatedState['type'];
};
const GenericMovableGrid = React.memo(
    <
        FieldDefinition,
        ItemProps extends {
            'data-originaldefinition': string;
        },
    >(
        props: MovableGridProps<FieldDefinition, ItemProps>,
    ) => {
        const {
            overlapInsert = false,
            getLayoutFromElements,
            adjustLayoutLeft = true,
            autoCalcHeight = true,
            rowHeight = 67,
            compactType = null,
            MAX_COLS = 12,
            getAddToBottomFieldWidth,
            addFieldLabel,
            newFieldH,
            renderMoveButton,
            gridChangeKey,
        } = props;
        const [resizedOnce, setResizedOnce] = useState(true);
        const lastWidth = useRef(0);
        const { onLayoutChange: _onLayoutChange, columnStartsAt, renderFieldAdder, createField } = props;
        const rootDivRef = useRef<HTMLDivElement>();

        const itemRefs = useRef<Array<HTMLDivElement | null>>([]);
        const initialLayout = useMemo(() => {
            return getLayoutFromElements(props.fields, {
                columnStartsAt,
                MAX_COLS,
            });
        }, []); // eslint-disable-line
        // [props.fields, columnStartsAt]);
        const [_layout, dispatch] = useReducer(layoutReducer, initialLayout);
        /**
         * We provide refreshK to force react-grid-layout to rerender when the layout prop changes
         * becuase sometimes state will equal the incoming layout prop getDerivedStateFromProps
         * preventing the rerendering.
         */
        const layout = useMemo(() => {
            const rowIsAlreadyOffset = _layout.length > 0 && Math.min(..._layout.map((l) => l.y)) >= 1;
            return _layout.map((item) => {
                /**
                 * If overlapInsert, and an item occupies row 0, add 1 to all rows.
                 * If an item does not occupy row 0, leave as is.
                 */
                const y = (() => {
                    if (!overlapInsert || rowIsAlreadyOffset) {
                        return item.y;
                    }
                    return item.y + 1;
                })();
                return { ...item, y, refreshk: Math.random() };
            });
        }, [_layout, overlapInsert]);

        const [heightIsCalculatedState, dispatchHeightIsCalculatedState] = useReducer(
            (state: HeightCalculatedState, action: HeightCalculatedAction): HeightCalculatedState => {
                return {
                    type: action.to,
                    timesCalculated: action.to === 'calculated' ? state.timesCalculated + 0 : state.timesCalculated,
                } as HeightCalculatedState;
            },
            {
                type: 'calculated',
                timesCalculated: 0,
            } as HeightCalculatedState,
        );

        // start reset-to-initial
        const prevHeightIsCalculatedStateRef = useRef(heightIsCalculatedState.type);
        const justCalculated =
            prevHeightIsCalculatedStateRef.current !== 'calculated' && heightIsCalculatedState.type === 'calculated';
        prevHeightIsCalculatedStateRef.current = heightIsCalculatedState.type;
        const layoutAfterInitialCalculation = useMemo(() => {
            return layout;
        }, [justCalculated]); // eslint-disable-line

        // a stack of all states, up to and including the current state.
        // This is loaded with the initialState once heights of elements on the page are calculated, and we have an official starting point.
        const undoStack = useRef([]);
        const undo = useCallback(() => {
            undoStack.current.pop();
            const prevLayout = undoStack.current[undoStack.current.length - 1];
            dispatch({
                type: 'newLayout',
                layout: prevLayout,
            });
        }, [dispatch]);
        // end reset-to-initial

        const onLayoutChange = useCallback(
            (args: { layout: LayoutState }) => {
                if (
                    undoStack.current.length > 0 &&
                    !layoutsEqual(undoStack.current[undoStack.current.length - 1], args.layout)
                ) {
                    undoStack.current.push(args.layout);
                }
                _onLayoutChange(args);
            },
            [_onLayoutChange],
        );
        useEffect(() => {
            if (justCalculated) {
                undoStack.current = [layoutAfterInitialCalculation];
            }
        }, [justCalculated, layoutAfterInitialCalculation]);

        const entitiesAreLoading = useEntitiesAreLoading();
        useEffect(() => {
            dispatchHeightIsCalculatedState({
                // cause an update- if the entitiesLoading captured is > 0, we have to delay height calcs until it's 0
                // otherwise we simple calculate heights.
                to: 'next_update',
            });
        }, [props.recalculateHeightKey]);
        useEffect(() => {
            if (heightIsCalculatedState.type === 'next_update') {
                const to = setTimeout(() => {
                    dispatchHeightIsCalculatedState({
                        to: 'wait_for_entitiesloading_to_reach_0',
                    });
                }, 100);
                return () => {
                    clearTimeout(to);
                };
            }
        }, [heightIsCalculatedState.type, dispatchHeightIsCalculatedState]);

        useEffect(() => {
            if (heightIsCalculatedState.type === 'wait_for_entitiesloading_to_reach_0' && !entitiesAreLoading) {
                dispatchHeightIsCalculatedState({
                    to: 'calculated',
                });
                if (autoCalcHeight) {
                    dispatch({
                        type: 'transformLayout',
                        transform: (layout) => {
                            // need to adjust 'y's for all elements
                            const l = layout.map((l) => {
                                const itemElem = itemRefs.current[parseInt(l.i, 10)];
                                if (!itemElem) {
                                    // could be abandoned update, e.g. we switched tabs before the timeout
                                    return l;
                                }
                                let h = calculateElementHeight(itemElem, false);

                                const compoundWidgetTypes = new Set(['BPM_FORM_BUILDER']);

                                const fieldDefinition = JSON.parse(l?.['data-originaldefinition'] ?? null);
                                if (compoundWidgetTypes.has(fieldDefinition?.widgetType)) {
                                    // If we are using a compound field then lets recalculate h including children
                                    h = calculateElementHeight(itemElem, true);
                                }

                                return {
                                    ...l,
                                    // when doing overlapInsert, we double rows so there's an insertion row, so heights need to double
                                    h: overlapInsert ? h * 2 : h,
                                    isResizeable: false,
                                };
                            });
                            // now push everything down.
                            // get all rows and elements per row
                            /*
                                for each row, figure out tallest element,
                                figure out starting point of next 'y'
                                figure out next 'y'
                                add the difference between the two to all'y's after the current row.
                            */
                            let correctedLayout = correctLayout(l.map(({ content, ...e }) => e)).map((e) => ({
                                ...e,
                                content: l.find((le) => le.i === e.i)?.content,
                            }));
                            return correctedLayout;
                        },
                    });
                }
            }
        }, [heightIsCalculatedState.type, entitiesAreLoading, autoCalcHeight, overlapInsert]);

        useEffect(() => {
            dispatch({
                type: 'newLayout',
                layout: getLayoutFromElements(props.fields, { columnStartsAt, MAX_COLS }),
            });
            dispatchHeightIsCalculatedState({
                to: 'next_update',
            });
        }, [gridChangeKey]); //eslint-disable-line
        // Lets dispatch newLayout when view changes via MoveFieldDialog

        const setLayoutHandler = useCallback(
            (_layout: LayoutState) => {
                const domStuff = _layout.reduce(
                    (prev, curr) => {
                        prev[curr.i] = {
                            content: curr.content,
                            mouseEvent: curr.mouseEvent,
                        };
                        return prev;
                    },
                    {} as {
                        [i: string]: Pick<LayoutStateItem, 'content' | 'mouseEvent'>;
                    },
                );
                type ReducedItem = Omit<LayoutStateItem, 'content' | 'mouseEvent'>;
                if (adjustLayoutLeft) {
                    const correctedLayout: LayoutState = fixLayout(_layout).map((e) => ({
                        ...e,
                        ...domStuff[e.i],
                    })) as LayoutState;
                    if (
                        !deepEql(
                            correctedLayout.map(({ content, mouseEvent, ...e }) => e),
                            layout.map(({ content, mouseEvent, ...e }) => e),
                        )
                    ) {
                        const newLayout = getNewLayout(layout, correctedLayout);
                        onLayoutChange({ layout: newLayout });
                        dispatch({
                            type: 'newLayout',
                            layout: newLayout,
                        });
                    }
                } else {
                    if (
                        !deepEql(
                            _layout.map(({ content, mouseEvent, ...e }) => e),
                            layout.map(({ content, mouseEvent, ...e }) => e),
                        )
                    ) {
                        const newLayout = getNewLayout(layout, _layout);
                        onLayoutChange({ layout: newLayout });
                        dispatch({
                            type: 'newLayout',
                            layout: newLayout,
                        });
                    }
                }
            },
            [adjustLayoutLeft, layout, dispatch, onLayoutChange],
        );
        const fieldElements = useMemo(() => {
            return layout.map((item) => {
                const initialValues = JSON.parse(item['data-originaldefinition']);
                const errorMessageNotes = `Field: ${initialValues.field}. See full definition below.`;
                const errorMessageCollapsedNotes = JSON.stringify(initialValues, null, 1);
                return (
                    <GridElement
                        key={'' + item.i}
                        {...item}
                        style={{ minHeight: rowHeight, minWidth: 67, height: '100%' }}
                    >
                        <WithErrorBoundary notes={errorMessageNotes} collapsedNotes={errorMessageCollapsedNotes}>
                            <div
                                style={{ position: 'relative', height: '100%', minHeight: rowHeight, minWidth: 67 }}
                                ref={(ref) => {
                                    itemRefs.current.push(ref);
                                }}
                            >
                                <WithErrorBoundary
                                    notes={errorMessageNotes}
                                    collapsedNotes={errorMessageCollapsedNotes}
                                >
                                    <span
                                        onClickCapture={(e) => {
                                            // Lets use onClickCapture to stop element from being clickable while we are drag and dropping
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                    >
                                        {item.content}
                                    </span>
                                </WithErrorBoundary>
                                <span
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    style={{ position: 'absolute', top: 0, right: 50 }}
                                >
                                    {renderMoveButton && renderMoveButton(item)}
                                </span>
                                {/* pass renderFieldAddr (or a variation) to a component which has dialog state controlled. Like 'GridElementActionsPopup' */}
                                <span
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    style={{ position: 'absolute', top: 0, right: 24 }}
                                >
                                    <DialogLayoutFieldEdit
                                        addField={(fieldDefinition) => {
                                            const newLayout = layout.map(
                                                (e): LayoutStateItem =>
                                                    e.i === item.i
                                                        ? {
                                                              ...e,
                                                              'data-originaldefinition':
                                                                  JSON.stringify(fieldDefinition),
                                                              // todo: test when 'appCases' is selected
                                                              // or was it documents? one of those made it crash
                                                              content: createField(fieldDefinition),
                                                          }
                                                        : e,
                                            );
                                            dispatch({
                                                type: 'newLayout',
                                                layout: newLayout,
                                            });
                                            onLayoutChange({ layout: newLayout });
                                        }}
                                        renderFieldAdder={({ addField, renderToggler }) =>
                                            renderFieldAdder({
                                                addField,
                                                renderToggler,
                                                initialValues,
                                            })
                                        }
                                    />
                                </span>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setLayoutHandler(layout.filter((e) => e.i !== item.i));
                                        // dispatch({
                                        //     type: 'transformLayout',
                                        //     transform: layout => layout.filter(e => e.i !== item.i),
                                        // });
                                    }}
                                    style={{ position: 'absolute', top: 0, right: 0, zIndex: 400 }}
                                >
                                    <Clear />
                                </IconButton>
                            </div>
                        </WithErrorBoundary>
                    </GridElement>
                );
            });
        }, [layout, createField, setLayoutHandler, renderFieldAdder, onLayoutChange, rowHeight, renderMoveButton]);

        const [fieldToAdd, setFieldToAdd] = useState<(FieldDefinition & { widgetType?: string }) | null>();

        const onDragStop = (
            layout: LayoutState,
            oldItem: LayoutStateItem,
            newItem: LayoutStateItem,
            placeholder: LayoutStateItem,
            e: MouseEvent,
            element: HTMLElement,
        ) => {
            const itemsOnSameRow = layout.filter((item) => item.y === newItem.y && item.i !== newItem.i);
            const itemsToPushRight = itemsOnSameRow.filter((item) => {
                return item.x >= newItem.x;
            });
            const itemsToPushRightObj = itemsToPushRight.reduce((prev, curr) => {
                prev[curr.i] = true;
                return prev;
            }, {} as { [i: string]: true });

            const leftElems = itemsOnSameRow.filter((item) => item.x < newItem.x);
            const leftMostInsertionPoint = Math.max(0, ...leftElems.map((el) => el.x + el.w));

            if (itemsToPushRight.length > 0) {
                const amountToPushRightBy =
                    leftMostInsertionPoint + newItem.w - Math.min(...itemsToPushRight.map((item) => item.x));
                const newLayout = layout.map((item) => {
                    if (itemsToPushRightObj[item.i]) {
                        return { ...item, x: item.x + amountToPushRightBy };
                    }
                    if (item.i === newItem.i) {
                        return { ...item, x: leftMostInsertionPoint };
                    }
                    return item;
                });
                setLayoutHandler(newLayout);
            } else if (leftMostInsertionPoint > newItem.x) {
                setLayoutHandler(
                    layout.map((item) => (item.i === newItem.i ? { ...newItem, x: leftMostInsertionPoint } : item)),
                );
            } else {
                setLayoutHandler(layout);
            }
        };

        if (props.recalculateHeightKey === 'false') {
            return null;
        }
        return (
            <div
                style={{
                    position: 'relative',
                    overflowX: 'auto',
                    overflowY: autoCalcHeight ? 'visible' : 'hidden',
                }}
            >
                {heightIsCalculatedState.type === 'next_update' ||
                heightIsCalculatedState.type === 'wait_for_entitiesloading_to_reach_0' ||
                !resizedOnce ? (
                    <div
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            zIndex: 99999999,
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                        }}
                    >
                        <DeferredSpinner />
                    </div>
                ) : null}
                {!fieldToAdd ? (
                    <div
                        style={{
                            padding: '6px',
                            marginBottom: '20px',
                            marginRight: '10px',
                        }}
                    >
                        {props.renderFieldAdder({
                            renderToggler: ({ openDialog }) => {
                                return (
                                    <div>
                                        {undoStack.current.length > 1 && (
                                            <IconButton size="small" onClick={undo} aria-label="Undo">
                                                <Undo />
                                            </IconButton>
                                        )}
                                        &nbsp;&nbsp;
                                        <Button
                                            aria-label="Add field"
                                            size="small"
                                            endIcon={addFieldLabel && <Add />}
                                            color="primary"
                                            variant="contained"
                                            onClick={openDialog()}
                                        >
                                            {addFieldLabel || <Add />}
                                        </Button>
                                    </div>
                                );
                            },
                            addField: (f) => {
                                setFieldToAdd(f);
                            },
                        })}
                    </div>
                ) : (
                    <div>
                        <div style={{ border: '1px dashed black' }}>
                            <div style={{ padding: '.5em' }}>
                                <span>Drag and drop the field into the layout below</span>
                            </div>
                            <WithErrorBoundary>
                                <div style={{ cursor: 'pointer' }}>
                                    <DraggableSource
                                        targetRef={rootDivRef}
                                        dispatch={(action) => {
                                            if (action.type === 'finaliseTemporaryItem') {
                                                setFieldToAdd(null);
                                            }
                                            if (action.type === 'addTemp') {
                                                dispatch(overlapInsert ? { ...action, h: newFieldH ?? 2 } : action);
                                                return;
                                            }
                                            dispatch(action);
                                        }}
                                        key="draggableitem"
                                    >
                                        <div
                                            style={{ padding: '10px' }}
                                            onClickCapture={(e) => {
                                                // Lets use onClickCapture to stop element onclick while it is in waiting area
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                        >
                                            {props.createField(fieldToAdd)}
                                        </div>
                                    </DraggableSource>
                                </div>
                            </WithErrorBoundary>
                            <div style={{ padding: '.5em' }}>
                                <Button size="small" variant="contained" onClick={() => setFieldToAdd(null)}>
                                    Clear&nbsp;
                                    <Clear fontSize="small" />
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        setFieldToAdd(null);
                                        const getNewLayout = (layout: LayoutState) => {
                                            const max = layout.reduce((prev, curr) => {
                                                return Math.max(prev, curr.y + curr.h);
                                            }, 0);

                                            const element = props.createField(fieldToAdd);
                                            const definition = element.props['data-originaldefinition'];
                                            const w = getAddToBottomFieldWidth?.(element) ?? 4;

                                            const h = (() => {
                                                if (fieldToAdd?.widgetType === 'BPM_FORM_BUILDER') {
                                                    // This is an inexact measurement
                                                    return overlapInsert ? 10 : 6;
                                                }
                                                const _h = newFieldH ?? 1;
                                                if (overlapInsert) {
                                                    return _h * 2;
                                                }
                                                return _h;
                                            })();
                                            const i = String(
                                                Math.max(
                                                    layout.length - 1,
                                                    ...layout.map((item) => parseInt(item.i)).filter((n) => !isNaN(n)),
                                                ) + 1,
                                            );
                                            // We are accounting for the overlapInsert doubled rows by checking the height in max
                                            const y = max;

                                            return [
                                                ...layout,
                                                {
                                                    i,
                                                    x: 0,
                                                    y,
                                                    w,
                                                    // when doing overlapInsert, heights are doubled
                                                    h,
                                                    content: element,
                                                    'data-originaldefinition': definition,
                                                },
                                            ];
                                        };
                                        const newLayout = getNewLayout(layout);
                                        dispatch({
                                            type: 'transformLayout',
                                            transform: getNewLayout,
                                        });
                                        onLayoutChange({ layout: newLayout });
                                    }}
                                >
                                    Add to bottom
                                </Button>
                            </div>
                        </div>
                        <br />
                    </div>
                )}
                <div ref={rootDivRef} style={{ width: '100%' }}>
                    <ReactResizeDetector
                        handleWidth
                        onResize={(x, y) => {
                            if (x) {
                                lastWidth.current = x;
                                setResizedOnce(true);
                            }
                        }}
                    >
                        {({ width }) => (
                            <div
                                style={{
                                    position: 'relative',
                                    width: MAX_COLS === 24 ? '200%' : '100%',
                                    height: '100%',
                                }}
                            >
                                {MAX_COLS === 24 ? (
                                    <div
                                        style={{
                                            background: `repeating-linear-gradient(
                                            -55deg,
                                            rgba(0,0,0,0.05),
                                            rgba(0,0,0,0.05) 10px,
                                            rgba(0,0,0,0) 10px,
                                            rgba(0,0,0,0) 20px
                                        )`,
                                            right: 0,
                                            bottom: 0,
                                            paddingTop: '1em',
                                            position: 'absolute',
                                            width: '50%',
                                            height: '100%',
                                            display: 'grid',
                                            placeItems: 'center',
                                        }}
                                    >
                                        <Typography variant="h3">
                                            <Box fontStyle="italic" style={{ opacity: '.5' }} m={1}>
                                                OVERFLOW
                                            </Box>
                                        </Typography>
                                    </div>
                                ) : null}
                                <ReactGridLayout
                                    width={
                                        (width || lastWidth.current || window.innerWidth - 275) *
                                        (MAX_COLS === 24 ? 2 : 1)
                                    }
                                    compactType={compactType}
                                    allowOverlap={overlapInsert}
                                    useCSSTransforms={true}
                                    cols={MAX_COLS}
                                    // overlapInsert requires half-height rows so there's an insertion row
                                    rowHeight={overlapInsert ? rowHeight / 2 : rowHeight}
                                    className="layout"
                                    style={{ minHeight: 100, minWidth: 500 }}
                                    layout={layout}
                                    margin={[5, 10]}
                                    onDragStop={overlapInsert ? onDragStop : undefined}
                                    onResizeStop={overlapInsert ? onDragStop : undefined}
                                    onLayoutChange={overlapInsert ? undefined : setLayoutHandler}
                                >
                                    {fieldElements}
                                </ReactGridLayout>
                            </div>
                        )}
                    </ReactResizeDetector>
                </div>
            </div>
        );
    },
    (prevProps, props) => props.recalculateHeightKey === 'false',
);
export default GenericMovableGrid;
