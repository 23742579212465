import React, { useCallback, useMemo, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import uniqueId from 'lodash/uniqueId';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import useTextFieldUtils from '../hooks/useTextFieldUtils';
import renderTextFieldLabel from 'fieldFactory/util/renderTextFieldLabel';
import TextInput from './DebouncedTextInput';

interface NumberInputProps {
    input: Pick<WrappedFieldInputProps, 'onBlur' | 'value' | 'onChange'>;
    isInteger: boolean;
    elStyle?: {};
    isRequired?: boolean;
    label: string;
    meta: Partial<WrappedFieldMetaProps>;
    options?: {};
    source: string;
    resource?: string;
    disabled?: boolean;
    className?: string;
    renderLabel?: boolean;
    ariaInputProps?: {};
    overrideAriaLabel?: string;
    negativeAllowed?: boolean;
}
const NumberFormatInteger = (props) => {
    const { inputRef, onChange, negativeAllowed, ...other } = props;
    return (
        <NumericFormat
            {...other}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            getInputRef={inputRef}
            decimalScale={0}
            allowNegative={negativeAllowed ?? false}
            step={1}
            isAllowed={(values) => {
                const { floatValue, formattedValue } = values;
                return (
                    formattedValue === '' ||
                    formattedValue === '-' ||
                    (floatValue >= Number.MIN_SAFE_INTEGER && floatValue <= Number.MAX_SAFE_INTEGER)
                );
            }}
        />
    );
};

const NumberFormatFloat = (props) => {
    const { inputRef, onChange, negativeAllowed, ...other } = props;
    return (
        <NumericFormat
            {...other}
            allowNegative={negativeAllowed}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            getInputRef={inputRef}
        />
    );
};

export const NumberInput: React.FC<NumberInputProps> = ({
    elStyle,
    input,
    label,
    meta,
    options,
    source,
    resource,
    isRequired,
    disabled,
    className,
    ariaInputProps = {},
    renderLabel = true,
    input: { onChange, onBlur },
    isInteger,
    overrideAriaLabel,
    negativeAllowed,
}) => {
    const { touched, error } = meta;
    const {
        InputPropsClasses,
        createInputLabelProps,
        createFormHelperTextProps,
        muiErrorProp,
        helperText,
        fieldVariant,
    } = useTextFieldUtils(meta as WrappedFieldMetaProps);
    const errorMessageId = useMemo(() => uniqueId('numberinput'), []);

    const parseValue = useCallback(
        (inputValue: string) => {
            const value = isInteger ? parseInt(inputValue, 10) : parseFloat(inputValue);
            return isNaN(value) ? null : value;
        },
        [isInteger],
    );

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const v = parseValue(e.target.value);
            onChange(v);
        },
        [onChange, parseValue],
    );
    const handleBlur = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const v = parseValue(e.target.value);
            onChange(v);
            onBlur(v);
        },
        [onBlur, parseValue, onChange],
    );
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.which === 13) {
                const value = parseValue(inputRef.current?.value);
                onChange(value);
                onBlur(value);
            }
        },
        [onBlur, onChange, parseValue],
    );
    const InputProps = useMemo(() => {
        return touched && error ? { 'aria-errormessage': errorMessageId } : undefined;
    }, [touched, error, errorMessageId]);

    const inputProps = {
        ...ariaInputProps,
        ...InputProps,
        // removed the below for https://strategicsolutionsgroup.atlassian.net/browse/VEA-1302
        // title: `Allows only ${isInteger ? 'integer' : 'floating point'} values`,
    };
    if (overrideAriaLabel) {
        inputProps['aria-label'] = overrideAriaLabel;
    }

    const value = useMemo(() => '' + (input.value ?? ''), [input.value]);
    return (
        <TextField
            {...input}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            InputLabelProps={createInputLabelProps({
                shrink: true,
            })}
            variant={fieldVariant}
            className={className}
            fullWidth={true}
            margin="none"
            label={renderTextFieldLabel(fieldVariant, renderLabel, isRequired, label)}
            InputProps={{
                inputComponent: isInteger ? NumberFormatInteger : NumberFormatFloat,
                classes: InputPropsClasses,
                inputProps: {
                    negativeAllowed,
                    ...inputProps,
                },
            }}
            error={muiErrorProp}
            helperText={helperText}
            style={elStyle}
            disabled={disabled}
            FormHelperTextProps={createFormHelperTextProps(InputProps)}
            {...options}
        />
    );
};

export const DebouncedNumberInput: React.FC<NumberInputProps & { debounceTime?: number }> = ({
    debounceTime = 250,
    ...numberInputProps
}) => {
    const renderNumberInput = useCallback(
        ({ onChange, onBlur, value }) => {
            return (
                <NumberInput
                    {...numberInputProps}
                    input={{
                        ...numberInputProps.input,
                        onChange,
                        onBlur,
                        value,
                    }}
                />
            );
        },
        [numberInputProps],
    );

    return (
        <TextInput
            debounceTime={debounceTime}
            emptyInitialValue={null}
            input={{
                onChange: numberInputProps.input.onChange,
                onBlur: numberInputProps.input.onBlur,
                value: numberInputProps.input.value,
            }}
            renderInput={renderNumberInput}
        />
    );
};

export default DebouncedNumberInput;
