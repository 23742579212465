import React, { useMemo, useState } from 'react';
import { stringify } from 'query-string';
import GenericList from 'components/generics/genericList';
import useViewConfig from 'util/hooks/useViewConfig';
import { useEvaluateTemplateInFormContext } from 'expressions/hooks/allForms/useEvaluateTemplate';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';

const useListLocation = (resource: string, filter?: {}) => {
    const [location, setLocation] = useState({
        pathname: `/${resource}`,
        search: `?${filter ? stringify({ filter: JSON.stringify(filter) }) : ''}`,
    });
    return [location, setLocation] as const;
};

const ListView: React.SFC<{
    viewName: string;
    noClick?: boolean;
    showActions?: boolean;
    filterExpression?: string;
}> = ({ viewName, noClick, showActions = false, filterExpression = '' }) => {
    const viewConfig = useViewConfig();
    const resource = viewConfig && viewConfig.views[viewName] && viewConfig.views[viewName].entity;

    const templatedFilter = useEvaluateTemplateInFormContext(filterExpression);
    const filter = useMemo(() => getFilterFromFilterString(templatedFilter), [templatedFilter]);
    const [location, setLocation] = useListLocation(resource, filter);
    return (
        <GenericList
            filter={filter}
            noClick={noClick}
            useCard={false}
            cancelRequestOnRouteChange={true}
            hasCreate={showActions}
            renderActions={showActions ? undefined : () => null}
            showFilters={true}
            updateUrlFromFilter={false}
            fakePush={setLocation}
            location={location}
            isPopover={false}
            title={showActions ? ' ' : null}
            resource={resource}
            viewName={viewName}
        />
    );
};
export default ListView;
