import * as React from 'react';
import ProcessPage from './ProcessPage';
import SsgAppBarMobile from '../../../components/SsgAppBarMobile';
import { processContext } from '../processContext';
import { GetComponentProps } from 'util/typeUtils';
import { Subtract } from 'utility-types';
import WithCaseTitle from './WithCaseTitle';
import { RootState, useAppSelector } from 'reducers/rootReducer';

type ProviderProps = GetComponentProps<typeof processContext.Provider>;
interface ProcessContextProviderPropsValue
    extends Subtract<ProviderProps['value'], Pick<ProviderProps['value'], 'refresh'>> {}
interface ProcessContextProviderProps {
    value: ProcessContextProviderPropsValue;
}
interface ProcessContextProviderState {
    key: number;
}
class ProcessContextProvider extends React.Component<ProcessContextProviderProps, ProcessContextProviderState> {
    state: ProcessContextProviderState = { key: 1 };
    refresh = () => {
        this.setState((state) => ({
            key: state.key + 1,
        }));
    };
    render() {
        const { value, children } = this.props;
        return (
            <processContext.Provider
                key={this.state.key}
                value={{
                    ...value,
                    refresh: this.refresh,
                }}
            >
                {children}
            </processContext.Provider>
        );
    }
}
const ProcessView: React.FC<{
    title?: string;
    process: string;
    task?: string | null;
    showAppBar?: boolean;
    showLinkedEntity?: boolean;
    readOnly?: boolean; // this means no buttons to trigger actions or redirects. For popover display
    queryParams?: {};
}> = ({ title, process, task, showAppBar = true, showLinkedEntity = true, readOnly = false, queryParams }) => {
    const printMode = useAppSelector((state: RootState) => state.printMode);
    return (
        <div>
            {showAppBar && !printMode && (
                <WithCaseTitle processId={process}>{({ title }) => <SsgAppBarMobile title={title} />}</WithCaseTitle>
            )}
            <ProcessContextProvider value={{ id: process, taskId: task || undefined }}>
                <ProcessPage
                    queryParams={queryParams}
                    taskId={task} // passed for Task form lookup, to generate form-linked fields in the GenericEdit.
                    processId={process}
                    readOnly={readOnly}
                    goldenRatio={!task}
                    showLinkedEntity={showLinkedEntity}
                />
            </ProcessContextProvider>
        </div>
    );
};

ProcessView.defaultProps = {
    task: null,
};

export default ProcessView;
