import React from 'react';
import { NetworkUnavailable, ServerError } from 'remoteStatus/one/components/pages';
import DeferredSpinner from 'components/DeferredSpinner';
import ExperimentalReportEditor from './ExperimentalReportEditor';
import useReportDefinition from 'report2/hooks/useReport';
import ReportDefinition from './ReportDefinition';
import { ReportDefinitionStateItem } from 'report2/hooks/ReportDefinitionTypes';
import { ReportDefinition as ReportWithDTO } from 'report2/ReportDefinition';
interface ReportProps {
    reportName: string;
}

export const renderReportForm = (def: ReportWithDTO, hideAvailableOutputs?: boolean) => {
    if (!def?.reportDefinitionDTO) {
        throw new Error('Successful request should return data with reportDefinitionDTO property.');
    }
    const reportDefinition: ReportDefinition = {
        ...def.reportDefinitionDTO,
        fields: def.params,
        config: def.reportDefinitionDTO.config ? JSON.parse(def.reportDefinitionDTO.config) : null,
    };
    return (
        <ExperimentalReportEditor
            initialReportDefinition={reportDefinition}
            hideAvailableOutputs={hideAvailableOutputs}
        />
    );
};

const RenderReportState = (state: ReportDefinitionStateItem, refresh: () => void) => {
    switch (state.type) {
        case 'INITIAL':
            return null;
        case 'LOADING':
            return <DeferredSpinner />;
        case 'FAILURE':
            if (state.error?.status) {
                return <ServerError code={state.error.status} message={state.error.message} />;
            }
            return <NetworkUnavailable retry={refresh} />;
        case 'SUCCESS':
            return renderReportForm(state.data);
    }
};

const Report: React.FC<ReportProps> = ({ reportName }) => {
    const [state, refresh] = useReportDefinition(reportName);
    return RenderReportState(state, refresh);
};

export default Report;
