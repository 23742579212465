import { useCallback, useRef, useState } from 'react';

export const useResizable = (initialSize) => {
    const initialSizeRef = useRef(initialSize);
    const [size, setSize] = useState(initialSize);
    const [isExpanded, setIsExpanded] = useState(false);

    const startResizing = useCallback(
        (mouseDownEvent) => {
            const startX = mouseDownEvent.clientX;
            const startY = mouseDownEvent.clientY;
            const startWidth = size.width;
            const startHeight = size.height;
            const viewportHeight = window.innerHeight;
            const maxAvailableHeight = viewportHeight - 90;
            const viewportWidth = window.innerWidth;
            const maxAvailableWidth = viewportWidth - 50;

            const doResize = (mouseMoveEvent) => {
                const currentX = mouseMoveEvent.clientX;
                const currentY = mouseMoveEvent.clientY;
                const widthDiff = startX - currentX;
                const heightDiff = startY - currentY;
                setSize({
                    width: Math.max(100, Math.min(startWidth + widthDiff, maxAvailableWidth)),
                    height: Math.max(200, Math.min(startHeight + heightDiff, maxAvailableHeight)),
                });
            };

            const stopResizing = () => {
                window.removeEventListener('mousemove', doResize);
                window.removeEventListener('mouseup', stopResizing);
            };

            window.addEventListener('mousemove', doResize);
            window.addEventListener('mouseup', stopResizing);
        },
        [size],
    );

    const toggleSize = useCallback(() => {
        if (isExpanded) {
            setSize(initialSizeRef.current);
        } else {
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;
            setSize({
                width: Math.min(viewportWidth * 0.8, viewportWidth - 50),
                height: Math.min(viewportHeight * 0.9, viewportHeight - 40),
            });
        }
        setIsExpanded((prev) => !prev);
    }, [isExpanded]);

    return { size, toggleSize, startResizing };
};
