import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { push as pushAction } from 'connected-react-router';
import { RootState } from '../../../../src/reducers/rootReducer';
import { Dispatch, AnyAction } from 'redux';
import { ArgumentTypes } from '../../../../src/util/typeUtils';
import Message from 'i18n/components/Message';

export interface BackToProps {}
const makeMapStateToProps = () => {
    // create any caches/selectors in this scope
    const mapStateToProps = (state: RootState, props: BackToProps) => {
        return {
            lastLoc: state.lastSearchOrProcessPage,
            isNotInPopup: state.viewStack.length === 0,
            currentLoc: state.router.location,
        };
    };
    return mapStateToProps;
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: BackToProps) => ({
    go: (loc: string) => dispatch(pushAction(loc)),
});
type ActionCreatorProps = {
    [key in keyof ReturnType<typeof mapDispatchToProps>]: (
        ...args: ArgumentTypes<ReturnType<typeof mapDispatchToProps>[key]>
    ) => void;
};
interface BackToComponentProps
    extends ReturnType<ReturnType<typeof makeMapStateToProps>>,
        ActionCreatorProps,
        BackToProps {}

class BackToComponent extends React.Component<BackToComponentProps> {
    changeLocation = () => {
        const { go, lastLoc } = this.props;
        if (lastLoc) {
            if (lastLoc.type === 'PROCESS') {
                if (lastLoc.taskId) {
                    go(`/processes/${lastLoc.processId}/tasks/${lastLoc.taskId}/start`);
                } else {
                    go(`/processes/${lastLoc.processId}`);
                }
            } else {
                go(`${lastLoc.pathname}${lastLoc.search}`);
            }
        }
    };
    isCurrentLocation = () => {
        const { currentLoc, lastLoc } = this.props;
        if (lastLoc && lastLoc.type === 'PROCESS') {
            return currentLoc.pathname.startsWith(`/processes/${lastLoc.processId}`);
        }
        return false;
    };
    render() {
        const { lastLoc, isNotInPopup } = this.props;
        if (lastLoc && isNotInPopup && !this.isCurrentLocation()) {
            const label =
                lastLoc.type === 'PROCESS'
                    ? (() => {
                          if (lastLoc.taskId) {
                              return 'Back To Task';
                          }
                          return <Message id="processes.backToCase" dm="Back to Case" />;
                      })()
                    : `Back To Search`;
            return (
                <Button
                    variant="text"
                    color="primary"
                    onClick={this.changeLocation}
                    sx={{ textTransform: 'capitalize', zIndex: 5 }}
                >
                    {label}
                </Button>
            );
        }
        return null;
    }
}

const BackTo: React.SFC<BackToProps> = compose(connect(makeMapStateToProps, mapDispatchToProps))(BackToComponent);

export default BackTo;
